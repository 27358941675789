import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import Select from 'react-select';
import { ForgotPassword } from '../../redux/services/ForgotPassword';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setChangedNumber,reSetToken } from '../../redux/features/authSlice';
import { getCountry } from '../../redux/services/common';
import CountryCode from '../../components/Common/CountryCode'
const ForgetPassword = () => {
    const CountryData = useSelector(state => state?.common?.CountryData);
    const { register, handleSubmit, setValue, getValues, formState: { errors }, reset } = useForm();
    const [showNumber, setShowNumber] = useState(true);
    const [showCountry, setShowCountry] = useState(false);
    const [errorMsg, setShowErrorMsg] = useState('')
    const [countryCode, setCountryCode] = useState('')
    const handleRadioChange = (e) => {
        const value = e.target.value === 'text'; // Assuming value attribute of radio is set to 'text' or 'email'
        setShowNumber(value);
        reset();
    };
    const dispatch = useDispatch();
    const navigate = useNavigate();
    //country api
    useEffect(() => {
        dispatch(getCountry());
    }, [])

    const onSubmit = async () => {
        const number = getValues('phone')
        const country_code = getValues('country_code')       
        let form;
        let apiCall = true
        if (showNumber) {
            if(!country_code){
                setShowCountry(true)
                apiCall= false
            }else{
                form = {
    
                    'email': getValues('phone'),
                    'country_code': getValues('country_code')
                }
            }
        } else {
            form = {
                'email': getValues('email')
            }
        }        
        if(apiCall){
            try {
                const response = await dispatch(ForgotPassword(form));                
                if (response?.payload?.status === false) {
                    setShowErrorMsg(response?.payload?.message);
                    setTimeout(() => {
                        setShowErrorMsg('')
                    }, 1000);
                } else {
                    dispatch(reSetToken())
                    dispatch(setChangedNumber({ 'phone': number, 'url_token': response?.payload?.data?.url_token, 'country_code':response?.payload?.data?.country_code, form: form }))
                    showNumber ? navigate('/reset-password-with-text') :
                        reset();
                }
            } catch (error) {
                // Handle error if dispatch fails
                console.error("Error during signup:", error);
            }
        }
    }


    const inputChangeHandler = async (e) => {
        const { name, value } = e.target;
        setCountryCode(value)
        setValue('country_code', value);
        setShowCountry(false)
    };

    return (
        <div className="first-time-login-page">
            <section className="ftl-sec">
                <div className="ftl-inner container">
                    <div className="sap-card">
                        <div className="sap-card-dialog">
                            {/* <HomeNavigate /> */}
                            <div className="sap-card-body">
                                <div className="alert alert-light sap-alert-heading">
                                    <div className="hd-title font-30">Forgot Password</div>
                                    <div className="hd-subtitle font-16"></div>
                                </div>
                                <div className='check-sec'>
                                    <div className="hd-title font-18">Choose your Options</div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" value="email"
                                            checked={!showNumber}
                                            onChange={handleRadioChange} />
                                        <label class="form-check-label" for="flexRadioDefault1">
                                            Email
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2"
                                            value="text"
                                            checked={showNumber} // Show 'Email' radio checked if showNumber is false
                                            onChange={handleRadioChange} />
                                        <label class="form-check-label" for="flexRadioDefault2">
                                            Text
                                        </label>
                                    </div>
                                </div>
                                <form onSubmit={handleSubmit(onSubmit)} className="first-time-login-form row" >
                                    {
                                        showNumber ?
                                            <>
                                                <div className="col-md-12 mb-3">
                                                    <label for="" className="form-label">Country-code*</label>
                                                    <div className='flag_main'>
                                                    <CountryCode CountryData={CountryData} country={countryCode} onChange={inputChangeHandler} name='country'/>
                                                        <input type="input" className="form-control" id="" onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault(); } }} maxLength={15}
                                                            placeholder="Phone number*" {...register('phone', {
                                                                required: "true", pattern: {
                                                                    value: /^\d{10,15}$/,
                                                                    message: 'Phone number must be 10 to 15 digits'
                                                                }
                                                            })} />
                                                    </div>
                                                    {showCountry && <span className="error">Country code is required!</span>}
                                                    {errors.phone && errors.phone.type === "required" && <span className="error">Phone number is required!</span>}
                                                    {errors.phone && errors.phone.type === "pattern" && <span className="error">{errors?.phone?.message}</span>}
                                                    <span className='error'>{errorMsg}</span>
                                                </div>
                                            </>
                                            :
                                            <div className="col-md-12 mb-3">
                                                <label htmlFor="" className="form-label">Email*</label>
                                                <input type="input" className="form-control" id="" placeholder="Email" {...register('email', {
                                                    required: 'true',
                                                    pattern: {
                                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                        message: 'Invalid email address'
                                                    }
                                                })} />
                                                {errors.email && errors.email.type === "required" && <span className="error">Email is required</span>}
                                                {errors.email && errors.email.type === "pattern" && <span className="error">{errors?.email?.message}</span>}
                                                <span className='error'>{errorMsg}</span>
                                            </div>
                                    }

                                    <div className="col-md-6 login-btn">
                                        <button type="submit" className="btn btn-primary">Submit</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default ForgetPassword;
