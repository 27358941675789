import React, { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form';
import { getCountry, getState } from '../../../redux/services/common';
import { useDispatch, useSelector } from 'react-redux';
import { Update_general_detail } from '../../../redux/services/AuthSettings';
import { useNavigate } from 'react-router-dom';
import { Send_Otp_Change_Number } from '../../../redux/services/VerifyOtp';
import { setChangedNumber } from '../../../redux/features/authSlice';
import defaultImg from "../../../Images/defaultImg.png";
import { getAuthSettingDetail } from '../../../redux/services/AuthSettings';
import Security from './Security'
const General = () => {
    const { register, handleSubmit, getValues, setValue, watch, formState: { errors }, reset } = useForm();
    // image 
    const [file, setFile] = useState(null);
    const [imageUrl, setImageUrl] = useState('')
    const inputFile = useRef(null);
    const [countryId, setCountryID] = useState('');
    const [stateError,setStateError]=useState('');
    const navigate=useNavigate();
    //get general setting data
    const generalDetail = useSelector(state => state?.AuthSettings?.settingInfo?.general);
    const CountryID = useSelector(state => state?.AuthSettings?.settingInfo?.organization?.country_id);
    useEffect(() => {
        setImageUrl(generalDetail?.profile_img_url)
        setCountryID(CountryID)
        setValue('first_name', generalDetail?.first_name);
        setValue('middle_name', generalDetail?.middle_name);
        setValue('last_name', generalDetail?.last_name);
        // setValue('address1', generalDetail?.address1);
        // setValue('zip_code', generalDetail?.zip_code);
        setValue('phone', generalDetail?.phone);
        setValue('email_address', generalDetail?.email_address);
        setValue('is_active', generalDetail?.is_active === true ? 'Active' : 'InActive');
        // setValue('City', generalDetail?.City); 
        // setTimeout(() => {
        //     setValue('State_id', (generalDetail?.State_id));
        // }, 1000)

    }, [generalDetail])
    const dispatch = useDispatch();
    const onButtonClick = () => {
        inputFile.current.click();
    };
    const handleFileUpload = e => {
        const { files } = e.target;
        if (files && files.length) {
            const selectedFile = files[0];
            setFile(selectedFile);
            if (imageUrl) {
                URL.revokeObjectURL(imageUrl);
            }
            const newImageUrl = URL.createObjectURL(selectedFile);
            setImageUrl(newImageUrl);
        }
    }
    const handleDelete = () => {
        setFile(null);
        setImageUrl('');
        if (inputFile.current) {
            inputFile.current.value = null;
        }
    };
    // const checkState=(e)=>{
    //     setStateError(e.target.value)
    //       setValue('City','')
    // }
    // country state data
    useEffect(() => {
        dispatch(getCountry());
        if (countryId) {
            dispatch(getState(countryId));
        }
    }, [countryId]);
    const stateData = useSelector(state => state?.common?.StateData)
    //on submit data
    console.log(generalDetail,'generalDetailgeneralDetailgeneralDetail');
    
    const onSubmit = async () => {
        const number = getValues('phone')
        const form = new FormData();
        form.append("profile_img_url", file ? file : (typeof imageUrl !== 'object' ? imageUrl : null));
        // form.append("address1", getValues('address1'));
        // form.append("City", getValues('City'));
        form.append("country_id", getValues('country_id'));
        form.append("email_address", getValues('email_address'));
        form.append("first_name", getValues('first_name'));
        form.append("middle_name", getValues('middle_name'));
        form.append("last_name", getValues('last_name'));
        form.append("phone", getValues('phone'));
        form.append("country_code", generalDetail?.country_code);
        // form.append("State_id", getValues('State_id'));
        // form.append("zip_code", getValues('zip_code'));
        form.append("is_active", getValues('is_active') === 'Active' ? true : false);
        try {
            if (number !== generalDetail?.phone) {
                dispatch(setChangedNumber({'phone':number, 'country_code':generalDetail?.country_code}))
                const sendOtp=await dispatch(Send_Otp_Change_Number({'phone':number, 'country_code':generalDetail?.country_code}))
                if(sendOtp?.payload?.status==true){
                    await dispatch(Update_general_detail(form));
                    navigate("/verify-phone");
                }
            }else{
                const response = await dispatch(Update_general_detail(form));
                if (response?.payload?.status) {
                    dispatch(getAuthSettingDetail());
                    // navigate("/auth/settings");
                }
            }

        } catch (error) {
            // Handle error if dispatch fails
            console.error("Error during signup:", error);
        }
    }
    return (
        // <div className="tab-pane fade show active" id="home" role="tabpanel"
        //     aria-labelledby="home-tab" >
        <>
            <div className="alert alert-light sap-alert-heading" id="general">
                <div className="hd-subtitle font-16">Fields coloured gray can only be
                    updated by an admin because they’re sensitive. Kindly contact an
                    admin to help update such details if needed</div>
            </div>
            <div className="font-20 py-3">Owner information</div>
            <div className="saf-img-upload">
                <div className="saf-img-block"><img height="100px" width="100px" src={imageUrl && imageUrl.length > 0 ? imageUrl : defaultImg} alt="defaultImg.png" /></div>
                <input style={{ display: "none" }} ref={inputFile} onChange={handleFileUpload} type="file" accept="image/jpeg, image/png" />
                <button type="button" className="btn btn-light" onClick={onButtonClick}>Update profile photo</button>
                {imageUrl ? <button type="button" className="btn btn-light" onClick={() => handleDelete()}>Delete</button> : ''}

            </div>
            <form action="" className="row" onSubmit={handleSubmit(onSubmit)}>
                <div className="col-md-4 col-12 mb-3">
                    <label for="" className="form-label">First name*</label>
                    <input type="input" className="form-control" id=""
                        placeholder="First Name"  {...register('first_name', { required: "true" })} />
                    {errors.first_name && errors.first_name.type === "required" && <span className="error">First name is required!</span>}
                </div>
                <div className="col-md-4 col-12 mb-3">
                    <label for="" className="form-label">Middle name*</label>
                    <input type="input" className="form-control" id=""
                        placeholder="Middle Name"  {...register('middle_name', { required: "true" })} />
                    {errors.middle_name && errors.middle_name.type === "required" && <span className="error">Middle name is required!</span>}
                </div>
                <div className="col-md-4 col-12 mb-3">
                    <label for="" className="form-label">Last name*</label>
                    <input type="input" className="form-control" id=""
                        placeholder="Last Name" {...register('last_name', { required: "true" })} />
                    {errors.last_name && errors.last_name.type === "required" && <span className="error">Last name is required!</span>}
                </div>
                {/* <div className="col-md-6 col-12 mb-3">
                    <label for="" className="form-label">Address</label>
                    <input type="text" className="form-control" id="" placeholder="Address" {...register('address1', { required: "true" })} />
                    {errors.address1 && errors.address1.type === "required" && <span className="error">Address is required!</span>}
                </div> */}
                {/* <div className="col-md-6 col-12 mb-3">
                    <label for="" className="form-label">Zip code</label>
                    <input type="text" className="form-control" id="" onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault(); } }}
                        maxLength={5} placeholder="Zip code" {...register('zip_code', {
                            required: "true", pattern: {
                                value: /^\d{5}$/,
                                message: 'ZIP code must be exactly 5 digits'
                            }
                        })} />
                    {errors.zip_code && errors.zip_code.type === "required" && <span className="error">Zip code is required!</span>}
                    {errors.zip_code && errors.zip_code.type === "pattern" && <span className="error">{errors?.zip_code?.message}</span>}
                </div> */}
                <div className="col-md-6 col-12 mb-3">
                    <label for="" className="form-label">Phone number*</label>
                    <input type="input" className="form-control" id="" onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault(); } }} maxLength={15}
                        placeholder="Phone number" {...register('phone', {
                            required: "true", pattern: {
                                value: /^\d{10,15}$/,
                                message: 'Phone number must be 10 to 15 digits'
                            }
                        })} />
                    {errors.phone && errors.phone.type === "required" && <span className="error">Phone number is required!</span>}
                    {errors.phone && errors.phone.type === "pattern" && <span className="error">{errors?.phone?.message}</span>}
                </div>
                <div className="col-md-6 col-12 mb-3">
                    <label for="" className="form-label">Email address*</label>
                    <input type="text" className="form-control" id=""
                        placeholder="Email address" {...register('email_address', {
                            required: "true", pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                message: 'Invalid email address'
                            }
                        })} disabled />
                    {errors.email_address && errors.email_address.type === "required" && <span className="error">Email is required!</span>}
                    {errors.email_address && errors.email_address.type === "pattern" && <span className="error">{errors?.email_address?.message}</span>}
                </div>
                {/* <div className="col-md-6 col-12 mb-3">
                    <label className="form-label">Country</label>
                    <select className="form-select" aria-label="Default select example"  {...register('country_id', { required: 'Counrty is required' })} onChange={(e) => setCountryID(e.target.value)} >
                        <option value="">Select country</option>
                        {CountryData && CountryData?.map((elem) => {
                            return <option value={elem?.id}>{elem?.name}</option>
                        })}
                    </select>
                    {
                        countryId === '' ?
                            errors.country_id && errors.country_id.type === "required" && <span className="error">Counrty is required!</span>
                            : ''
                    }

                </div> */}
                {/* <div className="col-md-6 col-12 mb-3">
                    <label for="" className="form-label">State</label>
                    <select className="form-select" aria-label="Default select example" name='State_id' {...register('State_id', { required: 'true' })} onChange={(e)=>checkState(e)}>
                        <option value="">Select state</option>
                        {stateData && stateData?.map((elem) => {
                            return <option value={elem?.id}>{elem?.address_state}</option>
                        })}
                    </select>
                    {stateError===''
                    ?
                    errors.State_id && errors.State_id.type === "required" && <span className="error">State is required!</span>:''
                }
                </div> */}
                {/* <div className="col-md-6 col-12 mb-3">
                    <label for="" className="form-label">City</label>
                    <input type="input" className="form-control" id=""
                        placeholder="City" {...register('City', { required: "true" })} />
                    {errors.City && errors.City.type === "required" && <span className="error">City is required!</span>}
                </div> */}
                <div className="col-md-12 col-12 mb-3">
                    <label for="" className="form-label">Status*</label>
                    <select className="form-select" aria-label="Default select example" name='is_active' {...register('is_active', { required: 'true' })}>
                        <option selected=''>Select status</option>
                        <option value={'Active'}>Active</option>
                        <option value={'InActive'}>Inactive</option>
                    </select>
                    {errors.is_active && errors.is_active.type === "required" && <span className="error">Status is required!</span>}

                </div>
                <div className="col-md-12 login-btn">
                    <button type="submit" className="btn btn-primary">Save Changes</button>
                </div>

            </form>
            <hr/>
            <Security/>
            </>
    )
}

export default General