import React from 'react'
import {sidebarItems} from '../../../../constant/MedicalHistorySidebar'

const SideBar = ({currentStep, MEDICALHISTORYFORMS}) => {
    
    return (
        <div className="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
            {sidebarItems.map((item, index) => {
                return (
                <button key={index} className = {`nav-link ${ index <= currentStep ? MEDICALHISTORYFORMS[currentStep] === item.formName ? 'active show-actie-tab' : 'active' : ''}`} type="button">
                    {item.svg}
                    {item.label}
                </button>
            )
            })}
        </div>
    )
}

export default SideBar