import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import PcspFooter from '../../PcspFooter'
import { get_vision_hearing_speech_list } from '../../../../../../redux/services/AuthMembers';

const VisionHearingSpeech = ({ onHandleBackStep, onSubmit, isGoBack }) => {
  const dispatch = useDispatch();

  const allDddPcsp = useSelector(state => state?.AuthMember?.allDddPcsp?.visionHearingSpeech);
  const visions = useSelector(state => state?.AuthMember?.allDddPcsp?.visions);
  const hearings = useSelector(state => state?.AuthMember?.allDddPcsp?.hearings);
  const visionHearingList = useSelector(state => state?.AuthMember?.visionHearingList);
  const UploadedPdfData = useSelector(state => state?.AuthMember?.UploadedPdfData);

  const [checkedVisionIds, setCheckedVisionIds] = useState([])
  const [checkedHearingIds, setCheckedHearingIds] = useState([])
  // Initialize the form with useForm hook
  const { register, handleSubmit, getValues, setValue, formState: { errors } } = useForm();

  useEffect(() => {
    dispatch(get_vision_hearing_speech_list())
  }, [])

  useEffect(() => {
    if (visions && visions.length > 0) {
      let Ids = []
      visions.map((item) => {
        Ids.push(item.option_id)
      })
      setCheckedVisionIds(Ids)
    }
    if (hearings && hearings.length > 0) {
      let Ids = []
      hearings.map((item) => {
        Ids.push(item.option_id)
      })
      setCheckedHearingIds(Ids)
    }
  }, [visions, hearings])

  useEffect(() => {
    if (UploadedPdfData?.length > 0 && !isGoBack) {
      UploadedPdfData.forEach((item) => {
        switch (item.key) {
          case 'How would you describe your vision?':
            setValue('vision_description', item?.value);
            break
          case 'How would you describe your hearing?':
            setValue('hearing_description', item?.value);
            break
        }
      })
    } else {
    setValue('vision_description', allDddPcsp?.vision_description);
    setValue('hearing_description', allDddPcsp?.hearing_description);
    setValue('has_med_adapt_equpmt_changed', allDddPcsp?.has_med_adapt_equpmt_changed);
    setValue('used_assistive_device_for_hearingvisionspeech', allDddPcsp?.used_assistive_device_for_hearingvisionspeech);
    }
  }, [allDddPcsp, UploadedPdfData])

  const handleVisionCheckboxChange = (id, type) => {
    let ids = [...checkedVisionIds]
    if (ids.includes(id)) {
      // Remove the item if it's already checked
      ids = ids.filter(item => item !== id)
    } else {
      // Add the item if it's not checked
      ids = [...ids, id]
    }
    setCheckedVisionIds(ids);
    setValue(`${type}`, ids);
  };

  const handleHearingCheckboxChange = (id, type) =>{
    let ids = [...checkedHearingIds]
    if (ids.includes(id)) {
      // Remove the item if it's already checked
      ids = ids.filter(item => item !== id)
    } else {
      // Add the item if it's not checked
      ids = [...ids, id]
    }
    setCheckedHearingIds(ids);
    setValue(`${type}`, ids);

  }

  return (
    <>
      <div className="member_vitalstep_one_form">
        <form onSubmit={handleSubmit(onSubmit)} className="row">
          <div className="alert alert-light sap-alert-heading">
            <div className="hd-title font-24">Vision/Hearing/Speech</div>
          </div>
          <div className="col-12 mb-3">
            <label for="" className="form-label">How would you describe your vision?*</label>
            <input
              type="input"
              className="form-control"
              placeholder="describe your vision"
              {...register(`vision_description`, { required: "This is required!" })}
              maxLength={50}
            />
            {errors?.vision_description && (
              <span className="error">{errors.vision_description.message}</span>
            )}
          </div>
          <div className="col-12">
            <div className="grade_content">
              <h2>Select all that apply</h2>
              <div className="date-slot activity-sec">
                <ul className="btn-group" role="group" aria-label="Basic checkbox toggle button group">
                  {visionHearingList && visionHearingList.length > 0 && visionHearingList.map((item, index) => {
                    if (item.type === 'vision') {
                      return (
                        <li className="list-group-item">
                          <input
                            type="checkbox"
                            className="btn-check"
                            id={`btncheck${index}`}
                            autocomplete="off"
                            value={item.id}
                            onChange={() => handleVisionCheckboxChange(item?.id, item.type)}
                            checked={checkedVisionIds.includes(item?.id)}
                             />
                          <label className="btn btn-outline-primary" for={`btncheck${index}`}>{item.name}</label>
                        </li>
                      )
                    }
                  })}
                </ul>
              </div>
            </div>
          </div>
          <div className="col-12 mb-3">
            <label for="" className="form-label">How would you describe your hearing?*</label>
            <input
              type="input"
              className="form-control"
              placeholder="describe your hearing"
              {...register(`hearing_description`, { required: "This is required!" })}
              maxLength={50}
            />
            {errors?.hearing_description && (
              <span className="error">{errors.hearing_description.message}</span>
            )}
          </div>
          <div className="col-12">
            <div className="grade_content">
              <h2>Select all that apply</h2>
              <div className="date-slot activity-sec">
                <ul className="btn-group" role="group" aria-label="Basic checkbox toggle button group">
                  {visionHearingList && visionHearingList.map((item, index) => {
                    if (item.type === 'hearing') {
                      return (
                        <li className="list-group-item">
                          <input
                            type="checkbox"
                            className="btn-check"
                            id={`btncheck${index}`}
                            autocomplete="off"
                            value={item.id}
                            onChange={() => handleHearingCheckboxChange(item?.id, item.type)}
                            checked={checkedHearingIds.includes(item.id)}
                             />
                          <label className="btn btn-outline-primary" for={`btncheck${index}`}>{item.name}</label>
                        </li>
                      )
                    }
                  })}
                </ul>
              </div>
            </div>
          </div>
          <div className="col-12 mb-3">
            <label for="" className="form-label">Has your medical or adaptive equipment changed since the last meeting?*</label>
            <select
              className="form-select"
              aria-label="Default select example"
              {...register(`has_med_adapt_equpmt_changed`, { required: "This is required!" })}>
              <option value="">Select</option>
              <option value="0">No</option>
              <option value="1">Yes</option>
              <option value="2">N/A</option>
            </select>
            {errors.has_med_adapt_equpmt_changed && (
              <span className="error">{errors.has_med_adapt_equpmt_changed.message}</span>
            )}
          </div>
          <div className="col-12 mb-3">
            <label for="" className="form-label">Do you use an assistive device to accommodate a vision, hearing, or speech impairment?*</label>
            <select
              className="form-select"
              aria-label="Default select example"
              {...register(`used_assistive_device_for_hearingvisionspeech`, { required: "This is required!" })}>
              <option value="">Select</option>
              <option value="0">No</option>
              <option value="1">Yes</option>
              <option value="2">N/A</option>
            </select>
            {errors.used_assistive_device_for_hearingvisionspeech && (
              <span className="error">{errors.used_assistive_device_for_hearingvisionspeech.message}</span>
            )}
          </div>
          <PcspFooter onHandleBackStep={onHandleBackStep} />
        </form>
      </div>
    </>
  )
}
export default VisionHearingSpeech