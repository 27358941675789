import { createSlice } from "@reduxjs/toolkit";
import { getOwnerInfo, Create_owner_account } from "../services/owner-info";
const initialState = {
    isLoading: false,
    error: null,
    ownerInfo: [],
}


const owner = createSlice({
    name: 'owner',
    initialState,
    reducers: {
         
    },
    extraReducers: (builder) => {
        // ==========Owner Information============== //
        builder.addCase(getOwnerInfo.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(getOwnerInfo.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
            state.ownerInfo = action?.payload?.data
        });
        builder.addCase(getOwnerInfo.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
           // ==========Create Owner Account============== //
           builder.addCase(Create_owner_account.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(Create_owner_account.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
        });
        builder.addCase(Create_owner_account.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
    }
})

export default owner?.reducer;