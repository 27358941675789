import React from 'react'
import { Link } from 'react-router-dom'

export const CancelSubscription = () => {
  return (
    <div className="subscriptions-step-form-page">
      <section className="subscriptions-sf-sec nsr">
        <div className="subscriptions-sf-inner container">
          <div className="congrats-block sad-block">
            {/* <i className="fa-regular fa-circle-check mb-2"></i> */}
            <i class="fa-regular fa-solid fa-face-frown mb-2"></i>
            <div className="font-30 hd-title"> We're sorry to see you go!</div>
            <div className="font-16 hd-subtitle mb-4">Your subscription has been successfully canceled. We're sorry to see you go!</div>
            <div className="dual-btn">
              <Link to="/setting/subscription" className="btn btn-primary">Subscription</Link>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
