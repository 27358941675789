import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { Cancel_Subscription_plan, get_subscripton_cancel_reasons } from '../../../../redux/services/AuthSettings';
import { clearStateAndPersistedData } from '../../../../redux/store';
import { useNavigate } from 'react-router-dom';
const customModalStyles = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 1000
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        border: 'none',
        maxWidth: '750px',
        // width: '100%',
        background: '#fff',
        borderRadius: '8px',
        padding: '20px',
        height: '75%',
        zIndex: '99',
        transition: 'all 1s ease-in-out'
    }
};

const DeleteSubscription = ({ DeleteModal, setDeleteModal }) => {
    const { register, handleSubmit, formState: { errors }, reset, setValue, getValues } = useForm();
    const subscription_id = useSelector(state => state?.AuthSettings?.settingInfo?.billingPlan?.id);
    const CancelReasons = useSelector(state => state?.AuthSettings?.SubscriptionCancelReasons);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(get_subscripton_cancel_reasons())
    }, []);
    const clearStorage=()=>{
        clearStateAndPersistedData();
        localStorage.clear();
        navigate('/cancel-subscription');
        window.location.reload();
    }
    const onClose = () => {
        setDeleteModal(false)
    }
    const onSubmit = async () => {
        const data = {
            "reason_id": getValues('reason'),
            "description": getValues('description'),
            "subscription_id": subscription_id
        }
        const res = await dispatch(Cancel_Subscription_plan(data))
        if (res?.payload?.status) {
            clearStorage();
        }
    }
    return (
        <Modal
            isOpen={DeleteModal}
            onRequestClose={() => setDeleteModal(false)}
            style={customModalStyles}
            contentLabel="Create Role Modal"
        >
            <div class="modal-dialog cancel-subscription">
                <div class="modal-content">
                    <div class="modal-header close-deactivate"><button type="button" class="btn-close" onClick={() => onClose()}></button></div>
                    <div class="modal-body">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <span>Cancel Plan</span>
                            {/* <div class="font-20 hd-title mb-2">Sorry to see you go :(</div> */}
                            <div class="hd-subtitle font-14">Your feedback is valuable to us and will help us improve our services. If there's anything we can do to assist you further or if you have any questions, please don't hesitate to contact our support team.
                                {/* at [Customer Support Contact Information]. */}
                            </div>
                            <div class="reason-sec">
                                <select class="form-select" aria-label="Default select example" name='reason' {...register('reason', { required: true })}>
                                    <label for="exampleFormControlInput1" class="form-label">What is your reason for cancelling subscription plan</label>
                                    <option value=''>Select your Reason</option>
                                    {CancelReasons && CancelReasons?.map((elem) => {
                                        return (
                                            <option value={elem?.id}>{elem?.reason_name}</option>
                                        )
                                    })}
                                </select>
                                {errors.reason && <span className="error">Reason is required!</span>}
                                <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" placeholder="Share your Feedback Here..." name='description' {...register('description', { required: true })}></textarea>
                                {errors.description && <span className="error">Description is required!</span>}
                            </div>
                            <div class="modal-footer-action">
                                <button type="button" class="btn btn-outline-primary me-2" onClick={() => onClose()}>Stay Subscribed</button>
                                <button type="submit" class="btn btn-primary" >Yes Cancel it</button>
                            </div>
                        </form>
                    </div>

                </div>
            </div>
        </Modal>
    )
}

export default DeleteSubscription;