import React, { useState, useEffect } from 'react'
import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom';
import {Forms} from './Forms'

const getCustomModalStyles = (isSmallScreen) => ({
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 1000
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        border: 'none',
        background: '#fff',
        borderRadius: '8px',
        padding: isSmallScreen ? '10px' : '20px',
        zIndex: 99,
        transition: 'all 1s ease-in-out',
        maxWidth: '750px',
        width: isSmallScreen ? '95%' : '90%',
        height: 'auto',
        maxHeight: '90vh',
    }
});

export const AfterAddMember = ({ showPopUp, setShowPopUp,id, handleBegin = {}, type = '' }) => {
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);
    useEffect(()=>{
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth < 768);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    },[])

    const navigate=useNavigate();
    const onClose=()=>{
        if(showPopUp?.id){
            navigate(`/auth/edit-member/${showPopUp?.id}`)
            setShowPopUp({modal:false});
        }else{
            setShowPopUp({modal:false});
            navigate('/auth/members');
        }
    }

    const handleClose = () => {
        setShowPopUp({modal:false});
    }
    const customModalStyles = getCustomModalStyles(isSmallScreen)
    return (
        <Modal
            isOpen={showPopUp?.modal}
            onRequestClose={() => setShowPopUp({ ...showPopUp, modal: false })}
            style={customModalStyles}
            contentLabel="Create Role Modal"
        >
                <div className="modal-dialog modal-lg "> 
                    <div className="modal-content">
                        <div className="modal-header">
                            <div className="load_img">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M16 12L12 8M12 8L8 12M12 8V16M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
                                        stroke="#079455" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>

                            </div>
                            <button type="button" className="btn-close" onClick={()=>onClose()}></button>
                        </div>
                        <Forms member_id={showPopUp?.id} handleClose={handleClose}/>
                    </div>
            </div>
        </Modal>
    )
}
