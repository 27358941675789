 const FormatDate = (inputDate) => {
    const [month, day, year] = inputDate?.inputDate.split('/').map(Number);
    const date = new Date(year, month - 1, day);
    const dayFormatted = String(date.getDate()).padStart(2, '0');
    const monthFormatted = String(date.getMonth() + 1).padStart(2, '0');
    const yearFormatted = date.getFullYear();
    return `${monthFormatted}/${dayFormatted}/${yearFormatted}`;
  };
  export default FormatDate;

  export const NewFormatDate = (inputDate) => {
    if(inputDate?.inputDate !== ''){
      const [year, month, day] = inputDate?.inputDate.split('-').map(Number);
      const date = new Date(year, month - 1, day);
      const dayFormatted = String(date.getDate()).padStart(2, '0');
      const monthFormatted = String(date.getMonth() + 1).padStart(2, '0');
      const yearFormatted = date.getFullYear();
      return `${monthFormatted}/${dayFormatted}/${yearFormatted}`;
    }else{
      return 'N/A'
    }
   
  };
