import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import Footer from '../../Footer'
import { get_all_medical_diseases } from '../../../../../../redux/services/AuthMembers';


const CurrentConditions = ({ onHandleBackStep, onSubmit }) => {
  const [checkedCurrentConditionsIds, setCheckedCurrentConditionsIds] = useState([])

  const allMedicalDiseases = useSelector(state => state?.AuthMember?.allMedicalDiseases);
  const allMedicalHistory = useSelector((state) => state?.AuthMember?.allMedicalHistory?.medicalHistoryCurrentCondition);
  const checkedCurrentCondition = allMedicalHistory?.membersMedicalHistoryCurrentConditionsSerhostresiginjill
  // Initialize the form with useForm hook
  const { register, handleSubmit, setValue, formState: { errors } } = useForm();


  useEffect(()=>{
    get_all_medical_diseases()
  },[])

  useEffect(() => {    
    if (checkedCurrentCondition && checkedCurrentCondition.length > 0) {
      let Ids = []
      checkedCurrentCondition.map((item) => {
        Ids.push(item.master_serhostresiginjill_id)
      })
      setCheckedCurrentConditionsIds(Ids)
    }
  }, [checkedCurrentCondition])

  useEffect(() => {
    setValue('current_issue_reactions', allMedicalHistory?.current_issue_reactions);
    setValue('dateresult_current_physical_examination', allMedicalHistory?.dateresult_current_physical_examination);
    setValue('current_issue_reactions', allMedicalHistory?.current_issue_reactions);
    setValue('dateresult_current_dental_examination', allMedicalHistory?.dateresult_current_dental_examination);
    setValue('serhostresiginjill', allMedicalHistory?.serhostresiginjill);
  }, [allMedicalHistory])


  const handleCurrentConditionsChange = (id, type) => {
    let ids = [...checkedCurrentConditionsIds]
    if (ids.includes(id)) {
      // Remove the item if it's already checked
      ids = ids.filter(item => item !== id)
    } else {
      // Add the item if it's not checked
      ids = [...ids, id]
    }
    setCheckedCurrentConditionsIds(ids);
    setValue('serhostresiginjill', ids);
  };
  return (
    <div className="tab-pane fade  goal_tab show active">
       <form onSubmit={handleSubmit(onSubmit)} className="row">
       <div className="col-12 mb-3">
          <label for="" className="form-label">Current medications and significant historical medication issues and/or previous reactions:*  </label>
          <textarea className="form-control" placeholder="Enter Current medications" {...register("current_issue_reactions", { required: "true" })} maxLength={150} />
          {errors.current_issue_reactions && errors.current_issue_reactions.type === "required" && (<span className="error">This is required!</span>)}
        </div>

        <div className="col-12 mb-3">
          <label for="" className="form-label">Date and results of current physical examination:*</label>
          <textarea className="form-control" placeholder="Enter Date and results of current physical examination:" {...register("dateresult_current_physical_examination", { required: "true" })} maxLength={150} />
          {errors.dateresult_current_physical_examination && errors.dateresult_current_physical_examination.type === "required" && (<span className="error">This is required!</span>)}
        </div>

        <div className="col-12 mb-3">
          <label for="" className="form-label">Date of current dental*</label>
          <textarea className="form-control" placeholder="Enter Date of current dental" {...register("dateresult_current_dental_examination", { required: "true" })} maxLength={150} />
          {errors.dateresult_current_dental_examination && errors.dateresult_current_dental_examination.type === "required" && (<span className="error">This is required!</span>)}
        </div>

        <div className="col-12">
          <label for="" className="form-label">Surgeries, Hospitalizations, Treatments, Significant Injuries & Illnesses:*</label>
          <div className="ddd_steptwo_para">
            <div className="date-slot activity-sec">
              <ul className="btn-group" role="group" aria-label="Basic checkbox toggle button group">
                {allMedicalDiseases && allMedicalDiseases.length > 0 && allMedicalDiseases.map((item, index) => {
                  return (
                    <li className="list-group-item">
                      <input
                        type="checkbox"
                        className="btn-check"
                        id={`btncheck${index}`}
                        autocomplete="off"
                        value={item.id}
                        onChange={() => handleCurrentConditionsChange(item?.id)}
                        checked={checkedCurrentConditionsIds.includes(item?.id)}
                      />
                      <label className="btn btn-outline-primary" for={`btncheck${index}`}>{item.name}</label>
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
        </div>
        <Footer onHandleBackStep={onHandleBackStep} />
      </form>
    </div>
  )
}

export default CurrentConditions
