import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { customAlert } from "../../utils/alert";
import { HTTPURL } from "../../constant/Matcher";


// add-communication
export const Add_communication = createAsyncThunk(
    "/api/add-communication",
    async (formData, thunkAPI) => {
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        const { rejectWithValue } = thunkAPI;
        try {
            let config = {
                method: 'Post',
                url: `${HTTPURL}api/add-communication `,
                headers: {
                    // "Content-Type": "application/json",
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
                data: formData
            };
            const res = await axios.request(config);
            if (res?.data?.status == true) {
                customAlert(res?.data?.message, 'success');
            } else {
                customAlert(res?.data?.message);
            }
            return res.data;
        } catch (error) {
            customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }

    }

);

//get  all-communication-homes
export const get_all_communication_homes = createAsyncThunk(
    "auth/all-communication-homes",
    async (SearchData, thunkAPI) => {
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        try {
            let config = {
                method: 'get',
                url: `${HTTPURL}api/all-communication-homes?page=${SearchData?.page}&listData=${SearchData?.listData}&searchQuery=${SearchData?.searchBar}`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
            };
            const response = await axios.request(config)
            return response.data;
        } catch (error) {
            customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }
    }
);

//get get-home-with-member
export const get_home_with_member = createAsyncThunk(
    "auth/get-home-with-member",
    async (home_table_id, thunkAPI) => {
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        try {
            let config = {
                method: 'get',
                url: `${HTTPURL}api/get-home-with-member?home_table_id=${home_table_id}`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
            };
            const response = await axios.request(config)
            return response.data;
        } catch (error) {
            customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }
    }
);

//get get-communication-member
export const get_communication_member = createAsyncThunk(
    "auth/get-communication-member",
    async (SearchData, thunkAPI) => {
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        try {
            let config = {
                method: 'get',
                url: `${HTTPURL}api/get-communication-member?page=${SearchData?.page}&listData=${SearchData?.listData}&member_id=${SearchData?.member_id}&searchQuery=${SearchData?.searchBar}`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
            };
            const response = await axios.request(config)
            return response.data;
        } catch (error) {
            customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }
    }
);
// get-communication-by-id
export const get_communication_by_id = createAsyncThunk(
    "auth/get-communication-by-id",
    async (communication_id, thunkAPI) => {
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        try {
            let config = {
                method: 'get',
                url: `${HTTPURL}api/get-communication-by-id?communication_id=${communication_id}`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
            };
            const response = await axios.request(config)
            return response.data;
        } catch (error) {
            customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }
    }
);
// destroy-communication
export const destroy_communication = createAsyncThunk(
    "auth/destroy-communication",
    async (communication_id, thunkAPI) => {
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        try {
            let config = {
                method: 'get',
                url: `${HTTPURL}api/destroy-communication?communication_id=${communication_id}`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
            };
            const response = await axios.request(config)
            return response.data;
        } catch (error) {
            customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }
    }
);

//get  all-communication-list
export const get_all_communication_list = createAsyncThunk(
    "auth/all-communication-list",
    async (SearchData, thunkAPI) => {
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        try {
            let config = {
                method: 'get',
                url: `${HTTPURL}api/all-communication-list?page=${SearchData?.page}&listData=${SearchData?.listData}&searchQuery=${SearchData?.searchBar}`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
            };
            const response = await axios.request(config)
            return response.data;
        } catch (error) {
            customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }
    }
);

//get loggedIn-home-member-list
export const get_loggedIn_home_member_list = createAsyncThunk(
    "auth/loggedIn-home-member-list",
    async (home_table_id, thunkAPI) => {
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        try {
            let config = {
                method: 'get',
                url: `${HTTPURL}api/loggedIn-home-member-list`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
            };
            const response = await axios.request(config)
            return response.data;
        } catch (error) {
            customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }
    }
)

//get export-communication-list
export const export_all_communication = createAsyncThunk(
    "auth/export-communication-list",
    async (SearchData, thunkAPI) => {
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        try {
            let config = {
                method: 'get',
                url: `${HTTPURL}api/export-communication-list`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
            };
            const response = await axios.request(config)
            return response.data;
        } catch (error) {
            customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }
    }
);