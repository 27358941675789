import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import Footer from '../../Footer'


const consistencyList = [
  { id: 'crawling_scooting', name: 'Crawling/scooting' },
  { id: 'Kneeling', name: 'Kneeling' },
  { id: 'Standing', name: 'Standing' },
  { id: 'Walking', name: 'Walking' },
  { id: 'Running', name: 'Running' },
  { id: 'Climbing', name: 'Climbing' },
]
const Mobility = ({ onHandleBackStep, onSubmit }) => {
  const [checkedIndependentMobilityIds, setCheckedIndependentMobilityIds] = useState([])

  const allIndividualizedHealth = useSelector(state => state?.AuthMember?.allIndividualizedHealth?.mobility);
  const independent_mobility = useSelector(state => state?.AuthMember?.allIndividualizedHealth?.mobility?.membersIndividualizedHealthSaftyneedsIndependentMobility);
  // Initialize the form with useForm hook
  const { register, handleSubmit, setValue, formState: { errors } } = useForm();


  useEffect(() => {
    if (independent_mobility && independent_mobility.length > 0) {
      let Ids = []
      independent_mobility.map((item) => {
        Ids.push(item.independent_mobility)
      })
      setCheckedIndependentMobilityIds(Ids)
    }
  }, [independent_mobility])
  useEffect(() => {
    setValue('balance_standing', allIndividualizedHealth?.balance_standing);
    setValue('utilize_adaptive', allIndividualizedHealth?.utilize_adaptive);
    setValue('independent_mobility', allIndividualizedHealth?.independent_mobility);
    setValue('balance_aids', allIndividualizedHealth?.balance_aids);
    setValue('other_specify', allIndividualizedHealth?.other_specify);
    setValue('positioning_instruction', allIndividualizedHealth?.positioning_instruction);
    setValue('lifting_instruction', allIndividualizedHealth?.lifting_instruction);
    setValue('facility_adaptiontione', allIndividualizedHealth?.facility_adaptiontione);
  }, [allIndividualizedHealth])


  const handleCheckboxChange = (id, type) => {
    let ids = [...checkedIndependentMobilityIds]
    if (ids.includes(id)) {
      // Remove the item if it's already checked
      ids = ids.filter(item => item !== id)
    } else {
      // Add the item if it's not checked
      ids = [...ids, id]
    }
    setCheckedIndependentMobilityIds(ids);
    setValue('independent_mobility', ids);
  };

  return (
    <div className="tab-pane fade  goal_tab show active">
    <form onSubmit={handleSubmit(onSubmit)} className="row">
    <div className="col-12">
        <label for="" className="form-label">Balance while standing*</label>
          <select
            className="form-select"
            aria-label="Default select example"
            {...register(`balance_standing`, { required: "Balance while standing is required!" })}>
            <option value="">Select</option>
            <option value="1">Excellent (Not an issue)</option>
            <option value="0">No</option>
          </select>
          {errors.balance_standing && (
            <span className="error">{errors.balance_standing.message}</span>
          )}
      </div>
      <div className="col-12">
        <label for="" className="form-label">Utilizes adaptive aid for balances*</label>
          <select
            className="form-select"
            aria-label="Default select example"
            {...register(`utilize_adaptive`, { required: "Utilizes adaptive aid for balances is required!" })}>
            <option value="">Select</option>
            <option value="1">Yes</option>
            <option value="0">No</option>
          </select>
          {errors.utilize_adaptive && (
            <span className="error">{errors.utilize_adaptive.message}</span>
          )}
      </div>
     <div className="col-12">
       <label for="" className="form-label">Independent mobility*</label>
         <div className="date-slot activity-sec">
           <ul className="btn-group" role="group" aria-label="Basic checkbox toggle button group">
             {consistencyList && consistencyList.length > 0 && consistencyList.map((item, index) => {
               return (
                 <li className="list-group-item">
                   <input
                     type="checkbox"
                     className="btn-check"
                     id={`btncheck${index}`}
                     autocomplete="off"
                     value={item.id}
                     onChange={() => handleCheckboxChange(item?.id)}
                     checked={checkedIndependentMobilityIds.includes(item?.id)}
                   />
                   <label className="btn btn-outline-primary" for={`btncheck${index}`}>{item.name}</label>
                 </li>
               )
             })}
           </ul>
         </div>
     </div>
     <div className="col-12">
        <label for="" className="form-label">Mobility/Balances aids*</label>
          <select
            className="form-select"
            aria-label="Default select example"
            {...register(`balance_aids`, { required: "Mobility/Balances aids is required!" })}>
            <option value="">Select</option>
            <option value="1">Wheelchair</option>
            <option value="0">No</option>
          </select>
          {errors.balance_aids && (
            <span className="error">{errors.balance_aids.message}</span>
          )}
      </div>
      <div className="col-12">
        <label for="" className="form-label">Other (Specify)*</label>
        <input
            type="input"
            className="form-control"
            placeholder="other"
            {...register(`other_specify`, { required: "Other is required!" })}
            maxLength={50}
          />
          {errors?.other_specify && (
            <span className="error">{errors.other_specify.message}</span>
          )}
      </div>
     <div className="col-12 mb-3">
       <label for="" className="form-label">Positioning instructions*</label>

       <textarea className="form-control" placeholder="Enter Positioning instructions" {...register("positioning_instruction", { required: "true" })} maxLength={150} />
       {errors.positioning_instruction && errors.positioning_instruction.type === "required" && (<span className="error">This is required!</span>)}
     </div>
     <div className="col-12 mb-3">
       <label for="" className="form-label">Lifting/Carrying instructions*</label>

       <textarea className="form-control" placeholder="Enter Lifting/Carrying instructions" {...register("lifting_instruction", { required: "true" })} maxLength={150} />
       {errors.lifting_instruction && errors.lifting_instruction.type === "required" && (<span className="error">This is required!</span>)}
     </div>
     <div className="col-12 mb-3">
       <label for="" className="form-label">Other adaptive equipment/facility adaptations*</label>

       <textarea className="form-control" placeholder="Enter Other adaptive equipment/facility adaptations" {...register("facility_adaptiontione", { required: "true" })} maxLength={150} />
       {errors.facility_adaptiontione && errors.facility_adaptiontione.type === "required" && (<span className="error">This is required!</span>)}
     </div>

     <Footer onHandleBackStep={onHandleBackStep} />
   </form>
 </div>
  )
}

export default Mobility;
