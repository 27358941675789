import React, { useEffect, useState } from 'react'
import { useForm, useFieldArray } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import PcspFooter from '../../PcspFooter'


const HealthAndMedicalRisks = ({ onHandleBackStep, onSubmit, StateOfRisk }) => {
    const allTypeRisk = useSelector(state => state?.AuthMember?.allTypeRisk?.health_medical_risk?.dddpcspRisksMaster);
    const allDddPcsp = useSelector(state => state?.AuthMember?.allDddPcsp?.risks);

    // Initialize the form with useForm hook
    const { control, handleSubmit, register, getValues, setValue, formState: { errors } } = useForm({
        defaultValues: {
            healthMediRisks: [{ dddpcsp_risk_master_id: "", state_of_risk: "", risk_type: "HealthAndMedicalRisks" }],
        }
    });

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'healthMediRisks'
    });

    useEffect(() => {
        let filterd = allDddPcsp && allDddPcsp.filter((item) => item.risk_type === 'HealthAndMedicalRisks')
        const lengthDifference = filterd?.length - fields.length;
        if (lengthDifference > 0) {
            for (let i = 0; i < lengthDifference; i++) {
                append({});
            }
        }
        filterd && filterd.forEach((item, index) => {
            setValue(`healthMediRisks.${index}.dddpcsp_risk_master_id`, item.dddpcsp_risk_master_id || '');
            setValue(`healthMediRisks.${index}.state_of_risk`, item.state_of_risk || '');
            setValue(`healthMediRisks.${index}.risk_type`, item.risk_type || '');
        });

    }, [allDddPcsp])

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)} className="row">
                <div className="col-12">
                    <div className="alert alert-light sap-alert-heading">
                        <div className="hd-subtitle font-16">The following shall be used to identify risks that compromise the
                            individual’s general health condition and quality of life.</div>
                    </div>
                </div>
                <div className="col-12">
                    <div className="alert alert-light sap-alert-heading">
                        <div className="hd-title font-24">Every individual must be assessed for risk</div>
                        <div className="hd-subtitle font-16">Indicate the following, as applicable, next to each risk identified
                            below:<strong> EM (Effectively Managed); FA (Further Assessment); RR (Rights Restricted); MRA (Managed
                                Risk Agreement)</strong></div>
                        <div className="hd-subtitle font-16 pt-3">Indicate the following, as applicable, next to each risk
                            identified below: EM (Effectively Managed); FA (Further Assessment); RR (Rights Restricted); MRA
                            (Managed Risk Agreement)
                            Consider normal and unusual risks for the individual in various areas of the person’s life. When risks
                            are identified, the team will look for the factors that lead to the risk. The team then develops
                            countermeasures and interventions to minimize or prevent the risk.</div>
                    </div>
                </div>
                <div className="member_vital_basic_title">
                    <h4>Health and medical risks</h4>
                </div>
                {fields.map((member, index) => (
                    <div key={member.id} className="row">
                        {index > 0 && (
                            <hr />
                        )}
                        <div className="col-md-6 mb-3">
                            <label for="" className="form-label">Risk*</label>
                            <select
                                className="form-select"
                                aria-label="Default select example"
                                {...register(`healthMediRisks.${index}.dddpcsp_risk_master_id`, { required: "Risk is required!" })}>
                                <option value="">Select</option>
                                {allTypeRisk && allTypeRisk.map((item, index) => {
                                    return (
                                        <option value={item.id}>{item.risk_name}</option>
                                    )
                                })}
                            </select>
                            {errors.healthMediRisks && errors.healthMediRisks[index]?.dddpcsp_risk_master_id && (
                                <span className="error">{errors.healthMediRisks[index].dddpcsp_risk_master_id.message}</span>
                            )}
                        </div>
                        <div className="col-md-6 mb-3">
                            <label for="" className="form-label">State of risk*</label>
                            <select
                                className="form-select"
                                aria-label="Default select example"
                                {...register(`healthMediRisks.${index}.state_of_risk`, { required: "state of risk is required!" })}>
                                <option value="">Select</option>
                                {StateOfRisk && StateOfRisk.map((item, index) => {
                                    return (
                                        <option value={item.value}>{item.lable}</option>
                                    )
                                })}
                            </select>
                            {errors.healthMediRisks && errors.healthMediRisks[index]?.state_of_risk && (
                                <span className="error">{errors.healthMediRisks[index].state_of_risk.message}</span>
                            )}
                        </div>
                        {index > 0 && (
                            <div className="col-12 mb-3">
                                <a
                                    href="#"
                                    className="stepone_remove"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        remove(index);
                                    }}
                                >
                                    Remove
                                </a>
                            </div>
                        )}
                    </div>
                ))}
                <div className="stepone_health_planbtn">
                    <button type="button" className="font-14" onClick={() => append({ risk_type: "HealthAndMedicalRisks" })}><svg width="12" height="12" viewBox="0 0 12 12" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path d="M6.00016 1.33325V10.6666M1.3335 5.99992H10.6668" stroke="#667085" stroke-width="2"
                            stroke-linecap="round" stroke-linejoin="round"></path>
                    </svg>
                        Add another health plan</button>
                </div>
                <PcspFooter onHandleBackStep={onHandleBackStep} />
            </form>
        </>
    )
}
export default HealthAndMedicalRisks