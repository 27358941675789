import { createSlice } from "@reduxjs/toolkit";
import { get_appointment_with_list, create_appointment, get_all_users_list, get_all_appointment_list, appointment_change_status, get_appointment_edit, appointment_destroy, appointment_export, get_existing_doctor_address, get_appointment_search_location } from "../services/AuthAppointment";

const initialState = {
    isLoading: false,
    error: null,
    AppointmentWithList:[],
    AllUserList:[],
    AllAppointmentList:[],
    EditAppointmentList:{},
    ExistingAddressList:[],
    ExistingLocationList:[]
}
const AuthAppointment = createSlice({
    name: 'AuthAppointment',
    initialState,
    reducers: {
        reSetEditAppointmentList: (state, action) => {
            state.EditAppointmentList= {}
          },
          reSetExistingAddressList: (state, action) => {
            state.ExistingAddressList = []
          },
          reSetExistingLocationList: (state, action) => {
            state.ExistingLocationList = []
          }
    },
    extraReducers: (builder) => {
        // ==========get_appointment_with_list============== //
        builder.addCase(get_appointment_with_list.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(get_appointment_with_list.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
            state.AppointmentWithList = action?.payload?.data
        });
        builder.addCase(get_appointment_with_list.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
         // ==========get_all_users_list============== //
         builder.addCase(get_all_users_list.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(get_all_users_list.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
            state.AllUserList = action?.payload?.data
        });
        builder.addCase(get_all_users_list.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
        // ==========create_appointment ============== //
        builder.addCase(create_appointment.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(create_appointment.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
        });
        builder.addCase(create_appointment.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
        // ==========get_all_appointment_list============== //
        builder.addCase(get_all_appointment_list.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(get_all_appointment_list.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
            state.AllAppointmentList = action?.payload?.data
        });
        builder.addCase(get_all_appointment_list.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
        // ==========appointment_change_status ============== //
        builder.addCase(appointment_change_status.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(appointment_change_status.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
        });
        builder.addCase(appointment_change_status.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
        // ==========get_appointment_edit============== //
        builder.addCase(get_appointment_edit.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(get_appointment_edit.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
            state.EditAppointmentList = action?.payload?.data
        });
        builder.addCase(get_appointment_edit.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
         // ==========appointment_destroy ============== //
         builder.addCase(appointment_destroy.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(appointment_destroy.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
        });
        builder.addCase(appointment_destroy.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
        // ==========appointment_export============== //
        builder.addCase(appointment_export.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(appointment_export.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
        });
        builder.addCase(appointment_export.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
        // ==========get_existing_doctor_address============== //
        builder.addCase(get_existing_doctor_address.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(get_existing_doctor_address.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
            state.ExistingAddressList = action?.payload?.data
        });
        builder.addCase(get_existing_doctor_address.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
         // ==========get_appointment_search_location============== //
         builder.addCase(get_appointment_search_location.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(get_appointment_search_location.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
            state.ExistingLocationList = action?.payload?.data
        });
        builder.addCase(get_appointment_search_location.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
    }
});
// Export the synchronous action
export const { reSetEditAppointmentList, reSetExistingAddressList, reSetExistingLocationList } = AuthAppointment.actions;
export default AuthAppointment?.reducer;