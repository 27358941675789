import React, { useEffect, useRef, useState } from 'react';
import Chart from 'chart.js/auto';

const LineChart = ({ data = {}, id = '' }) => {
  const chartContainer = useRef(null);
  const chartInstance = useRef(null); // To hold the chart instance
  
  useEffect(() => {
    if (chartContainer && chartContainer.current) {
      const lineChartctx = chartContainer.current.getContext('2d');

      // Clear the previous chart instance if it exists
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }

      // Create gradient fill for the line chart
      const gradientFill = lineChartctx.createLinearGradient(0, 160, 0, 0);
      gradientFill.addColorStop(0, "rgba(240, 68, 56, 0)");
      gradientFill.addColorStop(1, "rgba(240, 68, 56, 1)");

      // Get the current month index (0 = January, 11 = December)
      const currentMonthIndex = new Date().getMonth();

      const months = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
      ];

      // Get the last 6 months, including the current month
      const lastSixMonths = [];
      const lastSixMonthsData = [];
      const pointRadius = [];

      for (let i = 0; i < 6; i++) {
        const monthIndex = (currentMonthIndex - i + 12) % 12; // Handling negative indices for past months
        lastSixMonths.unshift(months[monthIndex]);
        lastSixMonthsData.unshift(data[months[monthIndex]] || 0); // Get data for this month, default to 0 if missing

        // Set pointRadius to 5 (visible circle) only for the current month
        pointRadius.unshift(monthIndex === currentMonthIndex ? 5 : 0);
      }

      const chartConfig = {
        labels: lastSixMonths,
        datasets: [{
          label: '',
          data: lastSixMonthsData,
          fill: true,
          backgroundColor: gradientFill,
          borderColor: "rgba(240, 68, 56, 1)",
          pointBackgroundColor: "rgba(255, 255, 255, 1)",
          pointBorderColor: "rgba(240, 68, 56, 1)",
          pointBorderWidth: 1, // Make the border visible
          pointRadius: pointRadius, // Dynamic point radius, showing a circle only for the current month
        }]
      };

      // Create a new chart instance and store it in the ref
      chartInstance.current = new Chart(lineChartctx, {
        type: 'line',
        data: chartConfig,
        options: {
          plugins: {
            legend: {
              display: false, // Hide the legend
            }
          },
          scales: {
            x: {
              grid: {
                display: false, // Hide the grid
              },
              ticks: {
                display: false, // Show the month labels on the x-axis
              }
            },
            y: {
              grid: {
                display: false, // Hide the y-axis grid
              },
              ticks: {
                display: false, // Hide the y-axis ticks
              }
            }
          }
        }
      });
    }

    return () => {
      // Clean up the chart instance when the component unmounts
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, [data]); // Re-run the effect if the data prop changes

  return (
    <div>
      <canvas ref={chartContainer} id={id} width="400" height="400"></canvas>

    </div>
  );
};

export default LineChart;
