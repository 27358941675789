import React,{ useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Pagination } from '../../Pagination';
import Pdf from '../../../Images/file_type_wrap.png'
import { getMonthDate, formatAMPM } from "../../../components/Common/Common"
import { get_communication_member } from '../../../redux/services/AuthCommunication'
import ResultNotFound from './ResultNotFound'

export const Communication = ({member_id}) => {
    const dispatch = useDispatch()
    const totalNoPages = useSelector(state => state?.AuthCommunication?.MemberCommunication?.totalNoPages);
    const communications = useSelector(state => state?.AuthCommunication?.MemberCommunication?.communications);

    const [currentPage, setCurrentPage] = useState(1);

    useEffect(()=>{
        let data = {
            page: 1,
            listData: 10,
            searchBar:'',
            member_id: member_id
        }
        dispatch(get_communication_member(data))

    },[member_id])

    const handlePageChange = (pageNumber) => {
		setCurrentPage(pageNumber)
		let data = {
			page: pageNumber,
			listData: 10,
			searchBar:'',
			member_id: member_id
		}
		dispatch(get_communication_member(data))
	};
console.log(communications,'communicationscommunicationscommunications===>>');

  return (
    <div class="tab-pane fade show active">
    <div class="row">
        <div class="col-md-12 col-12">
            <div class="table-sec">
                <div class="table-responsive">
                    {communications && communications.length > 0 ?
                        <table class="table table-hover">
                            <thead>
                                <tr>
                                    <th scope="col" colspan="2">Message
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {communications && communications.length > 0 && communications.map((item, index) => {
                                    let date = getMonthDate(item.created_date)
                                    let time = formatAMPM(item.created_date)
                                    return (
                                        <tr>
                                            <td>
                                                {/* <div class="table-profile" onClick={() => handleMessageClick(item)}> */}
                                                <div class="table-profile" >
                                                    <div
                                                        class="profile-sec  appointment-member">
                                                        <div class="profile-info">
                                                            <p class="font-14 hd-title">
                                                                {item.title}
                                                                <span class="d-block hd-subtitle">{item.description}</span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="samples_png">
                                            {item.communicationDocument.length > 0 && item.communicationDocument.map((item) => {
                                                return (
                                                    <div className="brain_image">
                                                        <img src={Pdf} alt="" />
                                                        <p className="font-14 hd-subtitle">{item.file_name}</p>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                            </td>
                                            <td class="hd-subtitle text-dark text-end">{date} <br /> {time}</td>
                                        </tr>
                                    )
                                })}
                                <tr>
                                    <td colSpan="7">
                                        <Pagination
                                            currentPage={currentPage}
                                            totalPages={totalNoPages}
                                            onPageChange={handlePageChange}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        :
                        <ResultNotFound />
                    }
                </div>
            </div>
        </div>
    </div>
</div>
  )
}
