import React from 'react'

const PrivacyPolicy = () => {
  return (
    <div className="home-page">
    <section className="coming-soon-sec">
        <div className="coming-soon-inner container">
            <div className="coming-soon-block">
                <div className="font-60 hd-title">Privacy policy!</div>
                <div className="hd-subtitle font-16">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</div>
            </div>
        </div>
    </section>
</div>
  )
}

export default PrivacyPolicy;