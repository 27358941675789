import React, { useEffect } from "react";
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import Footer from '../../Footer'

const Seizures = ({ onHandleBackStep, onSubmit }) => {
    const allIndividualizedHealth = useSelector(state => state?.AuthMember?.allIndividualizedHealth?.healthMedicalSecondStep);
    // Initialize the form with useForm hook
    const { register, handleSubmit, setValue, formState: { errors } } = useForm();

    useEffect(() => {
        setValue('is_seizures', allIndividualizedHealth?.is_seizures);
        setValue('seizures_type', allIndividualizedHealth?.seizures_type);
        setValue('frequency', allIndividualizedHealth?.frequency);
        setValue('duration', allIndividualizedHealth?.duration);
        setValue('recommeded_staff_response', allIndividualizedHealth?.recommeded_staff_response);

    }, [allIndividualizedHealth])
    return (
        <form onSubmit={handleSubmit(onSubmit)} className="row">
            <div className="col-12">
                <label for="" className="form-label">Seizures*</label>
                <div className="ddd_steptwo_para">
                    <select
                        className="form-select"
                        aria-label="Default select example"
                        {...register(`is_seizures`, { required: "Seizures is required!" })}>
                        <option value="">Select</option>
                        <option value="1">Yes</option>
                        <option value="0">No</option>
                    </select>
                    {errors.is_seizures && (
                        <span className="error">{errors.is_seizures.message}</span>
                    )}
                </div>
            </div>
            <div className="col-12">
                <label for="" className="form-label">Type*</label>
                <div className="ddd_steptwo_para">
                    <input
                        type="input"
                        className="form-control"
                        placeholder="Enter Type"
                        {...register(`seizures_type`, { required: "Type is required!" })}
                        maxLength={20}
                    />
                    {errors?.seizures_type && (
                        <span className="error">{errors.seizures_type.message}</span>
                    )}
                </div>
            </div>
            <div className="col-12">
                <label for="" className="form-label">Frequency*</label>
                <div className="ddd_steptwo_para">
                    <input
                        type="input"
                        className="form-control"
                        placeholder="Enter Frequency"
                        {...register(`frequency`, { required: "Frequency is required!" })}
                        maxLength={20}
                    />
                    {errors?.frequency && (
                        <span className="error">{errors.frequency.message}</span>
                    )}
                </div>
            </div>
            <div className="col-12">
                <label for="" className="form-label">Approximate duration*</label>
                <div className="ddd_steptwo_para">
                    <input
                        type="input"
                        className="form-control"
                        placeholder="Enter Approximate duration"
                        {...register(`duration`, { required: "Approximate duration is required!" })}
                        maxLength={20}
                    />
                    {errors?.duration && (
                        <span className="error">{errors.duration.message}</span>
                    )}
                </div>
            </div>
            <div className="col-12">
                <label for="" className="form-label">Recommended staff response to seizure activity*</label>
                <div className="ddd_steptwo_para">
                    <textarea className="form-control" placeholder="Enter seizure activity" {...register("recommeded_staff_response", { required: "true" })} maxLength={150} />
                    {errors.recommeded_staff_response && errors.recommeded_staff_response.type === "required" && (<span className="error">This is required!</span>)}
                </div>
            </div>
            <Footer onHandleBackStep={onHandleBackStep} />
        </form>
    )

}
export default Seizures