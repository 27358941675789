import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import LineChart from "../Charts/LineChart";
import RedLineChart from '../Charts/RedLineChart';
import {HanburgerSvg, DecreaseSvg, IncreaseSvg} from '../../../Images/ComonSvgs'

const HomeChart = () => {

  const HomeData = useSelector(state => state?.AuthDashboard?.DashboardData?.home?.monthCount
  );
  const TotalHome = useSelector(state => state?.AuthDashboard?.DashboardData?.home?.count);
  const percentage = useSelector(state => state?.AuthDashboard?.DashboardData?.home?.percentage);
  const [increase, setIncrease] = useState(false)

    useEffect(()=>{
        if(percentage === 0){
            setIncrease(true)
        }
        if(percentage > 0){
            setIncrease(true)
        }
        if(percentage < 0){
            setIncrease(false)
        }
    },[percentage])


    return(
      <div className="col-md-4 col-12">
      <div className="graph-content">
        <div className="graph-header">
          <div className="font-16 hd-title">Homes</div>
          {/* <a href="#">
          <HanburgerSvg/>
          </a> */}
        </div>
        <div className="g-content-box">
          <div className="content-box">
            <div className="font-30 hd-title">{TotalHome}</div>
            <div className="progrss-sec">
            {increase ? <IncreaseSvg /> : <DecreaseSvg />}
              <p><span  className={`${increase ? '' : 'red'}`}>{Math.abs(percentage)}%</span> last mth</p>
            </div>
          </div>
          <div className="content-box">
          {increase ?
            <LineChart data = {HomeData} id='home_chart'/>
            :
            <RedLineChart data={HomeData}/>
            }
          </div>
        </div>

      </div>
    </div>
    )
}
export default HomeChart