import React from "react";
import { useSelector } from 'react-redux';

const Header = ({ setAddNewTask, viewType, onViewChange }) => {
	const currentUserRole = useSelector(state => state?.auth?.currentUserRole);
	const general = useSelector(state => state?.AuthSettings?.settingInfo?.general);
	
	return (
		<>
			<div class="right-main-header adminstrat_btn_main">
				<h2>Tasks</h2>
				{(currentUserRole === 'admin' || general?.type === 'Owner') &&
					<div class="adminstrat_btn">
						<button type="button" class="btn btn-primary" onClick={() => setAddNewTask(true)}>
							<svg width="21" height="20" viewBox="0 0 21 20" fill="none"
								xmlns="http://www.w3.org/2000/svg">
								<path d="M10.5 4.16699V15.8337M4.66663 10.0003H16.3333" stroke="white"
									stroke-width="1.66667" stroke-linecap="round"
									stroke-linejoin="round"></path>
							</svg>Create new task</button>
					</div>
				}
			</div>
			<div class="saf-img-upload task_header">
				<div class="task_search_box">
					<input type="search" class="form-control task_search" placeholder="Search" />
					<svg width="18" height="18" viewBox="0 0 18 18" fill="none"
						xmlns="http://www.w3.org/2000/svg">
						<path
							d="M16.5 16.5L13.5834 13.5833M15.6667 8.58333C15.6667 12.4954 12.4954 15.6667 8.58333 15.6667C4.67132 15.6667 1.5 12.4954 1.5 8.58333C1.5 4.67132 4.67132 1.5 8.58333 1.5C12.4954 1.5 15.6667 4.67132 15.6667 8.58333Z"
							stroke="#667085" stroke-width="1.66667" stroke-linecap="round"
							stroke-linejoin="round" />
					</svg>
				</div>
				<div class="member_day_main">
					<div class="member_day_btn">
						<select class="form-select" aria-label="Default select example" value={viewType} onChange={(e) => onViewChange(e.target.value)}>
							<option value='board_view'>Board View</option>
							<option value="list_view">List View</option>
						</select>
					</div>
				</div>

			</div>
		</>
	)
}
export default Header