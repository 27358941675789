import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getModulePermission, Create_role_permission } from '../../../../redux/services/AuthSettings';
import { useForm } from 'react-hook-form';
import Modal from 'react-modal';
import { getAuthSettingDetail } from '../../../../redux/services/AuthSettings';

const getCustomModalStyles = (isSmallScreen) => ({
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 1000
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        border: 'none',
        background: '#fff',
        borderRadius: '8px',
        padding: isSmallScreen ? '10px' : '20px',
        zIndex: 99,
        transition: 'all 1s ease-in-out',
        maxWidth: '750px',
        width: isSmallScreen ? '95%' : '90%',
        height: 'auto',
        maxHeight: '90vh',
    }
});

const AddRoles = ({ modalIsOpen, setModalIsOpen }) => {
	const [toggle, setToggle] = useState({});
	const [checkedPermissions, setCheckedPermissions] = useState({});
	const [errorMessage, setErrorMessage] = useState('');
	const [showMsg, setShowMsg] = useState(false);
	const [selectAll, setSelectAll] = useState(false);
	const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);
	const { register, handleSubmit, formState: { errors }, reset, setValue } = useForm();
	const ModulePermissions = useSelector(state => state?.AuthSettings.ModulePermissions);
	const dispatch = useDispatch();

	useEffect(()=>{
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth < 768);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    },[])

	useEffect(() => {
		dispatch(getModulePermission());
	}, [dispatch]);
	useEffect(() => {
		if (ModulePermissions) {
			const initialPermissions = {};
			ModulePermissions.forEach(module => {
				initialPermissions[module.id] = {};
				module.permission.forEach(permission => {
					initialPermissions[module.id][permission.id] = false;
				});
			});
			setCheckedPermissions(initialPermissions);
		}
	}, [ModulePermissions]);
	const toggleTab = (tabId) => {
		setToggle(prevState => ({
			...prevState,
			[tabId]: !prevState[tabId]
		}));
	};
	const handleCheckboxChange = (moduleId, permissionId, isChecked) => {
		setShowMsg(false)
		setCheckedPermissions(prevState => ({
			...prevState,
			[moduleId]: {
				...prevState[moduleId],
				[permissionId]: isChecked
			}
		}));
	};
	const handleSelectAllChange = (e) => {
		const isChecked = e.target.checked;
		setShowMsg(isChecked)
		setSelectAll(isChecked);
		const newCheckedPermissions = {};
		ModulePermissions.forEach(module => {
			newCheckedPermissions[module.id] = {};
			module.permission.forEach(permission => {
				newCheckedPermissions[module.id][permission.id] = isChecked;
			});
		});
		setCheckedPermissions(newCheckedPermissions);
	};

	const onSubmit = async (data) => {
		const permissions = [];
		try {
			let isAnyPermissionSelected = false;
			ModulePermissions.forEach(module => {
				module.permission.forEach(permission => {
					const isChecked = checkedPermissions[module.id]?.[permission.id] || false; // Default to false if not checked
					if (isChecked) {
						isAnyPermissionSelected = true;
					}
					permissions.push({
						permission_id: permission.id,
						permission_granted: isChecked
					});
				});
			});

			if (!isAnyPermissionSelected) {
				setErrorMessage('Please select at least one permission.');
				setShowMsg(true)
				setTimeout(() => {
					setShowMsg(false)
				}, 3000);
				return;
			}
			const formData = {
				role_name: data.role_name.trim(),
				role_desciption: data.role_desciption.trim(),
				permission: permissions
			};
			const response = await dispatch(Create_role_permission(formData));
			if (response?.payload?.status) {
				dispatch(getAuthSettingDetail());
				setSelectAll(false);
				setCheckedPermissions({});
				setValue('role_name', '')
				setValue('role_desciption', '')
				setModalIsOpen(false);
			}
		} catch (error) {
			console.error("Error during role creation:", error);
		}
	};
	const onClose = () => {
		setModalIsOpen(false);
		setSelectAll(false);
		setCheckedPermissions({});
		setErrorMessage('');
		setValue('role_name', '')
		setValue('role_desciption', '')
	}

	const customModalStyles = getCustomModalStyles(isSmallScreen)
 
	return (
		<>
			<Modal
				isOpen={modalIsOpen}
				onRequestClose={() => setModalIsOpen(false)}
				style={customModalStyles}
				contentLabel="Create Role Modal"
			>
				<div className="modal-dialog modal-lg permision-popup ">
					<svg className="modal-ripple" width="216" height="216" viewBox="0 0 216 216" fill="none"
						xmlns="http://www.w3.org/2000/svg">
						<mask id="mask0_9034_17571" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="-120" y="-120"
							width="336" height="336">
							<rect width="336" height="336" transform="translate(-120 -120)"
								fill="url(#paint0_radial_9034_17571)" />
						</mask>
						<g mask="url(#mask0_9034_17571)">
							<circle cx="48" cy="48" r="47.5" stroke="#EAECF0" />
							<circle cx="48" cy="48" r="47.5" stroke="#EAECF0" />
							<circle cx="48" cy="48" r="71.5" stroke="#EAECF0" />
							<circle cx="48" cy="48" r="95.5" stroke="#EAECF0" />
							<circle cx="48" cy="48" r="119.5" stroke="#EAECF0" />
							<circle cx="48" cy="48" r="143.5" stroke="#EAECF0" />
							<circle cx="48" cy="48" r="167.5" stroke="#EAECF0" />
						</g>
						<defs>
							<radialGradient id="paint0_radial_9034_17571" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
								gradientTransform="translate(168 168) rotate(90) scale(168 168)">
								<stop />
								<stop offset="1" stop-opacity="0" />
							</radialGradient>
						</defs>
					</svg>
					<div className="modal-content">
						<div className="modal-header ">
							<h5 className="modal-title" id="exampleModalLabel">
								<svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
									<g filter="url(#filter0_d_9022_25481)">
										<path
											d="M2.5 11C2.5 5.75329 6.75329 1.5 12 1.5H40C45.2467 1.5 49.5 5.75329 49.5 11V39C49.5 44.2467 45.2467 48.5 40 48.5H12C6.75329 48.5 2.5 44.2467 2.5 39V11Z"
											stroke="#EAECF0" shape-rendering="crispEdges" />
										<path
											d="M17 21L29 21M29 21C29 22.6569 30.3431 24 32 24C33.6569 24 35 22.6569 35 21C35 19.3431 33.6569 18 32 18C30.3431 18 29 19.3431 29 21ZM23 29L35 29M23 29C23 30.6569 21.6569 32 20 32C18.3431 32 17 30.6569 17 29C17 27.3431 18.3431 26 20 26C21.6569 26 23 27.3431 23 29Z"
											stroke="#344054" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
									</g>
									<defs>
										<filter id="filter0_d_9022_25481" x="0" y="0" width="52" height="52"
											filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
											<feFlood flood-opacity="0" result="BackgroundImageFix" />
											<feColorMatrix in="SourceAlpha" type="matrix"
												values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
											<feOffset dy="1" />
											<feGaussianBlur stdDeviation="1" />
											<feComposite in2="hardAlpha" operator="out" />
											<feColorMatrix type="matrix"
												values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.05 0" />
											<feBlend mode="normal" in2="BackgroundImageFix"
												result="effect1_dropShadow_9022_25481" />
											<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_9022_25481"
												result="shape" />
										</filter>
									</defs>
								</svg>
							</h5>
							<button type="button" className="btn-close" onClick={() => (onClose())}></button>
						</div>
						<div className="modal-body role-popup">
							<div className="font-24 hd-title py-3">Create role</div>
							<form onSubmit={handleSubmit(onSubmit)}>
								<div className="col-md-12 col-12 mb-3">
									<label htmlFor="role_name" className="form-label">Role*</label>
									<input type="text" className="form-control" id="role_name" {...register('role_name', { required: true })} maxLength={20} />
									{errors.role_name && <span className="error">Role name is required!</span>}
								</div>
								<div className="col-md-12 col-12 mb-3">
									<label htmlFor="role_description" className="form-label">Description*</label>
									<textarea className="form-control" id="role_description" rows="3" {...register('role_desciption', { required: true })} maxLength={100}></textarea>
									{errors.role_desciption && <span className="error">Role description is required!</span>}
								</div>
								<div className="col-md-12 col-12 mt-3">
									<div className="alert sap-alert-heading permision-head">
										<div className="font-24 hd-title">Permissions</div>
									</div>
									<div class="form-check selectall mb-4">
										<div class="form-check form-switch">
											<input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault"
												checked={selectAll}
												onChange={(e) => handleSelectAllChange(e)} />
										</div>
										<label class="form-check-label" for="flexCheckDefault">Select All</label>
									</div>
								</div>
								<div className="accordion accordion-flush sap-accor-form popup">
									{ModulePermissions && ModulePermissions.map((module, index) => {
										 const ascendingData = module?.permission?.slice().sort((a, b) => {
											if (!a.permission || !b.permission) return 0; // Handle undefined/null cases
											return a.permission.localeCompare(b.permission);
										});
										return(
										<div className="accordion-item" key={module?.id}>
											<h2 className="accordion-header">
												<button className={`accordion-button ${toggle[`tab${index + 1}`] ? '' : 'collapsed'}`}
													onClick={() => toggleTab(`tab${index + 1}`)} type="button" data-bs-toggle="collapse"
													data-bs-target={`#flush-collapse-${index + 1}`}
													aria-expanded={toggle[`tab${index + 1}`]}
													aria-controls={`flush-collapse-${index + 1}`}>
													<div className="saf-title-head">
														<div className="saf-left">
															<div className="font-20 hd-title">{module?.module_name}</div>
														</div>
													</div>
												</button>
											</h2>
											<div id={`flush-collapse-${index + 1}`} className={`accordion-collapse collapse ${toggle[`tab${index + 1}`] ? 'show' : ''}`}
												aria-labelledby={`flush-heading-${index + 1}`}
												data-bs-parent="#accordionFlushExample">
												<div className="accordion-body">
													{ascendingData && ascendingData.map(permission => (
														<>
															<div className="switch-tab-details" key={permission?.id}>
																<div className="switch-tabs">
																	<div className="font-20 hd-title">{permission?.permission}</div>
																	<div className="font-16 hd-subtitle">{permission?.permission_desc}</div>
																</div>
																<div className="form-check form-switch">
																	<input className="form-check-input"
																		type="checkbox"
																		id={`permission_${permission?.id}`}
																		checked={checkedPermissions[module?.id]?.[permission?.id] || false}
																		onChange={(e) => handleCheckboxChange(module?.id, permission?.id, e.target.checked)}
																	/>
																</div>
															</div>
														</>
													))}
												</div>
											</div>
										</div>
									)}
									)}
								</div>
								{showMsg && <div className='error'>{errorMessage}</div>}
								<div className="col-md-12 login-btn">
									<button type="button" className="btn-outline-primary btn" onClick={() => onClose()} >Cancel</button>
									<button type="submit" className="btn btn-primary" >Create Role</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</Modal>
		</>
	);
};

export default AddRoles;
