import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Create_task, get_all_admin_home_users } from '../../../redux/services/AuthTasks'
import { reSetEditTasksData } from '../../../redux/features/AuthTasksSlice'
import moment from 'moment'

const AddNewTask = ({ setAddNewTask, setTaskStatus, setTaskStatusModal, taskStatus={} }) => {
    const dispatch = useDispatch();

    const { handleSubmit, register, setValue, formState: { errors } } = useForm();
    const AllUsers = useSelector(state => state?.AuthTasks?.AllUsers);

    const [minDate, setMinDate] = useState('');


    useEffect(() => {
        dispatch(get_all_admin_home_users())
        const dtToday = new Date();

        const month = String(dtToday.getMonth() + 1).padStart(2, '0');
        const day = String(dtToday.getDate()).padStart(2, '0');
        const year = dtToday.getFullYear();

        const maxDate = `${year}-${month}-${day}`;

        setMinDate(maxDate);
    }, [])

    useEffect(() => {
        if (Object.keys(taskStatus).length > 0) {
            setValue('title', taskStatus.title)
            setValue('assigned_to', taskStatus.assigned_to)
            setValue('due_date', moment(taskStatus.due_date).format('YYYY-MM-DD'))
            setValue('description', taskStatus.description)
        }
    }, [taskStatus])

    const onSubmit = async (payload) => {
        let data = payload
        data.task_status = 'pending'
        
        if (Object.keys(taskStatus).length > 0) {
            data.task_id = taskStatus.id
            data.task_status = taskStatus.task_status
        }
        let response = await dispatch(Create_task(data))
        if (response?.payload?.status) {
            setAddNewTask(false)
            setTaskStatus({})
        setTaskStatusModal(false)
            dispatch(reSetEditTasksData())
        }
    }

    const handleCancleClick = () => {
        setTaskStatus({})
        setTaskStatusModal(false)
        setAddNewTask(false)
        dispatch(reSetEditTasksData())
    }    
    return (
        <>
            <div class="modal fade show new_medi_modal">
                <div class="modal-dialog modal-lg task_po">
                    <div class="modal-content ">
                        <div class="modal-header task_heading">
                            <button type="button" class="btn-close" onClick={() => handleCancleClick()}></button>
                        </div>
                        <div class="modal-body appointment_modal">
                            <div class="apnorht_head">
                                <div class="apnorht_head_inner h_task">
                                    <h4 class="font-18 hd-title">{`${Object.keys(taskStatus).length > 0 ? 'Update' : 'Create'} task`}</h4>
                                </div>
                            </div>

                            <form className="row" onSubmit={handleSubmit(onSubmit)}>

                                <div className="col-md-12 mb-3">
                                    <label for="" className="form-label">Title</label>
                                    <input type="input" className="form-control" id="" placeholder="Enter Title" {...register("title", { required: "true" })} maxLength={25} />
                                    {errors.title && errors.title.type === "required" && (<span className="error">Title is required!</span>)}
                                </div>

                                <div className="col-md-12 mb-3">
                                    <label htmlFor="" className="form-label">Assigned to</label>
                                    <select className="form-select" aria-label="Default select example" name='assigned_to' {...register('assigned_to', { required: 'true' })} disabled={Object.keys(taskStatus).length > 0 ? true : false} >
                                        <option value="">-Please Select -</option>
                                        {AllUsers && AllUsers.length > 0 && AllUsers.map((item) => <option value={item?.id}>{item?.first_name} {item?.last_name}</option>)}
                                    </select>
                                    {errors.assigned_to && errors.assigned_to.type === "required" && <span className="error">Assigned to request is required</span>}
                                </div>

                                <div className="col-md-12 mb-3">
                                    <label for="" className="form-label">Due date</label>
                                    <input type="date" min={minDate} className="form-control" id="" placeholder="Due Date" {...register("due_date", { required: "true", })}
                                        onKeyDown={(e) => e.preventDefault()} />
                                    {errors.due_date && errors.due_date.type === "required" && (<span className="error"> Due Date is required!</span>)}
                                </div>

                                <div className="col-md-12 mb-12 my-4">
                                    <label for="" className="form-label">Description</label>
                                    <textarea className="form-control" id="" rows="4"
                                        placeholder="Description" {...register('description')}></textarea>
                                </div>
                                <div class="cancel_delete">
                                    <button type="button" class="btn btn-light btn_min_width" onClick={() => handleCancleClick()}>Cancel</button> &nbsp;
                                    <button type="submit" class="btn btn-primary text-white btn_min_width">{`${Object.keys(taskStatus).length > 0 ? 'Update' : 'Create'}`}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default AddNewTask