import React from 'react'
import { useSelector } from 'react-redux';
const PcspFooter = ({onHandleBackStep}) => {
    const steps = useSelector(state => state?.AuthMember?.steps);    
    const isLastStep = steps?.step === steps?.totalSteps - 1 && steps?.subStep === steps?.totalSubSteps - 1;

    return(
        <>
        <div className="col-md-12 login-btn">
                        <div className="three-btn-sec">
                            <div className="two-btn-sec">
                                {/* <button type="button" className="btn btn-light">Save as draft</button> */}
                            </div>
                            <div className="two-btn-sec">
                                {steps?.step === 0 && steps?.subStep ===0 ?
                                null
                                :
                                <button type="button" className="btn btn-light me-md-2" onClick={()=>onHandleBackStep()}>
                                    <svg width="16" height="14" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7.91683 5.00016H2.0835M2.0835 5.00016L5.00016 7.91683M2.0835 5.00016L5.00016 2.0835"
                                            stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>Go Back</button>
                                }
                                <button type="submit" className="btn btn-primary" >{isLastStep ? 'Submit' : 'Next'}</button>

                            </div>
                        </div>
                    </div>
        </>
    )
}

export default PcspFooter