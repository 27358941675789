import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { getCountry, getState } from "../../../redux/services/common";
import { useDispatch, useSelector } from 'react-redux';
import { edit_home, update_home, Activate_home } from '../../../redux/services/AuthHomes';
import { useParams, Link, useNavigate } from 'react-router-dom';
import DeActivateHome from './DeActivateHome';

import HomeUsers from './HomeUsers';
import EditHomeDelete from './EditHomeDelete';
import HomeMembers from './HomeMembers';
import TimeZoneModal from './TimeZoneModal'

export const EditHome = () => {
  const CountryData = useSelector((state) => state?.common?.CountryData);
  const stateData = useSelector((state) => state?.common?.StateData);
  const [state, setState] = useState("");
  const [countryId, setCountryID] = useState("");
  const [notification, setNotification] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [deleteHome, setDeleteHome] = useState(false);
  const [timezones, setTimezones] = useState([])
  const [selectedTimezone, setSelectedTimezone] = useState('')
  const [timezoneModal, setTimezoneModal] = useState(false)
  const [deleteSingle, setDeleteSingle] = useState({
    modal: false,
    home_table_id: '',
    staff: ''
  });
  const [search, setSearch] = useState({
    page: 1,
    listData: 10,
    searchBar: '',
    max_cap: { start: '', end: '' },
    current_cap: { start: '', end: '' },
    no_users: { start: '', end: '' },
  });
  const [showError, setShowError] = useState({
    country: false,
    user: false
  });
  const id = useParams();
  const navigate = useNavigate();
  const { register, handleSubmit, getValues, setValue, watch, formState: { errors }, reset, control } = useForm();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(edit_home(id?.id));
  }, [])
  const EditHomeData = useSelector((state) => state?.AuthHomes?.EditHomeData);

  useEffect(() => {
    if(EditHomeData?.country_id){
      let time =  CountryData && CountryData?.filter((ele) => ele.id === EditHomeData?.country_id)
      setTimezones(JSON.parse(time[0].timezones))
    }
    if(EditHomeData?.time_zone){
      setSelectedTimezone(EditHomeData?.time_zone)
    }
    setValue('home_name', EditHomeData?.home_name);
    setValue('license_number', EditHomeData?.license_number);
    setValue('time_zone', EditHomeData?.time_zone);
    setValue('cost_center_number', EditHomeData?.cost_center_number);
    setValue('address1', EditHomeData?.address1);
    setValue('address2', EditHomeData?.address2);
    setValue('email_address', EditHomeData?.home_name);
    setCountryID(EditHomeData?.country_id);
    setValue('is_active', EditHomeData?.is_active === true ? 'true' : 'false');
    setValue('city', EditHomeData?.city);
    setValue('zip_code', EditHomeData?.zip_code);
    setValue('phone_no', EditHomeData?.phone_no);
    setValue('emergency_contact', EditHomeData?.emergency_contact);
    setValue('email', EditHomeData?.email);
    setValue('fax', EditHomeData?.fax || '');
    setValue('lic_issue_date', EditHomeData?.lic_issue_date);
    setValue('lic_expiry_date', EditHomeData?.lic_expiry_date);
    setValue('max_capacity', EditHomeData?.max_capacity);
    setValue('home_id', EditHomeData?.home_id);
    setValue('organization_code', EditHomeData?.organization_code);
    setNotification(EditHomeData?.lic_notification);
    setTimeout(() => {
      setValue('country_id', (EditHomeData?.country_id));
      setValue('state_id', (EditHomeData?.state_id));
    }, 1000)
  }, [EditHomeData])
  const onSubmit = async () => {
    const form = new FormData();
    form.append("home_name", getValues("home_name"));
    form.append("license_number", getValues("license_number"));
    form.append("time_zone", getValues('time_zone'));
    form.append("cost_center_number", getValues("cost_center_number"));
    form.append("address1", getValues("address1"));
    form.append("address2", getValues("address2"));
    form.append("country_id", getValues("country_id"));
    form.append("state_id", getValues("state_id"));
    form.append("city", getValues("city"));
    form.append("zip_code", getValues("zip_code"));
    form.append("phone_no", getValues("phone_no"));
    form.append("emergency_contact", getValues("emergency_contact"));
    form.append("email", getValues("email"));
    form.append("fax", getValues("fax") || '');
    form.append("lic_issue_date", getValues("lic_issue_date"));
    form.append("lic_expiry_date", getValues("lic_expiry_date"))
    form.append("lic_notification", notification);
    form.append("q_id", id?.id);
    form.append("max_capacity", getValues("max_capacity"));
    form.append("is_active", getValues("is_active"));
    const data = [];
    // data?.length > 0 ? data.forEach(value => {
    //   form.append("userAssign[]", value);
    // }) :
    form.append("userAssign", JSON.stringify(data));
    form.append("memberAssign", JSON.stringify([]));
    try {
      // if (selectedSubOptions.length == 0) {
      //   setShowError({ user: true })
      // } else {
      setShowError({ user: false })
      const response = await dispatch(update_home(form));
      if (response?.payload?.status === true) {
        // dispatch(setDataTableCheck({ homeData: 1 }));
        // dispatch(get_all_users(search));
        navigate('/auth/homes')
      }
      // }
    } catch (error) {
      console.error("Error during add home:", error);
    }
  }
  useEffect(() => {
    dispatch(getCountry());
    if (countryId) {
      dispatch(getState(countryId));
    }
  }, [countryId]);

  const handleStateChange = (e) => {
    setState(e);
    setValue("city", "");
  };
  const handleCountryChange = (e) => {
    setCountryID(e.target.value);
    setValue("state_id", "");
    e.target.value ? setShowError({ country: false }) : setShowError({ country: true });
    setValue("city", "");
    setState("");
    let time = CountryData && CountryData?.filter((ele) => ele.id === e.target.value)
    setTimezones(JSON.parse(time[0].timezones))
    let zone = JSON.parse(time[0].timezones)
    setSelectedTimezone(`${zone[0]?.zoneName} (${zone[0]?.gmtOffsetName})`)
    setValue('time_zone',`${zone[0]?.zoneName} (${zone[0]?.gmtOffsetName})`)
  };
  //Activate Home
  const ActivatHome = async () => {
    const response = await dispatch(Activate_home(id?.id));
    navigate("/auth/homes");
  }
  const handleTimeZone = () => {
    setTimezoneModal(!timezoneModal)
  }

  const sortedCountryData = CountryData ? 
  [CountryData.find(elem => elem.iso2 === "US"), 
  ...CountryData.filter(elem => elem.iso2 !== "US")] 
  : [];


  return (
    <div id="layoutSidenav_content">
      <main>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 col-lg-12 col-12">
              <div className="right-main-sec">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/auth/dashboard">
                      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6.77168 11.6663C7.14172 13.104 8.4468 14.1663 10 14.1663C11.5532 14.1663 12.8583 13.104 13.2283 11.6663M9.18141 2.30297L3.52949 6.6989C3.15168 6.99275 2.96278 7.13968 2.82669 7.32368C2.70614 7.48667 2.61633 7.67029 2.56169 7.86551C2.5 8.0859 2.5 8.32521 2.5 8.80384V14.833C2.5 15.7664 2.5 16.2331 2.68166 16.5896C2.84144 16.9032 3.09641 17.1582 3.41002 17.318C3.76654 17.4996 4.23325 17.4996 5.16667 17.4996H14.8333C15.7668 17.4996 16.2335 17.4996 16.59 17.318C16.9036 17.1582 17.1586 16.9032 17.3183 16.5896C17.5 16.2331 17.5 15.7664 17.5 14.833V8.80384C17.5 8.32521 17.5 8.0859 17.4383 7.86551C17.3837 7.67029 17.2939 7.48667 17.1733 7.32368C17.0372 7.13968 16.8483 6.99275 16.4705 6.69891L10.8186 2.30297C10.5258 2.07526 10.3794 1.9614 10.2178 1.91763C10.0752 1.87902 9.92484 1.87902 9.78221 1.91763C9.62057 1.9614 9.47418 2.07526 9.18141 2.30297Z" stroke="#667085" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                    </Link>
                  </li>
                  <li className="breadcrumb-item active">
                    <Link to="/auth/homes">
                      Homes
                    </Link>
                  </li>
                  <li className="breadcrumb-item active">{EditHomeData?.home_name}</li>
                </ol>
                <div className="right-main-header search-sec">
                  <div className="left-side-content">
                    <div className="font-30 hd-title">{EditHomeData?.home_name}</div>
                  </div>
                  <div className="left-side-content">
                    <button type="submit" className="btn btn-danger mx-2" onClick={() => (setDeleteHome(true), setDeleteSingle({ modal: true, home_table_id: id?.id }))}>Delete</button>
                    {EditHomeData?.is_active == true ?
                      <button type="button" className="btn btn-outline-danger mx-2" onClick={() => setModalIsOpen(true)}>
                        Deactivate Home
                      </button>
                      :
                      <button type="button" className="btn btn-outline-primary mx-2" onClick={() => ActivatHome()} >
                        Activate Home
                      </button>
                    }
                  </div>
                </div>
                <div className="setting-tabs">
                  <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation">
                      <button className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">Home Information</button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button className="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">Users</button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button className="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact" type="button" role="tab" aria-controls="contact" aria-selected="false">Members</button>
                    </li>
                  </ul>
                  <div className="tab-content" id="myTabContent">
                    <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                      <form onSubmit={handleSubmit(onSubmit)} enctype="multipart/form-data">
                        <div className="font-18 py-3 hd-title">Basic information</div>
                        <div className="row">
                          <div className="col-md-6 col-12 mb-3">
                            <label for="" className="form-label">Home name*</label>
                            <input type="input" className="form-control" id="" placeholder="Home name"  {...register("home_name", { required: "true" })} maxLength={20} />
                            {errors.home_name && errors.home_name.type === "required" && (<span className="error"> Home name is required!</span>)}
                          </div>
                          <div className="col-md-6 col-12 mb-3">
                            <label for="" className="form-label">Home ID*</label>
                            <input type="input" className="form-control" id="" placeholder=" Home ID" {...register("home_id")} disabled />
                          </div>
                          <div className="col-md-6 col-12 mb-3">
                          <label for="" className="form-label"> Site code*</label>
                              <input type="input" className="form-control" id="" placeholder="Site code" {...register("cost_center_number", { required: "true", pattern: /^[A-Za-z0-9]+$/, })} maxLength={15} onKeyPress={(event) => {
                                  if (!/[A-Za-z0-9]/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                }} />
                            {errors.cost_center_number && errors.cost_center_number.type === "required" && (<span className="error">Cost center number is required!</span>)}
                          </div>
                          <div className="col-md-6 col-12 mb-3">
                            <label for="" className="form-label">License number*</label>
                            <input type="input" className="form-control" id="" placeholder=" License number" {...register("license_number", {
                              required: true,
                              // pattern: /^[A-Z0-9]{10,20}$/, 
                            })}
                              maxLength={20}  onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault()}}}/>
                            {errors.license_number && errors.license_number.type === "required" && (<span className="error">License number is required!</span>)}
                            {/* {errors.license_number &&
                              errors.license_number.type === "pattern" && (
                                <span className="error">
                                  License number must contain only uppercase letters and numbers, and be between 10 to 20 characters long!
                                </span>
                              )} */}
                          </div>
                          <div className="col-md-12 col-12 mb-3">
                            <label for="" className="form-label">Organization Code*</label>
                            <input type="input" className="form-control" id="" placeholder=" Organization Code" {...register("organization_code")} disabled />
                          </div>
                        </div>
                        <div className="col-md-12 login-btn">
                          <hr />
                        </div>
                        <div className="font-18 py-3 hd-title">Location</div>
                        <div className="row">
                          <div className="col-md-6 mb-3">
                          <label for="" className="form-label">Address</label>
                            <input type="input" className="form-control" id="" placeholder="Street 1"  {...register("address1")} maxLength={50} />
                            {/* {errors.address1 && errors.address1.type === "required" && (<span className="error"> Address is required!</span>)} */}
                          </div>
                          <div className="col-md-6 mb-3">
                          <label for="" className="form-label">Address 2</label>
                            <input type="input" className="form-control" id="" placeholder="Street 2"  {...register("address2")} maxLength={50}/>
                          </div>
                          <div className="col-md-6 mb-3">
                          <label for="" className="form-label">Country*</label>
                            <select className="form-select" aria-label="Default select example" {...register("country_id", { required: true })} onChange={(e) => (handleCountryChange(e))} name="country_id" autocomplete="off">
                              <option value="">Select country</option>
                              {sortedCountryData && sortedCountryData.map((elem) => {                                
                                return (<option key={elem?.id} value={elem?.id}>{elem?.country}</option>);
                              })}
                            </select>
                            {showError.country ? errors.country_id && errors.country_id.type === "required" && (<span className="error">Country is required</span>
                            )
                              : ""}
                          </div>
                          <div className="col-md-6 mb-3">
                          <label for="" className="form-label">State</label>
                            <select className="form-select" aria-label="Default select example" name="state_id" {...register("state_id")} onChange={(e) => handleStateChange(e.target.value)}>
                              <option value="">Select state</option>
                              {stateData &&
                                stateData?.map((elem) => {
                                  return (<option value={elem?.id}>{elem?.address_state}</option>);
                                })}
                            </select>
                            {/* {state === "" ? errors.state_id && errors.state_id.type === "required" && (<span className="error">State is required!</span>) : ""} */}
                          </div>
                          <div className="col-md-6 mb-3">
                          <label for="" className="form-label">City</label>
                            <input type="input" className="form-control" id="" placeholder=" City"  {...register("city")} maxLength={50} />
                            {/* {errors.city && errors.city.type === "required" && (<span className="error">City is required!</span>)} */}
                          </div>
                          <div className="col-md-6 mb-3">
                          <label for="" className="form-label">Zip code</label>
                            <input type="input" className="form-control" id="" placeholder=" Zip code" onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault(); } }} maxLength={5}
                              {...register("zip_code", { pattern: { value: /^\d{5}$/, message: "ZIP code must be exactly 5 digits", }, })} />
                            {/* {errors.zip_code && errors.zip_code.type === "required" && (<span className="error">Zipcode is required!   </span>)} */}
                            {errors.zip_code && errors.zip_code.type === "pattern" && (<span className="error">{errors?.zip_code?.message}</span>)}
                          </div>

                          <div className="col-md-8 mb-3">
                              <label htmlFor="timezone" className="form-label">Timezone Location</label>
                              <input type="input" className="form-control" id="" value={selectedTimezone} placeholder="Timezone" {...register("time_zone")} readOnly/>
                              {/* {errors.time_zone && errors.time_zone.type === "required" && (<span className="error">Timezone is required!</span>)} */}
                            </div>
                            <div className="col-md-4 login-btn">
                              {timezones && timezones.length > 0 &&
                              <>
                              <button type="button" className="btn btn-primary timezone" onClick={handleTimeZone} >Change Timezone</button>
                              </>
                              }
                            </div>
                        </div>
                        <div className="col-md-12 login-btn">
                          <hr />
                        </div>
                        <div className="font-18 py-3 hd-title">Contact details</div>
                        <div className="row">
                          <div className="col-md-12 mb-3">
                            <label for="" className="form-label">Phone</label>
                            <input type="input" className="form-control" id="" placeholder="Phone" maxLength={15} onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault(); } }}
                              {...register("phone_no", {
                                pattern: {
                                  value: /^\d{10,15}$/,
                                  message: "Phone number must be 10 to 15 digits",
                                },
                              })} />
                            {/* {errors.phone_no && errors.phone_no.type === "required" && (<span className="error">Phone number is required</span>)} */}
                            {errors.phone_no && errors.phone_no.type === "pattern" && (<span className="error">{errors?.phone_no?.message}</span>)}
                          </div>
                          <div className="col-md-12 mb-3">
                            <label for="" className="form-label">Email address</label>
                            <input type="input" className="form-control" id="" placeholder="Email address" {...register("email")} disabled />
                          </div>
                          <div className="col-md-12 mb-3">
                            <label for="" className="form-label">Fax</label>
                            <input type="input" className="form-control" id="" placeholder="Fax number" {...register("fax")} maxLength={20} />
                            {/* {errors.fax && errors.fax.type === "required" && (<span className="error">Fax is required!</span>)} */}
                          </div>
                          <div className="col-md-12 mb-3">
                            <label for="" className="form-label">Emergency Contact*</label>
                            <input type="input" className="form-control" id="" placeholder="Emergency Contact" maxLength={15} onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault(); } }}
                              {...register("emergency_contact", {
                                required: "true",
                                pattern: {
                                  value: /^\d{10,15}$/,
                                  message: "Emergency Contact number must be 10 to 15 digits",
                                },
                              })} />
                            {errors.emergency_contact && errors.emergency_contact.type === "required" && (<span className="error">Phone number is required</span>)}
                            {errors.emergency_contact && errors.emergency_contact.type === "pattern" && (<span className="error">{errors?.emergency_contact?.message}</span>)}
                          </div>
                        </div>
                        <div className="col-md-12 login-btn">
                          <hr />
                        </div>
                        <div className="font-18 py-3 hd-title">Others</div>
                        <div className="row">
                          <div className="col-md-6 mb-3">
                            <label for="" className="form-label">Status*</label>
                            <select className="form-select" aria-label="Default select example"   {...register("is_active", { required: "true" })}>
                              <option value="">Select Status</option>
                              <option value="true">Active</option>
                              <option value="false">InActive</option>
                            </select>
                            {errors.is_active && errors.is_active.type === "required" && (<span className="error">selection is required!</span>)}

                          </div>
                          <div className="col-md-6 mb-3">
                            <label for="" className="form-label">Home max capacity*</label>
                            <input type="input" className="form-control" id="" placeholder=" Home max capacity"   {...register("max_capacity")} maxLength={3}
                              disabled />
                            {/* {errors.max_capacity && errors.max_capacity.type === "required" && (<span className="error"> maxCapacity is required!</span>)} */}
                          </div>
                          <div className="col-md-6 mb-3">
                            <label for="" className="form-label">Issue Date*</label>
                            <input type="date" className="form-control" id=""  {...register("lic_issue_date", { required: "true", })}
                              // max={(new Date(Date.now() - 86400000)).toISOString().split('T')[0]}
                              onKeyDown={(e) => e.preventDefault()} />
                            {errors.lic_issue_date && errors.lic_issue_date.type === "required" && (<span className="error">Issue Date is required!</span>)}
                          </div>
                          <div className="col-md-6 mb-3">
                            <label for="" className="form-label">Expiration Date*</label>
                            <input type="date" className="form-control" id="" placeholder="Expiration Date"  {...register("lic_expiry_date", { required: "true", })}
                              //  min={new Date().toISOString().split('T')[0]} max="2099-12-31" 
                              onKeyDown={(e) => e.preventDefault()} />
                            {errors.lic_expiry_date && errors.lic_expiry_date.type === "required" && (<span className="error">Expiration Date is required!</span>)}
                          </div>
                          <div className="col-md-12 mb-3">
                            <div className="form-check">
                              <input className="form-check-input" type="checkbox" value='' id="flexCheckDefault" onChange={(e) => setNotification(e.target.checked)} checked={notification == true ? true : false} />
                              <label className="form-check-label" for="flexCheckDefault">
                                By checking this box you choose to be notified 90 days before the home license expiration date
                              </label>
                            </div>
                          </div>
                          <div className="col-md-12 login-btn">
                            <button type="submit" className="btn btn-primary">Save Changes</button>
                          </div>
                        </div>

                      </form>
                    </div>
                    <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                      <HomeUsers id={id} />
                    </div>
                    <div className="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
                         <HomeMembers id={id} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <TimeZoneModal modalIsOpen={timezoneModal} setModalIsOpen={setTimezoneModal} timezones={timezones} setSelectedTimezone={setSelectedTimezone}/>
      <DeActivateHome id={id} modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen} />
      <EditHomeDelete modalIsOpen={deleteHome} setModalIsOpen={setDeleteHome} type={'Deactivate_single'} search={search} setDeleteSingle={setDeleteSingle} deleteSingle={deleteSingle} />
    </div>
  )
}
