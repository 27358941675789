import React, { useEffect } from "react";
import { useForm, useFieldArray } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Footer from '../../Footer'

const VisionAids = ({ onHandleBackStep, onSubmit }) => {
    const allIndividualizedHealth = useSelector(state => state?.AuthMember?.allIndividualizedHealth?.healthMedicalFourthStep);
    // Initialize the form with useForm hook
    const { register, handleSubmit, setValue, formState: { errors } } = useForm();

    useEffect(() => {
        setValue('vision_aids', allIndividualizedHealth?.vision_aids);
        setValue('hearing_aids', allIndividualizedHealth?.hearing_aids);
        setValue('dental_appliance', allIndividualizedHealth?.dental_appliance);
        setValue('protective_device', allIndividualizedHealth?.protective_device);
        setValue('purpose', allIndividualizedHealth?.purpose);
        setValue('instruction_of_use', allIndividualizedHealth?.instruction_of_use);
        setValue('other_individualized', allIndividualizedHealth?.other_individualized);

    }, [allIndividualizedHealth])
    return (
        <form onSubmit={handleSubmit(onSubmit)} className="row">
            <div className="col-md-6">
                <label for="" className="form-label">Vision aids*</label>
                <div className="ddd_steptwo_para">
                    <select
                        className="form-select"
                        aria-label="Default select example"
                        {...register(`vision_aids`, { required: "Vision aids is required!" })}>
                        <option value="">Select</option>
                        <option value="1">Yes</option>
                        <option value="0">No</option>
                    </select>
                    {errors.vision_aids && (
                        <span className="error">{errors.vision_aids.message}</span>
                    )}
                </div>
            </div>
            <div className="col-md-6">
                <label for="" className="form-label">Hearing aids*</label>
                <div className="ddd_steptwo_para">
                    <select
                        className="form-select"
                        aria-label="Default select example"
                        {...register(`hearing_aids`, { required: "Hearing aids is required!" })}>
                        <option value="">Select</option>
                        <option value="1">Yes</option>
                        <option value="0">No</option>
                    </select>
                    {errors.hearing_aids && (
                        <span className="error">{errors.hearing_aids.message}</span>
                    )}
                </div>
            </div>
            <div className="col-md-6">
                <label for="" className="form-label">Dental appliances*</label>
                <div className="ddd_steptwo_para">
                    <select
                        className="form-select"
                        aria-label="Default select example"
                        {...register(`dental_appliance`, { required: "Dental appliances is required!" })}>
                        <option value="">Select</option>
                        <option value="1">Yes</option>
                        <option value="0">No</option>
                    </select>
                    {errors.dental_appliance && (
                        <span className="error">{errors.dental_appliance.message}</span>
                    )}
                </div>
            </div>
            <div className="col-md-6">
                <label for="" className="form-label">Protective devices*</label>
                <div className="ddd_steptwo_para">
                    <select
                        className="form-select"
                        aria-label="Default select example"
                        {...register(`protective_device`, { required: "Protective devices is required!" })}>
                        <option value="">Select</option>
                        <option value="1">Yes</option>
                        <option value="0">No</option>
                    </select>
                    {errors.protective_device && (
                        <span className="error">{errors.protective_device.message}</span>
                    )}
                </div>
            </div>
            <div className="col-12">
                <label for="" className="form-label">Purpose*</label>
                <div className="ddd_steptwo_para">
                    <textarea className="form-control" placeholder="Enter Purpose" {...register("purpose", { required: "true" })} maxLength={150} />
                    {errors.purpose && errors.purpose.type === "required" && (<span className="error">This is required!</span>)}
                </div>
            </div>
            <div className="col-12">
                <label for="" className="form-label">Instructions for use*</label>
                <div className="ddd_steptwo_para">
                    <textarea className="form-control" placeholder="Enter Instructions for use" {...register("instruction_of_use", { required: "true" })} maxLength={150} />
                    {errors.instruction_of_use && errors.instruction_of_use.type === "required" && (<span className="error">This is required!</span>)}
                </div>
            </div>
            <div className="col-12">
                <label for="" className="form-label">Other individualized health care routines*</label>
                <div className="ddd_steptwo_para">
                    <textarea className="form-control" placeholder="Enter Other individualized health care routines" {...register("other_individualized", { required: "true" })} maxLength={150} />
                    {errors.other_individualized && errors.other_individualized.type === "required" && <span className="error">This is required!</span>}
                </div>
            </div>
            <Footer onHandleBackStep={onHandleBackStep} />
        </form>
    )

}
export default VisionAids