import React, {useEffect, useState} from "react";
import { useDispatch, useSelector } from 'react-redux';
import { setUserPermissions } from '../../../redux/features/commonSlice'
import Header from './Header'
import ResultNotFound from './ResultNotFound'
import TableView from './TableView'
import AddDocuments from './AddDocuments'
import ViewDocuments from "./ViewDocuments";
import {get_all_documents_list, destroy_document} from '../../../redux/services/AuthDocuments'

const initionalSearch = {
    page: 1,
    listData: 10,
    searchBar: '',
    category_id: '',
    file_type: '',
  }
const Documents = () => {
    const dispatch = useDispatch()
    const moduleData = useSelector((state) => state?.common?.moduleData);
    const userPermissions = useSelector((state) => state?.common?.userPermissions);
    const documentsById = useSelector((state) => state?.AuthDocuments?.DocumentsById);
    const AlldocumentsList = useSelector((state) => state?.AuthDocuments?.AllDocumentsList?.documentsData)
    const totalPages = useSelector((state) => state?.AuthDocuments?.AllDocumentsList?.totalNoPages)

    const [addNewModal, setAddNewModal] = useState(false);
    const [viewDocumentModal, setViewDocumentModal] = useState('');
    const [search, setSearch] = useState(initionalSearch);
    const [currentPage, setCurrentPage] = useState(1);


    useEffect(() => {
        if (moduleData.length > 0) {
          let module = moduleData.find((item) => item.module_name === 'Documents')
          console.log(Object.keys(module).length,'modulemodule');
          
          if (module && Object.keys(module).length > 0) {
            const permissionsArray = module.permission.map(item => item.permission);
            dispatch(setUserPermissions(permissionsArray))
          }
        }
      }, [moduleData])

      useEffect(() => {
        if (!addNewModal) {
          dispatch(get_all_documents_list(search))
        }
      }, [search, addNewModal, viewDocumentModal])
    
      const handleSearchBar = (e) => {
        setSearch({
          ...search,
          page: 1,
          searchBar: e.target.value,
        });
      };
    
      const handleRowClick = (id) => {
        console.log(userPermissions,'userPermissionsuserPermissions');
        
        if (userPermissions && userPermissions.includes('Download')) {
            setViewDocumentModal(id)
        }
      }
    
      const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber)
        setSearch({
          ...search,
          page: pageNumber
        });
      };

      const handleDelete = async () => {
        // Delete the document
        let response = await dispatch(destroy_document(documentsById.id));
        
        // If deletion is successful
        if (response?.payload?.status) {
            // Check if we are on page 2 and there are no documents left
            const isLastPageEmpty = (currentPage !== 1) && (AlldocumentsList.length === 1);
            
            if (isLastPageEmpty) {
                // If page 2 becomes empty after delete, set the page to 1
                setCurrentPage(currentPage - 1)
                setSearch({
                    ...search,
                    page: currentPage - 1
                });
            }
    
            // Reset the view document modal
            setViewDocumentModal('');
        }
    }
    
    return (
        <div id="layoutSidenav_content">
          <main>
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12 col-lg-12 col-12">
                <Header handleSearchBar={handleSearchBar} search={search} setSearch={setSearch} onModalToggle={setAddNewModal} />
                  {AlldocumentsList && AlldocumentsList.length === 0 ?
                    <ResultNotFound />
                    :
                    <TableView documentsList={AlldocumentsList} currentPage={currentPage} totalPages={totalPages} handlePageChange={handlePageChange} search={search} onRowClick={handleRowClick} />
                  }
                  {addNewModal && <AddDocuments onModalToggle={setAddNewModal} />}
                  {viewDocumentModal !== '' && <ViewDocuments id={viewDocumentModal} onModalToggle={setViewDocumentModal} handleDelete={handleDelete}/>}
                </div>
              </div>
            </div>
          </main>
        </div>
      )
}
export default Documents