import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import ResultNotFound from './ResultNotFound';
import { get_list_document_member } from '../../../redux/services/AuthDocuments';
import { Pagination } from '../../Pagination';
import { monthDayYearFormat } from '../../../components/Common/Common';

const initialSearch = {
    page: 1,
    listData: 10,
    searchBar: '',
    category_id: '',
    file_type: '',
    member_id: ''
};

const fileFormat = {
    'vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx',
    'vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
    'msword': 'doc',
    'jpeg': 'jpeg',
    'jpg': 'jpg',
    'png': 'png',
    'pdf': 'pdf',
};

const Documents = () => {
    const dispatch = useDispatch();
    const { id } = useParams();

    const moduleData = useSelector((state) => state?.common?.moduleData);
    const userPermissions = useSelector((state) => state?.common?.userPermissions);
    const AlldocumentsList = useSelector((state) => state?.AuthDocuments?.AllDocumentsListMember?.documentsData);
    const totalPages = useSelector((state) => state?.AuthDocuments?.AllDocumentsListMember?.totalNoPages);

    const [search, setSearch] = useState(initialSearch);
    const [currentPage, setCurrentPage] = useState(1);
    const [sortConfig, setSortConfig] = useState({ key: 'created_date', direction: 'desc' });

    useEffect(() => {
        if(id) {
            let payload = {...search}
            payload.member_id = id            
            dispatch(get_list_document_member(payload));
        }
    }, [search, id]);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
        setSearch({
            ...search,
            page: pageNumber
        });
    };

    const handleDownload = async (file) => {        
        if (file.file_url) {
            try {
                const response = await fetch(file.file_url);
                if (!response.ok) throw new Error('Network response was not ok');

                const blob = await response.blob();
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = `${file?.file_name}`;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } catch (error) {
                console.error('Download failed:', error);
            }
        }
    };

    const handleSort = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
    };

    const sortedDocs = AlldocumentsList && [...AlldocumentsList].sort((a, b) => {        
        if (sortConfig.key === 'file_name') {
            const nameA = a.file_name ? a.file_name.split('.')[0].toLowerCase() : '';
            const nameB = b.file_name ? b.file_name.split('.')[0].toLowerCase() : '';
            return sortConfig.direction === 'asc' ? nameA.localeCompare(nameB) : nameB.localeCompare(nameA);
        } else if (sortConfig.key === 'file_type') {
            const typeA = fileFormat[a.file_type.split('/')[1]] || '';
            const typeB = fileFormat[b.file_type.split('/')[1]] || '';
            return sortConfig.direction === 'asc' ? typeA.localeCompare(typeB) : typeB.localeCompare(typeA);
        } else if (sortConfig.key === 'module_name') {
            const moduleNameA = a?.module?.module_name ? a.module.module_name.toLowerCase() : '';
            const moduleNameB = b?.module?.module_name ? b.module.module_name.toLowerCase() : '';
            return sortConfig.direction === 'asc' ? moduleNameA.localeCompare(moduleNameB) : moduleNameB.localeCompare(moduleNameA);
        } else if (sortConfig.key === 'createdPlans.first_name') {
            const uploaderA = a?.createdPlans?.first_name ? a.createdPlans.first_name.toLowerCase() : '';
            const uploaderB = b?.createdPlans?.first_name ? b.createdPlans.first_name.toLowerCase() : '';
            return sortConfig.direction === 'asc' ? uploaderA.localeCompare(uploaderB) : uploaderB.localeCompare(uploaderA);
        } else if (sortConfig.key === 'created_date') {
            const dateA = new Date(a.created_date);
            const dateB = new Date(b.created_date);            
            return sortConfig.direction === 'asc' ? dateA - dateB : dateB - dateA;
        }
        return 0;
    });

    return (
        <>
            {AlldocumentsList && AlldocumentsList.length === 0 ?
                <ResultNotFound />
                :
                <div className="main-content-sec vit_info_data medicat_tb_contt">
                    <div className="row">
                        <div className="col-md-12 col-12">
                            <div className="table-sec">
                                <div className="table-responsive">
                                    <table className="table table-hover">
                                        <thead>
                                            <tr>
                                                <th scope="col">
                                                    <div className="table-profile">
                                                        Name
                                                        <span className={`sort_icon ${sortConfig.key === 'file_name' && sortConfig.direction}`} onClick={() => handleSort('file_name')}>
                                                            <svg className="ms-1" width="12" height="12"
                                                                viewBox="0 0 12 12" fill="none"
                                                                xmlns="http://www.w3.org/2000/svg">
                                                                <path
                                                                    d="M6 1.33337V10.6667M6 10.6667L10.6667 6.00004M6 10.6667L1.33333 6.00004"
                                                                    stroke="#475467" strokeWidth="0.888889"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round" />
                                                            </svg>
                                                        </span>
                                                    </div>
                                                </th>
                                                <th scope="col">
                                                    <div className="table-profile">
                                                        Type
                                                        <span className={`sort_icon ${sortConfig.key === 'file_type' && sortConfig.direction}`} onClick={() => handleSort('file_type')}>
                                                            <svg className="ms-1" width="12" height="12"
                                                                viewBox="0 0 12 12" fill="none"
                                                                xmlns="http://www.w3.org/2000/svg">
                                                                <path
                                                                    d="M6 1.33337V10.6667M6 10.6667L10.6667 6.00004M6 10.6667L1.33333 6.00004"
                                                                    stroke="#475467" strokeWidth="0.888889"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round" />
                                                            </svg>
                                                        </span>
                                                    </div>
                                                </th>
                                                <th scope="col">
                                                    <div className="table-profile">
                                                        Category
                                                        <span className={`sort_icon ${sortConfig.key === 'module_name' && sortConfig.direction}`} onClick={() => handleSort('module_name')}>
                                                            <svg className="ms-1" width="12" height="12"
                                                                viewBox="0 0 12 12" fill="none"
                                                                xmlns="http://www.w3.org/2000/svg">
                                                                <path
                                                                    d="M6 1.33337V10.6667M6 10.6667L10.6667 6.00004M6 10.6667L1.33333 6.00004"
                                                                    stroke="#475467" strokeWidth="0.888889"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round" />
                                                            </svg>
                                                        </span>
                                                    </div>
                                                </th>
                                                <th scope="col">
                                                    <div className="table-profile">
                                                        Uploaded by
                                                        <span className={`sort_icon ${sortConfig.key === 'createdPlans.first_name' && sortConfig.direction}`} onClick={() => handleSort('createdPlans.first_name')}>
                                                            <svg className="ms-1" width="12" height="12"
                                                                viewBox="0 0 12 12" fill="none"
                                                                xmlns="http://www.w3.org/2000/svg">
                                                                <path
                                                                    d="M6 1.33337V10.6667M6 10.6667L10.6667 6.00004M6 10.6667L1.33333 6.00004"
                                                                    stroke="#475467" strokeWidth="0.888889"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round" />
                                                            </svg>
                                                        </span>
                                                    </div>
                                                </th>
                                                <th scope="col">
                                                    <div className="table-profile">
                                                        Uploaded date
                                                        <span className={`sort_icon ${sortConfig.key === 'created_date' && sortConfig.direction}`} onClick={() => handleSort('created_date')}>
                                                            <svg className="ms-1" width="12" height="12"
                                                                viewBox="0 0 12 12" fill="none"
                                                                xmlns="http://www.w3.org/2000/svg">
                                                                <path
                                                                    d="M6 1.33337V10.6667M6 10.6667L10.6667 6.00004M6 10.6667L1.33333 6.00004"
                                                                    stroke="#475467" strokeWidth="0.888889"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round" />
                                                            </svg>
                                                        </span>
                                                    </div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {sortedDocs && sortedDocs.map((item, index) => {
                                                let name = item.file_name.split('.');
                                                let type = item.file_type.split('/');

                                                return (
                                                    <tr key={index}>
                                                        <td>
                                                            <div className="table-profile">
                                                                <div className="profile-sec appointment-member" onClick={() => handleDownload(item)}>
                                                                    {name[0]}
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="hd-subtitle text-dark">{fileFormat[type[1]]}</td>
                                                        <td className="hd-subtitle text-dark">{item?.module?.module_name}</td>
                                                        <td className="hd-subtitle text-dark">{item?.createdPlans?.first_name}</td>
                                                        <td className="approval-status-tbl">{monthDayYearFormat(item.created_date, true)}</td>
                                                    </tr>
                                                );
                                            })}
                                            <tr>
                                                <td colSpan="7">
                                                    <Pagination
                                                        currentPage={currentPage}
                                                        totalPages={totalPages}
                                                        onPageChange={handlePageChange}
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
};

export default Documents;
