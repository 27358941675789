import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import UrlBar from './UrlBar'
import Header from './Header'
import BoardView from './BoardView'
import ListView from './ListView'
import AddNewTask from './AddNewTask'
import StatusModal from './StatusModal'
import DeleteTask from "./DeleteTask";
import { reSetEditTasksData } from '../../../redux/features/AuthTasksSlice'
import { get_task_board_view, edit_task, destroy_task, Task_status_change, get_task_table_view } from '../../../redux/services/AuthTasks'

const initionalSearch = {
    page:1,
    listData: 10,
    searchBar: '',
    role:''
}
const Tasks = () => {
    const dispatch = useDispatch();

    const currentUserRole = useSelector(state => state?.auth?.currentUserRole);


    const [addNewTask, setAddNewTask] = useState(false)
    const [taskStatus, setTaskStatus] = useState({})
    const [taskStatusModal, setTaskStatusModal] = useState(false)
    const [deleteTask, setDeleteTask] = useState('')
    const [search, setSearch] = useState(initionalSearch)
    const [viewType, setViewType] = useState('board_view')

    useEffect(() => {
        if (!addNewTask) {
            let role = currentUserRole
            if(viewType === 'board_view'){
                dispatch(get_task_board_view(role))
            }else{
                let data = search
                data.role = currentUserRole
                dispatch(get_task_table_view(data))
            }
        }
    }, [search, viewType, addNewTask, taskStatus, deleteTask, currentUserRole])

    const handleViewChange = (view) => {
        setViewType(view)
    }
    const handleTaskClick = async(task) => {
        let response = await dispatch(edit_task(task.id))
        if (response?.payload?.status) {
            setTaskStatus(response?.payload?.data)
            setTaskStatusModal(true)
        }
    }

    const handleEditTask = (id) => {
        setTaskStatusModal(false)
        setAddNewTask(true)
    }

    const handleDeleteModal = (id) => {
        setDeleteTask(id)

    }
    const handleDeleteTask = async () => {
        let response = await dispatch(destroy_task(deleteTask))
        if (response?.payload?.status) {
            setDeleteTask('')
            setTaskStatus({})
            setTaskStatusModal(false)
            dispatch(reSetEditTasksData())
        }
    }

    const handleMarkAsComplate = async (task, status) => {
        if(status !== ''){
            let payload = {
                task_id: task.id,
                task_status: status.toLowerCase()
            }
            let response = await dispatch(Task_status_change(payload))
            if (response?.payload?.status) {
                setDeleteTask('')
                setTaskStatus({})
                setTaskStatusModal(false)
                dispatch(reSetEditTasksData())
            }
        }
    }

    const handleToggalStatusModal = (data) => {
        setTaskStatus({})
        setTaskStatusModal(false)
        dispatch(reSetEditTasksData())
    }

    return (
        <div id="layoutSidenav_content">
            <main>
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-md-12 col-lg-12 col-12">
                            <div class="right-main-sec appointment_dash_sec">
                                <UrlBar />
                                <Header setAddNewTask={setAddNewTask} viewType={viewType} onViewChange={handleViewChange}/>
                                {viewType === 'board_view' && <BoardView onTaskClick={handleTaskClick} handleMarkAsComplate={handleMarkAsComplate}/>}
                                {viewType === 'list_view' && <ListView onTaskClick={handleTaskClick} search={search} setSearch={setSearch}/>}
                                {addNewTask && <AddNewTask setAddNewTask={setAddNewTask} setTaskStatusModal={setTaskStatusModal} setTaskStatus={setTaskStatus} taskStatus={taskStatus}/>}
                            </div>
                        </div>
                    </div>
                </div>
                {taskStatusModal && <StatusModal taskDetail={taskStatus} toggalStatusModal={handleToggalStatusModal} onEditTask={handleEditTask} onDeleteTask={handleDeleteModal} onMarkAsComplate={handleMarkAsComplate}/>}
                {deleteTask !== '' && <DeleteTask deleteTask={deleteTask} setDeleteTask={setDeleteTask} onDeleteSubmit={handleDeleteTask} />}
            </main>
        </div>
    )

}
export default Tasks