import { createSlice } from "@reduxjs/toolkit";
import { Add_communication, get_all_communication_homes, get_home_with_member, get_communication_member, get_communication_by_id, destroy_communication, get_all_communication_list, get_loggedIn_home_member_list, export_all_communication } from "../services/AuthCommunication";

const initialState = {
    isLoading: false,
    error: null,
    communicationList:[],
    AllcommunicationList:[],
    communicationMemberList:{},
    MemberCommunication:[],
    CommunicationById: {},
    homeMemberList:[],
}
const AuthCommunication = createSlice({
    name: 'AuthCommunication',
    initialState,
    reducers: {
        reSetCommunicationById: (state, action) => {
            state.CommunicationById= {}
          },
    },
    extraReducers: (builder) => {
        // ==========Add_communication ============== //
        builder.addCase(Add_communication.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(Add_communication.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
        });
        builder.addCase(Add_communication.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
        // ==========get_all_communication_homes============== //
        builder.addCase(get_all_communication_homes.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(get_all_communication_homes.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
            state.communicationList = action?.payload?.data
        });
        builder.addCase(get_all_communication_homes.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
         // ==========get_home_with_member============== //
         builder.addCase(get_home_with_member.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(get_home_with_member.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
            state.communicationMemberList = action?.payload?.data
        });
        builder.addCase(get_home_with_member.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
        // ==========get_communication_member============== //
        builder.addCase(get_communication_member.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(get_communication_member.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
            state.MemberCommunication = action?.payload?.data
        });
        builder.addCase(get_communication_member.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
        // ==========get_communication_by_id============== //
        builder.addCase(get_communication_by_id.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(get_communication_by_id.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
            state.CommunicationById = action?.payload?.data
        });
        builder.addCase(get_communication_by_id.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
        // ==========destroy_communication ============== //
        builder.addCase(destroy_communication.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(destroy_communication.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
        });
        builder.addCase(destroy_communication.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
        // ==========get_all_communication_list============== //
        builder.addCase(get_all_communication_list.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(get_all_communication_list.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
            state.AllcommunicationList = action?.payload?.data
        });
        builder.addCase(get_all_communication_list.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
        // ==========get_loggedIn_home_member_list============== //
        builder.addCase(get_loggedIn_home_member_list.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(get_loggedIn_home_member_list.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
            state.homeMemberList = action?.payload?.data
        });
        builder.addCase(get_loggedIn_home_member_list.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
        // ==========export_all_communication============== //
        builder.addCase(export_all_communication.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(export_all_communication.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
        });
        builder.addCase(export_all_communication.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
    }
});
// Export the synchronous action
export const { reSetCommunicationById } = AuthCommunication.actions;
export default AuthCommunication?.reducer;