import { createAsyncThunk } from "@reduxjs/toolkit";
import { HTTPURL } from "../../constant/Matcher";

export const logout = createAsyncThunk("auth/logout", async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
        const res = await fetch(`${HTTPURL}/logout/`, {
            method: "POST",
            headers: {
                Authorization: `Token ${JSON.parse(localStorage.getItem("token"))}`,
            },
            redirect: "follow",
        });
        const data = await res.text();
        return data;
    } catch (error) {
        return rejectWithValue(error);
    }
});
