import React from 'react'
import StepsProgressBar from '../StepsProgressBar'
import PaidSupport from './PaidSupport'
import NonPaidServices from './NonPaidServices'
import NonALTCSFunded from './NonALTCSFunded'

const ServicesAuthorized = ({ step, subStep, totalSubSteps, onHandleBackStep,onSubmit}) => {

  const renderSubStep = () => {
    switch (subStep) {
        case 0:
            return <PaidSupport onHandleBackStep={onHandleBackStep} onSubmit={onSubmit} step={step} subStep={subStep}/>
        case 1:
            return <NonPaidServices onHandleBackStep={onHandleBackStep} onSubmit={onSubmit} step={step} subStep={subStep} />
        case 2:
            return <NonALTCSFunded onHandleBackStep={onHandleBackStep} onSubmit={onSubmit} step={step} subStep={subStep} /> 
        default:
            return <PaidSupport onHandleBackStep={onHandleBackStep} onSubmit={onSubmit} step={step} subStep={subStep} />
    }
}

  return (
    <div className="tab-pane fade  goal_tab show active" id="v-pills-ser" role="tabpanel" aria-labelledby="v-pills-ser-tab">
       <StepsProgressBar
                currentSubStep={subStep + 1}
                totalSubSteps={totalSubSteps}
            />
            {renderSubStep()}
    </div>
  )
}

export default ServicesAuthorized