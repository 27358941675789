import { createSlice } from "@reduxjs/toolkit";
import { get_dashboard_data } from "../services/AuthDashboard";

const initialState = {
    isLoading: false,
    error: null,
    DashboardData:[],
};

const AuthDashboardSlice = createSlice({
    name: "AuthDashboardSlice", 
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // ==========get_dashboard_data============== //
        builder.addCase(get_dashboard_data.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(get_dashboard_data.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
            state.DashboardData = action?.payload?.data
        });
        builder.addCase(get_dashboard_data.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
    },
});
export default AuthDashboardSlice.reducer;