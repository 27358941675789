import React, { useState } from "react";
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { appointment_change_status } from '../../../redux/services/AuthAppointment';
import { PluseSvg } from '../../../Images/ComonSvgs';
import {appointmentStatusOptions} from './CommonData'

const AppointmentStatus = ({ appointmentStatus, onTaggalStatus, statusType }) => {
    const dispatch = useDispatch();
    const { handleSubmit, register, setValue, formState: { errors } } = useForm();
    const [isFollowUp, setIsFollowUp] = useState('no');

    const onSubmit = async (payload) => {
        payload.appointment_id = appointmentStatus?.id;

        if (statusType === 'appointment_status') {
            payload.approval_status = '';
            payload.approve_comment = '';
            payload.follow_up = '';
            payload.follow_up_date = '';
            payload.follow_up_time = '';
        } else {
            payload.appointment_status = '';
            payload.appointment_comment =  '';
        }

        const response = await dispatch(appointment_change_status(payload));
        if (response?.payload?.status) {
            onTaggalStatus({},'');
        }
    };

    return (
        <div className="modal fade appointment_status show">
            <div className="modal-dialog">
                <form className="row" onSubmit={handleSubmit(onSubmit)}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <PluseSvg />
                            <button type="button" className="btn-close changes_top_btn" onClick={() => onTaggalStatus({})}></button>
                        </div>
                        <div className="border-bottom bdr-b">
                            <h3 className="font-18 hd-title mb-3">Change Status</h3>
                        </div>
                        <div className="modal-body">
                            <div className="status_chang_box">
                                <div className="col-md-12 col-12 mb-3">
                                    <label className="form-label">Status*</label>
                                    {statusType === 'appointment_status' ?
                                    <select className="form-select" {...register(`appointment_status`, { required: 'This is required' })}>
                                    <option value="">-Please Select-</option>
                                    {appointmentStatusOptions.map((item)=><option value={item.value}>{item.lable}</option>)}
                                </select>
                                :
                                <select className="form-select" {...register(`approval_status`, { required: 'This is required' })}>
                                        <option value="pending">Pending</option>
                                        <option value="approved">Approved</option>
                                    </select>
}
                                    {statusType === 'appointment_status' ?
                                        <>
                                            {errors.appointment_status && <span className="error">{errors.appointment_status.message}</span>}
                                        </>
                                        :
                                        <>
                                            {errors.approval_status && <span className="error">{errors.approval_status.message}</span>}
                                        </>
                                    }
                                </div>
                                <div className="col-md-12 col-12 mb-3">
                                    <label className="form-label">Comment*</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Comment"
                                        {...register(`${statusType === 'appointment_status' ? 'appointment_comment' : 'approve_comment'}`, { required: 'Comment is required' })}
                                        maxLength={25}
                                    />
                                    {statusType === 'appointment_status' ?
                                        <>
                                            {errors.appointment_comment && <span className="error">{errors.appointment_comment.message}</span>}
                                        </>
                                        :
                                        <>
                                            {errors.approve_comment && <span className="error">{errors.approve_comment.message}</span>}
                                        </>
                                    }
                                </div>

                                {statusType === 'approval_status' && (
                                    <div className="col-md-12 col-12 mb-3">
                                        <p className="font-14 hd-title mb-2">Follow-up needed</p>
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            value="yes"
                                            id="follow_upYes"
                                            checked={isFollowUp === 'yes'}
                                            onChange={() => {
                                                setIsFollowUp('yes');
                                                setValue('follow_up', 'yes');
                                            }}
                                        />
                                        <label className="form-check-label ms-1" htmlFor="follow_upYes">Yes</label>

                                        <input
                                            className="form-check-input ms-3"
                                            type="radio"
                                            value="no"
                                            id="follow_upNo"
                                            checked={isFollowUp === 'no'}
                                            onChange={() => {
                                                setIsFollowUp('no');
                                                setValue('follow_up', 'no');
                                            }}
                                        />
                                        <label className="form-check-label ms-1" htmlFor="follow_upNo">No</label>
                                        {isFollowUp === 'yes' && (
                                            <div className="row mt-3">
                                                <div className="col-md-6 col-12 mb-3">
                                                    <label className="form-label">Date*</label>
                                                    <input
                                                        type="date"
                                                        className="form-control"
                                                        {...register('follow_up_date', { required: 'Date is required' })}
                                                        onKeyDown={(e) => e.preventDefault()}
                                                    />
                                                    {errors.follow_up_date && <span className="error">{errors.follow_up_date.message}</span>}
                                                </div>
                                                <div className="col-md-6 col-12 mb-3">
                                                    <label className="form-label">Time*</label>
                                                    <input
                                                        type="time"
                                                        className="form-control"
                                                        {...register('follow_up_time', { required: 'Time is required' })}
                                                        onKeyDown={(e) => e.preventDefault()}
                                                    />
                                                    {errors.follow_up_time && <span className="error">{errors.follow_up_time.message}</span>}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="change_status_btn">
                            <div className="row">
                                <div className="col-md-6 col-12 mb-3">
                                    <button type="button" className="btn btn-secondary btn-light" onClick={() => onTaggalStatus({})}>Cancel</button>
                                </div>
                                <div className="col-md-6 col-12 mb-3">
                                    <button type="submit" className="btn btn-primary">Change Status</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default AppointmentStatus;
