import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import PcspFooter from '../../PcspFooter'

const serviceModals = [
    "Traditional",
    "Agency with Choice",
    "Independent Provider (DDD)",
    "Self-Directed Attendant Care",
    "Spousal Attendant Care",
    "NA"
]
const PaidSupport = ({ onHandleBackStep, onSubmit }) => {
    const allDddPcsp = useSelector(state => state?.AuthMember?.allDddPcsp?.servicesAuthorized);
    // Initialize the form with useForm hook
    const { register, handleSubmit, setValue, formState: { errors } } = useForm();

    useEffect(() => {
        setValue('notes', allDddPcsp?.notes);
        setValue('additional_discussion_notes', allDddPcsp?.additional_discussion_notes);
        setValue('service_modal_id', allDddPcsp?.service_modal_id);

    }, [allDddPcsp])

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)} className="row">
                <div className="col-12">
                    <div className="alert alert-light sap-alert-heading">
                        <div className="hd-title font-24">Paid support</div>
                        <div className="hd-subtitle font-16">Documentation shall contain confirmation that all services are being
                            received as scheduled, and address any gaps in services if they exist. If gaps are identified the team
                            should develop a plan to assure that authorized services are being received. Document member’s
                            satisfaction with long-term care services and providers.</div>
                    </div>
                </div>
                <div className="col-12">
                    <label for="" className="form-label">Notes*</label>
                    <div className="mb-3">
                        <textarea className="form-control" placeholder="Enter notes" {...register("notes", { required: "true" })} maxLength={150} />
                        {errors.notes && errors.notes.type === "required" && (<span className="error">Notes is required!</span>)}
                    </div>
                </div>
                <div className="col-12">
                    <div className="alert alert-light sap-alert-heading">
                        <div className="hd-subtitle font-16">For individuals living in their own home, ensure all service models
                            have been discussed using ALTCS Member Service Options Decision Tree.</div>
                        <div className="hd-subtitle font-16 pt-3">For members who have chosen the Agency with Choice or
                            Self-Directed Attendant Care option, ask the following questions to help assess whether or not they
                            are fulfilling their respective roles and responsibilities and/or if they need additional support
                            including member-training services that may be authorized.</div>
                    </div>
                </div>
                <div className="col-12">
                    <label for="" className="form-label">Additional notes from discussion*</label>
                    <div className="ddd_steptwo_para">
                        <input
                            type="input"
                            className="form-control"
                            placeholder="Enter Additional notes"
                            {...register(`additional_discussion_notes`, { required: "Additional notes is required!" })}
                            maxLength={100}
                        />
                        {errors?.additional_discussion_notes && (
                            <span className="error">{errors.additional_discussion_notes.message}</span>
                        )}
                    </div>
                </div>
                <div className="member_vital_basic_title">
                    <h4>Service model selected</h4>
                </div>
                <div className="col-12 mb-3">
                    <select
                        className="form-select"
                        aria-label="Default select example"
                        {...register(`service_modal_id`, { required: "Service model is required!" })}>
                        {serviceModals.map((item,index)=>{
                            return(
                                <option value={index}>{item}</option>
                            )
                        })}
                    </select>
                    {errors.service_modal_id && (
                        <span className="error">{errors.service_modal_id.message}</span>
                    )}
                </div>
                <PcspFooter onHandleBackStep={onHandleBackStep} />
            </form>
        </>
    )
}

export default PaidSupport
