import React, { useState, useEffect } from "react"
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { CrossSvg, SearchSvg, AfterSearchSvg } from '../../../Images/DocumentsSvg';
import { HeadingSvg, AppointmentSvg } from '../../../Images/ComonSvgs';
import { get_loggedIn_user_member_list } from '../../../redux/services/AuthDocuments'
import { get_appointment_with_list, get_all_users_list, create_appointment, get_existing_doctor_address, get_appointment_search_location } from '../../../redux/services/AuthAppointment'
import { reSetEditAppointmentList, reSetExistingAddressList, reSetExistingLocationList } from '../../../redux/features/AuthAppointmentSlice'
import { FrequencyOption } from './CommonData'
import {AppointmentDates} from '../../../components/Common/Common'
import moment from 'moment'

const AddNewAppointment = ({ onModalToggle }) => {
    const dispatch = useDispatch();

    const { handleSubmit, register, setError, clearErrors, setValue, getValues, formState: { errors } } = useForm();
    const AllUserList = useSelector(state => state?.AuthAppointment?.AllUserList);
    const memberList = useSelector((state) => state?.AuthDocuments?.memberList);
    const AppointmentWithList = useSelector((state) => state?.AuthAppointment?.AppointmentWithList);
    const EditAppointmentList = useSelector((state) => state?.AuthAppointment?.EditAppointmentList)
    const ExistingAddressList = useSelector((state) => state?.AuthAppointment?.ExistingAddressList)
    const ExistingLocationList = useSelector((state) => state?.AuthAppointment?.ExistingLocationList)

    const [selectedMember, setSelectedMember] = useState([]);
    const [searchInput, setSearchInput] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [selectedType, setSelectedType] = useState('')
    const [selectedTime, setSelectedTime] = useState({ start: '', end: '' })
    const [selectedDate, setSelectedDate] = useState('')
    const [selectedWith, setSelectedWith] = useState('')
    const [minDate, setMinDate] = useState('');
    const [endMinDate, setEndMinDate] = useState('');
    const [docAddress, setDocAddress] = useState('');
    const [docLocation, setDocLocation] = useState('');
    const [selectedFrequency, setSelectedFrequency] = useState('does_not_repeat')
    const [appointmentDates, setAppointmentDates] = useState([])
    const [endDate, setEndDate] = useState('')

    useEffect(() => {
        dispatch(get_appointment_with_list())
        dispatch(get_loggedIn_user_member_list())
        dispatch(get_all_users_list())
        dispatch(reSetExistingAddressList())
        dispatch(reSetExistingLocationList())

        const dtToday = new Date();

        const month = String(dtToday.getMonth() + 1).padStart(2, '0');
        const day = String(dtToday.getDate()).padStart(2, '0');
        const year = dtToday.getFullYear();

        const maxDate = `${year}-${month}-${day}`;

        setMinDate(maxDate);
    }, [])

    useEffect(() => {
        if (Object.keys(EditAppointmentList).length > 0) {
            console.log(EditAppointmentList,'EditAppointmentListEditAppointmentList');
            
            let time = EditAppointmentList?.appointment_time.split('-')

            if(EditAppointmentList?.parent_id){
                setEndDate(EditAppointmentList.allDates[0])
            }else{
                setEndDate(EditAppointmentList.allDates[EditAppointmentList.allDates.length - 1])
            }
            setSelectedTime({ start: time[0], end: time[1] })
            setSelectedMember(EditAppointmentList?.appointmentMemberList
            )
            setSelectedWith(EditAppointmentList?.appointment_with)
            setSelectedDate(EditAppointmentList?.appointment_date)
            setDocAddress(EditAppointmentList?.doctor_address)
            setSelectedFrequency(EditAppointmentList?.appointment_frequency)
            setDocLocation(EditAppointmentList.location)
            setValue('appointment_time', EditAppointmentList?.appointment_time)
            setValue('approval_request_to', EditAppointmentList?.approval_request_to)
            setValue('notification_level', EditAppointmentList?.notification_level)
            setValue('appointment_type', EditAppointmentList?.appointment_type)
            setValue('appointment_with', EditAppointmentList?.appointment_with)
            setValue('appointment_date', EditAppointmentList?.appointment_date)
            setValue('appointment_frequency', EditAppointmentList?.appointment_frequency)
            setValue('appointment_reason', EditAppointmentList?.appointment_reason)
            setValue('doctor_mobile', EditAppointmentList?.doctor_mobile)
            setValue('doctor_specialty', EditAppointmentList?.doctor_specialty)
            setValue('doctor_location_type', EditAppointmentList?.location)
            setValue('doctor_address', EditAppointmentList?.doctor_address)
            setSelectedType(EditAppointmentList?.appointment_type)

            if (EditAppointmentList?.appointmentPatientHistory && Object.keys(EditAppointmentList?.appointmentPatientHistory).length > 0) {
                setValue('current_behaviour', EditAppointmentList?.appointmentPatientHistory.current_behaviour)
                setValue('observerd_side_effects', EditAppointmentList?.appointmentPatientHistory.observerd_side_effects)
                setValue('other_monitoring_info', EditAppointmentList?.appointmentPatientHistory.other_monitoring_info)
                setValue('td_screening_date', EditAppointmentList?.appointmentPatientHistory.td_screening_date)
                setValue('patient_results', EditAppointmentList?.appointmentPatientHistory.patient_results)
                setValue('td_screening_results', EditAppointmentList?.appointmentPatientHistory.td_screening_results)
                setValue('recommendations', EditAppointmentList?.appointmentPatientHistory.recommendations)
                setValue('side_effect_screening_completed_results', EditAppointmentList?.appointmentPatientHistory.side_effect_screening_completed_results)
            }
        }
    }, [EditAppointmentList])
    
    useEffect(() => {
        // let dateEnd = ''
        // let DateSelected = moment(selectedDate);
        // if (selectedFrequency === 'daily') {
        //     dateEnd = DateSelected.clone().add(1, 'week').format('YYYY-MM-DD');
        //   } else if (selectedFrequency === 'weekly') {
        //     dateEnd = DateSelected.clone().add(1, 'month').format('YYYY-MM-DD');
        //   } else if (selectedFrequency === 'monthly') {
        //     dateEnd = DateSelected.clone().add(1, 'year').format('YYYY-MM-DD');
        //   } else if (selectedFrequency === 'does_not_repeat') {
        //     dateEnd = '';
        //   }
        // setEndMaxDate(dateEnd)  
        setEndMinDate(selectedDate)  
    },[selectedDate])

    const onSubmit = async (payload) => {
        if (selectedType === '') {
            setError('appointment_type', { type: 'required' });
        } else if (selectedDate === '') {
            setError('appointment_date', { type: 'required', message: 'Enter valid date' })
        } else if (selectedTime.start === '' || selectedTime.end === '') {
            if (selectedTime.start === '') {
                setError('appointment_start_time', { type: 'required' });
            } else {
                setError('appointment_end_time', { type: 'required', message: 'This is required' });
            }

        } else if (selectedWith === '') {
            setError('appointment_with', { type: 'required' });
        } else if (selectedMember.length === 0) {
            setError('member_ids', { type: 'required' });
        } else if (docAddress === '') {
            setError('doctor_address', { type: 'required', message: 'Address is required!' })
        } else if( docLocation === ''){
            setError('doctor_location_type', { type: 'required', message: 'Location Type is required' })
        } else {
            // Use map to create an array of IDs and then join them with commas
            payload.member_ids = selectedMember.map((member) => member?.id).join(',');
            payload.appointment_time = `${selectedTime.start}-${selectedTime.end}`
            if (Object.keys(EditAppointmentList).length > 0) {
                payload.appointment_id = EditAppointmentList?.id
            }
            if (Object.keys(EditAppointmentList).length > 0) {
                payload.appointment_date = appointmentDates && appointmentDates.length > 0 ? appointmentDates : EditAppointmentList?.allDates
            }else {
                
                payload.appointment_date = appointmentDates && appointmentDates.length > 0 ? appointmentDates : [selectedDate]
            }
            
            const response = await dispatch(create_appointment(payload));
            if (response?.payload?.status) {
                onModalToggle(false);
                setSelectedMember([]);
                setFilteredData([])
                setSearchInput('')
                dispatch(reSetEditAppointmentList())
            }
        }
    }

    const handleMemberSearch = (e) => {
        const input = e.target.value.toLowerCase();
        if (input !== '') {
            setSearchInput(input);

            // Get the IDs of selected members for filtering
            const selectedIds = selectedMember.map(member => member.id);

            const filtered = memberList && memberList.filter(user =>
                user.first_name.toLowerCase().includes(input) &&
                !selectedIds.includes(user.id) // Exclude selected members
            );
            setFilteredData(filtered);
        } else {
            setFilteredData([]);
            setSearchInput('');
        }
    }

    const handleMemberClick = (data) => {
        clearErrors('member_ids')
        if (selectedType === "psych") {
            if (selectedMember.length < 1) {
                setSelectedMember((prevFiles) => [...prevFiles, data])
                setFilteredData([]);
            }
        } else {
            setSelectedMember((prevFiles) => [...prevFiles, data])
            const newSelectedUsers = filteredData && filteredData.filter((item) => item.id !== data.id)
            setFilteredData(newSelectedUsers);
        }
    }

    const handleMemberDelete = (data) => {
        const deletedMember = selectedMember.filter((elm) => elm.id === data.id);
        const otherMember = selectedMember.filter((elm) => elm.id !== data.id);
        setFilteredData((prevFiles) => [...prevFiles, ...deletedMember])
        setSelectedMember(otherMember);
    }

    const handleAppointmentType = (type) => {
        setSelectedType(type)
        setValue('appointment_type', type)
        clearErrors('appointment_type')
        setSelectedWith('')
        setValue('doctor_mobile', '')
        setValue('doctor_specialty', '')
        setValue('doctor_location_type', '')
        setValue('doctor_address', '')
    }

    const handleDateChange = (e) => {
        if(e.target.name === 'startDate'){
            setValue('appointment_date', e.target.value)
            setSelectedDate(e.target.value)
            let appointments = AppointmentDates(e.target.value, selectedFrequency)
            setAppointmentDates(appointments?.appointmentDates)
            setEndDate(appointments?.endDate)
            clearErrors('appointment_date');
        }else{
            let appointments = AppointmentDates(selectedDate, selectedFrequency, e.target.value)
            setAppointmentDates(appointments?.appointmentDates)
            setEndDate(appointments?.endDate)
        }
    }
    const handleTimeChange = (e) => {
        const { name, value } = e.target;

        if (selectedDate === '') {
            setError('appointment_date', { type: 'required', message: 'Select date before time' });
        } else {
            if (name === 'start_time') {
                const newStartTime = value;
                setSelectedTime({ start: newStartTime, end: '' });
                clearErrors('appointment_start_time');
            } else {
                const startTime = moment(`${selectedDate}T${selectedTime.start}`);
                const endTime = moment(`${selectedDate}T${value}`);
                let startValue = selectedTime.start.split(':')
                let endValue = value.split(':')

                if (startValue[0] === '00' && endValue[0] === '00') {
                    setError('appointment_end_time', { type: 'required', message: 'End time must be at least 1 hour after start time.' });
                } else {
                    // If end time is midnight, increment the date for comparison
                    if (endValue[0] === '00') {
                        endTime.add(1, 'day');
                    }

                    // Check if the end time is at least 1 hour after the start time
                    if (endTime.diff(startTime, 'hours') >= 1) {
                        setSelectedTime({ start: selectedTime.start, end: value });
                        clearErrors('appointment_end_time');
                    } else {
                        // Handle the case where the end time is not valid
                        setError('appointment_end_time', { type: 'required', message: 'End time must be at least 1 hour after start time.' });
                        console.error('End time must be at least 1 hour after start time.');
                        // Optionally set an error state or provide feedback to the user
                    }
                }
            }
        }
    }
    const handleCancle = () => {
        onModalToggle(false)
        dispatch(reSetEditAppointmentList())
    }
    const handleAppointmentWith = (e) => {
        setSelectedWith(e.target.value)
        setValue('appointment_with', e.target.value)
        clearErrors('appointment_with')
        let selected = AppointmentWithList && AppointmentWithList.find((item) => item.id === e.target.value)
        if (selected) {
            setDocAddress(`${selected.streetAddress}, ${selected.city}, ${selected.zipCode}`)
            setValue('doctor_mobile', selected.phoneNo)
            setValue('doctor_address', `${selected.streetAddress}, ${selected.city}, ${selected.zipCode}`)
        }
    }
    const handleSubmitButton = () => {
        if (selectedType === '') {
            setError('appointment_type', { type: 'required' });
        }
        if (selectedDate === '') {
            setError('appointment_date', { type: 'required', message: 'Enter valid date' })
        }
        if (selectedTime.start === '') {
            setError('appointment_start_time', { type: 'required' });
        }
        if (selectedTime.end === '') {
            setError('appointment_end_time', { type: 'required', message: 'This is required' });
        }
        if (selectedWith === '') {
            setError('appointment_with', { type: 'required' });
        }
        if (selectedMember.length === 0) {
            setError('member_ids', { type: 'required' });
        }
        if (docAddress.trim() === '') {
            setError('doctor_address', { type: 'required', message: 'Address is required!' })
        }
        if( docLocation.trim() === ''){
            setError('doctor_location_type', { type: 'required', message: 'Location Type is required' })
        }
        
    }

    const handleAddressChange = async (e) => {
        let value = e.target.value
        setDocAddress(value)
        clearErrors('doctor_address')
        setValue('doctor_address', value)
        if (value.length > 2) {
            let payload = {
                id: selectedWith,
                search_address: value
            }
            await dispatch(get_existing_doctor_address(payload))
        }
    }

    const handleAddressList = (data) => {
        setDocAddress(data)
        setValue('doctor_address', data)
        dispatch(reSetExistingAddressList())
    }
    const handleFrequencyChange = (e) => {
        setSelectedFrequency(e.target.value)
        setValue('appointment_frequency',e.target.value)
        let appointments = AppointmentDates(selectedDate, e.target.value)
        setAppointmentDates(appointments?.appointmentDates)
        setEndDate(appointments?.endDate)        
        // {...register('appointment_frequency', { required: 'true' })}
    }
    const handleLocationChange = async(e) =>{
        let value = e.target.value        
        setDocLocation(value)
        clearErrors('doctor_location_type')
        setValue('doctor_location_type', value.toLowerCase())
        if (value.length > 2) {
            await dispatch(get_appointment_search_location(value))
        }
    }
    const handleLocationList = (data) => {
        setDocLocation(data.name)
        setValue('doctor_location_type', data.name.trim().toLowerCase())
        dispatch(reSetExistingLocationList())
    }

    let dateDisbale = Object.keys(EditAppointmentList).length > 0 && EditAppointmentList?.parent_id
        
    return (
        <div className="modal fade new_medi_modal show">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <div className="apnorht_home appointment_top_icon">
                            <AppointmentSvg />
                        </div>
                        <button
                            type="button"
                            className="btn-close appointment-hr-btn"
                            onClick={() => handleCancle()}
                        />
                    </div>
                    {Object.keys(EditAppointmentList).length > 0 ?
                        <div className="edit_view">
                            <div className="left_div">
                                <h4 className="font-18 hd-title">{EditAppointmentList?.doctor_specialty}</h4>
                                <h3 className="font-18 hd-title">Group Home: <span>{EditAppointmentList?.group_home}</span></h3>
                            </div>

                            <div className="right_div">
                                <div>
                                    <div class="font-14 hd-subtitle">Entered By</div>
                                </div>
                                <div className="role">
                                    <span class="hd-title font-14">{EditAppointmentList.entered_by}</span>
                                    <div class="apnman">{EditAppointmentList.role}</div>
                                </div>

                            </div>
                        </div>
                        :
                        <div className="apmt-hdg">
                            <h4 className="font-18 hd-title">Create Appointment</h4>
                        </div>
                    }
                    <form className="row" onSubmit={handleSubmit(onSubmit)}>
                        <div className="col-12 col-md-12">
                            <div className="appointment_select">
                                <div className="row">
                                    <div className="col-md-6 mb-3">
                                        <label htmlFor="" className="form-label">Send approval request to*</label>
                                        <select className="form-select" aria-label="Default select example" name='approval_request_to' {...register('approval_request_to', { required: 'true' })} disabled={Object.keys(EditAppointmentList).length > 0 ? true : false}>
                                            <option value="">-Please Select -</option>
                                            {AllUserList && AllUserList.length > 0 && AllUserList.map((item) => <option value={item?.id}>{item?.first_name} {item?.last_name}</option>)}
                                        </select>
                                        {errors.approval_request_to && errors.approval_request_to.type === "required" && <span className="error">Send approval request is required</span>}
                                    </div>
                                    <div className="col-md-6 mb-6">
                                        <label for="" className="form-label">Notification Level*</label>
                                        <select className="form-select" aria-label="Default select example" name='notification_level' {...register('notification_level', { required: 'true' })}>
                                            <option value="">-Please Select -</option>
                                            <option value="critical">Critical</option>
                                            <option value="high">High</option>
                                            <option value="medium">Medium</option>
                                            <option value="low">Low</option>
                                        </select>
                                        {errors.notification_level && errors.notification_level.type === "required" && <span className="error">Notification Level is required</span>}
                                    </div>
                                </div>
                            </div>
                            <div className="doctor_text">
                                <h3 className="font-20">
                                    <HeadingSvg />
                                    Doctor Details
                                </h3>
                            </div>

                            <div className="appointment_select">
                                <div className="row">
                                    <div className="col-md-6 mb-3">
                                        <label htmlFor="" className="form-label">
                                            Appointment Type*
                                        </label>
                                        <select className="form-select" aria-label="Default select example" value={selectedType} onChange={(e) => handleAppointmentType(e.target.value)} disabled={Object.keys(EditAppointmentList).length > 0 ? true : false}>
                                            <option value="">-Please Select -</option>
                                            <option value='medical'>Medical</option>
                                            <option value='psych'>Psych</option>
                                        </select>
                                        {errors.appointment_type && errors.appointment_type.type === "required" && <span className="error">Appointment Type is required</span>}
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label htmlFor="" className="form-label">
                                            Appointment With*
                                        </label>
                                        <select className="form-select" aria-label="Default select example" value={selectedWith} onChange={(e) => handleAppointmentWith(e)}>
                                            <option value="">-Please Select -</option>
                                            {AppointmentWithList && AppointmentWithList.map((item) => <option value={item.id}>{item.first_name} {item.last_name}</option>)}
                                        </select>
                                        {errors.appointment_with && errors.appointment_with.type === "required" && <span className="error">Appointment With is required</span>}
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label for="" className="form-label">Phone number*</label>
                                        <input type="input" className="form-control" id="" placeholder="Enter phone number" {...register("doctor_mobile", { required: "true", pattern: { value: /^\d{10,15}$/, message: "Phone number must be 10 to 15 digits" } })} maxLength={15} onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault() } }} readOnly />
                                        {errors.doctor_mobile && errors.doctor_mobile.type === "required" && (<span className="error">Phone number is required</span>)}
                                        {errors.doctor_mobile && errors.doctor_mobile.type === "pattern" && (<span className="error">{errors?.doctor_mobile?.message}</span>)}
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label for="" className="form-label">Specialty*</label>
                                        <input type="input" className="form-control" id="" placeholder="Enter Specialty" {...register("doctor_specialty", { required: "true", setValueAs: (value) => value.trimStart() })} maxLength={25} />
                                        {errors.doctor_specialty && errors.doctor_specialty.type === "required" && (<span className="error">Specialty is required!</span>)}
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label for="" className="form-label">Location*</label>
                                        <input type="input" className="form-control" id="" placeholder="Enter location" maxLength={25} value={docLocation} onChange={(e) => handleLocationChange(e)} />
                                        {errors.doctor_location_type && errors.doctor_location_type.type === "required" && (<span className="error">{errors.doctor_location_type.message}</span>)}
                                        {ExistingLocationList && ExistingLocationList.length > 0 && docLocation !== '' &&
                                            <div className="address_div">
                                                <ul>
                                                    {ExistingLocationList.map((item) => <><li onClick={() => handleLocationList(item)}>{item.name}</li> <hr/></>)}
                                                </ul>
                                            </div>
                                        }
                                    </div>

                                    <div className="col-md-6 mb-3">
                                        <label for="" className="form-label">Address*</label>
                                        <input type="input" className="form-control" id="" placeholder="Enter address" maxLength={25} value={docAddress} onChange={(e) => handleAddressChange(e)} />
                                        {errors.doctor_address && errors.doctor_address.type === "required" && (<span className="error">{errors.doctor_address.message}</span>)}
                                        {ExistingAddressList && ExistingAddressList.length > 0 &&
                                            <div className="address_div">
                                                <ul>
                                                    {ExistingAddressList.map((item) => <><li onClick={() => handleAddressList(item.doctor_address)}>{item.doctor_address}</li> <hr/></>)}
                                                </ul>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="doctor_text">
                                <h3 className="font-20">
                                    <HeadingSvg />
                                    Appointment Information
                                </h3>
                            </div>
                            <div className="appointment_select">
                                <div className="row">
                                    <div className="col-12 mb-3">
                                        <label for="" className="form-label">Start Date*</label>
                                        <input type="date" min={minDate} className="form-control" id="" placeholder="Exp. Jan 6, 2024" value={selectedDate} name= 'startDate' onChange={(e) => handleDateChange(e)} onKeyDown={(e) => e.preventDefault()} disabled={dateDisbale}/>
                                        {errors?.appointment_date && errors.appointment_date.type === "required" && (
                                            <span className="error">{errors?.appointment_date?.message}</span>
                                        )}
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label for="" className="form-label">Start Time*</label>
                                        <input type="time" className="form-control" id="" placeholder="Exp. 3:00pm" name="start_time" value={selectedTime.start} onChange={(e) => handleTimeChange(e)} onKeyDown={(e) => e.preventDefault()} />
                                        {errors?.appointment_start_time && errors.appointment_start_time.type === "required" && (
                                            <span className="error"> This is required</span>
                                        )}
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label for="" className="form-label">End Time*</label>
                                        <input type="time" className="form-control" id="" placeholder="Exp. 3:00pm" name="end_time" value={selectedTime.end} onChange={(e) => handleTimeChange(e)} onKeyDown={(e) => e.preventDefault()} />
                                        {errors?.appointment_end_time && errors.appointment_end_time.type === "required" && (
                                            <span className="error">{errors.appointment_end_time.message}</span>
                                        )}
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label htmlFor="" className="form-label">
                                            Frequency*
                                        </label>
                                        <select className="form-select" aria-label="Default select example" value={selectedFrequency} onChange={(e)=> handleFrequencyChange(e)} disabled={EditAppointmentList?.parent_id ? true : false}>
                                            {FrequencyOption && FrequencyOption.map((item) => <option value={item.value}>{item.lable}</option>)}

                                        </select>
                                        {errors.appointment_frequency && errors.appointment_frequency.type === "required" && <span className="error">{errors.appointment_frequency.message}Frequency is required</span>}
                                    </div>
                                    {selectedFrequency !== 'does_not_repeat' && !EditAppointmentList?.parent_id &&
                                    <div className="col-md-6 mb-3">
                                        <label for="" className="form-label">End date*</label>
                                        <input type="date"
                                         min={endMinDate}
                                        //  max={endMaxDate}
                                         className="form-control" 
                                         id=""
                                         placeholder="Exp. Jan 6, 2024" 
                                         value={endDate}
                                         name = "endDate"
                                         onChange={(e) => handleDateChange(e)} 
                                         onKeyDown={(e) => e.preventDefault()} 
                                         
                                         />
                                    </div>
                                    }
                                </div>
                            </div>
                            <div className="reason_for_appointment">
                                <label for="" className="form-label">Reason for Appointment*</label>
                                <div className="mb-3">
                                    <textarea className="form-control" placeholder="Enter Reason" {...register("appointment_reason", { required: "true", setValueAs: (value) => value.trimStart()  })} maxLength={150} />
                                    {errors.appointment_reason && errors.appointment_reason.type === "required" && (<span className="error">Reason is required!</span>)}
                                </div>
                            </div>

                            {selectedMember.length === 1 && selectedType === 'psych' ?
                                <></>
                                :
                                <div className="col-12 ">
                                    <div className="appointment_select search_document">
                                        <div className="row">
                                            <label className="form-check-label" for="flexSearch">Members</label>
                                            <div className="col-md-12 mb-12 search-sect">
                                                <SearchSvg />
                                                <input type="search" className="form-control" placeholder="Search a member then click or press enter to add them" value={searchInput} onChange={(e) => handleMemberSearch(e)} />
                                                <div className="search_icon_s">
                                                    <AfterSearchSvg />
                                                </div>
                                            </div>
                                            {errors.member_ids && errors.member_ids.type === "required" && (<span className="error">Please select member</span>)}
                                            {searchInput !== '' &&
                                                <div className="member_container">
                                                    <ul>
                                                        {filteredData.map(user => {
                                                            let shortName = user?.first_name?.charAt(0)?.toUpperCase() + user?.last_name?.charAt(0)?.toUpperCase() || '';
                                                            return (
                                                            <li key={user.id} className="member_li" onClick={() => handleMemberClick(user)}>
                                                                {/* {user.profile_pic && <img src={user.profile_pic} alt={user.first_name} width="50" />} */}
                                                                <span class="name ">
														{user?.profile_pic ?
															<img src={user?.profile_pic} /> :
															shortName
														}
													</span>
                                                                {user.first_name} {user.last_name}
                                                            </li>
                                                        )}
                                                        )}
                                                    </ul>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            }

                            <div className="filter-option document_option">
                                {selectedMember && selectedMember.map((item) => {
                                    let shortName = item?.first_name?.charAt(0)?.toUpperCase() + item?.last_name?.charAt(0)?.toUpperCase() || '';
                                    return (
                                        <>
                                            <div className="profile-sec  appointment-member">
                                                <span className="name ">
                                                    {item?.profile_pic ?
                                                        <img src={item?.profile_pic} /> :
                                                        shortName
                                                    }
                                                </span>
                                                <div className="profile-info">
                                                    <p className="font-14 hd-title">{item?.first_name}
                                                        <span onClick={() => handleMemberDelete(item)}><CrossSvg /></span>
                                                    </p>
                                                </div>
                                            </div>
                                        </>
                                    )
                                })}
                            </div>
                            <div className="appointment_cont">
                                <p className="font-16 hd-subtitle">
                                    When creating a psych appointment every appointment is associated with
                                    one member. This means that attaching multiple individuals to one
                                    appointment is not possible.
                                </p>
                            </div>
                            {selectedType === "psych" && (
                                <>
                                    <div className="doctor_text">
                                        <h3 className="font-20">
                                            <HeadingSvg />
                                            Patient History
                                        </h3>
                                    </div>
                                    <div className="patient_history">
                                        <div className="row">
                                            <div className="col-md-6 mb-3">
                                                <label for="" className="form-label">Summary of current behaviours*</label>
                                                <input type="input" className="form-control" id="" placeholder="Enter Summary of current behaviours" {...register("current_behaviour", { required: "true" })} maxLength={25} />
                                                {errors.current_behaviour && errors.current_behaviour.type === "required" && (<span className="error">Summary of current behaviours is required!</span>)}
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <label for="" className="form-label">Observed side effects (indicate N/A if none)*</label>
                                                <input type="input" className="form-control" id="" placeholder="Enter bserved side effects" {...register("observerd_side_effects", { required: "true" })} maxLength={25} />
                                                {errors.observerd_side_effects && errors.observerd_side_effects.type === "required" && (<span className="error">Observed side effects is required!</span>)}
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <label for="" className="form-label">Other monitoring information presented to the physician*</label>
                                                <input type="input" className="form-control" id="" placeholder="Enter Other monitoring" {...register("other_monitoring_info", { required: "true" })} maxLength={25} />
                                                {errors.other_monitoring_info && errors.other_monitoring_info.type === "required" && (<span className="error">Other monitoring information presented to the physician is required!</span>)}
                                            </div>
                                            <div className="col-12 mb-3">
                                                <label for="" className="form-label">Date of last tardive dyskinesia screening*</label>
                                                <input type="date" className="form-control" id="" placeholder="Exp. Jan 6, 2024" {...register(`td_screening_date`, { required: "Enter valid date", })} onKeyDown={(e) => e.preventDefault()} />
                                                {errors?.td_screening_date && (
                                                    <span className="error">{errors.td_screening_date.message}</span>
                                                )}
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <label for="" className="form-label">Results*</label>
                                                <input type="input" className="form-control" id="" placeholder="Enter Results" {...register("patient_results", { required: "true" })} maxLength={25} />
                                                {errors.patient_results && errors.patient_results.type === "required" && (<span className="error">Results is required!</span>)}
                                            </div>
                                            <div className="col-md-12 col-12 mb-3">
                                                <label htmlFor="" className="form-label">
                                                    Was a tardive dyskinesia screening needed today? If yes, what is
                                                    the result? *
                                                </label>
                                                <input type="input" className="form-control" id="" placeholder="Enter" {...register("td_screening_results", { required: "true" })} maxLength={25} />
                                                {errors.td_screening_results && errors.td_screening_results.type === "required" && (<span className="error">This is required!</span>)}
                                            </div>
                                            <div className="col-md-12 col-12 mb-3">
                                                <label htmlFor="" className="form-label">
                                                    Was screening for side effects completed today? If yes, what is the result?*
                                                </label>
                                                <input type="input" className="form-control" id="" placeholder="Enter" {...register("side_effect_screening_completed_results", { required: "true" })} maxLength={25} />
                                                {errors.side_effect_screening_completed_results && errors.side_effect_screening_completed_results.type === "required" && (<span className="error">This is required!</span>)}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="appointment_cont">
                                        <p className="font-16 hd-subtitle">
                                            If the physician , individual/responsible person, and ISP team for
                                            appropriate action in the event of positive screening results for side
                                            effects/tardive dyskinesia; and, notify the Program Review Committee
                                            (PRC) and the Intelligence Oversight Committee, and the Division’s
                                            Medical Director within 15 working days for review of the positive
                                            screening results.
                                        </p>
                                    </div>
                                    <div className="patient_history">
                                        <div className="row">
                                            <div className="col-md-12 col-12 mb-3">
                                                <label htmlFor="" className="form-label">
                                                    Summary/recommendations from the physician? *
                                                </label>
                                                <input type="input" className="form-control" id="" placeholder="Enter recommendations" {...register("recommendations", { required: "true" })} maxLength={25} />
                                                {errors.recommendations && errors.recommendations.type === "required" && (<span className="error">This is required!</span>)}
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}

                            <div className="cancel_save">
                                <div className="row">
                                    <div className="col-md-6 mb-3">
                                        <div className="save_changes_btn">
                                            <button
                                                type="button"
                                                className="btn btn-light"
                                                onClick={() => handleCancle()}
                                            >
                                                Cancel
                                            </button>
                                        </div>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <div className="save_changes_btn">
                                            <button type="submit" className="btn btn-primary" onClick={() => handleSubmitButton()}>
                                                {`${Object.keys(EditAppointmentList).length > 0 ? 'Update Appointment' : 'Create Appointment'}`}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>


                </div>
            </div>
        </div>
    )

}
export default AddNewAppointment