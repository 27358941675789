import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import FilterAndSearch from "./FilterAndSearch";
import DataNotFound from './DataNotFound';
import { Pagination } from '../../../Pagination';
import { calculateTimeDifference, formatAMPM, monthDayYearFormat } from '../../../../components/Common/Common';
import AddComment from '../AddComment';
import { get_edit_listing_all_data } from '../../../../redux/services/AuthMar';

const OverdueTab = ({ id, activeTab }) => {
    const dispatch = useDispatch();
    
    // Redux state selectors
    const totalPages = useSelector(state => state?.AuthMar?.AllListingData?.totalNoPages);
    const AllListingData = useSelector(state => state?.AuthMar?.AllListingData?.marListData);
    const approvalStatus = useSelector(state => state?.AuthMar?.EditMemberDetails?.approval_status);
    
    // Local state management
    const [currentPage, setCurrentPage] = useState(1);
    const [addComment, setAddComment] = useState(false);
    const [checkBoxId, setCheckBoxId] = useState('');
    const [sortType, setSortType] = useState(true); // true for ascending, false for descending
    const [listingData, setListingData] = useState([]);

    // Effect to fetch data when component mounts or when id or addComment changes
    useEffect(() => {
        if (id) {
            const data = {
                page: 1,
                listData: 10,
                mar_detail_id: id,
                type: activeTab.toLowerCase()
            };
            dispatch(get_edit_listing_all_data(data));
        }
        // Reset checkBoxId when not adding a comment
        if (!addComment) {
            setCheckBoxId('');
        }
    }, [id, addComment, dispatch, activeTab]);

    // Handle page change for pagination
    const handlePageChange = (pageNumber) => {
        const data = {
            page: pageNumber,
            listData: 10,
            mar_detail_id: id,
            type: activeTab.toLowerCase()
        };
        setCurrentPage(pageNumber);
        dispatch(get_edit_listing_all_data(data));
    };

    // Toggle checkbox for adding comments
    const handleChange = (e, id) => {
        setCheckBoxId(id);
        setAddComment(!addComment);
    };

    // Toggle sorting order
    const handleSort = () => {
        setSortType(!sortType);
    };

    // Effect to sort listing data when sortType or AllListingData changes
    useEffect(() => {
        if (AllListingData && AllListingData.length > 0) {
            const data = [...AllListingData]; // Create a shallow copy of AllListingData
            data.sort((a, b) => {
                // Combine date and time for comparison
                const dateTimeA = new Date(`${a.administer_date.split('-').reverse().join('-')}T${a.administer_time}`);
                const dateTimeB = new Date(`${b.administer_date.split('-').reverse().join('-')}T${b.administer_time}`);
                
                return sortType ? dateTimeA - dateTimeB : dateTimeB - dateTimeA; // Ascending or descending
            });
            setListingData(data);
        } else {
            setListingData([]); // Reset if no data
        }
    }, [sortType, AllListingData]);

    return (
        <>
            <div className="tab-pane fade vit_info_data mar_pending show active">
                {/* <FilterAndSearch /> Uncomment to use filtering functionality */}
                <div className="col-md-12 col-12">
                    <div className="table-sec">
                        <div className="table-responsive">
                            {listingData && listingData.length === 0 ? (
                                <DataNotFound activeTab={activeTab} />
                            ) : (
                                <table className="table table-hover">
                                    <thead>
                                        <tr>
                                            <th scope="col">Due Date
                                                <a onClick={handleSort}>
                                                    {sortType ? (
                                                        <svg className="ms-1" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M6 1.33337V10.6667M6 10.6667L10.6667 6.00004M6 10.6667L1.33333 6.00004" stroke="#475467" strokeWidth="0.888889" strokeLinecap="round" strokeLinejoin="round" />
                                                        </svg>
                                                    ) : (
                                                        <svg className="ms-1" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M6 10.6667V1.33337M6 1.33337L10.6667 6.00004M6 1.33337L1.33333 6.00004" stroke="#475467" strokeWidth="0.888889" strokeLinecap="round" strokeLinejoin="round" />
                                                        </svg>
                                                    )}
                                                </a>
                                            </th>
                                            <th scope="col">Route</th>
                                            <th scope="col">Frequency</th>
                                            <th scope="col">Quantity</th>
                                            <th scope="col" className="edit_mar_decs">Administered</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {listingData.map(item => {
                                            // Calculate due time and formatted date/time
                                            const dueTime = calculateTimeDifference(`${item?.administer_date} ${item?.administer_time}`, true);
                                            const time = formatAMPM(item?.administer_time);
                                            const checkDis = (dueTime.includes('2 hours') || dueTime.includes('minutes')) && (approvalStatus === 'pending' ? false : true);

                                            const nameInitials = `${item?.membersMarDetail?.user?.first_name.charAt(0)}${item?.membersMarDetail?.user?.last_name.charAt(0)}`;
                                            const date = monthDayYearFormat(item?.administer_date);
                                            
                                            return (
                                                <tr key={item.id}>
                                                    <td>
                                                        <span className="due_time">{dueTime || ''}</span>
                                                        <span className="d-block">{date || ''}</span>
                                                        <span className="d-block">{time || ''}</span>
                                                    </td>
                                                    <td>{item?.masterRoute?.name || ''}</td>
                                                    <td className="lower">{item?.frequency || ''} x Daily</td>
                                                    <td>{item?.give_amount_quantity || ''} Tablet</td>
                                                    <td className="edit_mar_decs">
                                                        <input className="form-check-input" type="checkbox"
                                                            checked={item?.id === checkBoxId}
                                                            onChange={(e) => handleChange(e, item?.id)}
                                                            disabled={!checkDis}
                                                        />
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                        <tr>
                                            <td colSpan="5">
                                                <Pagination
                                                    currentPage={currentPage}
                                                    totalPages={totalPages}
                                                    onPageChange={handlePageChange}
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {addComment && (
                <AddComment handleCommentToggle={setAddComment} checkBoxId={checkBoxId} parentId={AllListingData[0]?.membersMarDetail?.id} />
            )}
        </>
    );
};

export default OverdueTab;
