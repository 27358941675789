import React, { useEffect, useState } from "react";
import Select from 'react-select';

const CountryCode = ({ CountryData, country, onChange, name }) => {
    const [ascendingCountry, setAscendingCountry] = useState([]);

    useEffect(() => {
        if (CountryData) {
            const ascendingData = CountryData?.slice().sort((a, b) => {
                // Prioritize "US"
                if (a.iso2 === 'US') return -1; // Move "US" to the top
                if (b.iso2 === 'US') return 1;  // Move "US" to the top

                // Regular localeCompare sorting for other countries
                if (!a.iso2 || !b.iso2) return 0; // Handle undefined/null cases
                return a.iso2.localeCompare(b.iso2);
            });
            setAscendingCountry(ascendingData);
        }
    }, [CountryData]);

    const SingleValue = ({ data }) => (
        <div className='flag_icon' style={{ display: 'flex', alignItems: 'center' }}>
            <img src={data.logo} alt={data.label} style={{ width: '20px', marginRight: '8px' }} />
            {data.label}
        </div>
    );

    const Option = (props) => (
        <div {...props.innerProps} style={{ display: 'flex', alignItems: 'center', padding: '8px' }}>
            <img src={props.data.logo} alt={props.data.label} style={{ width: '20px', marginRight: '8px' }} />
            {props.data.label}
        </div>
    );

    const options = ascendingCountry.map(item => ({
        value: item.country_code,
        label: item.iso2,
        logo: item.logo,
    }));

    return (
        <>
            <Select
                options={options}
                value={options.find(option => option.value === country)}
                onChange={(selectedOption) => onChange({ target: { name: name, value: selectedOption.value } })}
                components={{ Option, SingleValue }}
            />
        </>
    );
}

export default CountryCode;
