import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import PcspFooter from '../../PcspFooter'

const MemberProfile = ({ onHandleBackStep, onSubmit, isGoBack }) => {
  const allDddPcsp = useSelector(state => state?.AuthMember?.allDddPcsp?.meetingInfo?.memberdddrpcspmemberprofile);
  const UploadedPdfData = useSelector(state => state?.AuthMember?.UploadedPdfData);
  // Initialize the form with useForm hook
  const { register, handleSubmit, setValue, formState: { errors } } = useForm();

  useEffect(() => {
    if (UploadedPdfData?.length > 0 && !isGoBack) {
      UploadedPdfData.forEach((item) => {
        switch (item.key) {
          case 'Notes:':
            setValue('notes', item?.value);
            break
          case 'What do you understand about your physical and/or mental health from your doctor or service providers?':
            setValue('physical_mental_health', item?.value);
            break
        }
      })
    } else {
      // Member/Responsible person
      setValue('served_in_military', allDddPcsp?.served_in_military);
      setValue('notes', allDddPcsp?.notes);
      setValue('major_life_changes_recently', allDddPcsp?.major_life_changes_recently);
      setValue('physical_mental_health', allDddPcsp?.physical_mental_health);
      setValue('supports_related_to_health', allDddPcsp?.supports_related_to_health);
    }
  }, [allDddPcsp, UploadedPdfData])

  return (
    <div className="tab-pane fade steptwo_mprofile show active">
      <div className="alert alert-light sap-alert-heading">
        <div className="hd-subtitle font-16">Document brief background of the member’s life experiences (e.g. place of
          birth, developmental, education, and employment history, justice system involvement, previous living
          situations):</div>
      </div>
      <div className="col-12">
        <div className="member_vital_basic_title">
          <h4>Profile of member</h4>
        </div>
        <div className="vital_stepone_para">
          <p>A detailed bio of the member</p>
        </div>
      </div>
      <div className="member_vitalstep_one_form">
        <form onSubmit={handleSubmit(onSubmit)} className="row">
          <div className="col-12 mb-3">
            <label className="form-label">Have you served in the military?*</label>
            <select
              className="form-select"
              aria-label="Default select example"
              {...register(`served_in_military`, { required: "This is required!" })}>
              <option value="">Select</option>
              <option value="0">No</option>
              <option value="1">Yes</option>
              <option value="2">N/A</option>
            </select>
            {errors.served_in_military && errors.served_in_military && (
              <span className="error">{errors.served_in_military.message}</span>
            )}
          </div>
          <div className="col-12 mb-3">
            <label for="" className="form-label">Notes*</label>
            <textarea className="form-control" placeholder="Enter notes" {...register("notes", { required: "true" })} maxLength={150} />
            {errors.notes && errors.notes.type === "required" && (<span className="error">Notes is required!</span>)}
          </div>
          <div className="col-12 mb-3">
            <label for="" className="form-label">Have there been any major changes in your life recently (since we last spoke/last review)?*</label>
            <select
              className="form-select"
              aria-label="Default select example"
              {...register(`major_life_changes_recently`, { required: "This is required!" })}>
              <option value="">Select</option>
              <option value="0">No</option>
              <option value="1">Yes</option>
              <option value="2">N/A</option>
            </select>
            {errors.major_life_changes_recently && errors.major_life_changes_recently && (
              <span className="error">{errors.major_life_changes_recently.message}</span>
            )}
          </div>

          <div className="col-12 mb-3">
            <label for="" className="form-label">What do you understand about your physical and/or mental health from your doctor or service providers?*</label>
            <textarea className="form-control"
              placeholder="Enter physical and/or mental health"
              maxLength={150}
              {...register('physical_mental_health', { required: "This is required!" })}
            />
            {errors.physical_mental_health && errors.physical_mental_health && (
              <span className="error">{errors.physical_mental_health.message}</span>
            )}
          </div>
          <div className="col-12 mb-3">
            <label for="" className="form-label">Is there an area regarding your physical or mental health or services and supports related to your health that you want to work towards improving?*</label>
            <select
              className="form-select"
              aria-label="Default select example"
              {...register(`supports_related_to_health`, { required: "This is required!" })}>
              <option value="">Select</option>
              <option value="0">No</option>
              <option value="1">Yes</option>
              <option value="2">N/A</option>
            </select>
            {errors.supports_related_to_health && errors.supports_related_to_health && (
              <span className="error">{errors.supports_related_to_health.message}</span>
            )}
          </div>
          <PcspFooter onHandleBackStep={onHandleBackStep} />
        </form>
      </div>
    </div>
  )
}

export default MemberProfile