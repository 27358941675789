import React from 'react';
import Header from '../../components/auth/Header';
import Footer from '../../components/auth/Footer';
import Siderbar from '../../components/auth/Siderbar';
import { Outlet, useLocation, useParams, matchPath } from 'react-router-dom';

const AuthLayout = () => {
  const location = useLocation();
  const { id } = useParams();
  
  const pathsToExclude = [
    '/auth/add-home',
    '/auth/add-member',
    `/auth/edit-members/${id}`,
    `/auth/add-member/${id}`,
    `/auth/edit-members/ddd/pcsp/${id}`,
    `/auth/edit-members/individualized/${id}`,
    `/auth/edit-members/medicalhistory/${id}`,
    `/auth/edit-members/general-consent/${id}`,
    `/auth/edit-members/behavior-modifying/${id}`,
    // `/auth/documents`,
  ];

  const shouldHideHeaderAndSidebar = pathsToExclude.some((path) => 
    matchPath(path, location.pathname)
  );

  return (
    <div className='sb-nav-fixed'>
      {!shouldHideHeaderAndSidebar && <Header />}
      <div id="layoutSidenav">
        {!shouldHideHeaderAndSidebar && <Siderbar />}
        <Outlet />
      </div>
    </div>
  );
}
export default AuthLayout;