import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { customAlert } from "../../utils/alert";
import { HTTPURL } from "../../constant/Matcher";

export const signup = createAsyncThunk(
    "auth/register",
    async (formData, thunkAPI) => {
        const { rejectWithValue } = thunkAPI;
        try {
            const res = await axios.post(
                HTTPURL,
                JSON.stringify(formData),
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                },
                {
                    redirect: "follow",
                }
            );
            customAlert("Welcome to Lifetrack!", "success");
            return res.data;
        } catch (error) { customAlert(error?.response?.data? error?.response?.data?.message : error?.message);;
        }
    }
);
