import React, { useEffect } from 'react'
import { useForm, useFieldArray } from 'react-hook-form';
import { useSelector } from 'react-redux';
import PcspFooter from '../../PcspFooter'

const NonALTCSFunded = ({ onHandleBackStep, onSubmit }) => {
  const allDddPcsp = useSelector(state => state?.AuthMember?.allDddPcsp?.servicesAuthorizedNonAltcs);
  // Initialize the form with useForm hook
  const { control, handleSubmit, register, getValues, setValue, formState: { errors } } = useForm({
    defaultValues: {
      nonAlTCSFund: [{ responsible_party: "", service_frequency: "", nonaltcs_service_name: "" }],
    }
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'nonAlTCSFund'
  });

  useEffect(() => {
    const lengthDifference = allDddPcsp?.length - fields.length;
    if (lengthDifference > 0) {
        for (let i = 0; i < lengthDifference; i++) {
            append({});
        }
    }
    allDddPcsp?.forEach((item, index) => {
        setValue(`nonAlTCSFund.${index}.responsible_party`, item.responsible_party || '');
        setValue(`nonAlTCSFund.${index}.service_frequency`, item.service_frequency || '');
        setValue(`nonAlTCSFund.${index}.nonaltcs_service_name`, item.nonaltcs_service_name || '');
    });

  }, [allDddPcsp])

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="row">
        <div className="member_vital_basic_title">
          <h4>List all non-ALTCS funded services provided by payer source (i.e. medicare)</h4>
        </div>

        {fields.map((member, index) => (
          <div key={member.id} className="row">
            {index > 0 && (
                <hr/>
            )}
            <div className="col-12 mb-3">
              <label for="" className="form-label">Non-ALCTS funded service*</label>
              <input
                type="input"
                className="form-control"
                placeholder="Enter Non-ALCTS funded service"
                {...register(`nonAlTCSFund.${index}.nonaltcs_service_name`, { required: "Non-ALCTS funded service is required!" })}
                maxLength={20}
              />
              {errors.nonAlTCSFund && errors.nonAlTCSFund[index]?.nonaltcs_service_name && (
                <span className="error">{errors.nonAlTCSFund[index].nonaltcs_service_name.message}</span>
              )}
            </div>
            <div className="col-12 mb-3">
              <label for="" className="form-label">Responsible party/payer source*</label>
              <input
                type="input"
                className="form-control"
                placeholder="Enter Responsible party"
                {...register(`nonAlTCSFund.${index}.responsible_party`, { required: "Responsible party is required!" })}
                maxLength={20}
              />
              {errors.nonAlTCSFund && errors.nonAlTCSFund[index]?.responsible_party && (
                <span className="error">{errors.nonAlTCSFund[index].responsible_party.message}</span>
              )}
            </div>
            <div className="col-12 mb-3">
              <label for="" className="form-label">Approximate service frequency (e.g. Daily, Weekly, Monthly)*</label>
              <input
                type="input"
                className="form-control"
                placeholder="Enter Approximate service"
                {...register(`nonAlTCSFund.${index}.service_frequency`, { required: "This is required!" })}
                maxLength={20}
              />
              {errors.nonAlTCSFund && errors.nonAlTCSFund[index]?.service_frequency && (
                <span className="error">{errors.nonAlTCSFund[index].service_frequency.message}</span>
              )}
            </div>
            {index > 0 && (
              <div className="col-12 mb-3">
                <a
                  href="#"

                  className="stepone_remove"
                  onClick={(e) => {
                    e.preventDefault();
                    remove(index);
                  }}
                >
                  Remove
                </a>
              </div>
            )}

          </div>
        ))}
        <div className="stepone_health_planbtn">
          <button type="button" className="font-14" onClick={() => append({ id: Date.now() })}><svg width="12" height="12" viewBox="0 0 12 12" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M6.00016 1.33325V10.6666M1.3335 5.99992H10.6668" stroke="#667085" stroke-width="2"
              stroke-linecap="round" stroke-linejoin="round"></path>
          </svg>
            Add another health plan</button>
        </div>
        <PcspFooter onHandleBackStep={onHandleBackStep} />
      </form>
    </>
  )
}

export default NonALTCSFunded
