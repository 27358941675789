export const mapTasksToColumns = (tasksData) => { 
           return [
               {
                   id: 'pending',
                   title: 'Pending',
                   color: 'pending_task',
                   tasks: tasksData?.pending?.map(task => ({
                       id: task.id,
                       title: task.title,
                       description: task.description,
                       due_date: task.due_date,
                       task_status: task.task_status,
                       created_by: task.created_by,
                       assignee: {
                           avatar: task.userAssigned.profile_img_url,
                           name: task.userAssigned?.first_name?.charAt(0)?.toUpperCase() + task.userAssigned?.last_name?.charAt(0)?.toUpperCase() || '',
                           fullName: `${task?.userAssigned?.first_name} ${task.userAssigned?.last_name}`
                       }
                   }))
               },
               {
                   id: 'past-due',
                   title: 'Past due',
                   color: 'past_due',
                   tasks: tasksData['past due']?.map(task => ({
                       id: task.id,
                       title: task.title,
                       description: task.description,
                       due_date: task.due_date,
                       task_status: task.task_status,
                       created_by: task.created_by,
                       assignee: {
                           avatar: task.userAssigned.profile_img_url,
                           name: task.userAssigned?.first_name?.charAt(0)?.toUpperCase() + task.userAssigned?.last_name?.charAt(0)?.toUpperCase() || '',
                           fullName: `${task?.userAssigned?.first_name} ${task.userAssigned?.last_name}`
                       }
                   }))
               },
               {
                   id: 'pending-approval',
                   title: 'Pending approval',
                   color: 'pending_approval',
                   tasks: tasksData['pending approval']?.map(task => ({
                       id: task.id,
                       title: task.title,
                       description: task.description,
                       due_date: task.due_date,
                       task_status: task.task_status,
                       created_by: task.created_by,
                       assignee: {
                           avatar: task.userAssigned.profile_img_url,
                           name: task.userAssigned?.first_name?.charAt(0)?.toUpperCase() + task.userAssigned?.last_name?.charAt(0)?.toUpperCase() || '',
                           fullName: `${task?.userAssigned?.first_name} ${task.userAssigned?.last_name}`
                       }
                   }))
               },
               {
                   id: 'completed',
                   title: 'Completed',
                   color: 'task_completed',
                   tasks: tasksData?.completed?.map(task => ({
                       id: task.id,
                       title: task.title,
                       description: task.description,
                       due_date: task.due_date,
                       task_status: task.task_status,
                       created_by: task.created_by,
                       assignee: {
                           avatar: task.userAssigned.profile_img_url,
                           name: task.userAssigned?.first_name?.charAt(0)?.toUpperCase() + task.userAssigned?.last_name?.charAt(0)?.toUpperCase() || '',
                           fullName: `${task?.userAssigned?.first_name} ${task.userAssigned?.last_name}`
                       }
                   }))
               }
           ];
};