import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import PcspFooter from '../../PcspFooter'

const DailyLife = ({ onHandleBackStep, onSubmit, isGoBack }) => {

  const allDddPcsp = useSelector(state => state?.AuthMember?.allDddPcsp?.dailyLife);
  const UploadedPdfData = useSelector(state => state?.AuthMember?.UploadedPdfData);
  // Initialize the form with useForm hook
  const { register, handleSubmit, setValue, formState: { errors } } = useForm();

  useEffect(() => {
    if (UploadedPdfData?.length > 0 && !isGoBack) {
      UploadedPdfData.forEach((item) => {
        switch (item.key) {
          case 'Document alternative programs settings considered by/offered to the member including information that helped\ninform the choices selected and decisions made by the member (e.g. preferences, needs, visits to other settings,\netc.):':
            setValue('document_alternative_program_settings', item?.value);
            break
          case 'FORM MEMBERS IN A DAY, ADULT DAY HEALTH PROGRAM OR EMPLOYMENT PROGRAM':
            setValue('health_employment_program_notes', item?.value);
            break
        }
      })
    } else {
    setValue('document_alternative_program_settings', allDddPcsp?.document_alternative_program_settings);
    setValue('health_employment_program_notes', allDddPcsp?.health_employment_program_notes);
    setValue('notes', allDddPcsp?.notes);
    setValue('suggestion_for_community_based_housing', allDddPcsp?.suggestion_for_community_based_housing);
    setValue('suggestion_to_improve_daily_program', allDddPcsp?.suggestion_to_improve_daily_program);
    }
  }, [allDddPcsp])
  return (
    <>
    <form onSubmit={handleSubmit(onSubmit)} className="row">
      <div className="col-12">
        <div className="alert alert-light sap-alert-heading">
          <div className="hd-title font-24">Daily life (Programs/Employment/Education)</div>
          <div className="hd-subtitle font-16">Questions should be modified appropriately to ensure age appropriateness
            and applicability to institutional setting types. For example, questions related to a program may not be
            applicable to members living in a skilled nursing facility, but other questions regarding a meaningful
            day including deciding what to do every day, learning new skills and activities do apply to these
            settings.</div>
        </div>

      </div>
      <div className="col-12">
        <label for="" className="form-label">Form members in a day, adult day health program or employment
          program*</label>
        <div className="ddd_steptwo_para">
          <textarea className="form-control" placeholder="Enter notes" {...register("health_employment_program_notes", { required: "true" })} maxLength={150} />
          {errors.health_employment_program_notes && errors.health_employment_program_notes.type === "required" && (<span className="error">Notes is required!</span>)}

        </div>
      </div>
      <div className="col-12 mb-3">
        <label for="" className="form-label">Document alternative programs settings considered by/offered to the member
          including information that helped inform the choices selected and decisions made by the member (e.g.
          preferences, needs, visits to other settings, etc.):*</label>
        <input
          type="input"
          className="form-control"
          placeholder="No concern"
          {...register(`document_alternative_program_settings`, { required: "This is required!" })}
          maxLength={20}
        />
        {errors?.document_alternative_program_settings && (
          <span className="error">{errors.document_alternative_program_settings.message}</span>
        )}
      </div>
      <div className="member_vital_basic_title">
        <h4>If member expresses dissatisfaction with program or wants to explore other options</h4>
      </div>
      <div className="col-12 mb-3">
        <label for="" className="form-label">Do you have suggestions of what we could work on that could make your
          program (e.g., day/employment/educational program) better? If yes, note in goal section as
          appropriate*</label>
        <select
          className="form-select"
          aria-label="Default select example"
          {...register(`suggestion_to_improve_daily_program`, { required: "This is required!" })}>
          <option value="">Select</option>
          <option value="0">No</option>
          <option value="1">Yes</option>
          <option value="2">N/A</option>
        </select>
        {errors.suggestion_to_improve_daily_program && (
          <span className="error">{errors.suggestion_to_improve_daily_program.message}</span>
        )}
      </div>
      <div className="col-12 mb-3">
        <label for="" className="form-label">Does member require assistance with community based Housing, Employment
          and/or Education (e.g. Housing Choice Voucher [formerly called HUD Section 8]; Utility Assistance;
          Vocational Rehabilitation; Social Security Administration; AHCCCS Freedom to Work)?*</label>
        <select
          className="form-select"
          aria-label="Default select example"
          {...register(`suggestion_for_community_based_housing`, { required: "This is required!" })}>
          <option value="">Select</option>
          <option value="1">Yes</option>
          <option value="0">No</option>
        </select>
        {errors.suggestion_for_community_based_housing && (
          <span className="error">{errors.suggestion_for_community_based_housing.message}</span>
        )}
      </div>
      <div className="col-12">
        <label for="" className="form-label">Notes*</label>
        <div className="mb-3">
          <textarea className="form-control" placeholder="Enter notes" {...register("notes", { required: "true" })} maxLength={150} />
          {errors.notes && errors.notes.type === "required" && (<span className="error">Notes is required!</span>)}

        </div>
      </div>
      <PcspFooter onHandleBackStep={onHandleBackStep} />
      </form>
    </>
  )
}
export default DailyLife