import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Get_all_individualized_health_data, Basic_information, Health_medical_first_step, Health_medical_second_step, Health_medical_third_step, Health_medical_fourth_step, Diet_food, Diet_beverage, Communication_form, Mobility_form, Personal_care_skills, Behavioral_concern } from '../../../../redux/services/AuthMembers';
import { addSteps } from '../../../../redux/features/AuthMembersSlice';

import Header from './Header';
import SideBar from './SideBar';
import {AfterAddMember} from '../AfterAddMember'

// Import forms
import HealthMedical from './Forms/HealthMedical';
import BasicInformation from './Forms/BasicInformation';
import Diet from './Forms/Diet';
import Communication from './Forms/Communication';
import Mobility from './Forms/Mobility';
import PersonalCareSkills from './Forms/PersonalCareSkills';
import BehavioralConcerns from './Forms/BehavioralConcerns';

const INDIVIDUALIZEDFORMS = [
    'BasicInformation',
    'HealthMedical',
    'Diet',
    'Communication',
    'Mobility',
    'PersonalCareSkills',
    'BehavioralConcerns'
];

const formSubSteps = {
    BasicInformation: 1,
    HealthMedical: 4,
    Diet: 2,
    Communication: 1,
    Mobility: 1,
    PersonalCareSkills: 1,
    BehavioralConcerns: 1,
};

const formComponents = {
    BasicInformation,
    HealthMedical,
    Diet,
    Communication,
    Mobility,
    PersonalCareSkills,
    BehavioralConcerns,
};

const IndividualizedHealth = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [step, setStep] = useState(0);
    const [subStep, setSubStep] = useState(0);
    const [isGoBack, setIsGoBack] = useState(false)
    const [showPopUp, setShowPopUp] = useState({
        modal: false,
        message: '',
        id: ''
      })
    const totalSteps = INDIVIDUALIZEDFORMS.length;

    const getSubSteps = (formName) => formSubSteps[formName] || 1;
    const allIndividualizedHealth = useSelector(state => state?.AuthMember?.allIndividualizedHealth);
    const stepForm = useSelector(state => state?.AuthMember?.allIndividualizedHealth?.stepForm);

    const get_all_individualized_health_data = () => {
        dispatch(Get_all_individualized_health_data(id))
    }
    useEffect(() => {
        get_all_individualized_health_data()
    }, [])

    useEffect(()=>{
        const data = {
            step: step,
            subStep: subStep,
            totalSteps: totalSteps,
            totalSubSteps: getSubSteps(INDIVIDUALIZEDFORMS[step])
        }
        dispatch(addSteps(data))
    },[totalSteps,step, subStep])

    useEffect(() => {
        if(stepForm && !isGoBack){
            setSubStep((Number(stepForm?.sub_step_no) === 0 || stepForm?.sub_step_no === null) ? 0 : Number(stepForm?.sub_step_no) - 1);
            setStep((Number(stepForm?.step_no) === 0 || stepForm?.step_no) === null ? 0 : Number(stepForm?.step_no) - 1);
        }
    }, [allIndividualizedHealth, stepForm]);

    const handleNextStep = () => {
        const currentSubSteps = getSubSteps(INDIVIDUALIZEDFORMS[step]);
        if (subStep < currentSubSteps - 1) {
            setSubStep(subStep + 1);
        } else if (step < totalSteps - 1) {
            setStep(step + 1);
            setSubStep(0);
        }
    };

    const handleBackStep = () => {
        if (subStep > 0) {
            setSubStep(subStep - 1);
        } else if (step > 0) {
            setStep(step - 1);
            setSubStep(getSubSteps(INDIVIDUALIZEDFORMS[step - 1]) - 1);
        }
        setIsGoBack(true)
        get_all_individualized_health_data()
    };

    const handleCrossClick = () => {
        navigate(`/auth/edit-member/${id}`);
    };

    const onSubmit = async (data) => {
        let payload = {
            profile_status: 1,
            step_no: step + 1,
            sub_step_no: subStep + 1,
            member_id: id,
            ...data,
        };
        if (INDIVIDUALIZEDFORMS[step] === 'Diet' && subStep === 0) {
            let consistancy_of_food = [];
            if (data && data.consistancy_of_food) {
                consistancy_of_food = data.consistancy_of_food.map((item) => ({
                    consistancy_of_food: item
                }));
            }
            payload = { ...payload, consistancy_of_food };
        }
        if (INDIVIDUALIZEDFORMS[step] === 'Communication') {
            let communication_skill = [];
            if (data && data.communication_skill) {
                communication_skill = data.communication_skill.map((item) => ({
                    communication_skill: item
                }));
            }
            payload = { ...payload, communication_skill };
        }
        if (INDIVIDUALIZEDFORMS[step] === 'Mobility') {
            let independent_mobility = [];
            if (data && data.independent_mobility) {
                independent_mobility = data.independent_mobility.map((item) => ({
                    independent_mobility: item
                }));
            }
            payload = { ...payload, independent_mobility };
        }
        try {
            let response;
            switch (INDIVIDUALIZEDFORMS[step]) {
                case 'BasicInformation':
                    response = await dispatch(Basic_information(payload));
                    break;
                case 'HealthMedical':
                    if (subStep === 0) {
                        response = await dispatch(Health_medical_first_step(payload));
                    } else if (subStep === 1) {
                        response = await dispatch(Health_medical_second_step(payload));
                    } else if (subStep === 2) {
                        response = await dispatch(Health_medical_third_step(payload));
                    } else if (subStep === 3) {
                        response = await dispatch(Health_medical_fourth_step(payload));
                    }
                    break;
                case 'Diet':
                    if (subStep === 0) {
                        response = await dispatch(Diet_food(payload));

                    } else if (subStep === 1) {
                        response = await dispatch(Diet_beverage(payload));
                    }
                    break;
                case 'Communication':
                    response = await dispatch(Communication_form(payload));
                    break;
                case 'Mobility':
                    response = await dispatch(Mobility_form(payload));
                    break;
                case 'PersonalCareSkills':
                    response = await dispatch(Personal_care_skills(payload));
                    break;
                case 'BehavioralConcerns':
                    payload.profile_status = 0
                    response = await dispatch(Behavioral_concern(payload));
                    if(response?.payload?.status){
                        setShowPopUp({ modal: true, message: response?.payload?.message, id: id })
                    }
                    break;
                // Add additional cases for other forms as needed
                default:
                    break;
            }
            if (response?.payload?.status) {
                handleNextStep();
            }
        } catch (error) {
            console.log('Error:', error);
        }
    };
    
    const renderForm = () => {
        const FormComponent = formComponents[INDIVIDUALIZEDFORMS[step]];
        return FormComponent ? (
            <FormComponent
                step={step}
                subStep={subStep}
                totalSubSteps={getSubSteps(INDIVIDUALIZEDFORMS[step])}
                onHandleBackStep={handleBackStep}
                onSubmit={onSubmit}
            />
        ) : null;
    };

    return (
        <section className="step-home-sec container member_vitalinfo_stepone">
            <Header currentStep={step + 1} totalSteps={totalSteps} onClickCross={handleCrossClick} />
            <div className="pills-steps">
                <SideBar currentStep={step} INDIVIDUALIZEDFORMS={INDIVIDUALIZEDFORMS} />
                <div className="tab-content" id="v-pills-tabContent">
                    {renderForm()}
                </div>
            </div>
            <AfterAddMember setShowPopUp={setShowPopUp} showPopUp={showPopUp} type= 'IndividualizedHealth'/>
        </section>
    );
};

export default IndividualizedHealth;
