import React, { useEffect, useState } from "react";
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { UploadIconSvg, PdfIconSvg, DeleteIconSvg } from '../../../Images/CommunicationSvg'
import defaultImg from "../../../Images/defaultImg.png";
import { Add_communication, get_loggedIn_home_member_list } from '../../../redux/services/AuthCommunication'
import { json } from "react-router-dom";

const Types = [
	{ id: '1', name: 'Health' },
	{ id: '2', name: 'US/Arizona' },
	{ id: '3', name: 'US/Arizona' },
	{ id: '4', name: 'US/Arizona' },
]
const AddCommunication = ({ onModalToggle, currentHomeName = '', currentMemberId = '', selectedMember = {} }) => {
	const dispatch = useDispatch();
	const CommunicationById = useSelector(state => state?.AuthCommunication?.CommunicationById);
	const homeMemberList = useSelector(state => state?.AuthCommunication?.homeMemberList);

	const { handleSubmit, register, setError, clearErrors, setValue, formState: { errors } } = useForm();

	const [files, setFiles] = useState([]);
	const [progress, setProgress] = useState({});
	const [communicationId, setCommunicationId] = useState('')
	const [selectedMemberId, setSelectedMemberId] = useState(currentMemberId)
	const [isDisable, setIsDisable] = useState(false)
	const [communicationDocument, setCommunicationDocument] = useState([])
	const [deletedDocument, setDeletedDocument] = useState([])

	useEffect(() => {
		dispatch(get_loggedIn_home_member_list())
	}, [])

	useEffect(() => {
		if (Object.keys(CommunicationById).length > 0) {
			setIsDisable(true)
			setCommunicationId(CommunicationById?.id)
			setSelectedMemberId(CommunicationById?.member_id)
			setValue('member_id', CommunicationById?.member_id)
			setValue('title', CommunicationById?.title)
			setValue('description', CommunicationById?.description)
			setValue('type', CommunicationById?.type)
			setValue('notification_level', CommunicationById?.notification_level)
			setValue('send_notification', CommunicationById?.send_notification)
			setValue('body_check', CommunicationById?.body_check)
			setValue('physical_harm', CommunicationById?.physical_harm)
			setValue('incident_report', CommunicationById?.incident_report)
			setCommunicationDocument(CommunicationById?.communicationDocument)
		}
	}, [CommunicationById])

	const onSubmit = async (payload) => {
		if ((selectedMemberId === '')) {
			setError('member_id', { type: 'required', message: 'Select member' });
		} else {
			let formData = new FormData();
			formData.append('communication_id', communicationId);
			// formData.append('home_table_id', homeId);
			formData.append('member_id', selectedMemberId);
			formData.append('title', payload?.title);
			formData.append('description', payload?.description);
			formData.append('type', payload?.type);
			formData.append('notification_level', payload?.notification_level);
			formData.append('send_notification', payload?.send_notification);
			formData.append('body_check', payload?.body_check);
			formData.append('physical_harm', payload?.physical_harm);
			formData.append('incident_report', payload?.incident_report);
			// Append files directly under "documents"
			if (files.length > 0) {
				files.forEach((item) => {
					formData.append('documents', item);
				});
			} else {
				formData.append('documents', []);
			}

			if (deletedDocument.length === 1) {
				formData.append('delete_files', JSON.stringify([deletedDocument[0]]));
			} else if (deletedDocument.length > 1) {
				deletedDocument.forEach((item) => {
					formData.append('delete_files', item);
				});
			} else {
				formData.append('delete_files', []);
			}

			try {
				const response = await dispatch(Add_communication(formData));
				if (response?.payload?.status) {
					onModalToggle(false);
					setDeletedDocument([])
				}
			} catch (error) {
				console.error("Error during add communication:", error);
			}
		}
	};


	const handleDeleteFile = (index) => {
		// Create a new array without the file at the specified index
		const newFiles = files.filter((_, i) => i !== index);
		setFiles(newFiles);
	};
	const handleDeleteDoc = (id) => {
		let deleted = communicationDocument && communicationDocument.find((item) => item.id === id)
		let newDocument = communicationDocument && communicationDocument.filter((item) => item.id !== id)
		if (setDeletedDocument) {
			setDeletedDocument((prevDoc) => [...prevDoc, deleted.id])
		}
		setCommunicationDocument(newDocument)
	}

	const handleFileChange = (event) => {
		const selectedFiles = Array.from(event.target.files);
		setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
		// Simulate progress for each selected file
		selectedFiles.forEach((file) => simulateProgress(file));
	};

	const simulateProgress = (file) => {
		const totalDuration = 2000; // Simulated duration for progress (e.g., 2 seconds)
		const interval = 100; // Update interval (e.g., every 100ms)
		const totalSteps = totalDuration / interval;

		let currentStep = 0;

		const progressInterval = setInterval(() => {
			currentStep++;
			const percentComplete = (currentStep / totalSteps) * 100;

			setProgress((prev) => ({
				...prev,
				[file.name]: percentComplete,
			}));

			if (currentStep >= totalSteps) {
				clearInterval(progressInterval);
			}
		}, interval);
	};

	const handleMemberChane = (e) => {
		clearErrors('member_id');
		setSelectedMemberId(e.target.value)
		setValue('member_id', e.target.value)
	}

	return (
		<>
			<div className="modal fade new_medi_modal show">
				<div className="modal-dialog modal-lg permision-popup">
					<div className="modal-content ">
						<div className='modal-header margin_header'>
							<button type="button" className="btn-close" onClick={() => onModalToggle(false)}></button>
						</div>
						<div className="modal-body medication_modal">
							{Object.keys(selectedMember).length > 0 &&
								<div className="mjohan_contt">
									<div className="saf-img-upload edit-member-sec">
										<div className="mavtar_img">
											<img height="100px" width="100px" src={selectedMember.profile_pic ? selectedMember.profile_pic : defaultImg} alt="defaultImg.png" />
										</div>
									</div>
									<div className="pop-up-imgcontent">
										<h4>{selectedMember.first_name || 'N/A'}</h4>
										<p>{currentHomeName}</p>
									</div>
								</div>
							}
							{Object.keys(selectedMember).length > 0 &&
								<hr></hr>
							}
							<form class="row" onSubmit={handleSubmit(onSubmit)}>
								{currentHomeName === '' && currentMemberId === '' &&
									<>
										<div className="col-md-12 mb-12">
											<label for="" className="form-label">Member*</label>
											<select className="form-select" aria-label="Default select example" name='member' value={selectedMemberId} onChange={(e) => handleMemberChane(e)} disabled={isDisable}>
												<option value="">-Please Select -</option>
												{homeMemberList && homeMemberList.map((item) => <option value={item.id}>{item.first_name} {item.last_name}</option>)}
											</select>
											{errors.member_id && errors.member_id.type === "required" && <span className="error">{errors.member_id.message}</span>}
										</div>
									</>
								}
								<div className="col-md-12 mb-12">
									<label for="" className="form-label">Title*</label>
									<input type="input" className="form-control" id="" placeholder="Title" {...register('title', { required: "true" })} />
									{errors.title && errors.title.type === "required" && <span className="error">Title is required!</span>}

								</div>
								<div className="col-md-12 mb-12 my-4">
									<label for="" className="form-label">Description</label>
									<textarea className="form-control" id="" rows="2"
										placeholder="Description" {...register('description')}></textarea>
								</div>
								<div className="col-md-6 mb-6">
									<label for="" className="form-label">Type*</label>
									<select className="form-select" aria-label="Default select example" name='type' {...register('type', { required: 'true' })}>
										<option value="">-Please Select -</option>
										{Types && Types.map((item) => <option value={item.id}>{item.name}</option>)}
									</select>
									{errors.type && errors.type.type === "required" && <span className="error">Type is required</span>}
								</div>
								<div className="col-md-6 mb-6">
									<label for="" className="form-label">Notification Level*</label>
									<select className="form-select" aria-label="Default select example" name='notification_level' {...register('notification_level', { required: 'true' })}>
										<option value="">-Please Select -</option>
										<option value="critical">Critical</option>
										<option value="high">High</option>
										<option value="medium">Medium</option>
										<option value="low">Low</option>
									</select>
									{errors.notification_level && errors.notification_level.type === "required" && <span className="error">Notification Level is required</span>}
								</div>
								<div className="col-md-12 col-12 my-4">
									<p className="font-14 hd-title mb-2">Send Notification to admin? *</p>

									<input
										className="form-check-input"
										type="radio"
										value="yes"
										id="send_notificationYes"
										{...register('send_notification', { required: true })}
									/>
									<label className="form-check-label ms-1" htmlFor="send_notificationYes">
										Yes
									</label> &nbsp; &nbsp;

									<input
										className="form-check-input"
										type="radio"
										value="no"
										id="send_notificationNo"
										{...register('send_notification', { required: true })}
									/>
									<label className="form-check-label ms-1" htmlFor="send_notificationNo">
										No
									</label>
									<br />
									{errors.send_notification && errors.send_notification.type === "required" && (
										<span className="error">Notification is required</span>
									)}
								</div>
								<div className="col-md-12 col-12 my-4">
									<p className="font-14 hd-title mb-2">Was body check completed? *</p>

									<input
										className="form-check-input"
										type="radio"
										value="yes"
										id="body_checkYes"
										{...register('body_check', { required: true })}
									/>
									<label className="form-check-label ms-1" htmlFor="body_checkYes">
										Yes
									</label> &nbsp; &nbsp;

									<input
										className="form-check-input"
										type="radio"
										value="no"
										id="body_checkNo"
										{...register('body_check', { required: true })}
									/>
									<label className="form-check-label ms-1" htmlFor="body_checkNo">
										No
									</label>
									<br />
									{errors.body_check && errors.body_check.type === "required" && (
										<span className="error">body check is required</span>
									)}
								</div>
								<div className="col-md-12 col-12 my-4">
									<p className="font-14 hd-title mb-2">Was any physical harm observed? *</p>

									<input
										className="form-check-input"
										type="radio"
										value="yes"
										id="physical_harmYes"
										{...register('physical_harm', { required: true })}
									/>
									<label className="form-check-label ms-1" htmlFor="physical_harmYes">
										Yes
									</label> &nbsp; &nbsp;

									<input
										className="form-check-input"
										type="radio"
										value="no"
										id="physical_harmNo"
										{...register('physical_harm', { required: true })}
									/>
									<label className="form-check-label ms-1" htmlFor="physical_harmNo">
										No
									</label>
									<br />
									{errors.physical_harm && errors.physical_harm.type === "required" && (
										<span className="error">physical harm is required</span>
									)}
								</div>
								<div className="col-md-12 col-12 my-4">
									<p className="font-14 hd-title mb-2">Was incident report completed? *</p>

									<input
										className="form-check-input"
										type="radio"
										value="yes"
										id="incident_reportYes"
										{...register('incident_report', { required: true })}
									/>
									<label className="form-check-label ms-1" htmlFor="incident_reportYes">
										Yes
									</label> &nbsp; &nbsp;

									<input
										className="form-check-input"
										type="radio"
										value="no"
										id="incident_reportNo"
										{...register('incident_report', { required: true })}
									/>
									<label className="form-check-label ms-1" htmlFor="incident_reportNo">
										No
									</label>
									<br />
									{errors.incident_report && errors.incident_report.type === "required" && (
										<span className="error">incident report is required</span>
									)}
								</div>
								<div className="col-md-12 my-4">
									<h2 className="font-14 hd-title">Document Attachment</h2>
									<p className="font-14 hd-subtitle">You can share photos and files</p>
								</div>
								<div className="upload-btn-wrapper">
									<button className="vitalu_cont">
										<div className="upload_icon"><UploadIconSvg /></div>
										<div className="vital_text_png"><span>Click to upload</span> or drag and drop <br />PDF, PNG,
											JPEG, JPG, DOCX (max. 25mb)</div>
									</button>
									<input type="file" name="myfile" multiple onChange={handleFileChange} />
								</div>
								{files.map((file, index) => {
									return (
										<div key={file.name} className="progress_bar">
											<div className="progress_pdf">
												<PdfIconSvg />
											</div>
											<div className="progres_file">
												<div className="progres_file_cont">
													<h4>{file.name}<span>{(file.size / 1024).toFixed(2)} KB</span></h4>
													<div className="upload_pdf_del" onClick={() => handleDeleteFile(index)}><DeleteIconSvg /></div>
												</div>
												<div className="progress_line">
													<div className="progres_line_blue" style={{ width: `${Object.keys(CommunicationById).length > 0 ? '100' : progress[file.name] || 0}%` }}></div>
													<h4>{Object.keys(CommunicationById).length > 0 ? '100%' : progress[file.name] ? `${progress[file.name].toFixed(0)}%` : '0%'}</h4>
												</div>
											</div>
										</div>
									)
								}
								)}
								{communicationDocument && communicationDocument.length > 0 && communicationDocument.map((item, index) => {
									return (
										<div key={item.name} className="progress_bar">
											<div className="progress_pdf">
												<PdfIconSvg />
											</div>
											<div className="progres_file">
												<div className="progres_file_cont">
													<h4>{item.name}<span>{(item.size / 1024).toFixed(2)} KB</span></h4>
													<div className="upload_pdf_del" onClick={() => handleDeleteDoc(item.id)}><DeleteIconSvg /></div>
												</div>
												<div className="progress_line">
													<div className="progres_line_blue" style={{ width: '100%' }}></div>
													<h4>100%</h4>
												</div>
											</div>
										</div>
									)
								})}

								<div class="up_vitalm_btn d-flex">
									<button type="button" class="w-50" onClick={() => onModalToggle(false)}>Cancel</button>
									<button type="submit" class="w-50">Save Communication Log</button>
								</div>

							</form>
						</div>
					</div>
				</div>
			</div>
		</>

	)
}
export default AddCommunication