import React from "react";

const DeleteModal = ({onDeleteToggal, onDeleteSubmit}) => {

    return(
        <div class="modal fade delete_model_sec show">
            <div class="modal-dialog delete_model">
			<div class="modal-content ">
				<div class="modal-header">
					<button type="button" class="btn-close" onClick={()=>onDeleteToggal('')}></button>
				</div>
				<div class="delete_box">
					<p class="font-18 ">Are you sure you want to delete </p>
					<p class="font-14 hd-subtitle delete_model_text">Please note that this action cannot be undone.</p>

					<div class="cancel_delete">
						<button type="button" class="btn btn-light" onClick={()=>onDeleteToggal('')}>Cancel</button> &nbsp;
						<button type="button" class="btn btn-outline-danger bg-danger text-white" onClick={()=>onDeleteSubmit()}>Yes Delete</button>
					</div>
				</div>
			</div>
            </div>
		</div>
    )
}
export default DeleteModal