import React from 'react'

const PcspHeader = ({ currentStep, totalSteps, onClickCross }) => {
    const progressPercentage = (currentStep / totalSteps) * 100    

    return (
        <div className="sap-card-header progress-bar-sec">
            <div className="member_vitalinfo_stepone_title">
                <h5>DDD/PCSP</h5>
                <div className="steps-progress">
                    <div className="progress">
                        <div
                            className="progress-bar"
                            role="progressbar"
                            style={{ width: `${progressPercentage}%` }}
                            aria-valuenow={progressPercentage}
                            aria-valuemin="0"
                            aria-valuemax="100"
                        >
                            {Math.round(progressPercentage)}%
                        </div>
                    </div>
                    <h5 className="sap-card-title">STEP {currentStep} OF {totalSteps}</h5>
                </div>
            </div>
            <button type="button" className="btn-close" aria-label="Close" onClick={()=>onClickCross()}></button>
        </div>
    )
}

export default PcspHeader
