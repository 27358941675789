import React from 'react'
import homeEmpty from '../../../Images/homes.png'
import { Link, useNavigate } from 'react-router-dom'
export const NoHomeData = () => {
	const navigate = useNavigate()
	return (
		<>
			<div className="main-content-sec">
				<div className="empty-sec">
					<img src={homeEmpty} alt="" title="" />
					<div className="font-16 hd-title">There are no homes yet</div>
					<div className="hd-subtitle">Currently no homes have been added yet. To see the list of homes, you can begin by adding one using the button on top.</div>
					{/* <button type="button" className="btn btn-primary" onClick={() => navigate('/auth/add-home')}>
						<svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M10.5 4.16699V15.8337M4.66663 10.0003H16.3333" stroke="white" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
						</svg>
						New Homes
					</button> */}
				</div>
			</div>
		</>

	)
}
