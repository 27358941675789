import React from 'react'

const Home = () => {
    return (
        <div className="home-page">
            <section className="coming-soon-sec">
                <div className="coming-soon-inner container">
                    <div className="coming-soon-block">
                        <div className="font-60 hd-title">Coming Soon!</div>
                        <div className="hd-subtitle font-16">We have something <span className="font-30 hd-title">BIG</span> waiting for you.</div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Home
