import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import AdminStatusChange from './AdminStatusChange';
import { edit_member_medication } from '../../../redux/services/AuthMar';
import { monthDayYearFormat } from '../../../components/Common/Common';

const SideBar = () => {
    const dispatch = useDispatch();
    const { id } = useParams();

    // Selector to get the required state data
    const {
        EditMemberDetails,
        MasterMedicationList,
        MasterUnitsList,
        MasterDoesForm,
        MasterCodingSystem,
        MasterRoute,
        MasterTypeList
    } = useSelector(state => state?.AuthMar || {});
    
    const currentUser = useSelector(state => state?.AuthSettings?.settingInfo?.general);
    
    // Local state to manage medication data and modal visibility
    const [medicationData, setMedicationData] = useState({});
    const [adminStatusModal, setAdminStatusModal] = useState(false);
    const [approvelName, setApprovelNamel] = useState('');
    const [isApprove, setIsApprove] = useState(false);

    // Fetch medication data when the component mounts or id changes
    useEffect(() => {
        if (id) {
            dispatch(edit_member_medication(id));
        }
    }, [dispatch, id]);

    // Process EditMemberDetails and update local state accordingly
    useEffect(() => {
        if (!EditMemberDetails) return;

        // Check if the current user is the approver
        setIsApprove(EditMemberDetails?.approval_request_to === currentUser?.id);

        // Function to find names by ID in various master lists
        const findItemById = (list, id) => list?.find(item => item.id === id)?.name || '';

        // Prepare medication data for display
        const data = {
            medication_name: findItemById(MasterMedicationList, EditMemberDetails?.medication_name),
            dose_form: findItemById(MasterDoesForm, EditMemberDetails?.dose_form),
            code: EditMemberDetails?.medication_code || '',
            coding_system: findItemById(MasterCodingSystem, EditMemberDetails?.coding_system),
            strength: EditMemberDetails?.strength || '',
            strength_unit: findItemById(MasterUnitsList, EditMemberDetails?.strength_unit),
            type: findItemById(MasterTypeList, EditMemberDetails?.type),
            begin_date: monthDayYearFormat(EditMemberDetails?.adminis_start_date, true) || '',
            end_date: monthDayYearFormat(EditMemberDetails?.adminis_end_date, true) || '',
            route: findItemById(MasterRoute, EditMemberDetails?.route),
            frequency: EditMemberDetails?.frequency + ' x Daily' || '',
            give_amount_quantity: EditMemberDetails?.give_amount_quantity + ' Tablet' || '',
            measurement_unit: findItemById(MasterUnitsList, EditMemberDetails?.measurement_unit),
        };
        
        // Update the medication data state
        setMedicationData(data);
        
        // Set the approver name if available
        if (EditMemberDetails) {
            setApprovelNamel(`${EditMemberDetails?.user?.first_name} ${EditMemberDetails?.user?.last_name}`);
        }
    }, [EditMemberDetails, MasterMedicationList, MasterUnitsList, MasterDoesForm, MasterCodingSystem, MasterRoute, MasterTypeList, currentUser]);

    return (
        <>
            <div id="layoutSidenav_nav">
                <nav className="sb-sidenav accordion sb-sidenav-dark" id="sidenavAccordion">
                    <div className="sb-sidenav-menu">
                        <div className="mar_list_left_menu">
                            <ul>
                                {/* Render medication data dynamically */}
                                {Object.entries(medicationData).map(([key, value]) => {
                                    // Format key for display
                                    const name = key.replace(/_/g, " ").replace(/\b\w/g, char => char.toUpperCase());
                                    return (
                                        <li key={key}>
                                            {name === 'Give Amount Quantity' ? 'Given Amount/Quantity' : name}
                                            <div>{value}</div>
                                        </li>
                                    );
                                })}
                                {/* Approval status section */}
                                <li>
                                    Approval status
                                    <div className="d-flex align-items-center">
                                        <div>{approvelName}</div>
                                        <span className={`ms-1 ${EditMemberDetails?.approval_status === 'pending' ? 'span_pend' : 'span_approv'}`}>
                                            {EditMemberDetails?.approval_status}
                                        </span>
                                    </div>
                                </li>
                            </ul>
                            {/* Show approval controls if user is approver */}
                            {isApprove && (
                                <div className="approve_left">
                                    <div className="approve_inner">
                                        <div className="approve_head">Approval status</div>
                                        <div className={`${EditMemberDetails?.approval_status === 'pending' ? 'approve_pend' : 'approve_approv'}`}>
                                            {EditMemberDetails?.approval_status}
                                        </div>
                                    </div>
                                    {EditMemberDetails?.approval_status === 'pending' && (
                                        <button type="button" onClick={() => setAdminStatusModal(true)}>Change</button>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </nav>
            </div>
            {/* Render modal for changing admin status if triggered */}
            {adminStatusModal && <AdminStatusChange handleCommentToggle={setAdminStatusModal} />}
        </>
    );
};

export default SideBar;
