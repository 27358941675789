import React, { useEffect, useState } from 'react'
import { useForm, useFieldArray } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import PcspFooter from '../../PcspFooter'

const FinancialRisks = ({ onHandleBackStep, onSubmit, StateOfRisk }) => {
    const allTypeRisk = useSelector(state => state?.AuthMember?.allTypeRisk?.finalcial_risk?.dddpcspRisksMaster);
    const allDddPcsp = useSelector(state => state?.AuthMember?.allDddPcsp?.risks);

    // Initialize the form with useForm hook
    const { control, handleSubmit, register, getValues, setValue, formState: { errors } } = useForm({
        defaultValues: {
            healthMediRisks: [{ dddpcsp_risk_master_id: "", state_of_risk: "", risk_type: "FinancialRisks" }],
        }
    });

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'healthMediRisks'
    });

    useEffect(() => {
        let filterd = allDddPcsp && allDddPcsp.filter((item) => item.risk_type === 'FinancialRisks')
        const lengthDifference = filterd?.length - fields.length;
        if (lengthDifference > 0) {
            for (let i = 0; i < lengthDifference; i++) {
                append({});
            }
        }
        filterd && filterd.forEach((item, index) => {
            setValue(`healthMediRisks.${index}.dddpcsp_risk_master_id`, item.dddpcsp_risk_master_id || '');
            setValue(`healthMediRisks.${index}.state_of_risk`, item.state_of_risk || '');
            setValue(`healthMediRisks.${index}.risk_type`, item.risk_type || '');
        });

    }, [allDddPcsp])
    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)} className="row">
                <div className="member_vital_basic_title">
                    <h4>Financial risks</h4>
                </div>
                {fields.map((member, index) => (
                    <div key={member.id} className="row">
                        {index > 0 && (
                            <hr />
                        )}
                        <div className="col-md-6 mb-3">
                            <label for="" className="form-label">Risk*</label>
                            <select
                                className="form-select"
                                aria-label="Default select example"
                                {...register(`healthMediRisks.${index}.dddpcsp_risk_master_id`, { required: "Risk is required!" })}>
                                <option value="">Select</option>
                                {allTypeRisk && allTypeRisk.map((item, index) => {
                                    return (
                                        <option value={item.id}>{item.risk_name}</option>
                                    )
                                })}
                            </select>
                            {errors.healthMediRisks && errors.healthMediRisks[index]?.dddpcsp_risk_master_id && (
                                <span className="error">{errors.healthMediRisks[index].dddpcsp_risk_master_id.message}</span>
                            )}
                        </div>
                        <div className="col-md-6 mb-3">
                            <label for="" className="form-label">State of risk*</label>
                            <select
                                className="form-select"
                                aria-label="Default select example"
                                {...register(`healthMediRisks.${index}.state_of_risk`, { required: "state of risk is required!" })}>
                                <option value="">Select</option>
                                {StateOfRisk && StateOfRisk.map((item, index) => {
                                    return (
                                        <option value={item.value}>{item.lable}</option>
                                    )
                                })}
                            </select>
                            {errors.healthMediRisks && errors.healthMediRisks[index]?.state_of_risk && (
                                <span className="error">{errors.healthMediRisks[index].state_of_risk.message}</span>
                            )}
                        </div>
                        {index > 0 && (
                            <div className="col-12 mb-3">
                                <a
                                    href="#"
                                    className="stepone_remove"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        remove(index);
                                    }}
                                >
                                    Remove
                                </a>
                            </div>
                        )}
                    </div>
                ))}
                <div className="stepone_health_planbtn">
                    <button type="button" className="font-14" onClick={() => append({ risk_type: "FinancialRisks" })}><svg width="12" height="12" viewBox="0 0 12 12" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path d="M6.00016 1.33325V10.6666M1.3335 5.99992H10.6668" stroke="#667085" stroke-width="2"
                            stroke-linecap="round" stroke-linejoin="round"></path>
                    </svg>
                        Add another health plan</button>
                </div>
                <PcspFooter onHandleBackStep={onHandleBackStep} />
            </form>
        </>
    )
}
export default FinancialRisks