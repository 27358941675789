import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { CommunicationSvg } from '../../../Images/CommunicationSvg';

const UrlBar = ({homeName = ''}) => {
    const location = useLocation();
    const navigate = useNavigate();

    const handleClick = (url) => {
        navigate(url)
    }
    return (
        <>
            <ol className="breadcrumb">
                <li className="breadcrumb-item" onClick={() => handleClick('/auth/communication')}><CommunicationSvg /></li>
                <li className="breadcrumb-item active" onClick={() => handleClick('/auth/communication')} >Communication</li>
                {homeName !== '' && <li className="breadcrumb-item active">{homeName}</li>}
                {/* {location.pathname.includes('edit-mar') && <li className="breadcrumb-item active">Edit</li>}
                {location.pathname.includes('open-mar') && <li className="breadcrumb-item active">Open MAR</li>} */}
                
            </ol>
        </>
    )

}
export default UrlBar