import { createSlice } from "@reduxjs/toolkit";
import {
    Add_new_user, get_all_users, update_user, edit_user, Delete_user,
    Deactivate_all_users, Activate_user, ActiveUsers, export_excel_file_users
} from "../services/AuthUser";

const initialState = {
    isLoading: false,
    error: null,
    AllUsers: [],
    EditUserData: [],
    AllActiveUsers: []
}
const AuthUsers = createSlice({
    name: 'AuthUser',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        // ==========Create new user ============== //
        builder.addCase(Add_new_user.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(Add_new_user.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
        });
        builder.addCase(Add_new_user.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
        // ===========get all users============== //
        builder.addCase(get_all_users.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(get_all_users.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
            state.AllUsers = action?.payload?.data
        });
        builder.addCase(get_all_users.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
        // ==========get edit user data============== //
        builder.addCase(edit_user.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(edit_user.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
            state.EditUserData = action?.payload?.data
        });
        builder.addCase(edit_user.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
        // ==========deactivate User ============== //
        builder.addCase(Delete_user.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(Delete_user.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
        });
        builder.addCase(Delete_user.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
        // ==========update User ============== //
        builder.addCase(update_user.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(update_user.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
        });
        builder.addCase(update_user.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
        // ==========deactivate all User ============== //
        builder.addCase(Deactivate_all_users.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(Deactivate_all_users.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
        });
        builder.addCase(Deactivate_all_users.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
        // ==========activate User ============== //
        builder.addCase(Activate_user.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(Activate_user.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
        });
        builder.addCase(Activate_user.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });

        // ==========get all active Users ============== //
        builder.addCase(ActiveUsers.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(ActiveUsers.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
            state.AllActiveUsers = action?.payload?.data
        });
        builder.addCase(ActiveUsers.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;

        });
        // ==========export user excel file============== //
        builder.addCase(export_excel_file_users.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(export_excel_file_users.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
        });
        builder.addCase(export_excel_file_users.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
    }
})

export default AuthUsers?.reducer;