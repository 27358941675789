import React from 'react';
export const Pagination = ({ currentPage, totalPages, onPageChange }) => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
    }
    return (
        <div>

            <div className="paging">
                <div >
                    <button type="button" className="btn btn-light" onClick={() => onPageChange(currentPage - 1)} disabled={currentPage === 1 ? true : false}>Previous</button>
                </div>
                    <div  >
                        <div className="page-num"  >Page {currentPage} of {totalPages}</div>
                    </div>
                <div>
                    <button type="button" className="btn btn-light" onClick={() => onPageChange(currentPage + 1)} disabled={currentPage === totalPages||totalPages==0?true:false}>Next</button>
                </div>
            </div>
        </div>
    );
};







