import React from 'react'
import StepsProgressBar from '../StepsProgressBar'
import PlanningMeetingMembers from './PlanningMeetingMembers'
import MemberResponsible from './MemberResponsible'
import MeetingNotes from './MeetingNotes'

const MeetingInformation = ({ step, subStep, totalSubSteps, onHandleBackStep,onSubmit, isGoBack}) => {

    const renderSubStep = () => {
        switch (subStep) {
            case 0:
                return <PlanningMeetingMembers onHandleBackStep={onHandleBackStep} onSubmit={onSubmit} step={step} subStep={subStep} isGoBack={isGoBack}/>
            case 1:
                return <MemberResponsible onHandleBackStep={onHandleBackStep} onSubmit={onSubmit} step={step} subStep={subStep} isGoBack={isGoBack}/>
            case 2:
                return <MeetingNotes onHandleBackStep={onHandleBackStep} onSubmit={onSubmit} step={step} subStep={subStep} isGoBack={isGoBack}/> 
            default:
                return <PlanningMeetingMembers onHandleBackStep={onHandleBackStep} onSubmit={onSubmit} step={step} subStep={subStep} isGoBack={isGoBack}/>
        }
    }

    return (
        <div className="tab-pane fade show active">
            <StepsProgressBar
                currentSubStep={subStep + 1}
                totalSubSteps={totalSubSteps}
            />
            {renderSubStep()}
        </div>
    )
}

export default MeetingInformation
