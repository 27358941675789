import { configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import rootReducer from './features';
import { persistStore, persistReducer } from 'redux-persist';
const persistConfig = {
    key: 'root',
    storage,
    blacklist: ['AuthUsers'],
}
const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = configureStore({
    reducer: persistedReducer
});

const clearStateAndPersistedData =async () => {
    store.dispatch({ type: 'CLEAR_STATE_AND_PERSISTED_DATA' });
    await persistor.purge(); // Clear persisted data
    console.log('clear <<<<<<<<<<<<');
  };

const persistor = persistStore(store);
export { store, persistor,clearStateAndPersistedData };
