
export const AppointmentColors = {
    pending: { background: '#D77A3A', border: 'solid 1px #B54708' }, // lighter shade
    scheduled: { background: '#A3D89E', border: 'solid 1px #86CB3C' }, // lighter shade
    notScheduled: { background: '#F9A1A1', border: 'solid 1px #F97066' }, // lighter shade
    rescheduled: { background: '#FDDC77', border: 'solid 1px #FDB022' }, // lighter shade
    resultsPending: { background: '#A3B7E8', border: 'solid 1px #2342BF' }, // lighter shade
    completed: { background: '#A3E3B5', border: 'solid 1px #17B26A' }, // lighter shade
    missed: { background: '#E0E4E7', border: 'solid 1px #D0D5DD' }, // lighter shade
    declined: { background: '#A94B36', border: 'solid 1px #55160C' }, // lighter shade
    cancelled: { background: '#FEC9C3', border: 'solid 1px #FECDCA' }, // lighter shade
};
export const appointmentStatusOptions = [
    {lable:'Pending', value:'pending'},
    {lable:'Scheduled', value:'scheduled'},
    {lable:'Not Scheduled', value:'notScheduled'},
    {lable:'Rescheduled', value:'rescheduled'},
    {lable:'Results Pending', value:'resultsPending'},
    {lable:'Completed', value:'completed'},
    {lable:'Missed', value:'missed'},
    {lable:'Declined', value:'declined'},
    {lable:'Cancelled', value:'cancelled'}
]
// export const locationTypes = [
//     { lable: 'Hospital', value: 'hospital' },
//     { lable: 'Online', value: 'online' },
//     { lable: 'Arizona State Hospital', value: 'arizona_state_hospital' },
//     { lable: 'Missionary Home Centre', value: 'missionary_home_centre' },
//     { lable: 'Aga Khan Hospital', value: 'aga_khan_hospital' },
//     { lable: 'Mary Jane Hospice', value: 'mary_jane_hospice' },
//     { lable: 'Henry’s Clinic', value: 'henry’s_clinic' },
//     { lable: 'Wellness Centre', value: 'wellness_centre' },
//     { lable: 'The Elites Hospital', value: 'the_elites_hospital' },
// ]
export const FrequencyOption = [
    {lable:'Does not repeat',value:'does_not_repeat'},
    {lable:'Daily',value:'daily'},
    {lable:'Weekly',value:'weekly'},
    {lable:'Monthly',value:'monthly'},
]