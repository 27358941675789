import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import Footer from '../../Footer'

const PersonalCareSkills = ({ onHandleBackStep, onSubmit }) => {

  const allIndividualizedHealth = useSelector(state => state?.AuthMember?.allIndividualizedHealth?.personalCareSkills
  );
  // Initialize the form with useForm hook
  const { register, handleSubmit, setValue, formState: { errors } } = useForm();

  useEffect(() => {
    setValue('dressing', allIndividualizedHealth?.dressing);
    setValue('toileting', allIndividualizedHealth?.toileting);
    setValue('bathing', allIndividualizedHealth?.bathing);
    setValue('dental_care', allIndividualizedHealth?.dental_care);
    setValue('menses', allIndividualizedHealth?.menses);
    setValue('other', allIndividualizedHealth?.other);
    setValue('personal_care_routine', allIndividualizedHealth?.personal_care_routine);
    setValue('gender_assist_personal_care', allIndividualizedHealth?.gender_assist_personal_care);
    setValue('duration', allIndividualizedHealth?.duration);
    setValue('individual_have_ispp', allIndividualizedHealth?.individual_have_ispp);
    setValue('conditions', allIndividualizedHealth?.conditions);

  }, [allIndividualizedHealth])

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="row">
      <div className="col-md-6">
        <label for="" className="form-label">Dressing*</label>
        <div className="ddd_steptwo_para">
          <select
            className="form-select"
            aria-label="Default select example"
            {...register(`dressing`, { required: "Dressing is required!" })}>
            <option value="">Select</option>
            <option value="1">Yes</option>
            <option value="0">No</option>
          </select>
          {errors.dressing && (
            <span className="error">{errors.dressing.message}</span>
          )}
        </div>
      </div>
      <div className="col-md-6">
        <label for="" className="form-label">Toileting*</label>
        <div className="ddd_steptwo_para">
          <select
            className="form-select"
            aria-label="Default select example"
            {...register(`toileting`, { required: "Toileting is required!" })}>
            <option value="">Select</option>
            <option value="1">Yes</option>
            <option value="0">No</option>
          </select>
          {errors.toileting && (
            <span className="error">{errors.toileting.message}</span>
          )}
        </div>
      </div>
      <div className="col-md-6">
        <label for="" className="form-label">Bathing*</label>
        <div className="ddd_steptwo_para">
          <select
            className="form-select"
            aria-label="Default select example"
            {...register(`bathing`, { required: "Bathing is required!" })}>
            <option value="">Select</option>
            <option value="1">Yes</option>
            <option value="0">No</option>
          </select>
          {errors.bathing && (
            <span className="error">{errors.bathing.message}</span>
          )}
        </div>
      </div>
      <div className="col-md-6">
        <label for="" className="form-label">Dental care*</label>
        <div className="ddd_steptwo_para">
          <select
            className="form-select"
            aria-label="Default select example"
            {...register(`dental_care`, { required: "Dental care is required!" })}>
            <option value="">Select</option>
            <option value="1">Yes</option>
            <option value="0">No</option>
          </select>
          {errors.dental_care && (
            <span className="error">{errors.dental_care.message}</span>
          )}
        </div>
      </div>
      <div className="col-md-6">
        <label for="" className="form-label">Menses*</label>
        <div className="ddd_steptwo_para">
          <select
            className="form-select"
            aria-label="Default select example"
            {...register(`menses`, { required: "Menses is required!" })}>
            <option value="">Select</option>
            <option value="1">Yes</option>
            <option value="0">No</option>
          </select>
          {errors.menses && (
            <span className="error">{errors.menses.message}</span>
          )}
        </div>
      </div>
      <div className="col-md-6">
        <label for="" className="form-label">Other*</label>
        <div className="ddd_steptwo_para">
          <select
            className="form-select"
            aria-label="Default select example"
            {...register(`other`, { required: "Other is required!" })}>
            <option value="">Select</option>
            <option value="1">Yes</option>
            <option value="0">No</option>
          </select>
          {errors.other && (
            <span className="error">{errors.other.message}</span>
          )}
        </div>
      </div>
      <div className="col-12">
        <label for="" className="form-label">If applicable, specify other personal care routine*</label>
        <div className="ddd_steptwo_para">
          <input
            type="input"
            className="form-control"
            placeholder="Enter specify other personal"
            {...register(`personal_care_routine`, { required: "specify other personal is required!" })}
            maxLength={20}
          />
          {errors?.personal_care_routine && (
            <span className="error">{errors.personal_care_routine.message}</span>
          )}
        </div>
      </div>
      <div className="col-12">
        <label for="" className="form-label">If applicable, choice of gender to assist with personal care*</label>
        <div className="ddd_steptwo_para">
          <select
            className="form-select"
            aria-label="Default select example"
            {...register(`gender_assist_personal_care`, { required: "choice of gender is required!" })}>
            <option value="">Select</option>
            <option value="male">Male</option>
            <option value="female">Female</option>
          </select>
          {errors.gender_assist_personal_care && (
            <span className="error">{errors.gender_assist_personal_care.message}</span>
          )}
        </div>
      </div>
      <div className="col-12">
        <label for="" className="form-label">Does the individual have ISPP approved unsupervised time*</label>
        <div className="ddd_steptwo_para">
          <select
            className="form-select"
            aria-label="Default select example"
            {...register(`individual_have_ispp`, { required: "individual have ISPP is required!" })}>
            <option value="">Select</option>
            <option value="1">Yes</option>
            <option value="0">No</option>
          </select>
          {errors.individual_have_ispp && (
            <span className="error">{errors.individual_have_ispp.message}</span>
          )}
        </div>
      </div>
      <div className="col-12">
        <label for="" className="form-label">Duration (If yes)*</label>
        <div className="ddd_steptwo_para">
          <input
            type="input"
            className="form-control"
            placeholder="Enter Duration (If yes)"
            {...register(`duration`, { required: "Duration (If yes) is required!" })}
            maxLength={20}
          />
          {errors?.duration && (
            <span className="error">{errors.duration.message}</span>
          )}
        </div>
      </div>
      <div className="col-12">
        <label for="" className="form-label">Conditions (If yes) (If applicable)*</label>
        <div className="ddd_steptwo_para">
          <textarea className="form-control" placeholder="Enter Conditions" {...register("conditions", { required: "true" })} maxLength={150} />
          {errors.conditions && errors.conditions.type === "required" && <span className="error">This is required!</span>}
        </div>
      </div>
      <Footer onHandleBackStep={onHandleBackStep} />
    </form>
  )
}
export default PersonalCareSkills