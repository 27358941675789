import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { clearStateAndPersistedData } from '../../redux/store';
const Header = () => {
    // const checkToken = useSelector(state => state?.auth?.token);
    const checkToken = localStorage.getItem('token')
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [show, setShow] = useState(true);
    
    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            // Dispatch an action to authenticate the user with the token
            dispatch({ type: 'AUTHENTICATE_USER', payload: token });
        }
    }, []);

    useEffect(() => {
        setShow(!checkToken);
    }, [checkToken]);

    const Logout = () => {
        navigate('/login');
        setShow(true);
        window.location.reload();
        localStorage.clear();
        clearStateAndPersistedData();
    }
    return (
        <header>
            <nav className="navbar navbar-expand-lg bg-body-tertiary">
                <div className="container-fluid">
                    <Link className="navbar-brand" to="/"><img src="/assets\images\logo-dark.png" alt="LIFETRACKA" title="LIFETRACKA" /></Link>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <i className="fa-solid fa-bars"></i>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                            <li className="nav-item">
                                {
                                    checkToken ? <Link className="nav-link" to="/auth/dashboard">Dashboard</Link> :
                                        <Link className="nav-link" to="/setting/subscription">Subscription Plans</Link>
                                }
                            </li>
                            {
                                checkToken ?
                                    <li className="nav-item" onClick={() => Logout()}> <Link className="btn btn-primary">Logout</Link> </li>
                                    :
                                    <li className="nav-item"> <Link to="/login" className="btn btn-primary">Login</Link> </li>
                            }
                        </ul>
                    </div>
                </div>
            </nav>
        </header>
    )
}

export default Header;
