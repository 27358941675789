import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import PcspFooter from '../../PcspFooter'

const MemberResponsible = ({ onHandleBackStep, onSubmit, isGoBack }) => {
    const allDddPcsp = useSelector(state => state?.AuthMember?.allDddPcsp?.meetingInfo);
    const UploadedPdfData = useSelector(state => state?.AuthMember?.UploadedPdfData);
    // Initialize the form with useForm hook
    const { register, handleSubmit, setValue, formState: { errors } } = useForm();

    useEffect(() => {
        if (UploadedPdfData?.length > 0 && !isGoBack) {
            UploadedPdfData.forEach((item) => {
                switch (item.key) {
                    case 'Health Care Decision Maker (HCDM) (If applicable):':
                        setValue('hcdm_name', item?.value);
                        break
                    case 'Designated Representative (DR) (If applicable):':
                        setValue('dr_name', item?.value);
                        break
                    case "Power of Attorney (If applicable):":
                        setValue('power_attorney', item?.value);
                        break
                    case "Public Fiduciary (If applicable):":
                        setValue('public_fiduciary', item?.value);
                        break
                    case "Name of Social Security Payee (If applicable):":
                        setValue('ssp_name', item?.value);
                        break
                    case "Serious Mental Illness (SMI) Special Assistance Advocate (If applicable):":
                        setValue('smi', item?.value);
                        break

                }
            })
        } else {
            // Member/Responsible person
            setValue('hcdm_name', allDddPcsp?.memberdddpcspmeetingresponsibleperson?.hcdm_name);
            setValue('dr_name', allDddPcsp?.memberdddpcspmeetingresponsibleperson?.dr_name);
            setValue('power_attorney', allDddPcsp?.memberdddpcspmeetingresponsibleperson?.power_attorney);
            setValue('public_fiduciary', allDddPcsp?.memberdddpcspmeetingresponsibleperson?.public_fiduciary);
            setValue('ssp_name', allDddPcsp?.memberdddpcspmeetingresponsibleperson?.ssp_name);
            setValue('smi', allDddPcsp?.memberdddpcspmeetingresponsibleperson?.smi);
            setValue('other', allDddPcsp?.memberdddpcspmeetingresponsibleperson?.other);
        }
    }, [allDddPcsp, UploadedPdfData])
    return (
        <>
            <div className="member_vitalstep_one_form">
                <form onSubmit={handleSubmit(onSubmit)} className="row">
                    <div className="col-12">
                        <div className="member_vital_basic_title">
                            <h4>Member/Responsible person contact information</h4>
                        </div>
                        <div className="vital_stepone_para">
                            <p>If applicable or if information has changed</p>
                        </div>
                    </div>
                    <div className="col-md-6 mb-3">
                        <label htmlFor="hcdm" className="form-label">Health care decision maker (HCDM) (If applicable)*</label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Enter health care"
                            {...register('hcdm_name', { required: "Health care decision maker is required" })}
                        />
                        {errors.hcdm_name && (
                            <span className="error">{errors.hcdm_name.message}</span>
                        )}
                    </div>
                    <div className="col-md-6 mb-3">
                        <label htmlFor="dr" className="form-label">Designated representative (DR) (If applicable)*</label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Designated representative"
                            {...register('dr_name', { required: "Designated representative is required" })}
                        />
                        {errors.dr_name && (
                            <span className="error">{errors.dr_name.message}</span>
                        )}
                    </div>
                    <div className="col-md-6 mb-3">
                        <label htmlFor="powerOfAttorney" className="form-label">Power of attorney (If applicable)*</label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Power of attorney"
                            maxLength={50}
                            {...register('power_attorney', { required: "Power of attorney is required" })}
                        />
                        {errors.power_attorney && (
                            <span className="error">{errors.power_attorney.message}</span>
                        )}
                    </div>
                    <div className="col-md-6 mb-3">
                        <label htmlFor="publicFiduciary" className="form-label">Public fiduciary (If applicable)*</label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Public fiduciary"
                            maxLength={50}
                            {...register('public_fiduciary', { required: "Public fiduciary is required" })}
                        />
                        {errors.public_fiduciary && (
                            <span className="error">{errors.public_fiduciary.message}</span>
                        )}
                    </div>
                    <div className="col-12 mb-3">
                        <label htmlFor="socialSecurityPayee" className="form-label">Name of social security payee (If applicable)*</label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Name of social security payee"
                            {...register('ssp_name', { required: "Name of social security payee is required" })}
                        />
                        {errors.ssp_name && (
                            <span className="error">{errors.ssp_name.message}</span>
                        )}
                    </div>
                    <div className="col-12 mb-3">
                        <label htmlFor="smiAdvocate" className="form-label">Serious mental illness (SMI) Special assistance advocate (If applicable)*</label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="N/A"
                            maxLength={50}
                            {...register('smi', { required: "Serious mental illness is required" })}
                        />
                        {errors.smi && (
                            <span className="error">{errors.smi.message}</span>
                        )}
                    </div>
                    <div className="col-12 mb-3">
                        <label htmlFor="other" className="form-label">Other</label>
                        <textarea className="form-control" placeholder="Other" {...register("other")} maxLength={150} />
                        {/* {errors.other && (
                            <span className="error">{errors.other.message}</span>
                        )} */}
                    </div>
                    <PcspFooter onHandleBackStep={onHandleBackStep} />
                </form>
            </div>
        </>
    );
};

export default MemberResponsible;
