import React, { useEffect } from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import PcspFooter from '../../PcspFooter'
import { Get_selected_option_risks } from '../../../../../../redux/services/AuthMembers';

const RisksAssessment = ({ onHandleBackStep, onSubmit }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const allDddPcsp = useSelector(state => state?.AuthMember?.allDddPcsp?.risksAssemtents);
  const allSelectedOptionRisks = useSelector(state => state?.AuthMember?.allSelectedOptionRisks);
  // Initialize the form with useForm hook
  const { control, handleSubmit, register, getValues, setValue, formState: { errors } } = useForm({
    defaultValues: {
      riskaAssessments: [{ date_identified: "", describe_risk: "", factors_contributing_risk: "", risk_preventions: "", member_dddpcsp_risks_id: "" }],
    }
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'riskaAssessments'
  });
  useEffect(() => {
    dispatch(Get_selected_option_risks(id))
  }, [])

  useEffect(() => {
    const lengthDifference = allDddPcsp?.length - fields.length;
    if (lengthDifference > 0) {
        for (let i = 0; i < lengthDifference; i++) {
            append({});
        }
    }
    allDddPcsp.forEach((item, index) => {
        setValue(`riskaAssessments.${index}.date_identified`, item.date_identified || '');
        setValue(`riskaAssessments.${index}.describe_risk`, item.describe_risk || '');
        setValue(`riskaAssessments.${index}.factors_contributing_risk`, item.factors_contributing_risk || '');
        setValue(`riskaAssessments.${index}.member_dddpcsp_risks_id`, item.member_dddpcsp_risks_id || '');
        setValue(`riskaAssessments.${index}.risk_preventions`, item.risk_preventions || '');
    });
  }, [allDddPcsp])


  return (
    <div className="tab-pane fade  goal_tab show active" id="v-pills-ris" role="tabpanel" aria-labelledby="v-pills-ris-tab">

      <form onSubmit={handleSubmit(onSubmit)} className="row">
        <div className="col-12">
          <div className="alert alert-light sap-alert-heading">
            <div className="hd-subtitle font-16">This section is only applicable if a member’s rights are being
              restricted. The Risk Assessment will include information to identify what will be done differently to
              minimize or eliminate the risk. The Risk Assessment document should be easy to understand, simple,
              straightforward, visible and readily available to the staff working directly with the individual. It
              is designed to assist direct support staff in safeguarding the member from identified risks.</div>
          </div>
        </div>
        {fields.map((member, index) => (
          <div key={member.id} className="row">
            {index > 0 && (
                <hr/>
            )}
            <div className="col-12 mb-3">
              <label for="" className="form-label">What is the risk*</label>
              <select
                className="form-select"
                aria-label="Default select example"
                {...register(`riskaAssessments.${index}.member_dddpcsp_risks_id`, { required: "Risk is required!" })}>
                <option value="">Select</option>
                {allSelectedOptionRisks && allSelectedOptionRisks.map((item, index) => {
                  return (
                    <option value={item?.dddpcspRisksMaster?.id}>{item?.dddpcspRisksMaster?.risk_name}</option>
                  )
                })}
              </select>
              {errors.riskaAssessments && errors.riskaAssessments[index]?.member_dddpcsp_risks_id && (
                <span className="error">{errors.riskaAssessments[index].member_dddpcsp_risks_id.message}</span>
              )}
            </div>
            <div className="col-12 mb-3">
              <label for="" className="form-label">Date identified*</label>
              <input type="date" className="form-control" id="" placeholder="Date identified" {...register(`riskaAssessments.${index}.date_identified`, { required: "true", })} onKeyDown={(e) => e.preventDefault()} />
              {errors.riskaAssessments && errors.riskaAssessments[index]?.date_identified && (
                <span className="error">{errors.riskaAssessments[index].date_identified.message}</span>
              )}
            </div>
            <div className="col-12 mb-3">
              <label for="" className="form-label">Describe the risk. What does it look like for the member? Frequency?Location? Duration?*</label>
              <textarea className="form-control" placeholder="Describe the risk" {...register(`riskaAssessments.${index}.describe_risk`, { required: "true" })} maxLength={150} />
              {errors.riskaAssessments && errors.riskaAssessments[index].describe_risk.message && (<span className="error">This is required!</span>)}
            </div>
            <div className="col-12 mb-3">
              <label for="" className="form-label">List the factors contributing to the risk*</label>
              <textarea className="form-control" placeholder="List the factors contributing to the risk" {...register(`riskaAssessments.${index}.factors_contributing_risk`, { required: "true" })} maxLength={150} />
              {errors.riskaAssessments && errors.riskaAssessments[index].factors_contributing_risk.message && (<span className="error">This is required!</span>)}
            </div>
            <div className="col-12 mb-3">
              <label for="" className="form-label">What is currently working to prevent the risk (Interventions that are working and not working)*</label>
              <textarea className="form-control" placeholder="Enter currently working to prevent the risk" {...register(`riskaAssessments.${index}.risk_preventions`, { required: "true" })} maxLength={150} />
              {errors.riskaAssessments && errors.riskaAssessments[index].risk_preventions.message && (<span className="error">This is required!</span>)}
            </div>
            {index > 0 && (
              <div className="col-12 mb-3">
                <a
                  href="#"
                  className="stepone_remove"
                  onClick={(e) => {
                    e.preventDefault();
                    remove(index);
                  }}
                >
                  Remove
                </a>
              </div>
            )}
          </div>
        ))}
        <div className="stepone_health_planbtn">
          <button type="button" className="font-14" onClick={() => append({ id: Date.now() })}><svg width="12" height="12" viewBox="0 0 12 12" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M6.00016 1.33325V10.6666M1.3335 5.99992H10.6668" stroke="#667085" stroke-width="2"
              stroke-linecap="round" stroke-linejoin="round"></path>
          </svg>
            Add another health plan</button>
        </div>
        <PcspFooter onHandleBackStep={onHandleBackStep} />
      </form>

    </div>
  )
}

export default RisksAssessment