import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { PlusSvg, EditButtonSvg } from '../../../Images/MarSvg';

const UrlBar = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const handleClick = (url) => {
        navigate(url)
    }
    return (
        <>
            <ol className="breadcrumb">
                <li className="breadcrumb-item" onClick={() => handleClick('/auth/mar')}><PlusSvg /></li>
                <li className="breadcrumb-item active" onClick={() => handleClick('/auth/mar')} >Medication</li>
                {location.pathname.includes('edit-mar') && <li className="breadcrumb-item active">Edit</li>}
                {location.pathname.includes('open-mar') && <li className="breadcrumb-item active">Open MAR</li>}
                
            </ol>
        </>
    )

}
export default UrlBar