import React, { useEffect, useState } from 'react'
import StepsProgressBar from '../StepsProgressBar'
import HomeLife from './HomeLife'
import DailyLife from './DailyLife'

const IndividualSetting = ({ step, subStep, totalSubSteps, onHandleBackStep,onSubmit, isGoBack}) => {

  const renderSubStep = () => {
    switch (subStep) {
        case 0:
            return <HomeLife onHandleBackStep={onHandleBackStep} onSubmit={onSubmit} step={step} subStep={subStep} isGoBack={isGoBack}/>
        case 1:
            return <DailyLife onHandleBackStep={onHandleBackStep} onSubmit={onSubmit} step={step} subStep={subStep} isGoBack={isGoBack} />
       default:
            return <HomeLife onHandleBackStep={onHandleBackStep} onSubmit={onSubmit} step={step} subStep={subStep} isGoBack={isGoBack} />
    }
}

  return (
    <div className="tab-pane fade goal_tab show active"> 
     <StepsProgressBar
                currentSubStep={subStep + 1}
                totalSubSteps={totalSubSteps}
            />
            {renderSubStep()}
    </div>
  )
}

export default IndividualSetting
