import React, { useEffect, useState } from 'react'
import { useForm, useFieldArray } from 'react-hook-form';
import { useSelector } from 'react-redux';
import PcspFooter from '../../PcspFooter'

const MedicalSupports = ({ onHandleBackStep, onSubmit }) => {

  const allDddPcsp = useSelector(state => state?.AuthMember?.allDddPcsp?.preferenceStrenth);
  // Initialize the form with useForm hook
  const { control, handleSubmit, register, setValue, formState: { errors } } = useForm({
    defaultValues: {
      othersHealthInsurance: [{ insurance_type_txt: "", medicare_or_policy_num: "", medicare_part_a: "", medicare_part_b: "", medicare_part_c: "", medicare_part_d_plan_name: "", phone_num: "", name_of_insured: "" }],
    }
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'othersHealthInsurance'
  });

  useEffect(() => {
    setValue('is_medical_dental_behavoir_infochanged', allDddPcsp?.is_medical_dental_behavoir_infochanged);
    setValue('is_medicare_info_changed', allDddPcsp?.is_medicare_info_changed);
    const lengthDifference = allDddPcsp?.memberdddpcspprefstrengthsmedicalinfo?.length - fields.length;
    if (lengthDifference > 0) {
      for (let i = 0; i < lengthDifference; i++) {
        append({});
      }
    }
    allDddPcsp?.memberdddpcspprefstrengthsmedicalinfo.forEach((item, index) => {
      setValue(`othersHealthInsurance.${index}.insurance_type_txt`, item.insurance_type_txt || '');
      setValue(`othersHealthInsurance.${index}.medicare_or_policy_num`, item.medicare_or_policy_num || '');
      setValue(`othersHealthInsurance.${index}.medicare_part_a`, item.medicare_part_a || '');
      setValue(`othersHealthInsurance.${index}.medicare_part_b`, item.medicare_part_b || '');
      setValue(`othersHealthInsurance.${index}.medicare_part_c`, item.medicare_part_c || '');
      setValue(`othersHealthInsurance.${index}.medicare_part_d_plan_name`, item.medicare_part_d_plan_name || '');
      setValue(`othersHealthInsurance.${index}.name_of_insured`, item.name_of_insured || '');
      setValue(`othersHealthInsurance.${index}.phone_num`, item.phone_num || '');
    });

  }, [allDddPcsp])

  return (
    <>
      <div className="member_vitalstep_one_form">
        <form onSubmit={handleSubmit(onSubmit)} className="row">
          <div className="col-12">
            <div className="alert alert-light sap-alert-heading">
              <div className="hd-title font-24">Medical supports and information</div>
              <div className="hd-subtitle font-16">The following information may be filled out prior to the meeting,
                over the phone, or at the meeting, based on member or family preferences. At the planning meeting,
                you will be asked questions about what supports and services could assist you (or your family
                member). For the purpose of this document, medical supports include: health insurance, providers,
                medications, vision/hearing/speech, medical/adaptive equipment and/or supplies.</div>
            </div>
            <div className="member_vital_basic_title">
              <h4>Review medical supports and information for changes</h4>
            </div>
          </div>
          <div className="col-12 mb-3">
            <label for="" className="form-label">Has your Medicare or other health insurance information changed since
              the last meeting*</label>
            <select
              className="form-select"
              aria-label="Default select example"
              {...register(`is_medicare_info_changed`, { required: "This is required!" })}>
              <option value="">Select</option>
              <option value="0">No</option>
              <option value="1">Yes</option>
              <option value="2">N/A</option>
            </select>
            {errors.is_medicare_info_changed && errors.is_medicare_info_changed && (
              <span className="error">{errors.is_medicare_info_changed.message}</span>
            )}
          </div>
          <div className="member_vital_basic_title">
            <h4>Medicare or other health insurance</h4>
          </div>
          {fields.map((member, index) => (
            <div key={member.id} className="row">
              <div className="col-md-6 mb-3">
                <label for="" className="form-label">Medicare or other health insurance*</label>
                <input
                  type="input"
                  className="form-control"
                  placeholder="Enter Medicare or other health insurance"
                  {...register(`othersHealthInsurance.${index}.insurance_type_txt`, { required: "Medicare or other health insurance is required!" })}
                  maxLength={50}
                />
                {errors.othersHealthInsurance && errors.othersHealthInsurance[index]?.insurance_type_txt && (
                  <span className="error">{errors.othersHealthInsurance[index].insurance_type_txt.message}</span>
                )}
              </div>
              <div className="col-md-6 mb-3">
                <label for="" className="form-label">Medicare number or policy number*</label>
                <input
                  type="input"
                  className="form-control"
                  placeholder="Enter Medicare number or policy number"
                  {...register(`othersHealthInsurance.${index}.medicare_or_policy_num`, { required: "Medicare number or policy number is required!" })}
                  maxLength={20}
                  onKeyDown={(e) => {
                    // Allow only alphanumeric characters and standard keys like backspace, arrow keys, etc.
                    if (!/[a-zA-Z0-9]/.test(e.key) &&
                      !['Backspace', 'Tab', 'ArrowLeft', 'ArrowRight', 'Delete'].includes(e.key)) {
                      e.preventDefault();
                    }
                  }}
                />
                {errors.othersHealthInsurance && errors.othersHealthInsurance[index]?.medicare_or_policy_num && (
                  <span className="error">{errors.othersHealthInsurance[index].medicare_or_policy_num.message}</span>
                )}
              </div>

              <div className="col-md-6 col-lg-4 mb-3">
                <label for="" className="form-label">Medicare part A*</label>
                <select
                  className="form-select"
                  aria-label="Default select example"
                  {...register(`othersHealthInsurance.${index}.medicare_part_a`, { required: "Medicare part A is required!" })}>
                  <option value="">Select</option>
                  <option value="0">No</option>
                  <option value="1">Yes</option>
                  <option value="2">N/A</option>
                </select>
                {errors.othersHealthInsurance && errors.othersHealthInsurance[index]?.medicare_part_a && (
                  <span className="error">{errors.othersHealthInsurance[index].medicare_part_a.message}</span>
                )}
              </div>
              <div className="col-md-6 col-lg-4 mb-3">
                <label for="" className="form-label">Medicare part B *</label>
                <select
                  className="form-select"
                  aria-label="Default select example"
                  {...register(`othersHealthInsurance.${index}.medicare_part_b`, { required: "Medicare part B is required!" })}>
                  <option value="">Select</option>
                  <option value="0">No</option>
                  <option value="1">Yes</option>
                  <option value="2">N/A</option>
                </select>
                {errors.othersHealthInsurance && errors.othersHealthInsurance[index]?.medicare_part_b && (
                  <span className="error">{errors.othersHealthInsurance[index].medicare_part_b.message}</span>
                )}
              </div>
              <div className="col-md-6 col-lg-4 mb-3">
                <label for="" className="form-label">Medicare part C*</label>
                <select
                  className="form-select"
                  aria-label="Default select example"
                  {...register(`othersHealthInsurance.${index}.medicare_part_c`, { required: "Medicare part C is required!" })}>
                  <option value="">Select</option>
                  <option value="0">No</option>
                  <option value="1">Yes</option>
                  <option value="2">N/A</option>
                </select>
                {errors.othersHealthInsurance && errors.othersHealthInsurance[index]?.medicare_part_c && (
                  <span className="error">{errors.othersHealthInsurance[index].medicare_part_c.message}</span>
                )}
              </div>
              <div className="col-md-6 mb-3">
                <label for="" className="form-label">Medicare part D - Plan name*</label>
                <select
                  className="form-select"
                  aria-label="Default select example"
                  {...register(`othersHealthInsurance.${index}.medicare_part_d_plan_name`, { required: "Medicare part D - Plan name is required!" })}>
                  <option value="">Select</option>
                  <option value="0">No</option>
                  <option value="1">Yes</option>
                  <option value="2">N/A</option>
                </select>
                {errors.othersHealthInsurance && errors.othersHealthInsurance[index]?.medicare_part_d_plan_name && (
                  <span className="error">{errors.othersHealthInsurance[index].medicare_part_d_plan_name.message}</span>
                )}
              </div>
              <div className="col-md-6 mb-3">
                <label className="form-label">Phone number*</label>
                <input
                  type="input"
                  className="form-control"
                  placeholder="000 0000 000"
                  {...register(`othersHealthInsurance.${index}.phone_num`, {
                    required: "Phone number is required",
                    pattern: { value: /^\d{10,15}$/, message: "Phone number must be 10 to 15 digits" }
                  })}
                  maxLength={15}
                  onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault(); } }}
                />
                {errors.othersHealthInsurance && errors.othersHealthInsurance[index]?.phone_num && (
                  <span className="error">{errors.othersHealthInsurance[index].phone_num.message}</span>
                )}
              </div>
              <div className="col-12 mb-3">
                <label for="" className="form-label">Name of insured (If member is not primary holder of insurance)*</label>
                <input
                  type="input"
                  className="form-control"
                  placeholder="Enter Name of insured"
                  {...register(`othersHealthInsurance.${index}.name_of_insured`, { required: "Name of insured is required!" })}
                  maxLength={50}
                  onKeyDown={(e) => {
                    // Allow only alphanumeric characters and standard keys like backspace, arrow keys, etc.
                    if (!/[a-zA-Z0-9]/.test(e.key) &&
                      !['Backspace', 'Tab', 'ArrowLeft', 'ArrowRight', 'Delete'].includes(e.key)) {
                      e.preventDefault();
                    }
                  }}
                />
                {errors.othersHealthInsurance && errors.othersHealthInsurance[index]?.name_of_insured && (
                  <span className="error">{errors.othersHealthInsurance[index].name_of_insured.message}</span>
                )}
              </div>
              {index > 0 && (
                <div className="col-12 mb-3">
                  <a
                    href="#"
                    className="stepone_remove"
                    onClick={(e) => {
                      e.preventDefault();
                      remove(index);
                    }}
                  >
                    Remove
                  </a>
                </div>
              )}
              <hr />
            </div>
          ))}
          <div className="stepone_health_planbtn">
            <button type="button" className="font-14" onClick={() => append({ id: Date.now() })}><svg width="12" height="12" viewBox="0 0 12 12" fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path d="M6.00016 1.33325V10.6666M1.3335 5.99992H10.6668" stroke="#667085" stroke-width="2"
                stroke-linecap="round" stroke-linejoin="round"></path>
            </svg>
              Add another health plan</button>
          </div>
          <div className="col-12 mb-3">
            <label for="" className="form-label">Has your Medical, dental, or behavioral health provider information
              changed since the last meeting?*</label>
            <select
              className="form-select"
              aria-label="Default select example"
              {...register(`is_medical_dental_behavoir_infochanged`, { required: "This is required!" })}>
              <option value="">Select</option>
              <option value="0">No</option>
              <option value="1">Yes</option>
              <option value="2">N/A</option>
            </select>
            {errors.othersHealthInsurance && errors.is_medical_dental_behavoir_infochanged && (
              <span className="error">{errors.is_medical_dental_behavoir_infochanged.message}</span>
            )}
          </div>
          <PcspFooter onHandleBackStep={onHandleBackStep} />
        </form>
      </div>
    </>

  )
}
export default MedicalSupports