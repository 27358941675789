import React, { useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import ResultNotFound from "./ResultNotFound";
import { Pagination } from '../../Pagination';
import { getMonthDate, formatAMPM } from "../../../components/Common/Common"
import { destroy_communication } from '../../../redux/services/AuthCommunication'
import EditMessage from './EditMessage'
import DeleteMessage from './DeleteMessage'

const Messages = ({ handlePageChange, currentPage, setReloadData, setAddNewModal, selectedMember }) => {
    const dispatch = useDispatch()
    const totalNoPages = useSelector(state => state?.AuthCommunication?.MemberCommunication?.totalNoPages);
    const communications = useSelector(state => state?.AuthCommunication?.MemberCommunication?.communications);
    const userPermissions = useSelector((state) => state?.common?.userPermissions);


    const [editMessageModal, setEditMessageModal] = useState(false)
    const [deleteModal, setDeleteModal] = useState(false)
    const [MessageData, setMessageData] = useState({})

    const handleMessageClick = (data) => {
        if (userPermissions && userPermissions.includes('View')) {
            setMessageData(data)
            setEditMessageModal(true)
        }
    }

    const handleDeleteModal = () => {
        setEditMessageModal(!editMessageModal)
        setDeleteModal(!deleteModal)
    }

    const handleDeleteSumbit = async () => {
        let response = await dispatch(destroy_communication(MessageData.id))
        if (response.payload.status) {
            setReloadData(true)
            setDeleteModal(!deleteModal)
        }

    }
    return (
        <>
            <div class="tab-pane fade show active">
                <div class="row">
                    <div class="col-md-12 col-12">
                        <div class="table-sec">
                            <div class="table-responsive">
                                {communications && communications.length > 0 ?
                                    <table class="table table-hover">
                                        <thead>
                                            <tr>
                                                <th scope="col" colspan="2">Message
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {communications && communications.length > 0 && communications.map((item, index) => {
                                                let date = getMonthDate(item.created_date)
                                                let time = formatAMPM(item.created_date)
                                                return (
                                                    <tr>
                                                        <td>
                                                            <div class="table-profile" onClick={() => handleMessageClick(item)}>
                                                                <div
                                                                    class="profile-sec  appointment-member">
                                                                    <div class="profile-info">
                                                                        <p class="font-14 hd-title">
                                                                            {item.title}
                                                                            <span class="d-block hd-subtitle">{`${item.description.substring(0, 50)}...`}</span>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td class="hd-subtitle text-dark text-end date_capitalize">{date} <br /> {time}</td>
                                                    </tr>
                                                )
                                            })}
                                            <tr>
                                                <td colSpan="7">
                                                    <Pagination
                                                        currentPage={currentPage}
                                                        totalPages={totalNoPages}
                                                        onPageChange={handlePageChange}
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    :
                                    <ResultNotFound />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {editMessageModal && <EditMessage selectedMember={selectedMember} MessageData={MessageData} onModalToggle={setEditMessageModal} onDeleteModal={handleDeleteModal} setAddNewModal={setAddNewModal} />}
            {deleteModal && <DeleteMessage onDeleteModal={setDeleteModal} onDeleteSubmit={handleDeleteSumbit} />}
        </>
    )
}
export default Messages