import React from 'react'
import userImg from '../../../Images/user-empty.png'
import { Link } from 'react-router-dom';
const NoUsers = () => {
    return (
        <div className="main-content-sec">
        <div className="empty-sec">
            <img src={userImg} alt="" title="" />
            <div className="font-16 hd-title">There are no users yet</div>
            <div className="hd-subtitle">Currently no user that have been added. To see the list of users, you can begin by adding one using the button below.</div>
            {/* <Link  className="btn btn-primary" to='/auth/users-add'>
                <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.5 4.16699V15.8337M4.66663 10.0003H16.3333" stroke="white" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                New Users
            </Link> */}
        </div>
    </div>
    )
}

export default NoUsers;