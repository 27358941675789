import { createSlice } from "@reduxjs/toolkit";
import { login } from "../services/login";
import { signup } from "../services/register";
import { logout } from "../services/logout";
import { VerifyOtp } from "../services/VerifyOtp";
import { ForgotPassword } from "../services/ForgotPassword";
import { Send_Otp_Change_Number,Verify_Otp_Change_Number,Verify_text_otp_number } from "../services/VerifyOtp";

const initialState = {
    // token: JSON.parse(localStorage.getItem("token")) || null,
    isLoading: false,
    error: null,
    token: localStorage.getItem('token'),
    currentUserRole:'',
    changedNumber:{},
    Urltoken:{},
    errorMsg : '',
};

const authSlice = createSlice({
    name: "auth", 
    initialState,
    reducers: {
        setChangedNumber: (state, action) => {
            state.changedNumber = action?.payload;
            state.Urltoken=action?.payload
        },
        reSetToken: (state, action) => {
            state.token = null;
        },
    },
    extraReducers: (builder) => {
        // ========== Register ============== //
        builder.addCase(signup.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(signup.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
        });
        builder.addCase(signup.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
        
        // ========== Login ============== //
        builder.addCase(login.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(login.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
            state.errorMsg = action?.payload;
            // if (action?.payload.token) {
            //     state.token = action?.payload.token;
            //     localStorage.setItem("token", JSON.stringify(action?.payload.token));
            // }
        });
        builder.addCase(login.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
            state.errorMsg = action?.payload;
        });
        // ========== Logout ============== //
        builder.addCase(logout.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(logout.fulfilled, (state) => {
            state.isLoading = false;
            state.error = false;
            state.token = null;
            localStorage.removeItem("token");
        });
        builder.addCase(logout.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
         // ========== Verify Otp ============== //
         builder.addCase(VerifyOtp.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(VerifyOtp.fulfilled, (state, action) => {            
            state.isLoading = false;
            state.error = false;
            state.token =action?.payload?.data?.token;
            state.currentUserRole = action?.payload?.data?.roleName
        });
        builder.addCase(VerifyOtp.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
         // ========== Forgot Password ============== //
         builder.addCase(ForgotPassword.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(ForgotPassword.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
        });
        builder.addCase(ForgotPassword.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
         // ========== Send Otp to change number ============== //
         builder.addCase(Send_Otp_Change_Number.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(Send_Otp_Change_Number.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
        });
        builder.addCase(Send_Otp_Change_Number.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
           // ========== Verify Otp to change number ============== //
           builder.addCase(Verify_Otp_Change_Number.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(Verify_Otp_Change_Number.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
        });
        builder.addCase(Verify_Otp_Change_Number.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
           // ========== Verify text otp number without auth ============== //
           builder.addCase(Verify_text_otp_number.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(Verify_text_otp_number.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
        });
        builder.addCase(Verify_text_otp_number.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
    },
});
export const { setChangedNumber, reSetToken } = authSlice?.actions;
export default authSlice.reducer;