import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import Footer from '../../Footer'

const BehavioralConcerns = ({ onHandleBackStep, onSubmit }) => {

  const allIndividualizedHealth = useSelector(state => state?.AuthMember?.allIndividualizedHealth?.behavioralConcern);
  // Initialize the form with useForm hook
  const { register, handleSubmit, setValue, formState: { errors } } = useForm();

  useEffect(() => {
    setValue('aggression', allIndividualizedHealth?.aggression);
    setValue('self_injurious', allIndividualizedHealth?.self_injurious);
    setValue('property_desctruction', allIndividualizedHealth?.property_desctruction);
    setValue('eating_disorder', allIndividualizedHealth?.eating_disorder);
    setValue('awol_wanders', allIndividualizedHealth?.awol_wanders);
    setValue('self_stimulation', allIndividualizedHealth?.self_stimulation);
    setValue('sexual_acting_out', allIndividualizedHealth?.sexual_acting_out);
    setValue('other', allIndividualizedHealth?.other);
    setValue('is_behavioural_treatment_plan', allIndividualizedHealth?.is_behavioural_treatment_plan);
    setValue('behavioural_treatment_plan_ISPP', allIndividualizedHealth?.behavioural_treatment_plan_ISPP);

  }, [allIndividualizedHealth])

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="row">
      <div className="col-12 mb-3">
        <div className="alert alert-light sap-alert-heading">
          <div className="hd-title font-14">Kindly provide recommended intervention for each for of behavioural concern</div>
        </div>
      </div>
      <div className="col-12 mb-3">
        <label for="" className="form-label">Aggression*</label>
          <textarea className="form-control" placeholder="Enter Aggression" {...register("aggression", { required: "true" })} maxLength={150} />
          {errors.aggression && errors.aggression.type === "required" && (<span className="error">This is required!</span>)}
      </div>
      <div className="col-12 mb-3">
        <label for="" className="form-label">Self-injurious behaviour*</label>
          <textarea className="form-control" placeholder="Enter Self-injurious behaviour" {...register("self_injurious", { required: "true" })} maxLength={150} />
          {errors.self_injurious && errors.self_injurious.type === "required" && (<span className="error">This is required!</span>)}
      </div>
      <div className="col-12 mb-3">
        <label for="" className="form-label">Property destruction*</label>
          <textarea className="form-control" placeholder="Enter Property destruction" {...register("property_desctruction", { required: "true" })} maxLength={150} />
          {errors.property_desctruction && errors.property_desctruction.type === "required" && (<span className="error">This is required!</span>)}
      </div>
      <div className="col-12 mb-3">
        <label for="" className="form-label">Eating disorder*</label>
          <textarea className="form-control" placeholder="Enter Eating disorder" {...register("eating_disorder", { required: "true" })} maxLength={150} />
          {errors.eating_disorder && errors.eating_disorder.type === "required" && (<span className="error">This is required!</span>)}
      </div>
      <div className="col-12 mb-3">
        <label for="" className="form-label">AWOL/Wanders*</label>
          <textarea className="form-control" placeholder="Enter AWOL/Wanders" {...register("awol_wanders", { required: "true" })} maxLength={150} />
          {errors.awol_wanders && errors.awol_wanders.type === "required" && (<span className="error">This is required!</span>)}
      </div>
      <div className="col-12 mb-3">
        <label for="" className="form-label">Self stimulation*</label>
          <textarea className="form-control" placeholder="Enter Self stimulation" {...register("self_stimulation", { required: "true" })} maxLength={150} />
          {errors.self_stimulation && errors.self_stimulation.type === "required" && (<span className="error">This is required!</span>)}
      </div>
      <div className="col-12 mb-3">
        <label for="" className="form-label">Sexual acting out*</label>
          <textarea className="form-control" placeholder="Enter Sexual acting out" {...register("sexual_acting_out", { required: "true" })} maxLength={150} />
          {errors.sexual_acting_out && errors.sexual_acting_out.type === "required" && (<span className="error">This is required!</span>)}
      </div>
      <div className="col-12 mb-3">
        <label for="" className="form-label">Other*</label>
          <textarea className="form-control" placeholder="Enter Other" {...register("other", { required: "true" })} maxLength={150} />
          {errors.other && errors.other.type === "required" && (<span className="error">This is required!</span>)}
      </div>
      <div className="col-12 mb-3">
        <label for="" className="form-label">Is a behavioural treatment plan available for additional information*</label>
          <select
            className="form-select"
            aria-label="Default select example"
            {...register(`is_behavioural_treatment_plan`, { required: "behavioural treatment plan is required!" })}>
            <option value="">Select</option>
            <option value="1">Yes</option>
            <option value="0">No</option>
          </select>
          {errors.is_behavioural_treatment_plan && (
            <span className="error">{errors.is_behavioural_treatment_plan.message}</span>
          )}
      </div>
      <div className="col-12 mb-3">
        <label for="" className="form-label">Reference to the behavioural treatment plan or ISPP, if there are health care related issues contained*</label>
          <input
            type="input"
            className="form-control"
            placeholder="Enter Reference to the behavioural treatment"
            {...register(`behavioural_treatment_plan_ISPP`, { required: "Reference to the behavioural is required!" })}
            maxLength={20}
          />
          {errors?.behavioural_treatment_plan_ISPP && (
            <span className="error">{errors.behavioural_treatment_plan_ISPP.message}</span>
          )}
      </div>
      <Footer onHandleBackStep={onHandleBackStep} />
    </form>
  )
}
export default BehavioralConcerns
