import React from 'react';

export const CommunicationSvg = () => (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.25 7.75H5.25833M9 7.75H9.00833M12.75 7.75H12.7583M4.83333 14V15.9463C4.83333 16.3903 4.83333 16.6123 4.92436 16.7263C5.00352 16.8255 5.12356 16.8832 5.25045 16.8831C5.39636 16.8829 5.56973 16.7442 5.91646 16.4668L7.90434 14.8765C8.31043 14.5517 8.51347 14.3892 8.73957 14.2737C8.94017 14.1712 9.15369 14.0963 9.37435 14.051C9.62306 14 9.88308 14 10.4031 14H12.5C13.9001 14 14.6002 14 15.135 13.7275C15.6054 13.4878 15.9878 13.1054 16.2275 12.635C16.5 12.1002 16.5 11.4001 16.5 10V5.5C16.5 4.09987 16.5 3.3998 16.2275 2.86502C15.9878 2.39462 15.6054 2.01217 15.135 1.77248C14.6002 1.5 13.9001 1.5 12.5 1.5H5.5C4.09987 1.5 3.3998 1.5 2.86502 1.77248C2.39462 2.01217 2.01217 2.39462 1.77248 2.86502C1.5 3.3998 1.5 4.09987 1.5 5.5V10.6667C1.5 11.4416 1.5 11.8291 1.58519 12.147C1.81635 13.0098 2.49022 13.6836 3.35295 13.9148C3.67087 14 4.05836 14 4.83333 14ZM5.66667 7.75C5.66667 7.98012 5.48012 8.16667 5.25 8.16667C5.01988 8.16667 4.83333 7.98012 4.83333 7.75C4.83333 7.51988 5.01988 7.33333 5.25 7.33333C5.48012 7.33333 5.66667 7.51988 5.66667 7.75ZM9.41667 7.75C9.41667 7.98012 9.23012 8.16667 9 8.16667C8.76988 8.16667 8.58333 7.98012 8.58333 7.75C8.58333 7.51988 8.76988 7.33333 9 7.33333C9.23012 7.33333 9.41667 7.51988 9.41667 7.75ZM13.1667 7.75C13.1667 7.98012 12.9801 8.16667 12.75 8.16667C12.5199 8.16667 12.3333 7.98012 12.3333 7.75C12.3333 7.51988 12.5199 7.33333 12.75 7.33333C12.9801 7.33333 13.1667 7.51988 13.1667 7.75Z" stroke="#667085" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
);

export const CommunicationAddSvg = () => (
    <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d_9214_21593)">
            <rect x="2" y="1" width="48" height="48" rx="24" fill="#DCFAE6" shape-rendering="crispEdges" />
            <path d="M21.5 23.5H21.51M26 23.5H26.01M30.5 23.5H30.51M21 31V33.3355C21 33.8684 21 34.1348 21.1092 34.2716C21.2042 34.3906 21.3483 34.4599 21.5005 34.4597C21.6756 34.4595 21.8837 34.2931 22.2998 33.9602L24.6852 32.0518C25.1725 31.662 25.4162 31.4671 25.6875 31.3285C25.9282 31.2055 26.1844 31.1156 26.4492 31.0613C26.7477 31 27.0597 31 27.6837 31H30.2C31.8802 31 32.7202 31 33.362 30.673C33.9265 30.3854 34.3854 29.9265 34.673 29.362C35 28.7202 35 27.8802 35 26.2V20.8C35 19.1198 35 18.2798 34.673 17.638C34.3854 17.0735 33.9265 16.6146 33.362 16.327C32.7202 16 31.8802 16 30.2 16H21.8C20.1198 16 19.2798 16 18.638 16.327C18.0735 16.6146 17.6146 17.0735 17.327 17.638C17 18.2798 17 19.1198 17 20.8V27C17 27.93 17 28.395 17.1022 28.7765C17.3796 29.8117 18.1883 30.6204 19.2235 30.8978C19.605 31 20.07 31 21 31ZM22 23.5C22 23.7761 21.7761 24 21.5 24C21.2239 24 21 23.7761 21 23.5C21 23.2239 21.2239 23 21.5 23C21.7761 23 22 23.2239 22 23.5ZM26.5 23.5C26.5 23.7761 26.2761 24 26 24C25.7239 24 25.5 23.7761 25.5 23.5C25.5 23.2239 25.7239 23 26 23C26.2761 23 26.5 23.2239 26.5 23.5ZM31 23.5C31 23.7761 30.7761 24 30.5 24C30.2239 24 30 23.7761 30 23.5C30 23.2239 30.2239 23 30.5 23C30.7761 23 31 23.2239 31 23.5Z" stroke="#079455" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </g>
        <defs>
            <filter id="filter0_d_9214_21593" x="0" y="0" width="52" height="52" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                <feOffset dy="1" />
                <feGaussianBlur stdDeviation="1" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix type="matrix" values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.05 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_9214_21593" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_9214_21593" result="shape" />
            </filter>
        </defs>
    </svg>
)

export const New_ComSvg = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M17.5 15L16.6666 15.9117C16.2245 16.3951 15.6251 16.6667 15.0001 16.6667C14.3751 16.6667 13.7757 16.3951 13.3337 15.9117C12.891 15.4293 12.2916 15.1584 11.6668 15.1584C11.042 15.1584 10.4426 15.4293 9.99998 15.9117M2.5 16.6667H3.89545C4.3031 16.6667 4.50693 16.6667 4.69874 16.6206C4.8688 16.5798 5.03138 16.5125 5.1805 16.4211C5.34869 16.318 5.49282 16.1739 5.78107 15.8856L16.25 5.41669C16.9404 4.72634 16.9404 3.60705 16.25 2.91669C15.5597 2.22634 14.4404 2.22634 13.75 2.91669L3.28105 13.3856C2.9928 13.6739 2.84867 13.818 2.7456 13.9862C2.65422 14.1353 2.58688 14.2979 2.54605 14.468C2.5 14.6598 2.5 14.8636 2.5 15.2713V16.6667Z"
            stroke="#FCFCFD" stroke-width="1.66667" stroke-linecap="round"
            stroke-linejoin="round" />
    </svg>
)
export const FiltersSvg = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path d="M5 10H15M2.5 5H17.5M7.5 15H12.5" stroke="#344054"
            stroke-width="1.66667" stroke-linecap="round"
            stroke-linejoin="round">
        </path>
    </svg>
)

export const SearchSvg = () => (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M16.5 16.5L13.5834 13.5833M15.6667 8.58333C15.6667 12.4954 12.4954 15.6667 8.58333 15.6667C4.67132 15.6667 1.5 12.4954 1.5 8.58333C1.5 4.67132 4.67132 1.5 8.58333 1.5C12.4954 1.5 15.6667 4.67132 15.6667 8.58333Z"
            stroke="#667085" stroke-width="1.66667" stroke-linecap="round"
            stroke-linejoin="round"></path>
    </svg>
)
export const UploadIconSvg = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M11.6668 1.89111V5.33323C11.6668 5.79994 11.6668 6.0333 11.7577 6.21156C11.8376 6.36836 11.965 6.49584 12.1218 6.57574C12.3001 6.66656 12.5335 6.66656 13.0002 6.66656H16.4423M13.3335 10.8332H6.66683M13.3335 14.1665H6.66683M8.3335 7.49984H6.66683M11.6668 1.6665H7.3335C5.93336 1.6665 5.2333 1.6665 4.69852 1.93899C4.22811 2.17867 3.84566 2.56112 3.60598 3.03153C3.3335 3.56631 3.3335 4.26637 3.3335 5.6665V14.3332C3.3335 15.7333 3.3335 16.4334 3.60598 16.9681C3.84566 17.4386 4.22811 17.821 4.69852 18.0607C5.2333 18.3332 5.93336 18.3332 7.3335 18.3332H12.6668C14.067 18.3332 14.767 18.3332 15.3018 18.0607C15.7722 17.821 16.1547 17.4386 16.3943 16.9681C16.6668 16.4334 16.6668 15.7333 16.6668 14.3332V6.6665L11.6668 1.6665Z"
            stroke="#344054" stroke-width="1.66667" stroke-linecap="round"
            stroke-linejoin="round"></path>
    </svg>
)
export const PdfIconSvg = () => (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M7.75 4C7.75 2.20508 9.20508 0.75 11 0.75H27C27.1212 0.75 27.2375 0.798159 27.3232 0.883885L38.1161 11.6768C38.2018 11.7625 38.25 11.8788 38.25 12V36C38.25 37.7949 36.7949 39.25 35 39.25H11C9.20507 39.25 7.75 37.7949 7.75 36V4Z"
            stroke="#D0D5DD" stroke-width="1.5"></path>
        <path d="M27 0.5V8C27 10.2091 28.7909 12 31 12H38.5" stroke="#D0D5DD"
            stroke-width="1.5"></path>
        <path
            d="M1 20C1 18.8954 1.89543 18 3 18H25C26.1046 18 27 18.8954 27 20V32C27 33.1046 26.1046 34 25 34H3C1.89543 34 1 33.1046 1 32V20Z"
            fill="#D92D20"></path>
        <path
            d="M4.8323 30V22.7273H7.70162C8.25323 22.7273 8.72316 22.8326 9.11142 23.0433C9.49967 23.2517 9.7956 23.5417 9.9992 23.9134C10.2052 24.2827 10.3082 24.7088 10.3082 25.1918C10.3082 25.6747 10.204 26.1009 9.99565 26.4702C9.78732 26.8395 9.48547 27.1271 9.09011 27.3331C8.69712 27.5391 8.22127 27.642 7.66255 27.642H5.83372V26.4098H7.41397C7.7099 26.4098 7.95375 26.3589 8.14551 26.2571C8.33964 26.1529 8.48405 26.0097 8.57875 25.8274C8.67581 25.6428 8.72434 25.4309 8.72434 25.1918C8.72434 24.9503 8.67581 24.7396 8.57875 24.5597C8.48405 24.3774 8.33964 24.2365 8.14551 24.1371C7.95138 24.0353 7.70517 23.9844 7.40687 23.9844H6.36994V30H4.8323ZM13.885 30H11.3069V22.7273H13.9063C14.6379 22.7273 15.2676 22.8729 15.7955 23.1641C16.3235 23.4529 16.7295 23.8684 17.0136 24.4105C17.3 24.9527 17.4433 25.6013 17.4433 26.3565C17.4433 27.1141 17.3 27.7652 17.0136 28.3097C16.7295 28.8542 16.3211 29.272 15.7884 29.5632C15.2581 29.8544 14.6237 30 13.885 30ZM12.8445 28.6825H13.8211C14.2757 28.6825 14.658 28.602 14.9681 28.4411C15.2806 28.2777 15.515 28.0256 15.6713 27.6847C15.8299 27.3414 15.9092 26.8987 15.9092 26.3565C15.9092 25.8191 15.8299 25.38 15.6713 25.0391C15.515 24.6982 15.2818 24.4472 14.9717 24.2862C14.6615 24.1252 14.2792 24.0447 13.8247 24.0447H12.8445V28.6825ZM18.5823 30V22.7273H23.3976V23.995H20.1199V25.728H23.078V26.9957H20.1199V30H18.5823Z"
            fill="white"></path>
    </svg>
)
export const Mp4IconSvg = () => (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M7.75 4C7.75 2.20508 9.20508 0.75 11 0.75H27C27.1212 0.75 27.2375 0.798159 27.3232 0.883885L38.1161 11.6768C38.2018 11.7625 38.25 11.8788 38.25 12V36C38.25 37.7949 36.7949 39.25 35 39.25H11C9.20507 39.25 7.75 37.7949 7.75 36V4Z"
            stroke="#D0D5DD" stroke-width="1.5" />
        <path d="M27 0.5V8C27 10.2091 28.7909 12 31 12H38.5" stroke="#D0D5DD"
            stroke-width="1.5" />
        <path
            d="M1 20C1 18.8954 1.89543 18 3 18H28C29.1046 18 30 18.8954 30 20V32C30 33.1046 29.1046 34 28 34H3C1.89543 34 1 33.1046 1 32V20Z"
            fill="#155EEF" />
        <path
            d="M4.93093 22.7273H6.82724L8.83008 27.6136H8.91531L10.9181 22.7273H12.8145V30H11.323V25.2663H11.2626L9.3805 29.9645H8.36488L6.48278 25.2486H6.42241V30H4.93093V22.7273ZM14.0813 30V22.7273H16.9506C17.5022 22.7273 17.9722 22.8326 18.3604 23.0433C18.7487 23.2517 19.0446 23.5417 19.2482 23.9134C19.4542 24.2827 19.5572 24.7088 19.5572 25.1918C19.5572 25.6747 19.453 26.1009 19.2447 26.4702C19.0363 26.8395 18.7345 27.1271 18.3391 27.3331C17.9461 27.5391 17.4703 27.642 16.9116 27.642H15.0827V26.4098H16.663C16.9589 26.4098 17.2028 26.3589 17.3945 26.2571C17.5887 26.1529 17.7331 26.0097 17.8278 25.8274C17.9248 25.6428 17.9734 25.4309 17.9734 25.1918C17.9734 24.9503 17.9248 24.7396 17.8278 24.5597C17.7331 24.3774 17.5887 24.2365 17.3945 24.1371C17.2004 24.0353 16.9542 23.9844 16.6559 23.9844H15.619V30H14.0813ZM20.4032 28.7216V27.5107L23.4395 22.7273H24.4835V24.4034H23.8656L21.9515 27.4325V27.4893H26.2662V28.7216H20.4032ZM23.894 30V28.3523L23.9224 27.8161V22.7273H25.3642V30H23.894Z"
            fill="white" />
    </svg>
)
export const FigIconSvg = () => (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M7.75 4C7.75 2.20508 9.20508 0.75 11 0.75H27C27.1212 0.75 27.2375 0.798159 27.3232 0.883885L38.1161 11.6768C38.2018 11.7625 38.25 11.8788 38.25 12V36C38.25 37.7949 36.7949 39.25 35 39.25H11C9.20507 39.25 7.75 37.7949 7.75 36V4Z"
            stroke="#D0D5DD" stroke-width="1.5" />
        <path d="M27 0.5V8C27 10.2091 28.7909 12 31 12H38.5" stroke="#D0D5DD"
            stroke-width="1.5" />
        <path
            d="M1 20C1 18.8954 1.89543 18 3 18H22C23.1046 18 24 18.8954 24 20V32C24 33.1046 23.1046 34 22 34H3C1.89543 34 1 33.1046 1 32V20Z"
            fill="#2342BF" />
        <path
            d="M5.00222 30V22.7273H9.81756V23.995H6.53986V25.728H9.49796V26.9957H6.53986V30H5.00222ZM12.3895 22.7273V30H10.8518V22.7273H12.3895ZM18.477 25.0781C18.4273 24.9053 18.3575 24.7526 18.2675 24.62C18.1775 24.4851 18.0674 24.3714 17.9372 24.2791C17.8094 24.1844 17.6626 24.1122 17.4969 24.0625C17.3335 24.0128 17.1524 23.9879 16.9536 23.9879C16.5819 23.9879 16.2552 24.0803 15.9735 24.2649C15.6941 24.4496 15.4763 24.7183 15.32 25.071C15.1638 25.4214 15.0857 25.8499 15.0857 26.3565C15.0857 26.8632 15.1626 27.294 15.3165 27.6491C15.4704 28.0043 15.6882 28.2753 15.9699 28.4624C16.2516 28.647 16.5843 28.7393 16.9678 28.7393C17.3158 28.7393 17.6129 28.6778 17.8591 28.5547C18.1077 28.4292 18.2971 28.2528 18.4273 28.0256C18.5599 27.7983 18.6262 27.5296 18.6262 27.2195L18.9387 27.2656H17.0637V26.108H20.107V27.0241C20.107 27.6634 19.972 28.2126 19.7021 28.6719C19.4323 29.1288 19.0606 29.4815 18.5871 29.7301C18.1136 29.9763 17.5715 30.0994 16.9607 30.0994C16.2789 30.0994 15.6799 29.9491 15.1638 29.6484C14.6477 29.3454 14.2452 28.9157 13.9564 28.3594C13.67 27.8007 13.5267 27.1378 13.5267 26.3707C13.5267 25.7812 13.6119 25.2557 13.7824 24.794C13.9552 24.33 14.1967 23.937 14.5068 23.6151C14.817 23.2931 15.178 23.0481 15.5899 22.88C16.0019 22.7119 16.4481 22.6278 16.9287 22.6278C17.3406 22.6278 17.7242 22.6882 18.0793 22.8089C18.4344 22.9273 18.7493 23.0954 19.0239 23.3132C19.3009 23.531 19.527 23.7902 19.7021 24.0909C19.8773 24.3892 19.9898 24.7183 20.0395 25.0781H18.477Z"
            fill="white" />
    </svg>
)
export const DeleteIconSvg = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M13.3333 4.99984V4.33317C13.3333 3.39975 13.3333 2.93304 13.1517 2.57652C12.9919 2.26292 12.7369 2.00795 12.4233 1.84816C12.0668 1.6665 11.6001 1.6665 10.6667 1.6665H9.33333C8.39991 1.6665 7.9332 1.6665 7.57668 1.84816C7.26308 2.00795 7.00811 2.26292 6.84832 2.57652C6.66667 2.93304 6.66667 3.39975 6.66667 4.33317V4.99984M8.33333 9.58317V13.7498M11.6667 9.58317V13.7498M2.5 4.99984H17.5M15.8333 4.99984V14.3332C15.8333 15.7333 15.8333 16.4334 15.5608 16.9681C15.3212 17.4386 14.9387 17.821 14.4683 18.0607C13.9335 18.3332 13.2335 18.3332 11.8333 18.3332H8.16667C6.76654 18.3332 6.06647 18.3332 5.53169 18.0607C5.06129 17.821 4.67883 17.4386 4.43915 16.9681C4.16667 16.4334 4.16667 15.7333 4.16667 14.3332V4.99984"
            stroke="#475467" stroke-width="1.66667" stroke-linecap="round"
            stroke-linejoin="round" />
    </svg>
)
export const DownloadIconSvg = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6.6665 9.99984L9.99984 13.3332M9.99984 13.3332L13.3332 9.99984M9.99984 13.3332V6.6665M18.3332 9.99984C18.3332 14.6022 14.6022 18.3332 9.99984 18.3332C5.39746 18.3332 1.6665 14.6022 1.6665 9.99984C1.6665 5.39746 5.39746 1.6665 9.99984 1.6665C14.6022 1.6665 18.3332 5.39746 18.3332 9.99984Z" stroke="#344054" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

)