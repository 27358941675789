import React, { useEffect, useState } from 'react'
import StepsProgressBar from '../StepsProgressBar'
import Food from './Food'
import Beverages from './Beverages'

const Diet = ({ subStep, totalSubSteps, onHandleBackStep, onSubmit }) => {

  const renderSubStep = () => {
    switch (subStep) {
      case 0:
        return <Food onHandleBackStep={onHandleBackStep} onSubmit={onSubmit} />
      case 1:
        return <Beverages onHandleBackStep={onHandleBackStep} onSubmit={onSubmit} />
      default:
        return <Food onHandleBackStep={onHandleBackStep} onSubmit={onSubmit} />
    }
  }

  return (
    <>
      <div className="tab-pane fade strenght_stepthree_tab show active">
        <StepsProgressBar
          currentSubStep={subStep + 1}
          totalSubSteps={totalSubSteps}
        />
        {renderSubStep()}
      </div>
    </>
  )
}

export default Diet