import React, { useEffect, useRef, useState } from 'react'
import AddHealthInfo from './AddHealthInfo';
import { useNavigate, useParams } from 'react-router-dom';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { Activate_homes } from '../../../redux/services/AuthHomes';
import { useDispatch, useSelector } from 'react-redux';
import { get_languages, getState, getCountry } from '../../../redux/services/common';
import { Add_Member, get_races, get_diagnosis, edit_member, update_member } from '../../../redux/services/AuthMembers';
import { AfterAddMember } from './AfterAddMember';
import UploadAndManually from './UploadAndManually'
import defaultImg from "../../../Images/defaultImg.png";
const AddMember = () => {
  const EditId = useParams();
  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = useState('generalInfo');
  const [memberId, setMemberId] = useState('');
  const [showPopUp, setShowPopUp] = useState({
    modal: false,
    message: '',
    id: ''
  })
  const [uploadAndManuallyModal, setUploadAndManuallyModal] = useState(false)
  //upload image 
  const [imageUrl, setImageUrl] = useState('')
  const inputFile = useRef(null);
  const [file, setFile] = useState(null);
  const onButtonClick = () => {
    inputFile.current.click();
  };
  const handleFileUpload = async (e) => {
    const { files } = e.target;
    if (files && files.length) {
      const selectedFile = files[0];
      setFile(selectedFile);
      if (imageUrl) {
        URL.revokeObjectURL(imageUrl);
      }
      const newImageUrl = URL.createObjectURL(selectedFile);
      setImageUrl(newImageUrl);
      setValue('profile_pic', selectedFile);
    }
  }

  const formMethods = useForm({
    mode: "onChange",
    defaultValues: {
      additional_contacts: [{ a_firstName: "", a_lastName: "", a_homePhone: "", a_cellPhone: "", a_workPhone: "", a_email: "", a_streetAddress: "", a_city: "", a_state: "", a_zipCode: "" }],
      healthCare_altcs: [{ ha_planProvider: "", ha_IdNumber: "", ha_phoneNo: "", ha_website: "", ha_authorizationProcess: "" }]
    }
  });
  const { register, handleSubmit, getValues, setValue, watch, trigger, control, clearErrors, formState: { errors }, reset, } = formMethods;
  //add more additional contacts
  const { fields, append, remove } = useFieldArray({
    control,
    name: "additional_contacts"
  });
  const navigate = useNavigate();
  //get all active homes;
  const homeslist = useSelector(state => state?.AuthHomes?.ActivateHomes);
  //language list
  const LanguageList = useSelector(state => state?.common?.languageData);
  //get organization coutry id
  const CountryId = useSelector(state => state?.AuthSettings?.settingInfo?.organization?.country_id);
  //get all states
  const stateData = useSelector(state => state?.common?.StateData);
  //get all countrys for Citizenship
  const CountryData = useSelector(state => state?.common?.CountryData);
  //get race data
  const raceList = useSelector(state => state?.AuthMember?.raceList);
  //get diagnosis
  const diagnosisList = useSelector(state => state?.AuthMember?.diagnosisList);
  //common apis call
  useEffect(() => {
    dispatch(Activate_homes());
    dispatch(get_languages());
    dispatch(getCountry());
    dispatch(get_races());
    dispatch(get_diagnosis());
    dispatch(getState(CountryId));
  }, [])
  //change tab
  const changeTab = async (name) => {
    let valid = false;
    if (activeTab === 'generalInfo') {
      valid = await trigger(['first_name', 'last_name', 'ahss_id', 'memberConditionInfo', 'admissionDate', 'groupHome', 'streetAddress', 'city', 'state', 'zipCode', 'phoneNo', 'cellNo', 'primaryDiagnosis', 'secondaryDiagnosis', 'gendar', 'height', 'weight', 'eyeColor', 'hairColor', 'race', 'birthPlace', 'citizenship', 'primaryLanguage', 'religiousPreference'])

      if (valid) {
        setActiveTab(name)
      }
    } else if (activeTab === 'contacts') {
      valid = await trigger(['c_firstName', 'c_lastName', 'c_homePhone', 'c_cellPhone', 'c_workPhone', 'c_email', 'c_streetAddress', 'c_city', 'c_state', 'c_zipCode',])
      if (valid) {
        setActiveTab(name)
      }
    }
    else if (activeTab === 'AdditionalContacts') {
      const additionalContactFields = fields.map((_, index) => [
        `additional_contacts.${index}.a_firstName`,
        `additional_contacts.${index}.a_lastName`,
        `additional_contacts.${index}.a_homePhone`,
        `additional_contacts.${index}.a_cellPhone`,
        `additional_contacts.${index}.a_workPhone`,
        `additional_contacts.${index}.a_email`,
        `additional_contacts.${index}.a_streetAddress`,
        `additional_contacts.${index}.a_city`,
        `additional_contacts.${index}.a_state`,
        `additional_contacts.${index}.a_zipCode`,
      ]).flat();
      valid = await trigger(additionalContactFields)
      if (valid) {
        setActiveTab(name)
      }
    } else {
      setActiveTab(name)
    }
  }
  //close form
  const onClose = () => {
    navigate("/auth/members");
  };
  const onSubmit = async (data, step) => {
    try {     
      let payload =  data === null ? getValues() : data;      
      let formData = new FormData();
          formData.append("additional_contacts", JSON.stringify(payload?.additional_contacts));
          formData.append("healthCare_altcs", JSON.stringify(payload?.healthCare_altcs));
          formData.append('first_name', payload?.first_name || '');
          formData.append('last_name', payload?.last_name || '');
          formData.append('memberConditionInfo', payload?.memberConditionInfo || '');
          formData.append('admissionDate', payload?.admissionDate || '');
          formData.append('groupHome', payload?.groupHome || '');
          formData.append('streetAddress', payload?.streetAddress || '');
          formData.append('city', payload?.city || '');
          formData.append('state', payload?.state || '');
          formData.append('zipCode', payload?.zipCode || '');
          formData.append('phoneNo', payload?.phoneNo || '');
          formData.append('cellNo', payload?.cellNo || '');
          formData.append('primaryDiagnosis', payload?.primaryDiagnosis || '');
          formData.append('ahss_id', payload?.ahss_id || '');
          formData.append('secondaryDiagnosis', payload?.secondaryDiagnosis || '');
          formData.append('gendar', payload?.gendar || '');
          formData.append('height', payload?.height || '');
          formData.append('weight', payload?.weight || '');
          formData.append('eyeColor', payload?.eyeColor || '');
          formData.append('hairColor', payload?.hairColor || '');
          formData.append('race', payload?.race || '');
          formData.append('birthPlace', payload?.birthPlace || '');
          formData.append('citizenship', payload?.citizenship || '');
          formData.append('primaryLanguage', payload?.primaryLanguage || '');
          formData.append('religiousPreference', payload?.religiousPreference || '');
          formData.append('profile_pic', payload?.profile_pic || '');
          formData.append('c_firstName', payload?.c_firstName || '');
          formData.append('c_lastName', payload?.c_lastName || '');
          formData.append('c_homePhone', payload?.c_homePhone || '');
          formData.append('c_cellPhone', payload?.c_cellPhone || '');
          formData.append('c_workPhone', payload?.c_workPhone || '');
          formData.append('c_email', payload?.c_email || '');
          formData.append('c_streetAddress', payload?.c_streetAddress || '');
          formData.append('c_city', payload?.c_city || '');
          formData.append('c_state', payload?.c_state || '');
          formData.append('c_zipCode', payload?.c_zipCode || '');
          formData.append('p_firstName', payload?.p_firstName || '');
          formData.append('p_lastName', payload?.p_lastName || '');
          formData.append('p_phoneNo', payload?.p_phoneNo || '');
          formData.append('p_streetAddress', payload?.p_streetAddress || '');
          formData.append('p_city', payload?.p_city || '');
          formData.append('p_state', payload?.p_state || '');
          formData.append('p_zipCode', payload?.p_zipCode || '');
          formData.append('p_email', payload?.p_email || '');
          formData.append('b_firstName', payload?.b_firstName || '');
          formData.append('b_lastName', payload?.b_lastName || '');
          formData.append('b_phoneNo', payload?.b_phoneNo || '');
          formData.append('b_streetAddress', payload?.b_streetAddress || '');
          formData.append('b_city', payload?.b_city || '');
          formData.append('b_state', payload?.b_state || '');
          formData.append('b_zipCode', payload?.b_zipCode || '');
          formData.append('m_madicareSection', payload?.m_madicareSection || '');
          formData.append('m_IdNumber', payload?.m_IdNumber || '');
          formData.append('m_phoneNumber', payload?.m_phoneNumber || '');
          formData.append('m_planprovider', payload?.m_planprovider || '');
          formData.append('m_policyHolder', payload?.m_policyHolder || '');
          formData.append('m_groupNumber', payload?.m_groupNumber || '');
          formData.append('m_planPhoneNumber', payload?.m_planPhoneNumber || '');
          formData.append('step_no', '');
          formData.append('member_id', memberId || EditId?.id || "");
      if (data === null) {
        formData.append('profile_status', 1);
        const response = await dispatch(Add_Member(formData));
        if (response?.payload?.status === true) {
          setMemberId(response?.payload?.data?.member_id)
        }
      } else {
        if (EditId?.id) {
          data.profile_status = 0
          data.step_no = ''
          data.id = EditId?.id
          const response = await dispatch(update_member(data));
          if (response?.payload?.status === true) {
            setShowPopUp({ modal: true, message: response?.payload?.message, id: EditId?.id })
          } else {
            setShowPopUp({ modal: false })
          }
        } else {
          formData.append('profile_status', 0);
          const response = await dispatch(Add_Member(formData));
          if (response?.payload?.status === true) {
            setShowPopUp({ modal: true, message: response?.payload?.message })
          } else {
            setShowPopUp({ modal: false })
          }
        }
      }

    }
    catch (error) {
      // Handle error if dispatch fails
      console.error("Error during add member:", error);
    }
  }
  //Edit Member Info//
  const [EditHealthData, setEditHealthData] = useState({
    PrimaryCare: {},
    behaviouralHealth: {},
    ALTCS: [],
    MedicareHealth: {}
  })
  useEffect(() => {
    if (EditId?.id !== undefined) {
      dispatch(edit_member(EditId?.id))
    }
  }, [EditId?.id])
  //get edit member data
  const EditMemberList = useSelector(state => state?.AuthMember?.EditMemberList);

  //get edit member data
  useEffect(() => {
    if (EditId?.id) {
      setValue('birthPlace', EditMemberList?.birthPlace);
      setValue('cellNo', EditMemberList?.cellNo);
      setValue('citizenship', EditMemberList?.citizenship);
      setValue('city', EditMemberList?.city);
      setValue('eyeColor', EditMemberList?.eyeColor);
      setValue('first_name', EditMemberList?.first_name);
      setValue('ahss_id', EditMemberList?.ahss_id);
      setValue('gendar', EditMemberList?.gendar);
      setValue('groupHome', EditMemberList?.groupHome);
      setValue('hairColor', EditMemberList?.hairColor);
      setValue('height', EditMemberList?.height);
      setValue('memberConditionInfo', EditMemberList?.memberConditionInfo);
      setValue('last_name', EditMemberList?.last_name);
      setValue('phoneNo', EditMemberList?.phoneNo);
      setValue('primaryDiagnosis', EditMemberList?.primaryDiagnosis);
      setValue('primaryLanguage', EditMemberList?.primaryLanguage);
      setValue('race', EditMemberList?.race);
      setValue('religiousPreference', EditMemberList?.religiousPreference);
      setValue('secondaryDiagnosis', EditMemberList?.secondaryDiagnosis);
      setValue('streetAddress', EditMemberList?.streetAddress);
      setValue('weight', EditMemberList?.weight);
      setValue('zipCode', EditMemberList?.zipCode);
      setValue('profile_pic', EditMemberList?.profile_pic);
      setImageUrl(EditMemberList?.profile_pic)
      setTimeout(() => {
        setValue('admissionDate', EditMemberList?.admissionDate);
        setValue('state', EditMemberList?.state);
      }, 1000);
      //contact info
      setValue('c_cellPhone', EditMemberList?.contact?.cellPhone);
      setValue('c_firstName', EditMemberList?.contact?.first_name);
      setValue('c_lastName', EditMemberList?.contact?.last_name);
      setValue('c_homePhone', EditMemberList?.contact?.homePhone);
      setValue('c_workPhone', EditMemberList?.contact?.workPhone);
      setValue('c_email', EditMemberList?.contact?.email);
      setValue('c_streetAddress', EditMemberList?.contact?.streetAddress);
      setValue('c_city', EditMemberList?.contact?.city);
      setValue('c_state', EditMemberList?.contact?.state);
      setValue('c_zipCode', EditMemberList?.contact?.zipCode);
      //additional contact info
      const lengthDifference = EditMemberList?.additional_contact?.length - fields.length;
      if (lengthDifference > 0) {
        for (let i = 0; i < lengthDifference; i++) {
          append({});
        }
      }
      EditMemberList && EditMemberList?.additional_contact.forEach((item, index) => {
        setValue(`additional_contacts.${index}.a_firstName`, item.first_name || '');
        setValue(`additional_contacts.${index}.a_lastName`, item.last_name || '');
        setValue(`additional_contacts.${index}.a_homePhone`, item.homePhone || '');
        setValue(`additional_contacts.${index}.a_cellPhone`, item.cellPhone || '');
        setValue(`additional_contacts.${index}.a_workPhone`, item.workPhone || '');
        setValue(`additional_contacts.${index}.a_email`, item.email || '');
        setValue(`additional_contacts.${index}.a_streetAddress`, item.streetAddress || '');
        setValue(`additional_contacts.${index}.a_city`, item.city || '');
        setValue(`additional_contacts.${index}.a_state`, item.state || '');
        setValue(`additional_contacts.${index}.a_zipCode`, item.zipCode || '');
      });
    }
    setEditHealthData({
      PrimaryCare: EditMemberList?.memberHealthPrimaryPhysician,
      behaviouralHealth: EditMemberList?.memberHealthBehavioural,
      ALTCS: EditMemberList?.memberHealthALTCS,
      MedicareHealth: EditMemberList?.memberHealthPlans,
    })

  }, [EditMemberList])

  const handleBegin = () => {
    setUploadAndManuallyModal(true)
    setShowPopUp(false)
  }
  const UploadAndManuallyClose = () => {
    setUploadAndManuallyModal(false)
    navigate(`/auth/edit-member/${showPopUp?.id}`)
  }  
  
  const sortedCountryData = CountryData ? 
  [CountryData.find(elem => elem.iso2 === "US"), 
  ...CountryData.filter(elem => elem.iso2 !== "US")] 
  : [];

  return (
    <section className="step-home-sec container member_vitalinfo_stepone">
      <div className="sap-card-header progress-bar-sec">
        <div className="member_vitalinfo_stepone_title">
          <h5>Vital information</h5>
          <div className="steps-progress">
            <div className="progress">
              <div className="progress-bar" role="progressbar" style={{ width: (activeTab == "generalInfo" && "25%") || (activeTab == "contacts" && "50%") || (activeTab == "AdditionalContacts" && "75%") || (activeTab == "healthInfo" && "100%") }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">25%</div>
            </div>
            <h5 className="sap-card-title">STEP {(activeTab == "generalInfo" && 1) || (activeTab == "contacts" && 2) || (activeTab == "AdditionalContacts" && 3) || (activeTab == "healthInfo" && 4)} OF 4</h5>
          </div>
        </div>
        <button type="button" className="btn-close" aria-label="Close" onClick={() => onClose()}></button>
      </div>
      <div className="pills-steps">
        <div className="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
          <button className={`nav-link ${activeTab === 'generalInfo' ? 'active' : ''}`} id="v-pills-home-tab" type="button" role="tab" >
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12Z" fill="white" />
              <path d="M1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12Z" stroke="#98A2B3" stroke-width="2" />
              <path d="M17.3337 8L10.0003 15.3333L6.66699 12" stroke="#98A2B3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
            General information
          </button>
          <button className={`nav-link ${activeTab === 'contacts' ? 'active' : ''}`} type="button" role="tab" >
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12Z" fill="white" />
              <path d="M1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12Z" stroke="#98A2B3" stroke-width="2" />
              <path d="M17.3337 8L10.0003 15.3333L6.66699 12" stroke="#98A2B3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
            Contacts
          </button>
          <button className={`nav-link ${activeTab === 'AdditionalContacts' ? 'active' : ''}`} id="v-pills-messages-tab" type="button" role="tab">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12Z" fill="white" />
              <path d="M1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12Z" stroke="#98A2B3" stroke-width="2" />
              <path d="M17.3337 8L10.0003 15.3333L6.66699 12" stroke="#98A2B3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
            Additional contacts
          </button>
          <button className={`nav-link ${activeTab === 'healthInfo' ? 'active' : ''}`} id="v-pills-health-tab" type="button" role="tab">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12Z" fill="white" />
              <path d="M1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12Z" stroke="#98A2B3" stroke-width="2" />
              <path d="M17.3337 8L10.0003 15.3333L6.66699 12" stroke="#98A2B3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
            Health care information
          </button>
        </div>
        <div className="tab-content" id="v-pills-tabContent">
          <FormProvider {...formMethods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              {/* General Info */}
              {activeTab === 'generalInfo' &&
                <>
                <div className="mjohan_cont">
                  <div className="saf-img-upload edit-member-sec">
                    <div className="mavtar_img">
                      <img height="100px" width="100px" src={imageUrl && imageUrl.length > 0 ? imageUrl : defaultImg} alt="defaultImg.png" />
                      <div className='upload-icon-sec'>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={onButtonClick}>
                          <path d="M21 15V16.2C21 17.8802 21 18.7202 20.673 19.362C20.3854 19.9265 19.9265 20.3854 19.362 20.673C18.7202 21 17.8802 21 16.2 21H7.8C6.11984 21 5.27976 21 4.63803 20.673C4.07354 20.3854 3.6146 19.9265 3.32698 19.362C3 18.7202 3 17.8802 3 16.2V15M17 8L12 3M12 3L7 8M12 3V15" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                      </div>
                    </div>
                    </div>
                    <input style={{ display: "none" }} ref={inputFile} onChange={handleFileUpload} type="file" accept="image/jpeg, image/png" />
                  </div>
                  <div className="tab-pane fade show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
                    <div className="member_vital_basic_title">
                      <h4>Basic information</h4>
                    </div>
                    <div className="member_vitalstep_one_form">
                      <form action="" className="row">
                        <div className="col-md-6 mb-3">
                          <label for="" className="form-label">First Name*</label>
                          <input type="input" className="form-control" id="" placeholder="First Name" {...register("first_name", { required: "true" })} maxLength={20} />
                          {errors.first_name && errors.first_name.type === "required" && (<span className="error">First Name is required!</span>)}
                        </div>
                        <div className="col-md-6 mb-3">
                          <label for="" className="form-label">Last Name*</label>
                          <input type="input" className="form-control" id="" placeholder="Last Name" {...register("last_name", { required: "true" })} maxLength={10} />
                          {errors.last_name && errors.last_name.type === "required" && (<span className="error">Last Name is required!</span>)}
                        </div>
                        <div className="col-12 mb-3">
                          <label for="" className="form-label">Description of member’s condition*</label>
                          <textarea className="form-control" id="" placeholder="Description" {...register("memberConditionInfo", { required: "true" })} maxLength={150} />
                          {errors.memberConditionInfo && errors.memberConditionInfo.type === "required" && (<span className="error">Description is required!</span>)}

                        </div>
                        <div className="col-md-6 mb-3">
                          <label for="" className="form-label">Admission date*</label>
                          <input type="date" className="form-control" id="" placeholder="mm-dd-yyyy" {...register("admissionDate", { required: "true" })}
                            onKeyDown={(e) => e.preventDefault()} />
                          {errors.admissionDate && errors.admissionDate.type === "required" && (<span className="error">Admission date is required!</span>)}
                        </div>
                        <div className="col-md-6 mb-3">
                          <label for="" className="form-label">Group home*</label>
                          <select className="form-select" aria-label="Default select example" {...register("groupHome", { required: "true" })}>
                            <option value="">Select home</option>
                            {homeslist && homeslist?.map((elem) => {
                              return <option value={elem?.id}>{elem?.home_name}</option>
                            })
                            }
                          </select>
                          {errors.groupHome && errors.groupHome.type === "required" && (<span className="error">Home is required!</span>)}
                        </div>
                        <div className="col-md-6 mb-3">
                          <label for="" className="form-label">Street address*</label>
                          <input type="input" className="form-control" id="" placeholder="Enter address" {...register("streetAddress", { required: "true" })} maxLength={25} />
                          {errors.streetAddress && errors.streetAddress.type === "required" && (<span className="error">Street address is required!</span>)}
                        </div>
                        <div className="col-md-6 mb-3">
                          <label for="" className="form-label">City*</label>
                          <input type="input" className="form-control" id="" placeholder="Enter city" {...register("city", { required: "true" })} maxLength={25} />
                          {errors.city && errors.city.type === "required" && (<span className="error">City is required!</span>)}
                        </div>
                        <div className="col-md-6 mb-3">
                          <label for="" className="form-label">State*</label>
                          <select className="form-select" aria-label="Default select example" {...register("state", { required: "true" })}>
                            <option value="">Select State</option>
                            {
                              stateData && stateData?.map((elem) => {
                                return <option value={elem?.id}>{elem?.address_state}</option>
                              })
                            }
                          </select>
                          {errors.state && errors.state.type === "required" && (<span className="error">State is required!</span>)}
                        </div>
                        <div className="col-md-6 mb-3">
                          <label for="" className="form-label">Zip code*</label>
                          <input type="input" className="form-control" id="" placeholder="Enter zip code" onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault(); } }} maxLength={5}
                            {...register("zipCode", { required: true, pattern: { value: /^\d{5}$/, message: "ZIP code must be exactly 5 digits", } })} />
                          {errors.zipCode && errors.zipCode.type === "required" && (<span className="error">Zipcode is required!</span>)}
                          {errors.zipCode && errors.zipCode.type === "pattern" && (<span className="error">{errors?.zipCode?.message}</span>)}
                        </div>
                        <div className="col-md-6 mb-3">
                          <label for="" className="form-label">Home phone*</label>
                          <input type="input" className="form-control" id="" placeholder="Enter home phone" {...register("phoneNo", { required: "true", pattern: { value: /^\d{10,15}$/, message: "Home phone must be 10 to 15 digits" } })} maxLength={15} onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault() } }} />
                          {errors.phoneNo && errors.phoneNo.type === "required" && (<span className="error">Home phone is required</span>)}
                          {errors.phoneNo && errors.phoneNo.type === "pattern" && (<span className="error">{errors?.phoneNo?.message}</span>)}
                        </div>
                        <div className="col-md-6 mb-3">
                          <label for="" className="form-label">Cell phone*</label>
                          <input type="input" className="form-control" id="" placeholder="Enter cell phone" {...register("cellNo", { required: "true", pattern: { value: /^\d{10,15}$/, message: "Cell phone must be 10 to 15 digits" } })} maxLength={15} onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault() } }} />
                          {errors.cellNo && errors.cellNo.type === "required" && (<span className="error">Cell phone is required</span>)}
                          {errors.cellNo && errors.cellNo.type === "pattern" && (<span className="error">{errors?.cellNo?.message}</span>)}
                        </div>
                        <div className="col-md-6 mb-3">
                          <label for="" className="form-label">Primary qualifying diagnosis*</label>
                          <select className="form-select" aria-label="Default select example" {...register("primaryDiagnosis", { required: "true" })}>
                            <option value="">Select Primary qualifying diagnosis</option>
                            {
                              diagnosisList && diagnosisList?.map((elem) => {
                                return <option value={elem?.id}>{elem?.name}</option>
                              })
                            }
                            <option value="1">US/Arizona</option>
                          </select>
                          {errors.primaryDiagnosis && errors.primaryDiagnosis.type === "required" && (<span className="error">Primary qualifying diagnosis is required</span>)}
                        </div>
                        <div className="col-md-6 mb-3">
                          <label for="" className="form-label">AHSS ID*</label>
                          <input type="input" className="form-control" id="" placeholder="Enter AHSS ID" {...register("ahss_id", { required: "true" })} />
                          {errors.ahss_id && errors.ahss_id.type === "required" && (<span className="error">AHSS ID is required!</span>)}
                        </div>
                        <div className="col-12 mb-3">
                          <label for="" className="form-label">Secondary diagnosis (If applicable)</label>
                          <textarea type="text" className="form-control pb-5" id="" placeholder="Enter Secondary diagnosis" {...register("secondaryDiagnosis")} maxLength={150} />
                        </div>
                        <div className="col-md-6 col-12 mb-3">
                          <label for="" className="form-label">Gender*</label>
                          <select className="form-select" aria-label="Default select example" {...register("gendar", { required: "true" })}>
                            <option value=''>Select Gender</option>
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                            <option value="other">Other</option>
                          </select>
                          {errors.gendar && errors.gendar.type === "required" && (<span className="error">Gender is required</span>)}
                        </div>
                        <div className="col-12 col-md-6 mb-3">
                          <label htmlFor="height" className="form-label">Height (in ft)*</label>
                          <input
                            type="text"
                            className="form-control"
                            id="height"
                            placeholder="Enter height"
                            {...register("height", {
                              required: "Height is required",
                              pattern: {
                                value: /^\d{1,2}'\d{1,2}$/,
                                message: "Height must be in the format X'Y where X and Y are digits"
                              }
                            })}
                            maxLength={3}
                            onInput={(e) => e.target.value = e.target.value.replace(/[^\d']/g, '')}
                          />
                          {errors.height && errors.height.type === "required" && (<span className="error">Height is required</span>)}
                          {errors.height && errors.height.type === "pattern" && (<span className="error">{errors.height.message}</span>)}
                        </div>

                        <div className="col-12 col-md-6 mb-3">
                          <label htmlFor="weight" className="form-label">Weight (in pound)*</label>
                          <input
                            type="text"
                            className="form-control"
                            id="weight"
                            placeholder="Enter weight"
                            inputMode="decimal"
                            pattern="^\d{1,3}(\.\d{2})?$"
                            {...register("weight", {
                              required: "Weight is required",
                              pattern: {
                                value: /^\d{1,3}(\.\d{2})?$/,
                                message: "Weight must be a number between 1 and 999, optionally with one decimal place"
                              }
                            })}
                            maxLength={5}
                          />
                          {errors.weight && errors.weight.type === "required" && (
                            <span className="error">Weight is required</span>
                          )}
                          {errors.weight && errors.weight.type === "pattern" && (
                            <span className="error">{errors.weight.message}</span>
                          )}
                        </div>
                        <div className="col-12 col-md-6 mb-3">
                          <label for="" className="form-label">Eye color*</label>
                          <input type="text" className="form-control " id="" placeholder="Enter Eye color" {...register("eyeColor", { required: "true" })} maxLength={10} />
                          {errors.eyeColor && errors.eyeColor.type === "required" && (<span className="error">Eye color is required</span>)}
                        </div>
                        <div className="col-12 col-md-6 mb-3">
                          <label for="" className="form-label">Hair color*</label>
                          <input type="text" className="form-control " id="" placeholder="Enter Hair color" {...register("hairColor", { required: "true" })} maxLength={10} />
                          {errors.hairColor && errors.hairColor.type === "required" && (<span className="error">Hair color is required</span>)}
                        </div>
                        <div className="col-md-6 col-12 mb-3">
                          <label for="" className="form-label">Race*</label>
                          <select className="form-select" aria-label="Default select example" {...register("race", { required: "true" })}>
                            <option value=''>Select race</option>
                            {
                              raceList && raceList?.map((elem) => {
                                return <option value={elem?.id}>{elem?.name}</option>
                              })
                            }
                          </select>
                          {errors.race && errors.race.type === "required" && (<span className="error">Race is required</span>)}
                        </div>
                        <div className="col-12 col-md-6 mb-3">
                          <label for="" className="form-label">Birthplace*</label>
                          <input type="text" className="form-control " id="" placeholder="Enter Birthplace" {...register("birthPlace", {
                            required: "Birthplace is required",
                            pattern: {
                              value: /^[a-zA-Z\s]*$/,
                              message: "Birthplace can only contain letters"
                            }
                          })} maxLength={15} />
                          {errors.birthPlace && (<span className="error">{errors.birthPlace.message}</span>)}
                        </div>
                        <div className="col-md-6 col-12 mb-3">
                          <label for="" className="form-label">Citizenship*</label>
                          <select className="form-select" aria-label="Default select example" {...register("citizenship", { required: "true" })}>
                            <option value=''>Select Citizenship</option>
                            {
                              sortedCountryData && sortedCountryData?.map((elem) => {
                                return <option value={elem?.id}>{elem?.country}</option>
                              })
                            }
                          </select>
                          {errors.citizenship && errors.citizenship.type === "required" && (<span className="error">Citizenship is required</span>)}
                        </div>
                        <div className="col-md-6 col-12 mb-3">
                          <label for="" className="form-label">Primary language*</label>
                          <select className="form-select" aria-label="Default select example" {...register("primaryLanguage", { required: "true" })}>
                            <option value=''>Select Language</option>
                            {LanguageList && LanguageList?.map((elem) => {
                              return <option value={elem?.id}>{elem?.name}</option>
                            })
                            }
                          </select>
                          {errors.primaryLanguage && errors.primaryLanguage.type === "required" && (<span className="error">Primary language is required</span>)}
                        </div>
                        <div className="col-12 col-md-6 mb-3">
                          <label for="" className="form-label">Religious preference*</label>
                          <input type="text" className="form-control " id="" placeholder="Enter Religious" {...register("religiousPreference", { required: "true" })} />
                          {errors.religiousPreference && errors.religiousPreference.type === "required" && (<span className="error">Religious preference is required</span>)}
                        </div>
                        <div className="vitlinfo_step_onebtn">
                          <div>
                            {
                              EditMemberList?.profile_status === true || !EditId?.id ?
                                <button type="button" className="btn btn-light" onClick={() => onSubmit(null, 1)}>Save as draft</button> : ''
                            }
                          </div>
                          <div>
                            <button type="button" className="btn btn-primary" onClick={() => changeTab('contacts')}>Next</button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </>}
              {/* contacts */}
              {
                activeTab === 'contacts' &&
                <>
                  <div className="tab-pane active show">
                    <div className="member_vital_basic_title">
                      <h4>Legal guardian/Responsible person(s)</h4>
                    </div>
                    <div className="member_vitalstep_one_form">
                      <div className="row">
                        <div className="col-md-6 mb-3">
                          <label for="" className="form-label">First Name*</label>
                          <input type="input" className="form-control" id="" placeholder="First Name" {...register("c_firstName", { required: "true" })} maxLength={20} />
                          {errors.c_firstName && errors.c_firstName.type === "required" && (<span className="error">First Name is required!</span>)}
                        </div>
                        <div className="col-md-6 mb-3">
                          <label for="" className="form-label">Last Name*</label>
                          <input type="input" className="form-control" id="" placeholder="Last Name" {...register("c_lastName", { required: "true" })} maxLength={20} />
                          {errors.c_lastName && errors.c_lastName.type === "required" && (<span className="error">Last Name is required!</span>)}
                        </div>
                        <div className="col-md-6 mb-3">
                          <label for="" className="form-label">Home phone*</label>
                          <input type="input" className="form-control" id="" placeholder="Enter home phone" {...register("c_homePhone", { required: "true", pattern: { value: /^\d{10,15}$/, message: "Home phone must be 10 to 15 digits" } })} maxLength={15} onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault() } }} />
                          {errors.c_homePhone && errors.c_homePhone.type === "required" && (<span className="error">Home phone is required</span>)}
                          {errors.c_homePhone && errors.c_homePhone.type === "pattern" && (<span className="error">{errors?.c_homePhone?.message}</span>)}</div>
                        <div className="col-md-6 mb-3">
                          <label for="" className="form-label">Cell phone*</label>
                          <input type="input" className="form-control" id="" placeholder="Enter cell phone" {...register("c_cellPhone", { required: "true", pattern: { value: /^\d{10,15}$/, message: "Cell phone must be 10 to 15 digits" } })} maxLength={15} onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault() } }} />
                          {errors.c_cellPhone && errors.c_cellPhone.type === "required" && (<span className="error">Cell phone is required</span>)}
                          {errors.c_cellPhone && errors.c_cellPhone.type === "pattern" && (<span className="error">{errors?.c_cellPhone?.message}</span>)}
                        </div>
                        <div className="col-md-6 mb-3">
                          <label for="" className="form-label">Work phone</label>
                          <input type="input" className="form-control" id="" placeholder="Enter work phone" {...register("c_workPhone", { pattern: { value: /^\d{10,15}$/, message: "Work phone must be 10 to 15 digits" } })} maxLength={15} onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault() } }} />
                          {/* {errors.c_workPhone && errors.c_workPhone.type === "required" && (<span className="error">Work phone is required</span>)} */}
                          {errors.c_workPhone && errors.c_workPhone.type === "pattern" && (<span className="error">{errors?.c_workPhone?.message}</span>)}
                        </div>
                        <div className="col-md-6 mb-3">
                          <label for="" className="form-label">Email address*</label>
                          <input type="input" className="form-control" id="" placeholder="Enter email address" {...register("c_email", { required: true, pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, message: "Invalid email address", }, })} maxLength={50} />
                          {errors.c_email && errors.c_email.type === "required" && (<span className="error">Email is required!</span>)}
                          {errors.c_email && errors.c_email.type === "pattern" && (<span className="error">{errors?.c_email?.message}</span>)}
                        </div>
                        <div className="col-md-6 mb-3">
                          <label for="" className="form-label">Street address*</label>
                          <input type="input" className="form-control" id="" placeholder="Enter address" {...register("c_streetAddress", { required: "true" })} maxLength={25} />
                          {errors.c_streetAddress && errors.c_streetAddress.type === "required" && (<span className="error">Street address is required!</span>)}
                        </div>
                        <div className="col-md-6 mb-3">
                          <label for="" className="form-label">City*</label>
                          <input type="input" className="form-control" id="" placeholder="Enter city" {...register("c_city", { required: "true" })} maxLength={25} />
                          {errors.c_city && errors.c_city.type === "required" && (<span className="error">City is required!</span>)}
                        </div>

                        <div className="col-md-6 mb-3">
                          <label for="" className="form-label">State*</label>
                          <select className="form-select" aria-label="Default select example" {...register("c_state", { required: "true" })}>
                            <option value="">Select state</option>
                            {
                              stateData && stateData?.map((elem) => {
                                return <option value={elem?.id}>{elem?.address_state}</option>
                              })
                            }
                          </select>
                          {errors.c_state && errors.c_state.type === "required" && (<span className="error">State is required!</span>)}
                        </div>
                        <div className="col-md-6 mb-3">
                          <label for="" className="form-label">Zip code*</label>
                          <input type="input" className="form-control" id="" placeholder="Enter zip code" maxLength={5} onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault(); } }}
                            {...register("c_zipCode", { required: true, pattern: { value: /^\d{5}$/, message: "ZIP code must be exactly 5 digits" } })} />
                          {errors.c_zipCode && errors.c_zipCode.type === "required" && (<span className="error">Zipcode is required!</span>)}
                          {errors.c_zipCode && errors.c_zipCode.type === "pattern" && (<span className="error">{errors?.c_zipCode?.message}</span>)}
                        </div>

                        <div className="col-md-12 login-btn">
                          <div className="three-btn-sec">
                            <div className="two-btn-sec">
                              {
                                EditMemberList?.profile_status === true || !EditId?.id ?
                                  <button type="button" className="btn btn-light" onClick={() => onSubmit(null, 2)}>Save as draft</button> : ''
                              }
                            </div>
                            <div className="two-btn-sec">
                              <button type="button" className="btn btn-light me-md-2" onClick={() => setActiveTab('generalInfo')}>
                                <svg width="16" height="14" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M7.91683 5.00016H2.0835M2.0835 5.00016L5.00016 7.91683M2.0835 5.00016L5.00016 2.0835" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>Go Back</button>
                              <button type="button" className="btn btn-primary" onClick={() => changeTab('AdditionalContacts')}>Next</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              }
              {/* Addional contacts */}
              {
                activeTab === 'AdditionalContacts' &&
                <>
                  <div className="tab-panel" id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab">
                    <div className="member_vital_basic_title">
                      <h4>Additional contacts</h4>
                    </div>
                    <div className="vital_stepone_para"><p>Personal, Therapy, Specialists, HCBS Providers, etc.</p></div>
                    <div className="member_vitalstep_one_form">
                      <div className="row">
                        {fields.map((item, index) => (
                          <div className='row' key={item.id}>
                            {index > 0 && <hr />}
                            {index >= 0 ? (
                              // EditId?.id ? '' : 
                              <div className="col-12 mb-3">
                                <a className="stepone_remove" onClick={() => remove(index)}>Remove</a>
                              </div>
                            ) : null}
                            <div className="col-md-6 mb-3">
                              <label className="form-label">First Name*</label>
                              <input
                                type="input"
                                className="form-control"
                                placeholder="First Name"
                                {...register(`additional_contacts.${index}.a_firstName`, { required: "First Name is required!" })}
                                maxLength={20}
                              />
                              {errors.additional_contacts && errors.additional_contacts[index]?.a_firstName && (
                                <span className="error">{errors.additional_contacts[index].a_firstName.message}</span>
                              )}
                            </div>
                            <div className="col-md-6 mb-3">
                              <label className="form-label">Last Name*</label>
                              <input
                                type="input"
                                className="form-control"
                                placeholder="Last Name"
                                {...register(`additional_contacts.${index}.a_lastName`, { required: "Last Name is required!" })}
                                maxLength={20}
                              />
                              {errors.additional_contacts && errors.additional_contacts[index]?.a_lastName && (
                                <span className="error">{errors.additional_contacts[index].a_lastName.message}</span>
                              )}
                            </div>
                            <div className="col-md-6 mb-3">
                              <label className="form-label">Home phone*</label>
                              <input
                                type="input"
                                className="form-control"
                                placeholder="Enter home phone"
                                {...register(`additional_contacts.${index}.a_homePhone`, {
                                  required: "Home phone is required",
                                  pattern: { value: /^\d{10,15}$/, message: "Home phone must be 10 to 15 digits" }
                                })}
                                maxLength={15}
                                onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault(); } }}
                              />
                              {errors.additional_contacts && errors.additional_contacts[index]?.a_homePhone && (
                                <span className="error">{errors.additional_contacts[index].a_homePhone.message}</span>
                              )}
                            </div>
                            <div className="col-md-6 mb-3">
                              <label className="form-label">Cell phone*</label>
                              <input
                                type="input"
                                className="form-control"
                                placeholder="Enter cell phone"
                                {...register(`additional_contacts.${index}.a_cellPhone`, {
                                  required: "Cell phone is required",
                                  pattern: { value: /^\d{10,15}$/, message: "Cell phone must be 10 to 15 digits" }
                                })}
                                maxLength={15}
                                onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault(); } }}
                              />
                              {errors.additional_contacts && errors.additional_contacts[index]?.a_cellPhone && (
                                <span className="error">{errors.additional_contacts[index].a_cellPhone.message}</span>
                              )}
                            </div>
                            <div className="col-md-6 mb-3">
                              <label className="form-label">Work phone</label>
                              <input
                                type="input"
                                className="form-control"
                                placeholder="Enter work phone"
                                {...register(`additional_contacts.${index}.a_workPhone`, {
                                  pattern: { value: /^\d{10,15}$/, message: "Work phone must be 10 to 15 digits" }
                                })}
                                maxLength={15}
                                onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault(); } }}
                              />
                              {errors.additional_contacts && errors.additional_contacts[index]?.a_workPhone && (
                                <span className="error">{errors.additional_contacts[index].a_workPhone.message}</span>
                              )}
                            </div>
                            <div className="col-md-6 mb-3">
                              <label className="form-label">Email address*</label>
                              <input
                                type="input"
                                className="form-control"
                                placeholder="Enter email address"
                                {...register(`additional_contacts.${index}.a_email`, {
                                  required: "Email is required!",
                                  pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, message: "Invalid email address" }
                                })}
                                maxLength={50}
                              />
                              {errors.additional_contacts && errors.additional_contacts[index]?.a_email && (
                                <span className="error">{errors.additional_contacts[index].a_email.message}</span>
                              )}
                            </div>
                            <div className="col-md-6 mb-3">
                              <label className="form-label">Street address*</label>
                              <input
                                type="input"
                                className="form-control"
                                placeholder="Enter address"
                                {...register(`additional_contacts.${index}.a_streetAddress`, { required: "Street address is required!" })}
                                maxLength={25}
                              />
                              {errors.additional_contacts && errors.additional_contacts[index]?.a_streetAddress && (
                                <span className="error">{errors.additional_contacts[index].a_streetAddress.message}</span>
                              )}
                            </div>
                            <div className="col-md-6 mb-3">
                              <label className="form-label">City*</label>
                              <input
                                type="input"
                                className="form-control"
                                placeholder="Enter city"
                                {...register(`additional_contacts.${index}.a_city`, { required: "City is required!" })}
                                maxLength={25}
                              />
                              {errors.additional_contacts && errors.additional_contacts[index]?.a_city && (
                                <span className="error">{errors.additional_contacts[index].a_city.message}</span>
                              )}
                            </div>
                            <div className="col-md-6 mb-3">
                              <label className="form-label">State*</label>
                              <select
                                className="form-select"
                                aria-label="Default select example"
                                {...register(`additional_contacts.${index}.a_state`, { required: "State is required!" })}>
                                <option value="">Select state</option>
                                {
                                  stateData && stateData?.map((elem) => {
                                    return <option value={item.a_state || elem?.id}>{elem?.address_state}</option>
                                  })
                                }
                              </select>
                              {errors.additional_contacts && errors.additional_contacts[index]?.a_state && (
                                <span className="error">{errors.additional_contacts[index].a_state.message}</span>
                              )}
                            </div>
                            <div className="col-md-6 mb-3">
                              <label className="form-label">Zip code*</label>
                              <input
                                type="input"
                                className="form-control"
                                placeholder="Enter zip code"
                                maxLength={5}
                                onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault(); } }}
                                {...register(`additional_contacts.${index}.a_zipCode`, {
                                  required: "Zip code is required!",
                                  pattern: { value: /^\d{5}$/, message: "ZIP code must be exactly 5 digits" }
                                })}
                              />
                              {errors.additional_contacts && errors.additional_contacts[index]?.a_zipCode && (
                                <span className="error">{errors.additional_contacts[index].a_zipCode.message}</span>
                              )}
                            </div>

                          </div>
                        ))}
                        <div className="col-12">
                          {/* {
                            EditId?.id ? '' : */}
                              <div className="stepone_health_planbtn">
                                <button type="button" className="font-14" onClick={() => append({ a_firstName: "", a_lastName: "", a_homePhone: "", a_cellPhone: "", a_workPhone: "", a_email: "", a_streetAddress: "", a_city: "", a_state: "", a_zipCode: "" })}><svg width="12" height="12"
                                  viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M6.00016 1.33325V10.6666M1.3335 5.99992H10.6668" stroke="#667085"
                                    stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                                  Add another additional contacts</button>
                              </div>
                          {/* } */}
                        </div>
                        <div className="col-md-12 login-btn">
                          <div className="three-btn-sec">
                            <div className="two-btn-sec">
                              {
                                EditMemberList?.profile_status === true || !EditId?.id ?
                                  <button type="button" className="btn btn-light" onClick={() => onSubmit(null, 3)}>Save as draft</button> : ''
                              }
                            </div>
                            <div className="two-btn-sec">
                              <button type="button" className="btn btn-light me-md-2" onClick={() => setActiveTab('contacts')}>
                                <svg width="16" height="14" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M7.91683 5.00016H2.0835M2.0835 5.00016L5.00016 7.91683M2.0835 5.00016L5.00016 2.0835" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>Go Back</button>
                              <button type="button" className="btn btn-primary" onClick={() => changeTab('healthInfo')}>Next</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              }
              {/* Health car info */}
              {
                activeTab === 'healthInfo' &&
                <>
                  <AddHealthInfo stateData={stateData} setActiveTab1={setActiveTab} EditHealthData={EditHealthData} id={EditId?.id} onSubmit={onSubmit} profileStatus={EditMemberList?.profile_status} />
                </>
              }
            </form>
          </FormProvider>
        </div>
      </div>
      <AfterAddMember setShowPopUp={setShowPopUp} showPopUp={showPopUp} handleBegin={handleBegin} />
      <UploadAndManually id={showPopUp?.id} isOpen={uploadAndManuallyModal} setIsOpen={UploadAndManuallyClose} />
    </section>
  )
}

export default AddMember;