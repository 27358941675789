import React,{useEffect} from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { useSelector } from 'react-redux';
import './dashboard.css';
import './App.css';
import { AccountCreation, Login, VerifyPhone, ForgetPassword, ResetPassword, Home, Subscription,SubscriptionPage, Setting,Verify_text_otp_Number,Dashboard,AllUsers,AddUser,EditUser,PrivacyPolicy,  TermsAndServices,AddHome,AllHome, EditHome,CancelSubscription,AllMembers,AddMember,EditMember,DddPcsp,MAR,EditMar, OpenMar,Communication, CommunicationDetails, Appointment, Documents, Tasks} from "./pages/Index";
import MainLayout from "./layouts/Owner/MainLayout";
import AuthLayout from "./layouts/authOwnerLayout/AuthLayout";
import { AfterLogin, Protected_routes, Required_auth } from "./utils/Utils";
import 'bootstrap/dist/js/bootstrap.bundle.min';
import IndividualizedHealth from "./pages/auth/Members/IndividualizedHealth";
import MedicalHistory from "./pages/auth/Members/MedicalHistory";
import GeneralConsentAndAuthorization from "./pages/auth/Members/GeneralConsentAndAuthorization";
import BehaviorModifyingMedicationConsent from "./pages/auth/Members/BehaviorModifyingMedicationConsent";
import Loader from './components/Common/Loader';
import "react-date-range/dist/styles.css"; // Main style file
import "react-date-range/dist/theme/default.css"; // Theme css file

function App() {
  const location = useLocation();
  const { AuthHomes, AuthMember, AuthSetting, AuthUser, common, auth, AuthMar, AuthCommunication } = useSelector(state => state || {});
  
  const isLoading = AuthHomes?.isLoading ||  AuthMember?.isLoading || AuthSetting?.isLoading || 
AuthUser?.isLoading || common?.isLoading || auth?.isLoading || AuthMar?.isLoading || AuthCommunication?.isLoading

useEffect(()=>{  
  window.scrollTo(0,0)
},[location.pathname])
  return (
    <>
    {isLoading && <Loader/>}
    <Routes>
      <Route element={<MainLayout />}>
        <Route path="/verify-phone" element={<VerifyPhone />} />
        <Route path="/forget-password" element={<ForgetPassword />} />
        <Route path="/reset-password/:token" element={<ResetPassword />} />
        <Route path="/reset-password-with-text" element={<Verify_text_otp_Number />} />
        <Route path="/setting/subscription" element={<Subscription />} />
        <Route path="/setting/subscription-page" element={<SubscriptionPage />} />
        <Route path="/login" element={<AfterLogin><Login /></AfterLogin>} />
        <Route path="/" element={<Home />} />
        <Route path="/account-creation" element={<Protected_routes><AccountCreation /></Protected_routes>} />
        <Route path="/term-services" element={<TermsAndServices />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/cancel-subscription" element={<CancelSubscription />} />
      </Route>
      <Route element={<Required_auth />}>
        <Route element={<Protected_routes><AuthLayout /></Protected_routes>}>
          <Route path="/auth/settings" element={<Setting />} />
          <Route path="/auth/dashboard" element={<Dashboard />} />
          {/* Users Module */}
          <Route path="/auth/users" element={<AllUsers />} />
          <Route path="/auth/users-add" element={<AddUser />} />
          <Route path="/auth/users-edit/:id" element={<EditUser />} />
          {/* home module */}
          <Route path="/auth/homes" element={<AllHome />} />
          <Route path="/auth/add-home" element={<AddHome />} />
          <Route path="/auth/edit-home/:id" element={<EditHome />} />
          {/* member module */}
          <Route path="/auth/members" element={<AllMembers />} />
          <Route path="/auth/add-member" element={<AddMember />} />
          <Route path="/auth/edit-member/:id" element={<EditMember />} />
          <Route path="/auth/edit-members/:id" element={<AddMember />} />
          <Route path="/auth/edit-members/ddd/pcsp/:id" element={<DddPcsp />} />
          <Route path="/auth/add-member/:id" element={<AddMember />} />
          <Route path="/auth/edit-members/individualized/:id" element={<IndividualizedHealth />} />
          <Route path="/auth/edit-members/medicalhistory/:id" element={<MedicalHistory />} />
          <Route path="/auth/edit-members/general-consent/:id" element={<GeneralConsentAndAuthorization />} />
          <Route path="/auth/edit-members/behavior-modifying/:id" element={<BehaviorModifyingMedicationConsent />} />

          {/* MAR */}
          <Route path="/auth/mar" element={<MAR />} />
          <Route path="/auth/edit-mar/:id" element={<EditMar />} />
          <Route path="/auth/open-mar/:id" element={<OpenMar />} />
          {/* <Route path="/auth/add-mar" element={<AddMar />} /> */}
          
          {/* Appointment */}
          <Route path="/auth/appointment" element={<Appointment />} />

          {/* Communication */}
          <Route path="/auth/communication" element={<Communication />} />
          <Route path="/auth/communication-details/:id" element={<CommunicationDetails />} />

          {/* Documents */}
          <Route path="/auth/documents" element={<Documents/>} />

        
          <Route path="/auth/tasks" element={<Tasks/>} />
          
        </Route>
      </Route>
      <Route path="*" element={<h1>Not Found Page...!</h1>} />
    </Routes>
    </>
  );
}

export default App;
