import React, { useEffect } from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import { useSelector } from 'react-redux';
import PcspFooter from '../../PcspFooter'

const ActionPlan = ({ onHandleBackStep, onSubmit }) => {
  const allDddPcsp = useSelector(state => state?.AuthMember?.allDddPcsp?.actionplanFollowup);
  // Initialize the form with useForm hook
  const { control, handleSubmit, register, getValues, setValue, formState: { errors } } = useForm({
    defaultValues: {
      actionPlans: [{ actionplanfollowup_id: null, action_taken: "", person_responsible: "", due_date: "", followup_date: "", complete_date: "", comments: "" }],
    }
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'actionPlans'
  });

  useEffect(() => {
    const lengthDifference = allDddPcsp?.length - fields.length;
        if (lengthDifference > 0) {
            for (let i = 0; i < lengthDifference; i++) {
                append({});
            }
        }
        allDddPcsp?.forEach((item, index) => {
            setValue(`actionPlans.${index}.action_taken`, item.action_taken || '');
            setValue(`actionPlans.${index}.comments`, item.comments || '');
            setValue(`actionPlans.${index}.complete_date`, item.complete_date || '');
            setValue(`actionPlans.${index}.due_date`, item.due_date || '');
            setValue(`actionPlans.${index}.followup_date`, item.followup_date || '');
            setValue(`actionPlans.${index}.person_responsible`, item.person_responsible || '');
            setValue(`actionPlans.${index}.actionplanfollowup_id`, item.id || '');
        });
  }, [allDddPcsp])

  return (
    <div className="tab-pane fade  goal_tab show active">
      <form onSubmit={handleSubmit(onSubmit)} className="row">
        <div className="col-12">
          <div className="alert alert-light sap-alert-heading">
            <div className="hd-subtitle font-16">Documentation must reflect the individuals responsible for monitoring
              the PCSP. Action plan items should focus on measurable steps that will need to be taken to reach
              desired outcomes in the member’s life. These items may be related to a member’s goals or other areas
              that need to be addressed and followed up on.</div>
          </div>

        </div>

        {fields.map((member, index) => (
          <div key={member.id} className="row">
            {index > 0 && (
                <hr/>
            )}
            <div className="col-md-6 mb-3">
              <label for="" className="form-label">Action to be taken*</label>
              <input
                type="input"
                className="form-control"
                placeholder="Enter Action to be taken"
                {...register(`actionPlans.${index}.action_taken`, { required: "This field is required!" })}
                maxLength={20}
              />
              {errors.actionPlans && errors.actionPlans[index]?.action_taken && (
                <span className="error">{errors.actionPlans[index].action_taken.message}</span>
              )}
            </div>
            <div className="col-md-6 mb-3">
              <label for="" className="form-label">Person responsible*</label>
              <input
                type="input"
                className="form-control"
                placeholder="Enter Person responsible"
                {...register(`actionPlans.${index}.person_responsible`, { required: "This field is required!" })}
                maxLength={20}
              />
              {errors.actionPlans && errors.actionPlans[index]?.person_responsible && (
                <span className="error">{errors.actionPlans[index].person_responsible.message}</span>
              )}
            </div>
            <div className="col-md-6 col-lg-4 mb-3">
              <label for="" className="form-label">Due date (Target)*</label>
              <input type="date" className="form-control" id="" placeholder="jan 6, 2024" {...register(`actionPlans.${index}.due_date`, { required: "true", })} onKeyDown={(e) => e.preventDefault()} />
              {errors.actionPlans && errors.actionPlans[index]?.due_date && (
                <span className="error">{errors.actionPlans[index].due_date.message}</span>
              )}
            </div>
            <div className="col-md-6 col-lg-4 mb-3">
              <label for="" className="form-label">Follow up date*</label>
              <input type="date" className="form-control" id="" placeholder="jan 6, 2024" {...register(`actionPlans.${index}.followup_date`, { required: "true", })} onKeyDown={(e) => e.preventDefault()} />
              {errors.actionPlans && errors.actionPlans[index]?.followup_date && (
                <span className="error">{errors.actionPlans[index].followup_date.message}</span>
              )}
            </div>
            <div className="col-md-6 col-lg-4 mb-3">
              <label for="" className="form-label">Date complete*</label>
              <input type="date" className="form-control" id="" placeholder="jan 6, 2024" {...register(`actionPlans.${index}.complete_date`, { required: "true", })} onKeyDown={(e) => e.preventDefault()} />
              {errors.actionPlans && errors.actionPlans[index]?.complete_date && (
                <span className="error">{errors.actionPlans[index].complete_date.message}</span>
              )}
            </div>
            <div className="col-12 mb-3">
              <label for="" className="form-label">Comments*</label>
              <textarea className="form-control" placeholder="N/A" {...register(`actionPlans.${index}.comments`, { required: "true" })} maxLength={150} />
              {errors.actionPlans && errors.actionPlans[index].comments.type === "required" && (<span className="error">This is required!</span>)}
            </div>
            {index > 0 && (
            <div className="col-12 mb-3">
              <a
                href="#"
                className="stepone_remove"
                onClick={(e) => {
                  e.preventDefault();
                  remove(index);
                }}
              >
                Remove Task
              </a>
            </div>
          )}
          </div>
        ))}
        <div className="stepone_health_planbtn">
          <button type="button" className="font-14" onClick={() =>append({ actionplanfollowup_id: null }) }><svg width="12" height="12" viewBox="0 0 12 12" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M6.00016 1.33325V10.6666M1.3335 5.99992H10.6668" stroke="#667085" stroke-width="2"
              stroke-linecap="round" stroke-linejoin="round"></path>
          </svg>
            Add another health plan</button>
        </div>
        <PcspFooter onHandleBackStep={onHandleBackStep} />
      </form>
    </div>
  )
}

export default ActionPlan