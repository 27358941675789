import React, { useEffect, useState } from 'react';
import { gat_all_homes } from '../../../redux/services/AuthHomes';
import { useDispatch, useSelector } from 'react-redux';
import { Pagination } from '../../Pagination';
import HeaderHomes from './HeaderHomes';

const Homes = () => {
    const dispatch = useDispatch();

    const AllHomes = useSelector(state => state?.AuthHomes?.AllHomes?.homes);
    const totalPages = useSelector(state => state?.AuthHomes?.AllHomes?.totalPages);

    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState({
        page: currentPage,
        listData: 10,
        searchBar: '',
        max_cap: { start: '', end: '' },
        current_cap: { start: '', end: '' },
        no_users: { start: '', end: '' },
    });

    // Sort state: keeps track of sorting property and order (ascending/descending)
    const [sort, setSort] = useState('asc');

    useEffect(() => {
        dispatch(gat_all_homes(search));
    }, [search, currentPage]);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
        setSearch({
            ...search,
            page: pageNumber
        });
    };

    const handleSearchBar = (e) => {
        setCurrentPage(1);
        setSearch({
            ...search,
            searchBar: e.target.value,
            page: 1
        });
    };

    const searchCapacity = () => {
        dispatch(gat_all_homes(search));
    };

    const handleSorting = () => {
        // Toggle the sorting order (ascending <-> descending)
        const newOrder = sort === 'asc' ? 'desc' : 'asc';
        setSort(newOrder);
    };

    // Function to sort the homes based on the selected property and order
    const sortHomes = (homes) => {
        return [...homes].sort((a, b) => {
            let valueA = a.home_name.toLowerCase();
            let valueB = b.home_name.toLowerCase();

            if (sort === 'asc') {
                return valueA > valueB ? 1 : valueA < valueB ? -1 : 0;
            } else {
                return valueA < valueB ? 1 : valueA > valueB ? -1 : 0;
            }
        });
    };

    const sortedHomes = AllHomes ? sortHomes(AllHomes) : [];

    return (
        <div className="row">
            <div className="col-md-12 col-12 mt-4">
                <HeaderHomes search={search} setSearch={setSearch} handleSearchBar={handleSearchBar} searchCapacity={searchCapacity} />
                <div className="table-sec">
                    <div className="table-responsive">
                        <table className="table table-hover">
                            <thead>
                                <tr>
                                    <th>
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox"
                                                value="" id="flexCheckDefault" />
                                            <label className="form-check-label" htmlFor="flexCheckDefault">Homes</label>
                                            <span className={`sort_icon ${sort}`} onClick={() => handleSorting()}>
                                                <svg className="ms-1" width="12" height="12"
                                                    viewBox="0 0 12 12" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M6 1.33337V10.6667M6 10.6667L10.6667 6.00004M6 10.6667L1.33333 6.00004"
                                                        stroke="#475467" strokeWidth="0.888889"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round" />
                                                </svg>
                                            </span>
                                        </div>
                                    </th>
                                    <th scope="col">Status</th>
                                    <th scope="col">Members</th>
                                    <th scope="col">Location</th>
                                    <th scope="col">Staff</th>
                                </tr>
                            </thead>
                            <tbody>
                                {sortedHomes && sortedHomes.map((item) => {
                                    let homeName = item.home_name.split(' ');
                                    // Ensure there are at least two words before trying to access the second character
                                    let shortName = (homeName[0]?.charAt(0)?.toUpperCase() || '') +
                                        (homeName[1]?.charAt(0)?.toUpperCase() || '');

                                    return (
                                        <tr key={item.id}>
                                            <td>
                                                <div className="table-profile">
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox"
                                                            value="" id="flexCheckIndeterminate" />
                                                    </div>
                                                    <div className="profile-sec">
                                                        <span className="name yellow">{shortName}</span>
                                                        <div className="profile-info">
                                                            <div className="font-14 hd-title">{item.home_name}</div>
                                                            <div className="font-14 hd-subtitle">{item.emergency_contact}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="badges">
                                                    <span className={`${item.is_active ? 'actives' : 'pending'}`}></span>
                                                    {item.is_active ? 'Active' : 'Inactive'}
                                                </div>
                                            </td>
                                            <td>{item.currentMemberCapacity} members</td>
                                            <td>
                                                <div className="font-14 hd-title">{item.time_zone}</div>
                                            </td>
                                            <td>{item.staff}</td>
                                        </tr>
                                    );
                                })}

                                <tr>
                                    <td colSpan="7">
                                        <Pagination
                                            currentPage={currentPage}
                                            totalPages={totalPages}
                                            onPageChange={handlePageChange}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Homes;
