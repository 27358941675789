import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import PcspFooter from '../../PcspFooter'

const Modifications = ({ onHandleBackStep, onSubmit }) => {
  const allDddPcsp = useSelector(state => state?.AuthMember?.allDddPcsp?.risksPlanModification);
  // Initialize the form with useForm hook
  const { register, handleSubmit, setValue, formState: { errors } } = useForm();

  useEffect(() => {
      setValue('plan_modification_description', allDddPcsp?.plan_modification_description);
      setValue('identify_specific_individualized_needs', allDddPcsp?.identify_specific_individualized_needs);
      setValue('document_positive_intervention_support', allDddPcsp?.document_positive_intervention_support);
      setValue('document_less_intrusive_methods', allDddPcsp?.document_less_intrusive_methods);
      setValue('include_clear_description_conditions', allDddPcsp?.include_clear_description_conditions);
      setValue('include_timeline_for_regular_collections', allDddPcsp?.include_timeline_for_regular_collections);
      setValue('include_establieshed_time_limits', allDddPcsp?.include_establieshed_time_limits);
      setValue('describe_assurance', allDddPcsp?.describe_assurance);
  }, [allDddPcsp])

  return (
    <div className="tab-pane fade  goal_tab show active">
      <form onSubmit={handleSubmit(onSubmit)} className="row">
        <div className="col-12">
          <div className="alert alert-light sap-alert-heading">
            <div className="hd-subtitle font-16">This section is only applicable if a member’s rights are being
              restricted. The Risk Assessment will include information to identify what will be done differently to
              minimize or eliminate the risk. The Risk Assessment document should be easy to understand, simple,
              straightforward, visible and readily available to the staff working directly with the individual. It
              is designed to assist direct support staff in safeguarding the member from identified risks.</div>
          </div>
        </div>
        <div className="col-12 mb-3">
          <label for="" className="form-label">Describe the modification to the plan that is restricting the member’s rights*</label>
          <textarea className="form-control" placeholder="N/A" {...register("plan_modification_description", { required: "true" })} maxLength={150} />
          {errors.plan_modification_description && errors.plan_modification_description.type === "required" && (<span className="error">This is required!</span>)}
        </div>
        <div className="col-12 mb-3">
          <label for="" className="form-label">Identify the specific and individualized need that has been identified through the assessments of functionalized need (uniform assessment tool, HCBS needs tool, risk assessment tool)*</label>
          <textarea className="form-control" placeholder="N/A" {...register("identify_specific_individualized_needs", { required: "true" })} maxLength={150} />
            {errors.identify_specific_individualized_needs && errors.identify_specific_individualized_needs.type === "required" && (<span className="error">This is required!</span>)}
        </div>
        <div className="col-12 mb-3">
          <label for="" className="form-label">Document the positive interventions and supports used prior to any modifications to the PCSP*</label>
          <textarea className="form-control" placeholder="N/A" {...register("document_positive_intervention_support", { required: "true" })} maxLength={150} />
            {errors.document_positive_intervention_support && errors.document_positive_intervention_support.type === "required" && (<span className="error">This is required!</span>)}
        </div>
        <div className="col-12 mb-3">
          <label for="" className="form-label">Document less intrusive methods of meeting the need that have been tried but did not work*</label>
          <textarea className="form-control" placeholder="N/A" {...register("document_less_intrusive_methods", { required: "true" })} maxLength={150} />
            {errors.document_less_intrusive_methods && errors.document_less_intrusive_methods.type === "required" && (<span className="error">This is required!</span>)}
        </div>
        <div className="col-12 mb-3">
          <label for="" className="form-label">Include a clear description of the condition that is directly proportionate to the specific assessed need*</label>
          <textarea className="form-control" placeholder="N/A" {...register("include_clear_description_conditions", { required: "true" })} maxLength={150} />
            {errors.include_clear_description_conditions && errors.include_clear_description_conditions.type === "required" && (<span className="error">This is required!</span>)}
        </div>
        <div className="col-12 mb-3">
          <label for="" className="form-label">Include a timeline for the regular collection and review of data to measure the ongoing effectiveness of the modification*</label>
          <textarea className="form-control" placeholder="N/A" {...register("include_timeline_for_regular_collections", { required: "true" })} maxLength={150} />
            {errors.include_timeline_for_regular_collections && errors.include_timeline_for_regular_collections.type === "required" && (<span className="error">This is required!</span>)}
        </div>
        <div className="col-12 mb-3">
          <label for="" className="form-label">Include established time limits for periodic reviews to determine if the modification is still necessary or can be terminate*</label>
          <textarea className="form-control" placeholder="N/A" {...register("include_establieshed_time_limits", { required: "true" })} maxLength={150} />
            {errors.include_establieshed_time_limits && errors.include_establieshed_time_limits.type === "required" && (<span className="error">This is required!</span>)}
        </div>
        <div className="col-12 mb-3">
          <label for="" className="form-label">Describe the assurance that the interventions and supports will cause no harm to the individual*</label>
          <textarea className="form-control" placeholder="N/A" {...register("describe_assurance", { required: "true" })} maxLength={150} />
            {errors.describe_assurance && errors.describe_assurance.type === "required" && (<span className="error">This is required!</span>)}
        </div>
        <PcspFooter onHandleBackStep={onHandleBackStep} />
      </form>
    </div>
  )
}

export default Modifications