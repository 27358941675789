import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import { formatAMPM, monthDayYearFormat } from '../../../components/Common/Common'
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { AppointmentColors } from './CommonData'

const localizer = momentLocalizer(moment);

const Calenderview = ({ FromMember = '', onRowClick, onTaggalStatus, handlePageChange, currentPage }) => {
  const AllAppointmentList = useSelector((state) => state?.AuthAppointment?.AllAppointmentList?.appoinments);
  const [events, setEvents] = useState([]);
  const [appointmentData, setAppointmentData] = useState([])


  useEffect(() => {
    if (FromMember) { // Check if FromMember is truthy
      const filteredData = AllAppointmentList?.filter(item =>
        item?.appointmentMemberList?.some(member => member?.member?.id === FromMember)
      );
      setAppointmentData(filteredData)
    } else {
      setAppointmentData(AllAppointmentList)
    }
  }, [FromMember, AllAppointmentList]);


  useEffect(() => {
    if (appointmentData && appointmentData.length > 0) {
      const formattedEvents = appointmentData.map(appointment => {
        const [startHour, endHour] = appointment.appointment_time.split('-');
        const start = new Date(`${appointment.appointment_date}T${startHour}:00`);
        const end = new Date(`${appointment.appointment_date}T${endHour}:00`);

        return {
          id: appointment.id,
          title: appointment.doctor_specialty,
          start,
          end,
          startHour,
          endHour,
          allDay: false,
          appointmentStatus: appointment.appointment_status,
          approvalStatus: appointment.approval_status,
          appointmentMemberList: appointment.appointmentMemberList
        };
      });
      setEvents(formattedEvents);
    }
  }, [appointmentData]);

  // Update the EventComponent
  const EventComponent = ({ event }) => {
    let divStyle = AppointmentColors[event.appointmentStatus]
    return (
      <div className="event_div" style={divStyle}>
        {event?.appointmentMemberList?.length > 1 ?
          <>
            <h4>{event.title}</h4>
            <p>{formatAMPM(event.startHour)} - {formatAMPM(event.endHour)}</p>
            <div className="appointment-spacer"></div>
            <div className="profile-sec">
                      {event?.appointmentMemberList.map((member, index) => {
                        if (index < 3) {
                          let shortName = member.member?.first_name?.charAt(0)?.toUpperCase() + member.member?.last_name?.charAt(0)?.toUpperCase() || '';
                          return (
                            <div className="appointment-member" key={index}>
                              <span className="name" title={member.member?.first_name + ' ' + member.member?.last_name}>
                                {member.member?.profile_pic ? (
                                  <img src={member.member.profile_pic} alt={`${member.member.first_name}'s profile`} />
                                  ) : (
                                  shortName
                                )}
                              </span>
                            </div>
                          );
                        }
                        return null;
                      })}
                      {event?.appointmentMemberList.length > 3 && (
                        <span className="appointment_plus">+{event.appointmentMemberList.length - 3}</span>
                      )}
                    </div>
          </>
          :
          <>
            <div class="header_event">
              {event?.appointmentMemberList.map((member) => {
                let shortName = member.member?.first_name?.charAt(0)?.toUpperCase() + member.member?.last_name?.charAt(0)?.toUpperCase() || '';
                return (
                  <>
                    <div className="profile-sec  appointment-member">
                      <span class="name" style={{ border: divStyle.border }}>
                        {member.member?.profile_pic ?
                          <img src={member.member?.profile_pic} /> :
                          shortName
                        }
                      </span>
                      <div className="profile-info">
                        <p className="font-14 hd-title">{member.member?.first_name} {member.member?.last_name}</p>
                        <p>{event.title}</p>
                      </div>
                    </div>
                  </>
                )
              })}
            </div>
            <div className="appointment-spacer"></div>
            <p>{formatAMPM(event.startHour)} - {formatAMPM(event.endHour)}</p>
          </>
        }
      </div>
    );
  };

  return (
    <div style={{ height: '100vh', padding: '20px' }}>
      <Calendar
        localizer={localizer}
        events={events}
        components={{
          event: EventComponent,
        }}
        startAccessor="start"
        endAccessor="end"
        views={['month', 'week', 'day', 'agenda']}
        defaultView="month"
        selectable
        onSelectEvent={(event) => onRowClick(event.id)}
      // onSelectSlot={(slotInfo) => alert(`You selected slot: ${slotInfo.start} - ${slotInfo.end}`)}
      />
    </div>
  );
}

export default Calenderview;
