import React, { useState, useRef, useMemo } from 'react';
import { VerifyOtp, Verify_Otp_Change_Number, Verify_text_otp_number } from '../../redux/services/VerifyOtp';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { login } from '../../redux/services/login';
import { ClipLoader } from 'react-spinners';
import { ForgotPassword } from '../../redux/services/ForgotPassword';
import { setModuleData } from '../../redux/features/commonSlice';
// import { createClient } from 'redis';
// async function demo_redis(moduleData){
//     //redis
//     const client =await createClient()
//     .on('error',err=> console.log('redisclient',err))
//     .connect();
//        await client.set('moduleData',moduleData)

// }


const VerifyPhone = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const securityCheck=localStorage.getItem('tabs')
    const subscriptionPlan = useSelector(state => state?.suscription?.planInfo);
    const [otpValues, setOtpValues] = useState(Array(6).fill(""));
    const [timer, setTimer] = useState(0);
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [loading, setLoading] = useState(false);
    const [changeNumber, setChangeNumber] = useState(false);
    const inputsRef = useRef(Array(6).fill().map(() => React.createRef()));
    //get changed number
    const ChangedNumber = useSelector(state => state?.auth?.changedNumber)
    //check token
    // const checkToken = useSelector(state => state?.auth?.token);
    
    const checkToken = localStorage.getItem('token');
    const mobile = checkToken ? ChangedNumber?.number ? ChangedNumber?.number : subscriptionPlan?.number : subscriptionPlan?.number;
    const email = subscriptionPlan?.email;
    const organization_code = subscriptionPlan?.provider_code;
    const password = subscriptionPlan?.hash;
    const country_code = subscriptionPlan?.country_code
    const mobileShortCode = useMemo(() => { return mobile && mobile.length >= 5 ? `${mobile.slice(0, 3)}${'*'.repeat(mobile.length - 5)}${mobile.slice(-4)}` : '' }, [mobile]);
    const handleInputChange = index => e => {
        const { value } = e.target;
        const newValues = [...otpValues];
        newValues[index] = value;
        setOtpValues(newValues);
        const nextIndex = index + (value.length === 1 && index < 5 ? 1 : value.length === 0 && index > 0 ? -1 : 0);
        if (inputsRef.current[nextIndex]) {
            inputsRef.current[nextIndex].focus();
        }
    };    
    const onSubmit = async (e) => {
        setLoading(true)
        // setButtonDisabled(true)
        e.preventDefault();
        const form = {
            "otp": otpValues?.toString()?.replaceAll(',', ""),
            "phone": mobile,
            "email": email,
            "q_id":subscriptionPlan?.roleData?.id,
            "type":subscriptionPlan?.roleData?.roles,
            "country_code": country_code
        }
        const changenumber = {
            "otp": otpValues?.toString()?.replaceAll(',', ""),
            "phone": ChangedNumber?.phone,
            "country_code": country_code
        }
        try {
            if (checkToken) {
                const verifyOtp_change_number = await dispatch(Verify_Otp_Change_Number(changenumber))
                
                setLoading(false)
                setButtonDisabled(false)
                if (verifyOtp_change_number?.payload?.status == true) {
                    setChangeNumber(true)
                    if(securityCheck){
                        navigate("/auth/settings"); 
                        // localStorage.removeItem('security');
                    }else{
                    navigate("/auth/dashboard");}
                }
            } else {
                const res = await dispatch(VerifyOtp(form));                
                setLoading(false)
                setButtonDisabled(false)
                if (res?.payload?.data?.token) {
                    const token = res?.payload?.data?.token
                    const refreshToken = res?.payload?.data?.refreshToken
                    const moduleData=res?.payload?.data?.moduleData 
                    dispatch(setModuleData(moduleData))
                    localStorage.setItem('token', token)
                    localStorage.setItem('refreshToken', refreshToken)
                    if(subscriptionPlan?.roleData?.roles == 'user'){
                        navigate("/auth/dashboard");
                    }else{
                        if (res?.payload?.data?.updated_by !== '') {
                            localStorage.setItem('tabs','owner')
                            let subscribe = localStorage.getItem('subscribe')
                            if(subscribe){
                                localStorage.setItem('tabs','organization')
                                navigate("/auth/settings");
                            }else {
                                navigate("/auth/dashboard");
                            }
                        } else {
                            navigate("/account-creation");
                        }
                    }
                } else {
                    setLoading(false)

                }
            }
        } catch (error) {
            setLoading(false)
            console.error("Error during signup:", error);
        }

    }
    const startTimer = async () => {
        setButtonDisabled(true);
        setLoading(true);
        setTimer(60);
        // setOtpValues(...otpValues,otpValues);
        const submitData = {
            "email": email,
            "password": password,
            "organization_code": organization_code,
        };
        const intervalId = setInterval(() => {
            setTimer(prevTimer => prevTimer - 1);
        }, 1000);
        setTimeout(() => {
            clearInterval(intervalId); // Stop the interval after 60 seconds
            setButtonDisabled(false);
            setLoading(false);
        }, 60000);
        const res = await dispatch(login(submitData));
        setLoading(false);
        // setOtpValues(Array(6).fill(""));
        if (res?.data?.status == true) {

            // setResponse(res?.data?.message, 'success');
        } else {
            // setResponse(res?.data?.message);
        }
    };
    //onclick change number
    const ChangeNumber = () => {
        navigate('/auth/settings')
    }

    return (
        <div className="verify-phone-number-page">
            <section className="vpn-sec">
                <div className="container">
                    <div className="sap-card">
                        <div className="sap-card-dialog">
                            {/* <HomeNavigate /> */}
                            <div className="sap-card-body">
                                <div className="alert alert-light sap-alert-heading">
                                    <div className="hd-title font-30">Verify your phone number</div>
                                    <div className="hd-subtitle font-16">Enter the 6-digit code we sent to {country_code}-{mobileShortCode}</div>
                                </div>
                                <form action="" className="vpn-form row" onSubmit={(e) => onSubmit(e)}>
                                    {
                                        Array.from({ length: 6 }, (_, index) => (
                                            <div className="col mb-3" key={index}>
                                                <input
                                                    key={index}
                                                    type="text"
                                                    ref={(el) => (inputsRef.current[index] = el)}
                                                    className="form-control form-control-lg text-center"
                                                    pattern="\d*"
                                                    maxLength="1"
                                                    placeholder="0"
                                                    onChange={handleInputChange(index)}
                                                    required
                                                />
                                            </div>
                                        ))
                                    }
                                    <div className="hd-subtitle font-16">Didn’t receive a code? {!buttonDisabled && !loading
                                        ? (
                                            <Link href="#" onClick={startTimer} className="sap-link">
                                                Click to try again
                                            </Link>) : (<span className="diable-link">Click to try again</span>)}</div>
                                    <div>{buttonDisabled ? <span className='msg'>Please wait :{timer} sec</span> : ''}</div>
                                    <div className="dual-btn">
                                        {checkToken ? <button type="button" class="btn btn-light" onClick={() => ChangeNumber()} disabled={changeNumber ? false : true}>Change phone number</button> : ''}
                                        <button type="submit" className="btn btn-primary" disabled={loading ? true : false}>{loading ? <ClipLoader color="#fff" size={28} /> : 'Verify code'}</button>
                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

const Verify_text_otp_Number = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const subscriptionPlan = useSelector(state => state?.suscription?.planInfo);
    const [otpValues, setOtpValues] = useState(Array(6).fill(""));
    const [timer, setTimer] = useState(0);
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [loading, setLoading] = useState(false);
    const [changeNumber, setChangeNumber] = useState(false);
    const inputsRef = useRef(Array(6).fill().map(() => React.createRef()));
    //get changed number
    const ChangedNumber = useSelector(state => state?.auth?.changedNumber)
    //check token
    const checkToken = useSelector(state => state?.auth?.token);
    const mobile = !checkToken ? ChangedNumber?.phone : subscriptionPlan?.number;
    console.log(checkToken, mobile,'mobilemobilemobile',ChangedNumber, subscriptionPlan);
    
    const country_code = ChangedNumber?.country_code
    const email = subscriptionPlan?.email;
    const organization_code = subscriptionPlan?.provider_code;
    const password = subscriptionPlan?.hash;
    const mobileShortCode = useMemo(() => { return mobile && mobile.length >= 5 ? `${mobile.slice(0, 3)}${'*'.repeat(mobile.length - 5)}${mobile.slice(-4)}` : '' }, [mobile]);
    const handleInputChange = index => e => {
        const { value } = e.target;
        const newValues = [...otpValues];
        newValues[index] = value;
        setOtpValues(newValues);
        const nextIndex = index + (value.length === 1 && index < 5 ? 1 : value.length === 0 && index > 0 ? -1 : 0);
        if (inputsRef.current[nextIndex]) {
            inputsRef.current[nextIndex].focus();
        }
    };
console.log(ChangedNumber,'ChangedNumberChangedNumber', subscriptionPlan);

    const onSubmit = async (e) => {
        setLoading(true)
        e.preventDefault();
        const changenumberWithouAuth = {
            "otp": otpValues?.toString()?.replaceAll(',', ""),
            "phone": ChangedNumber?.phone,
            "url_token": ChangedNumber?.url_token,
            "country_code": country_code
        }
        try {
            const verify_text_otp_number = await dispatch(Verify_text_otp_number(changenumberWithouAuth))
            setLoading(false)
            if (verify_text_otp_number?.payload?.data) {
                navigate(`/reset-password/${verify_text_otp_number?.payload?.data?.url_token}`)
            }
        } catch (error) {
            setLoading(false)
            console.error("Error during signup:", error);
        }

    }
    const startTimer = async () => {
        setButtonDisabled(true);
        setLoading(true);
        setTimer(60);
        setOtpValues('');
        const submitData = {
            "email": ChangedNumber?.form?.email
        };
        const intervalId = setInterval(() => {
            setTimer(prevTimer => prevTimer - 1);
        }, 1000);
        setTimeout(() => {
            clearInterval(intervalId); // Stop the interval after 60 seconds
            setButtonDisabled(false);
            setLoading(false);
        }, 60000);
        const res = await dispatch(ForgotPassword(submitData));
        setLoading(false);
        setOtpValues(Array(6).fill(""));
        if (res?.data?.status == true) {

            // setResponse(res?.data?.message, 'success');
        } else {
            // setResponse(res?.data?.message);
        }
    };
    //onclick change number
    const ChangeNumber = () => {
        navigate('/auth/settings')
    }
    console.log(country_code,'country_codecountry_codecountry_code');
    
    return (
        <div className="verify-phone-number-page">
            <section className="vpn-sec">
                <div className="container">
                    <div className="sap-card">
                        <div className="sap-card-dialog">
                            {/* <HomeNavigate /> */}
                            <div className="sap-card-body">
                                <div className="alert alert-light sap-alert-heading">
                                    <div className="hd-title font-30">Verify your phone number</div>
                                    <div className="hd-subtitle font-16">Enter the 6-digit code we sent to {country_code}-{mobileShortCode}</div>
                                </div>
                                <form action="" className="vpn-form row" onSubmit={(e) => onSubmit(e)}>
                                    {
                                        Array.from({ length: 6 }, (_, index) => (
                                            <div className="col mb-3" key={index}>
                                                <input
                                                    key={index}
                                                    type="text"
                                                    ref={(el) => (inputsRef.current[index] = el)}
                                                    className="form-control form-control-lg text-center"
                                                    pattern="\d*"
                                                    maxLength="1"
                                                    placeholder="0"
                                                    onChange={handleInputChange(index)}
                                                    required
                                                />
                                            </div>
                                        ))
                                    }
                                    <div className="hd-subtitle font-16">Didn’t receive a code? {!buttonDisabled && !loading
                                        ? (
                                            <Link href="#" onClick={startTimer} className="sap-link">
                                                Click to try again
                                            </Link>) : (<span className="diable-link">Click to try again</span>)}</div>
                                    <div>{buttonDisabled ? <span className='msg'>Please wait :{timer} sec</span> : ''}</div>
                                    <div className="dual-btn">
                                        {checkToken ? <button type="button" class="btn btn-light" onClick={() => ChangeNumber()} disabled={changeNumber ? false : true}>Change phone number</button> : ''}
                                        <button type="submit" className="btn btn-primary" disabled={loading ? true : false}>{loading ? <ClipLoader color="#fff" size={28} /> : 'Verify code'}</button>
                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}


export { VerifyPhone, Verify_text_otp_Number }
