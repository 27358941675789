import React, { useEffect, useState } from 'react'
import StepsProgressBar from '../StepsProgressBar'
import SummaryOfConversation from './SummaryOfConversation'
import MedicalSupports from './MedicalSupports'
import MedicalDentalBehavioral from './MedicalDentalBehavioral'
import AdditionalProvider from './AdditionalProvider'
import Medications from './Medications'
import VisionHearingSpeech from './VisionHearingSpeech'
import VisionHearingSpeechForm from './VisionHearingSpeechForm'
import MedicalSupplies from './MedicalSupplies'
import MedicalSuppliesForm from './MedicalSuppliesForm'

const PreferencesAndStrengths = ({ subStep, totalSubSteps, onHandleBackStep, onSubmit, isGoBack }) => {

  const renderSubStep = () => {
    switch (subStep) {
      case 0:
        return <SummaryOfConversation onHandleBackStep={onHandleBackStep} onSubmit={onSubmit} isGoBack={isGoBack} />
      case 1:
        return <MedicalSupports onHandleBackStep={onHandleBackStep} onSubmit={onSubmit} isGoBack={isGoBack} />
      case 2:
        return <MedicalDentalBehavioral onHandleBackStep={onHandleBackStep} onSubmit={onSubmit} isGoBack={isGoBack} />
      case 3:
        return <AdditionalProvider onHandleBackStep={onHandleBackStep} onSubmit={onSubmit} isGoBack={isGoBack} />
      case 4:
        return <Medications onHandleBackStep={onHandleBackStep} onSubmit={onSubmit} isGoBack={isGoBack} />
      case 5:
        return <VisionHearingSpeech onHandleBackStep={onHandleBackStep} onSubmit={onSubmit} isGoBack={isGoBack} />
      case 6:
        return <VisionHearingSpeechForm onHandleBackStep={onHandleBackStep} onSubmit={onSubmit} isGoBack={isGoBack} />
      case 7:
        return <MedicalSupplies onHandleBackStep={onHandleBackStep} onSubmit={onSubmit} isGoBack={isGoBack} />
      case 8:
        return <MedicalSuppliesForm onHandleBackStep={onHandleBackStep} onSubmit={onSubmit} isGoBack={isGoBack} />
      default:
        return <SummaryOfConversation onHandleBackStep={onHandleBackStep} onSubmit={onSubmit} isGoBack={isGoBack} />
    }
  }

  return (
    <>
      <div className="tab-pane fade strenght_stepthree_tab show active">
        <StepsProgressBar
          currentSubStep={subStep + 1}
          totalSubSteps={totalSubSteps}
        />
        {renderSubStep()}
      </div>
    </>
  )
}

export default PreferencesAndStrengths