import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import StepsProgressBar from '../StepsProgressBar'
import HealthAndMedicalRisks from './HealthAndMedicalRisks'
import SafetyAndSelfHelpRisks from './SafetyAndSelfHelpRisks'
import MentalHealthAndLifeRisk from './MentalHealthAndLifeRisk'
import FinancialRisks from './FinancialRisks'
import { get_all_type_risk } from '../../../../../../redux/services/AuthMembers';

const StateOfRisk = [
  { value: "EM", lable: "EM (Effectively Managed)" },
  { value: "FA", lable: "FA (Further Assessment)" },
  { value: "RR", lable: "RR (Rights Restricted)" },
  { value: "MRA", lable: "MRA (Managed Risk Agreement)" },
]
const IdentificationOfRisks = ({ step, subStep, totalSubSteps, onHandleBackStep, onSubmit }) => {
  const dispatch = useDispatch();

useEffect(() => {
  dispatch(get_all_type_risk())
}, [])

  const renderSubStep = () => {
    switch (subStep) {
      case 0:
        return <HealthAndMedicalRisks onHandleBackStep={onHandleBackStep} onSubmit={onSubmit} step={step} subStep={subStep} StateOfRisk={StateOfRisk}/>
      case 1:
        return <SafetyAndSelfHelpRisks onHandleBackStep={onHandleBackStep} onSubmit={onSubmit} step={step} subStep={subStep} StateOfRisk={StateOfRisk} />
      case 2:
        return <MentalHealthAndLifeRisk onHandleBackStep={onHandleBackStep} onSubmit={onSubmit} step={step} subStep={subStep} StateOfRisk={StateOfRisk} />
      case 3:
        return <FinancialRisks onHandleBackStep={onHandleBackStep} onSubmit={onSubmit} step={step} subStep={subStep} StateOfRisk={StateOfRisk} />
      default:
        return <HealthAndMedicalRisks onHandleBackStep={onHandleBackStep} onSubmit={onSubmit} step={step} subStep={subStep} StateOfRisk={StateOfRisk} />
    }
  }

  return (
    <div className="tab-pane fade  goal_tab show active">
      <StepsProgressBar
        currentSubStep={subStep + 1}
        totalSubSteps={totalSubSteps}
      />
      {renderSubStep()}
    </div>
  )
}

export default IdentificationOfRisks
