import React from 'react'

const Footer = () => {
  return (
    <div>
      {/* <h5>footer!</h5> */}
    </div>
  )
}

export default Footer
