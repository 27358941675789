import React, { useEffect, useState } from 'react'
import { useForm, useFieldArray } from 'react-hook-form';
import { useSelector } from 'react-redux';
import PcspFooter from '../../PcspFooter'

const InformedConsent = ({ onHandleBackStep, onSubmit }) => {
  const allDddPcsp = useSelector(state => state?.AuthMember?.allDddPcsp?.informedconcent);
  const [allImages, setAllImages] = useState([])
  // Initialize the form with useForm hook
  const { control, handleSubmit, register, getValues, setValue, formState: { errors } } = useForm({
    defaultValues: {
      informedConcepts: [{ person_name: "", signature_image: "", agency_relationship_name: "", date: "" }],
    }
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'informedConcepts'
  });


  useEffect(() => {
    const lengthDifference = allDddPcsp?.length - fields.length;
    let images = []
    if (lengthDifference > 0) {
      for (let i = 0; i < lengthDifference; i++) {
        append({});
      }
    }
    allDddPcsp?.forEach((item, index) => {
      setValue(`informedConcepts.${index}.person_name`, item.person_name || '');
      setValue(`informedConcepts.${index}.agency_relationship_name`, item.agency_relationship_name || '');
      setValue(`informedConcepts.${index}.date`, item.date || '');
      setValue(`informedConcepts.${index}.signature_image_body`, item.signature_image || '');
      images.push(item.signature_image)
    });
    setAllImages(images)
  }, [allDddPcsp])

  const handleImage = (e, index) => {
    const { files } = e.target;
    let newImages = [...allImages];
  
    if (files && files.length) {
      const selectedFile = files[0];
      const newImageUrl = URL.createObjectURL(selectedFile);
  
      // Update the specific image in the array at the given index
      newImages[index] = newImageUrl;
      setAllImages(newImages);
  
      // Update the form value with the new file
      setValue(`informedConcepts.${index}.signature_image`, selectedFile);
      setValue(`informedConcepts.${index}.signature_image_body`, null);
    }
  };  
  return (
    <div className="tab-pane fade  goal_tab show active">
      <form onSubmit={handleSubmit(onSubmit)} className="row" enctype="multipart/form-data">
        <div className="col-12">
          <div className="alert alert-light sap-alert-heading">
            <div className="hd-subtitle font-16">Documentation must reflect the individuals responsible for monitoring
              the PCSP. Action plan items should focus on measurable steps that will need to be taken to reach
              desired outcomes in the member’s life. These items may be related to a member’s goals or other areas
              that need to be addressed and followed up on.</div>
          </div>
        </div>

        {fields.map((member, index) => (
          <div key={member.id} className="row">
            {index > 0 && (
                <hr/>
            )}
            <div className="col-md-6 mb-3">
              <label for="" className="form-label">Name*</label>
              <input
                type="input"
                className="form-control"
                placeholder="Enter Action to be taken"
                {...register(`informedConcepts.${index}.person_name`, { required: "This field is required!" })}
                maxLength={20}
              />
              {errors.informedConcepts && errors.informedConcepts[index]?.person_name && (
                <span className="error">{errors.informedConcepts[index].person_name.message}</span>
              )}
            </div>
            <div className="col-md-6 mb-3">
              <label for="" className="form-label">Name of agency/relationship*</label>
              <input
                type="input"
                className="form-control"
                placeholder="Enter Action to be taken"
                {...register(`informedConcepts.${index}.agency_relationship_name`, { required: "This field is required!" })}
                maxLength={20}
              />
              {errors.informedConcepts && errors.informedConcepts[index]?.agency_relationship_name && (
                <span className="error">{errors.informedConcepts[index].agency_relationship_name.message}</span>
              )}
            </div>
            <div className="col-md-6 mb-3">
              <label for="" className="form-label">Date*</label>
              <input type="date" className="form-control" id="" placeholder="jan 6, 2024" {...register(`informedConcepts.${index}.date`, { required: "true", })} onKeyDown={(e) => e.preventDefault()} />
              {errors.informedConcepts && errors.informedConcepts[index]?.date && (
                <span className="error">{errors.informedConcepts[index].date.message}</span>
              )}
            </div>
            <div className="col-md-6 mb-3">
              <label for="" className="form-label">Signature*</label>
              <input
                type="file"
                className="form-control"
                onChange={(e) => handleImage(e, index)}
                accept="image/png, image/jpeg"
                onKeyDown={(e) => e.preventDefault()}
              />
              {errors.informedConcepts && errors.informedConcepts[index]?.signature_image && (
                <span className="error">{errors.informedConcepts[index].signature_image.message}</span>
              )}
              {allImages && allImages.length > 0 &&
              <div className="imageDisplay">
                <img src={allImages[index]} />
                </div>
              }
            </div>
            {index > 0 && (
              <div className="col-12 mb-3">
                <a
                  href="#"
                  className="stepone_remove"
                  onClick={(e) => {
                    e.preventDefault();
                    remove(index);
                  }}
                >
                  Remove Task
                </a>
              </div>
            )}

          </div>
        ))}
        <div className="stepone_health_planbtn">
          <button type="button" className="font-14" onClick={() => append({ id: Date.now() })}><svg width="12" height="12" viewBox="0 0 12 12" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M6.00016 1.33325V10.6666M1.3335 5.99992H10.6668" stroke="#667085" stroke-width="2"
              stroke-linecap="round" stroke-linejoin="round"></path>
          </svg>
            Add another health plan</button>
        </div>
        <PcspFooter onHandleBackStep={onHandleBackStep} />
      </form>
    </div>
  )
}

export default InformedConsent