import React from 'react'
import Modal from 'react-modal';
import { get_home_assign_members, Delete_multiple_member_home } from '../../../redux/services/AuthHomes';
import { useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
const DeleteMember = ({setDeleteModal, deleteModal, selectedUsers, search, setSelectedUsers, setChecked}) => {
    const dispatch=useDispatch();
    const navigate=useNavigate();
    const id = useParams();
    const customModalStyles = {
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 1000
        },
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            border: 'none',
            maxWidth: '750px',
            // width: '100%',
            background: '#fff',
            borderRadius: '8px',
            padding: '20px',
            height: 'auto',
            zIndex: '99',
            transition: 'all 1s ease-in-out'
        }
    };
    const onSubmit = async() => {
        const payload = {
            home_table_id: id.id,
            memberIds: selectedUsers
        };
      const response= await dispatch(Delete_multiple_member_home(payload));      
    //    if(response?.payload?.status==true){
        setSelectedUsers([])
        setChecked(false)
        dispatch(get_home_assign_members(search))
        setDeleteModal(false);
    //    }
    }
    return (
        <Modal
            isOpen={deleteModal}
            onRequestClose={() => setDeleteModal(false)}
            style={customModalStyles}
            contentLabel="Create Role Modal"
        >
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header close-deactivate">
                        <button type="button" class="btn-close close-deactivate" onClick={()=>setDeleteModal(false)}></button>
                    </div>
                    <div class="modal-body">
                        <div class="font-18 hd-title">Delete member(s)</div>
                        <div class="hd-subtitle font-14">Are you sure you want to delete this member(s)? This action cannot be undone.</div>

                        <div class="pop-action-btn">
                            <button type="button" class="btn btn-light" onClick={()=>setDeleteModal(false)}>Cancel</button>
                            <button type="button" class="btn btn-danger mx-2" onClick={()=>onSubmit()}>Delete</button>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default DeleteMember