
import React, { useEffect } from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import { useSelector } from 'react-redux';
import Footer from '../../Footer'

const ImmunizationsAllergiesSeizures = ({ onHandleBackStep, onSubmit }) => {
  const allMedicalHistory = useSelector((state) => state?.AuthMember?.allMedicalHistory?.medicalHistoryAllergie);
  // Initialize the form with useForm hook
  const { control, handleSubmit, register, setValue, formState: { errors } } = useForm({
    defaultValues: {
      historyAllergiesTest: [{ test: "", result: "", date_issue: "" }],
    }
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'historyAllergiesTest'
  });


  useEffect(() => {
    const lengthDifference = allMedicalHistory?.membersMedicalHistoryAllergiesTest?.length - fields.length;
        if (lengthDifference > 0) {
            for (let i = 0; i < lengthDifference; i++) {
                append({});
            }
        }
        allMedicalHistory?.membersMedicalHistoryAllergiesTest.forEach((item, index) => {
            setValue(`historyAllergiesTest.${index}.test`, item.test || '');
            setValue(`historyAllergiesTest.${index}.result`, item.result || '');
            setValue(`historyAllergiesTest.${index}.date_issue`, item.date_issue || '');
        });
    setValue('alergy_history', allMedicalHistory?.alergy_history || '');
    setValue('alergy_history_text', allMedicalHistory?.alergy_history_text || '');
    setValue('seizures_history', allMedicalHistory?.seizures_history || '');
    setValue('seizures_history_text', allMedicalHistory?.seizures_history_text || '');
  }, [allMedicalHistory])

  return (
    <div className="tab-pane fade  goal_tab show active">
      <form onSubmit={handleSubmit(onSubmit)} className="row">
        <div className="col-12">
          <div className="alert alert-light sap-alert-heading">
            <div className="hd-subtitle font-24">Immunizations</div>
            <div className="hd-subtitle font-16">Most current dates listed, as applicable or information was not available</div>
          </div>
        </div>
        {fields.map((member, index) => (
          <div key={member.id} className="row">
            {index > 0 && (
                <hr/>
            )}
            <div className="col-12">
              <label for="" className="form-label">Test*</label>
              <select
                className="form-select"
                aria-label="Default select example"
                {...register(`historyAllergiesTest.${index}.test`, { required: "Test is required!" })}>
                <option value="">Select</option>
                <option value="1">Tuberclosis</option>
                <option value="0">No</option>
              </select>
              {errors.historyAllergiesTest && errors.historyAllergiesTest[index]?.test && (
                <span className="error">{errors.historyAllergiesTest[index].test.message}</span>
              )}
            </div>
            <div className="col-12">
              <label for="" className="form-label">Result*</label>
              <input
                type="input"
                className="form-control"
                placeholder="Enter Result"
                {...register(`historyAllergiesTest.${index}.result`, { required: "Result is required!" })}
                maxLength={50}
              />
              {errors.historyAllergiesTest && errors.historyAllergiesTest[index]?.result && (
                <span className="error">{errors.historyAllergiesTest[index].result.message}</span>
              )}
            </div>
            <div className="col-12 mb-3">
              <label for="" className="form-label">Date issued*</label>

              <input type="date" className="form-control" id="" placeholder="Enter Date issued" {...register(`historyAllergiesTest.${index}.date_issue`, { required: "Date issued is required", })} onKeyDown={(e) => e.preventDefault()} />
              {errors.historyAllergiesTest && errors.historyAllergiesTest[index]?.date_issue && (
                <span className="error">{errors.historyAllergiesTest[index].date_issue.message}</span>
              )}
            </div>
            {index > 0 && (
              <div className="col-12 mb-3">
                <a
                  href="#"
                  className="stepone_remove"
                  onClick={(e) => {
                    e.preventDefault();
                    remove(index);
                  }}
                >
                  Remove
                </a>
              </div>
            )}
          </div>
        ))}
        <div className="stepone_health_planbtn">
          <button
            type="button"
            className="font-14"
            onClick={() =>
              append({ id: Date.now() }) // Add a new member with a unique ID
            }
          >
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6.00016 1.33325V10.6666M1.3335 5.99992H10.6668" stroke="#667085" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
            </svg>
            Add another test
          </button>
        </div>
        <div className="col-12">
          <div className="alert alert-light sap-alert-heading">
            <div className="hd-subtitle font-24">Allergies</div>
          </div>
        </div>
        <div className="col-12 mb-3">
          <label className="form-label">History of allergies*</label>
          <select
            className="form-select"
            aria-label="Default select example"
            {...register('alergy_history', { required: "History of allergies is required" })}
          >
            <option value="">Select</option>
            <option value="0">No</option>
            <option value="1">Yes</option>
            <option value="2">N/A</option>
          </select>
          {errors.alergy_history && (
            <span className="error">{errors.alergy_history.message}</span>
          )}
        </div>
        <div className="col-12 mb-3">
          <label for="" className="form-label">If yes, specify*</label>
          <textarea className="form-control" placeholder="Enter If yes, specify" {...register("alergy_history_text", { required: "true" })} maxLength={150} />
          {errors.alergy_history_text && errors.alergy_history_text.type === "required" && (<span className="error">This is required!</span>)}
        </div>

        <div className="col-12">
          <div className="alert alert-light sap-alert-heading">
            <div className="hd-subtitle font-24">Seizures</div>
          </div>
        </div>
        <div className="col-12 mb-3">
          <label className="form-label">History of seizure activity*</label>
          <select
            className="form-select"
            aria-label="Default select example"
            {...register('seizures_history', { required: "History of seizure activity is required" })}
          >
            <option value="">Select</option>
            <option value="0">No</option>
            <option value="1">Yes</option>
            <option value="2">N/A</option>
          </select>
          {errors.seizures_history && (
            <span className="error">{errors.seizures_history.message}</span>
          )}
        </div>
        <div className="col-12 mb-3">
          <label for="" className="form-label">If yes, indicate type & frequency or date of the most recent*</label>
          <textarea className="form-control" placeholder="Enter If yes, specify" {...register("seizures_history_text", { required: "true" })} maxLength={150} />
          {errors.seizures_history_text && errors.seizures_history_text.type === "required" && (<span className="error">This is required!</span>)}
        </div>
        <Footer onHandleBackStep={onHandleBackStep} />
      </form>
    </div>
  )
}

export default ImmunizationsAllergiesSeizures