import React, { useEffect, useState } from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import { useSelector } from 'react-redux';
import Footer from '../../Footer'


const BehaviorModifyingMedicationConsentForm = ({ onHandleBackStep, onSubmit }) => {

  const allBehaviourModifyingMedication = useSelector((state) => state?.AuthMember?.allBehaviourModifyingMedication);
  // Initialize the form with useForm hook
  const { control, handleSubmit, register, setValue, formState: { errors } } = useForm({
    defaultValues: {
      medicationConsent: [{ medication: "", daily_maximum_dosage: "", prescribing_medication: "", expected_benefits: "", probable_side_effects: "", physicians_name: "" }],
    }
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'medicationConsent'
  });

  useEffect(() => {
    const lengthDifference = allBehaviourModifyingMedication?.length - fields.length;
    if (lengthDifference > 0) {
      for (let i = 0; i < lengthDifference; i++) {
        append({});
      }
    }
    allBehaviourModifyingMedication.forEach((item, index) => {
      setValue(`medicationConsent.${index}.medication`, item.medication || '');
      setValue(`medicationConsent.${index}.daily_maximum_dosage`, item.daily_maximum_dosage || '');
      setValue(`medicationConsent.${index}.prescribing_medication`, item.prescribing_medication || '');
      setValue(`medicationConsent.${index}.expected_benefits`, item.expected_benefits || '');
      setValue(`medicationConsent.${index}.probable_side_effects`, item.probable_side_effects || '');
      setValue(`medicationConsent.${index}.physicians_name`, item.physicians_name || '');
    });
  }, [allBehaviourModifyingMedication])


  return (
    <div className="tab-pane fade  goal_tab show active">
      <form onSubmit={handleSubmit(onSubmit)} className="row">
        <div className="col-12  mb-3">
          <div className="alert alert-light sap-alert-heading">
            <div className="hd-subtitle font-24">Behavior modifying medication consent</div>
          </div>
        </div>
        {fields.map((member, index) => (
          <div key={member.id} className="row">
            {index > 0 && (
                <hr/>
            )}
            <div className="col-12  mb-3">
              <label for="" className="form-label">Medication*</label>
              <input
                type="input"
                className="form-control"
                placeholder="Enter Medication"
                {...register(`medicationConsent.${index}.medication`, { required: "Medication is required!" })}
                maxLength={50}
              />
              {errors.medicationConsent && errors.medicationConsent[index]?.medication && (
                <span className="error">{errors.medicationConsent[index].medication.message}</span>
              )}
            </div>
            <div className="col-12  mb-3">
              <label for="" className="form-label">Daily maximum dosage*</label>
              <input
                type="input"
                className="form-control"
                placeholder="Enter Daily maximum dosage"
                {...register(`medicationConsent.${index}.daily_maximum_dosage`, { required: "Daily maximum dosage is required!" })}
                maxLength={50}
              />
              {errors.medicationConsent && errors.medicationConsent[index]?.daily_maximum_dosage && (
                <span className="error">{errors.medicationConsent[index].daily_maximum_dosage.message}</span>
              )}
            </div>
            <div className="col-12 mb-3">
              <label for="" className="form-label">Reason(s) for prescribing the medication*</label>
              <textarea className="form-control" placeholder="Enter Reason(s) for prescribing the medication" {...register(`medicationConsent.${index}.prescribing_medication`, { required: "This is required" })} maxLength={150} />
              {errors.medicationConsent && errors.medicationConsent[index]?.prescribing_medication && (<span className="error">{errors.medicationConsent[index].prescribing_medication.message}</span>)}
            </div>
            <div className="col-12 mb-3">
              <label for="" className="form-label">Expected benefits*</label>
              <textarea className="form-control" placeholder="Enter Expected benefits" {...register(`medicationConsent.${index}.expected_benefits`, { required: "This is required" })} maxLength={150} />
              {errors.medicationConsent && errors.medicationConsent[index]?.expected_benefits && (<span className="error">{errors.medicationConsent[index].expected_benefits.message}</span>)}
            </div>
            <div className="col-12 mb-3">
              <label for="" className="form-label">Probable side effects*</label>
              <textarea className="form-control" placeholder="Enter Probable side effects" {...register(`medicationConsent.${index}.probable_side_effects`, { required: "This is required" })} maxLength={150} />
              {errors.medicationConsent && errors.medicationConsent[index]?.probable_side_effects && (<span className="error">{errors.medicationConsent[index].probable_side_effects.message}</span>)}
            </div>
            <div className="col-12  mb-3">
              <label for="" className="form-label">Physicians name and title*</label>
              <input
                type="input"
                className="form-control"
                placeholder="Enter Physicians name and title"
                {...register(`medicationConsent.${index}.physicians_name`, { required: "Physicians name and title is required!" })}
                maxLength={50}
              />
              {errors.medicationConsent && errors.medicationConsent[index]?.physicians_name && (
                <span className="error">{errors.medicationConsent[index].physicians_name.message}</span>
              )}
            </div>
            {index > 0 && (
              <div className="col-12 mb-3">
                <a
                  href="#"
                  className="stepone_remove"
                  onClick={(e) => {
                    e.preventDefault();
                    remove(index);
                  }}
                >
                  Remove
                </a>
              </div>
            )}
          </div>
        ))}
        <div className="stepone_health_planbtn">
          <button
            type="button"
            className="font-14"
            onClick={() =>
              append({ id: Date.now() }) // Add a new member with a unique ID
            }
          >
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6.00016 1.33325V10.6666M1.3335 5.99992H10.6668" stroke="#667085" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
            </svg>
            Add another medication
          </button>
        </div>

        <Footer onHandleBackStep={onHandleBackStep} />
      </form>
    </div>
  )
}

export default BehaviorModifyingMedicationConsentForm
