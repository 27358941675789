import React, { useEffect, useState } from "react";
import { formatAMPM, monthDayYearFormat } from '../../../components/Common/Common';

const OpenMarTable = ({ tableData, currentMedi, TotalMed }) => {
    // State to hold unique administration times
    const [time, setTime] = useState([]);

    // Effect to extract unique administer times from tableData
    useEffect(() => {
        // Check if membersMarDetailTimeDay exists and has items
        if (tableData?.membersMarDetailTimeDay?.length) {
            // Create an array of unique administer times
            const uniqueTimes = Array.from(new Set(
                tableData.membersMarDetailTimeDay.map(item => item.administer_time)
            ));
            // Update the state with unique times
            setTime(uniqueTimes);
        }
    }, [tableData]); // Runs when tableData changes

    // Early return if tableData is not available
    if (!tableData) return null;

    // Destructure necessary properties from tableData for easier access
    const {
        masterMedication,
        masterDoesForm,
        frequency,
        adminis_start_date,
        strength,
        masterCodingSystem,
        user,
        approval_comment,
    } = tableData;

    return (
        <div className="mar_medi_record">
            <div className="open_mar_tbl">
                <div className="tbl_num_mar">
                    {/* Header showing medication name and current/total medications */}
                    <h2>{`${masterMedication?.name} - ${masterDoesForm?.name}, Scheduled (Medication)`}</h2>
                    <span>{`${currentMedi}/${TotalMed}`}</span>
                </div>
                <div className="row">
                    <div className="col-md-12 col-12">
                        <div className="table-sec">
                            <div className="table-responsive">
                                <table className="table table-hover">
                                    <thead>
                                        <tr>
                                            <th scope="col">Given Amount/Quantity</th>
                                            <th scope="col">Frequency</th>
                                            <th scope="col">Start Date & Time</th>
                                            <th scope="col">Schedule Repeat</th>
                                            <th scope="col">Schedule Time Slot(s)</th>
                                            <th scope="col">Strength</th>
                                            <th scope="col">Approved by</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            {/* Populate table cells with data */}
                                            <td>{`${masterMedication?.name} ${masterDoesForm?.name}`}</td>
                                            <td>{`${frequency} times a day`}</td>
                                            <td>{monthDayYearFormat(adminis_start_date, true)}</td>
                                            <td>{`Every Day ${frequency} time(s) a day`}</td>
                                            {/* Render schedule times, ensuring each has a unique key */}
                                            <td>
                                                {time.length > 0 && time.map((item, index) => (
                                                    <span key={index}>
                                                        {formatAMPM(item)}{index < time.length - 1 && ','} 
                                                    </span>
                                                ))}
                                            </td>
                                            <td>{`${strength} ${masterCodingSystem?.name}`}</td>
                                            <td>{`${user?.first_name} ${user?.last_name}`}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="open_inst">
                <h4>Instruction</h4>
                {/* Display instruction or 'N/A' if not available */}
                <p>{approval_comment || 'N/A'}</p>
            </div>
        </div>
    );
};

export default OpenMarTable;
