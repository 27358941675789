import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Footer from '../../Footer';
import { get_all_medical_disability, get_all_medical_diseases } from '../../../../../../redux/services/AuthMembers';

const MedicalHistoryForm = ({ onHandleBackStep, onSubmit }) => {
  const dispatch = useDispatch()
  const [checkedDevelopmentalDisabilityIds, setCheckedDevelopmentalDisabilityIds] = useState([]);
  const [checkedFamilyMedicalHistoryIds, setCheckedFamilyMedicalHistoryIds] = useState([]);
  const allMedicalHistory = useSelector((state) => state?.AuthMember?.allMedicalHistory?.medicalHistory);
  const MedicalDisability = useSelector((state) => state?.AuthMember?.allMedicalHistory?.medicalHistory?.membersMedicalHistoryDevelopmentDisability);
  const MedicalDiseases = useSelector((state) => state?.AuthMember?.allMedicalHistory?.medicalHistory?.membersMedicalHistoryFamilymedHistory);
  const allMedicalDisability = useSelector(state => state?.AuthMember?.allMedicalDisability);
  const allMedicalDiseases = useSelector(state => state?.AuthMember?.allMedicalDiseases);

  const { control, handleSubmit, register, setValue, formState: { errors } } = useForm();

  useEffect(() => {
    dispatch(get_all_medical_disability())
    dispatch(get_all_medical_diseases())
  }, [])

  useEffect(() => {
    if (MedicalDisability && MedicalDisability.length > 0) {
      let Ids = []
      MedicalDisability.map((item) => {
        Ids.push(item.development_disability_id)
      })
      setCheckedDevelopmentalDisabilityIds(Ids)
    }
    
    if (MedicalDiseases && MedicalDiseases.length > 0) {
      let Ids = []
      MedicalDiseases.map((item) => {
        Ids.push(item.master_famhistory_id)
      })
      setCheckedFamilyMedicalHistoryIds(Ids)
    }
  }, [MedicalDisability, MedicalDiseases])

  useEffect(() => {
    setValue('other_development_disability', allMedicalHistory?.other_development_disability || '');
    setValue('development_history', allMedicalHistory?.development_history || '');
    setValue('developmental_disability', allMedicalHistory?.MedicalDisability || '');
    setValue('medicalHistory', allMedicalHistory?.MedicalDiseases || '');
  }, [allMedicalHistory, setValue]);

  const handleDevelopmentalDisabilityChange = (id) => {
    let ids = [...checkedDevelopmentalDisabilityIds];
    if (ids.includes(id)) {
      ids = ids.filter((item) => item !== id);
    } else {
      ids = [...ids, id];
    }
    setCheckedDevelopmentalDisabilityIds(ids);
    setValue('developmental_disability', ids);
  };

  const handleFamilyMedicalHistoryChange = (id) => {
    let ids = [...checkedFamilyMedicalHistoryIds];
    if (ids.includes(id)) {
      ids = ids.filter((item) => item !== id);
    } else {
      ids = [...ids, id];
    }
    setCheckedFamilyMedicalHistoryIds(ids);
    setValue('medicalHistory', ids);
  };


  return (
    <div className="tab-pane fade goal_tab show active">
      <form onSubmit={handleSubmit(onSubmit)} className="row">
        <div className="col-12">
          <label className="form-label">Developmental disability*</label>
          <div className="date-slot activity-sec">
            <ul className="btn-group" role="group" aria-label="Basic checkbox toggle button group">
              {allMedicalDisability && allMedicalDisability.map((item, index) => (
                <li className="list-group-item" key={item.id}>
                  <input
                    type="checkbox"
                    className="btn-check"
                    id={`btncheckDev${index}`}
                    autoComplete="off"
                    value={item.id}
                    onChange={() => handleDevelopmentalDisabilityChange(item.id)}
                    checked={checkedDevelopmentalDisabilityIds.includes(item.id)}
                  />
                  <label className="btn btn-outline-primary" htmlFor={`btncheckDev${index}`}>
                    {item.name}
                  </label>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="col-12 mb-3">
          <label className="form-label">Other (Specify other developmental disability)*</label>
          <input
            type="input"
            className="form-control"
            placeholder="Enter Other"
            {...register('other_development_disability', { required: 'Other is required!' })}
            maxLength={50}
          />
          {errors.other_development_disability && <span className="error">{errors.other_development_disability.message}</span>}
        </div>
        <div className="col-12 mb-3">
          <label className="form-label">Developmental history (Not required if over 18 years of age)*</label>
          <textarea
            className="form-control"
            placeholder="Enter Developmental history (Not required if over 18 years of age)"
            {...register('development_history', { required: true })}
            maxLength={150}
          />
          {errors.development_history && errors.development_history.type === 'required' && (
            <span className="error">This is required!</span>
          )}
        </div>
        <div className="col-12">
          <label className="form-label">Family medical history*</label>
          <div className="date-slot activity-sec">
            <ul className="btn-group" role="group" aria-label="Basic checkbox toggle button group">
              {allMedicalDiseases && allMedicalDiseases.map((item, index) => (
                <li className="list-group-item" key={item.id}>
                  <input
                    type="checkbox"
                    className="btn-check"
                    id={`btncheckFam${index}`}
                    autoComplete="off"
                    value={item.id}
                    onChange={() => handleFamilyMedicalHistoryChange(item.id)}
                    checked={checkedFamilyMedicalHistoryIds.includes(item.id)}
                  />
                  <label className="btn btn-outline-primary" htmlFor={`btncheckFam${index}`}>
                    {item.name}
                  </label>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <Footer onHandleBackStep={onHandleBackStep} />
      </form>
    </div>
  );
};

export default MedicalHistoryForm;
