import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import StepsProgressBar from '../StepsProgressBar'
import AllergiesSideEffects from './AllergiesSideEffects'
import Seizures from './Seizures'
import MedicalMonitoring from './MedicalMonitoring'
import VisionAids from './VisionAids'

const HealthMedical = ({ step, subStep, totalSubSteps, onHandleBackStep, onSubmit }) => {
  const renderSubStep = () => {
    switch (subStep) {
      case 0:
        return <AllergiesSideEffects onHandleBackStep={onHandleBackStep} onSubmit={onSubmit} step={step} subStep={subStep}/>
      case 1:
        return <Seizures onHandleBackStep={onHandleBackStep} onSubmit={onSubmit} step={step} subStep={subStep} />
      case 2:
        return <MedicalMonitoring onHandleBackStep={onHandleBackStep} onSubmit={onSubmit} step={step} subStep={subStep} />
      case 3:
        return <VisionAids onHandleBackStep={onHandleBackStep} onSubmit={onSubmit} step={step} subStep={subStep} />
      default:
        return <AllergiesSideEffects onHandleBackStep={onHandleBackStep} onSubmit={onSubmit} step={step} subStep={subStep} />
    }
  }

  return (
    <div className="tab-pane fade  goal_tab show active">
    <StepsProgressBar
      currentSubStep={subStep + 1}
      totalSubSteps={totalSubSteps}
    />
    {renderSubStep()}
  </div>
  )
}

export default HealthMedical