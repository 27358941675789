import { createSlice } from "@reduxjs/toolkit";
import { Create_task, get_task_board_view, edit_task, destroy_task, Task_status_change, get_task_table_view, get_all_admin_home_users } from "../services/AuthTasks";

const initialState = {
    isLoading: false,
    error: null,
    BoardViewTasks:[],
    ListViewTasks:[],
    EditTasksData:{},
    AllUsers:[],
}
const AuthTasks = createSlice({
    name: 'AuthTasks',
    initialState,
    reducers: {
        reSetEditTasksData: (state, action) => {
            state.EditTasksData= {}
          },
    },
    extraReducers: (builder) => {
       
        // ==========Create_task ============== //
        builder.addCase(Create_task.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(Create_task.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
        });
        builder.addCase(Create_task.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
        // ==========get_task_board_view ============== //
        builder.addCase(get_task_board_view.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(get_task_board_view.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
            state.BoardViewTasks = action?.payload?.data
        });
        builder.addCase(get_task_board_view.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
        // ==========edit_task ============== //
        builder.addCase(edit_task.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(edit_task.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
            state.EditTasksData = action?.payload?.data
        });
        builder.addCase(edit_task.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
        // ==========destroy_task ============== //
        builder.addCase(destroy_task.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(destroy_task.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
        });
        builder.addCase(destroy_task.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
        // ==========Task_status_change ============== //
        builder.addCase(Task_status_change.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(Task_status_change.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
        });
        builder.addCase(Task_status_change.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
        // ==========get_task_table_view ============== //
        builder.addCase(get_task_table_view.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(get_task_table_view.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
            state.ListViewTasks = action?.payload?.data
        });
        builder.addCase(get_task_table_view.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
         // ==========get_all_admin_home_users ============== //
         builder.addCase(get_all_admin_home_users.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(get_all_admin_home_users.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
            state.AllUsers = action?.payload?.data
        });
        builder.addCase(get_all_admin_home_users.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
    }
});
// Export the synchronous action
export const { reSetEditTasksData } = AuthTasks.actions;
export default AuthTasks?.reducer;