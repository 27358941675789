import React, { useEffect, useState } from "react";
import LineChart from '../Charts/LineChart';
import RedLineChart from '../Charts/RedLineChart';
import { useSelector } from 'react-redux';
import {HanburgerSvg, DecreaseSvg, IncreaseSvg} from '../../../Images/ComonSvgs'

const MembersChart = () => {

    const MembersData = useSelector(state => state?.AuthDashboard?.DashboardData?.members?.monthCount
    );
    const TotalMembers = useSelector(state => state?.AuthDashboard?.DashboardData?.members?.count);
    const percentage = useSelector(state => state?.AuthDashboard?.DashboardData?.members?.percentage);

    const [increase, setIncrease] = useState(false)

    useEffect(()=>{
        if(percentage === 0){
            setIncrease(true)
        }
        if(percentage > 0){
            setIncrease(true)
        }
        if(percentage < 0){
            setIncrease(false)
        }
    },[percentage])

    return(
        <div className="col-md-4 col-12">
          <div className="graph-content">
            <div className="graph-header">
              <div className="font-16 hd-title">Members</div>
              {/* <a href="#">
                <HanburgerSvg/>
              </a> */}
            </div>
            <div className="g-content-box">
              <div className="content-box">
                <div className="font-30 hd-title">{TotalMembers || 0}</div>
                <div className="progrss-sec">
                  {increase ? <IncreaseSvg /> : <DecreaseSvg />}
                  <p><span className={`${increase ? '' : 'red'}`}>{Math.abs(percentage)}%</span> last mth</p>
                </div>
              </div>
              <div className="content-box">
              {increase ?
            <LineChart data = {MembersData} id='member_chart'/>
            :
            <RedLineChart data={MembersData}/>
            }
              </div>
            </div>

          </div>
        </div>
    )
}
export default MembersChart