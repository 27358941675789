import React, { useState, useEffect } from "react";


const HeaderHomes = ({ search, setSearch, handleSearchBar, searchCapacity }) => {

    const [showFilter, setShowFilter] = useState(false);
    const [showSearch, setShowSearch] = useState(false);
    const [filtersCleared, setFiltersCleared] = useState(false);
    const [filter, setFilter] = useState({
        startMaxCap: '',
        endMaxCap: '',
        startNoCap: '',
        endNoCap: '',
        startNoUser: '',
        endNoUser: ''
    })


    useEffect(() => {
        setSearch({
            ...search,
            max_cap: { start: filter.startMaxCap, end: filter.endMaxCap },
            current_cap: { start: filter.startNoCap, end: filter.endNoCap },
            no_users: { start: filter.startNoUser, end: filter.endNoUser }
        });
        const hasMaxCapFilter = filter.startMaxCap.length > 0 && filter.endMaxCap.length > 0;
        const hasNoCapFilter = filter.startNoCap.length > 0 && filter.endNoCap.length > 0;
        const hasNoUserFilter = filter.startNoUser.length > 0 && filter.endNoUser.length > 0;
        setShowSearch(hasMaxCapFilter || hasNoCapFilter || hasNoUserFilter);
    }, [filter]);

    const clear_all_filters = () => {
        setShowSearch(false);
        setFilter({ startMaxCap: '', endMaxCap: '', startNoCap: '', endNoCap: '', startNoUser: '', endNoUser: '' });
        setSearch({ ...search, max_cap: { start: '', end: '' }, current_cap: { start: '', end: '' }, no_users: { start: '', end: '' }, searchBar: '' });
        setFiltersCleared(true);
    }

    // close all filters
    const close_all_filters = () => {
        clear_all_filters();
    }

    return (
        <>
            <div className="data-table-custom">
                <div className="graph-header">
                    <div className="font-18 hd-title">Homes</div>
                    {/* <a href="#">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M10.0003 10.8335C10.4606 10.8335 10.8337 10.4604 10.8337 10.0002C10.8337 9.53993 10.4606 9.16683 10.0003 9.16683C9.54009 9.16683 9.16699 9.53993 9.16699 10.0002C9.16699 10.4604 9.54009 10.8335 10.0003 10.8335Z"
                            stroke="#98A2B3" stroke-width="1.66667"
                            stroke-linecap="round" stroke-linejoin="round" />
                          <path
                            d="M10.0003 5.00016C10.4606 5.00016 10.8337 4.62707 10.8337 4.16683C10.8337 3.70659 10.4606 3.3335 10.0003 3.3335C9.54009 3.3335 9.16699 3.70659 9.16699 4.16683C9.16699 4.62707 9.54009 5.00016 10.0003 5.00016Z"
                            stroke="#98A2B3" stroke-width="1.66667"
                            stroke-linecap="round" stroke-linejoin="round" />
                          <path
                            d="M10.0003 16.6668C10.4606 16.6668 10.8337 16.2937 10.8337 15.8335C10.8337 15.3733 10.4606 15.0002 10.0003 15.0002C9.54009 15.0002 9.16699 15.3733 9.16699 15.8335C9.16699 16.2937 9.54009 16.6668 10.0003 16.6668Z"
                            stroke="#98A2B3" stroke-width="1.66667"
                            stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                      </a> */}
                </div>
            </div>
            {/* <div className="right-main-header search-sec">
                    <div className="left-side-content">
                        <button type="button" className="btn btn-light">
                            20 + Members
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path d="M15 5L5 15M5 5L15 15" stroke="#344054"
                                    stroke-width="1.66667" stroke-linecap="round"
                                    stroke-linejoin="round" />
                            </svg>
                        </button>
                        <button type="button" className="btn btn-light">
                            Arizona
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path d="M15 5L5 15M5 5L15 15" stroke="#344054"
                                    stroke-width="1.66667" stroke-linecap="round"
                                    stroke-linejoin="round" />
                            </svg>
                        </button>
                        <button type="button" className="btn btn-light">
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path d="M5 10H15M2.5 5H17.5M7.5 15H12.5" stroke="#344054"
                                    stroke-width="1.66667" stroke-linecap="round"
                                    stroke-linejoin="round" />
                            </svg>
                            20 + Members
                        </button>
                    </div>
                    <div className="left-side-content searchbar">
                        <div className="form-group"><input type="text" name="" id=""
                            className="form-control" placeholder="Search" />
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M16.5 16.5L13.5834 13.5833M15.6667 8.58333C15.6667 12.4954 12.4954 15.6667 8.58333 15.6667C4.67132 15.6667 1.5 12.4954 1.5 8.58333C1.5 4.67132 4.67132 1.5 8.58333 1.5C12.4954 1.5 15.6667 4.67132 15.6667 8.58333Z"
                                    stroke="#667085" stroke-width="1.66667"
                                    stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </div>
                    </div>
                </div> */}

            {
                !showFilter ?
                    <div className="table-btn-sec">
                        <div className="form-group searchbar"><input type="text" name="" id="" className="form-control" placeholder="Search" onChange={(e) => handleSearchBar(e)} value={search?.searchBar} />
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M16.5 16.5L13.5834 13.5833M15.6667 8.58333C15.6667 12.4954 12.4954 15.6667 8.58333 15.6667C4.67132 15.6667 1.5 12.4954 1.5 8.58333C1.5 4.67132 4.67132 1.5 8.58333 1.5C12.4954 1.5 15.6667 4.67132 15.6667 8.58333Z" stroke="#667085" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </div>
                        <button type="button" className="btn btn-light" onClick={() => setShowFilter(true)}>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5 10H15M2.5 5H17.5M7.5 15H12.5" stroke="#344054" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                            More Filters
                        </button>
                    </div> : ''
            }
            {showFilter ?
                <div className="table-btn-sec more-filter">
                    <button type="button" className="btn btn-light" onClick={() => (setShowFilter(false), close_all_filters())}>
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15 5L5 15M5 5L15 15" stroke="#344054" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        Close Filters
                    </button>
                    <button className='clear-filter' onClick={() => clear_all_filters()}>Clear all filters</button>
                    <div className="filter-option">
                        <div className="form-group searchbar">
                            <label className="form-label">Search</label>
                            <input type="text" name="" id="" className="form-control" placeholder="Search Home Name..." onChange={(e) => handleSearchBar(e)} value={search?.searchBar} />
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M16.5 16.5L13.5834 13.5833M15.6667 8.58333C15.6667 12.4954 12.4954 15.6667 8.58333 15.6667C4.67132 15.6667 1.5 12.4954 1.5 8.58333C1.5 4.67132 4.67132 1.5 8.58333 1.5C12.4954 1.5 15.6667 4.67132 15.6667 8.58333Z" stroke="#667085" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </div>
                        <div className="form-group">
                            <label className="form-label">Member</label>
                            <div className='row'>
                                <div className='col-md-5'>
                                    <input type="text" name="" id="" className="form-control" placeholder="Min..." maxLength={3} onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault(); } }} value={filter?.startNoCap} onChange={(e) => setFilter({ ...filter, startNoCap: e.target.value })} />
                                </div>
                                _
                                <div className='col-md-6'>
                                    <input type="text" name="" id="" className="form-control" placeholder="Max..." maxLength={3} onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault(); } }} value={filter?.endNoCap} onChange={(e) => setFilter({ ...filter, endNoCap: e.target.value })} />
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="form-label">Staff</label>
                            <div className='row'>
                                <div className='col-md-5'>
                                    <input type="text" name="" id="" className="form-control" placeholder="Min..." maxLength={3} onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault(); } }} value={filter?.startNoUser} onChange={(e) => setFilter({ ...filter, startNoUser: e.target.value })} />
                                </div>
                                _
                                <div className='col-md-6'>
                                    <input type="text" name="" id="" className="form-control" placeholder="Max..." maxLength={3} onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault(); } }} value={filter?.endNoUser} onChange={(e) => setFilter({ ...filter, endNoUser: e.target.value })} />
                                </div>
                            </div>
                        </div>
                        {showSearch ?
                            <>
                                <div class="form-group">
                                    <label class="form-label invisible"></label>
                                    <button type="button" class="btn btn-light search-home-button" onClick={() => searchCapacity()}>Search</button>
                                </div>
                            </>
                            : ''
                        }
                    </div>
                </div> : ''
            }
        </>
    )
}
export default HeaderHomes