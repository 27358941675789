import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import FilterAndSearch from "./FilterAndSearch";
import { Pagination } from '../../../Pagination';
import AddComment from '../AddComment';
import DataNotFound from './DataNotFound'
import { formatAMPM, calculateTimeDifference, monthDayYearFormat } from '../../../../components/Common/Common'
import { get_edit_listing_all_data } from '../../../../redux/services/AuthMar'

const PandingTab = ({ id, activeTab, addNewModal }) => {
    const dispatch = useDispatch();
    const AllListingData = useSelector(state => state?.AuthMar?.AllListingData?.marListData);
    const totalPages = useSelector(state => state?.AuthMar?.AllListingData?.totalNoPages
    );
    const approvalStatus = useSelector(state => state?.AuthMar?.EditMemberDetails?.approval_status);
    const [currentPage, setCurrentPage] = useState(1);
    const [addComment, setAddComment] = useState(false);
    const [checkBoxId, setCheckBoxId] = useState('');
    const [sortType, setSortType] = useState(true);
    const [listingData, setListingData] = useState([])

    useEffect(() => {
        if (id) {
            let data = {
                page: 1,
                listData: 10,
                mar_detail_id: id,
                type: activeTab.toLowerCase()
            }
            dispatch(get_edit_listing_all_data(data));
        }
        if (!addComment) {
            setCheckBoxId('')
        }
    }, [addComment, id, addNewModal])

    const handlePageChange = (pageNumber) => {
        let data = {
            page: pageNumber,
            listData: 10,
            mar_detail_id: id,
            type: activeTab.toLowerCase()
        }
        setCurrentPage(pageNumber)
        dispatch(get_edit_listing_all_data(data));
    };

    const handleChange = async (e, id) => {
        // (type= 'overdue' || 'pending' || 'administered')
        setCheckBoxId(id)
        setAddComment(!addComment)

    }

    const getDisableHours = (data) => {
        let hours = calculateTimeDifference(data)
        console.log(hours,'hourshourshourshourshours');
        
        if (hours.includes('day') || hours.includes('days')) {
            return true
        }
        if (hours.includes('minute') || hours.includes('minutes')) {
            return false
        }
        if (hours.includes('hour') || hours.includes('hours')) {
            let splitHours = hours.split(' ')
            if (Number(splitHours[0]) <= 2) {
                return false
            } else {
                return true
            }
        }

    }
    const handleSort = () => {
        setSortType(!sortType);
    }

    useEffect(() => {
        if (AllListingData && AllListingData.length > 0) {
            let data = [...AllListingData]; // Create a shallow copy of AllListingData
            data.sort((a, b) => {
                // Combine date and time for comparison
                const dateTimeA = new Date(`${a.administer_date.split('-').reverse().join('-')}T${a.administer_time}`);
                const dateTimeB = new Date(`${b.administer_date.split('-').reverse().join('-')}T${b.administer_time}`);

                return sortType ? dateTimeA - dateTimeB : dateTimeB - dateTimeA; // Ascending or descending
            });
            setListingData(data);
        } else {
            setListingData([]);
        }
    }, [sortType, AllListingData]);



    return (
        <>
            <div class="tab-pane fade vit_info_data mar_pending show active">
                {/* <FilterAndSearch /> */}
                <div class="col-md-12 col-12">
                    <div class="table-sec">
                        <div class="table-responsive">
                            {listingData && listingData.length === 0 ?
                                <DataNotFound activeTab={activeTab} />
                                :
                                <table class="table table-hover">
                                    <thead>
                                        <tr>
                                            <th scope="col">Due Date
                                                <a onClick={() => handleSort()}>
                                                    {sortType ?
                                                        <svg class="ms-1" width="12"
                                                            height="12" viewBox="0 0 12 12" fill="none"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="M6 1.33337V10.6667M6 10.6667L10.6667 6.00004M6 10.6667L1.33333 6.00004"
                                                                stroke="#475467" stroke-width="0.888889"
                                                                stroke-linecap="round"
                                                                stroke-linejoin="round" />
                                                        </svg>
                                                        :
                                                        <svg class="ms-1" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M6 10.6667V1.33337M6 1.33337L10.6667 6.00004M6 1.33337L1.33333 6.00004"
                                                                stroke="#475467" stroke-width="0.888889"
                                                                stroke-linecap="round" stroke-linejoin="round" />
                                                        </svg>
                                                    }
                                                </a>
                                            </th>
                                            <th scope="col">Route</th>
                                            <th scope="col">Frequency</th>
                                            <th scope="col">Quantity</th>
                                            <th scope="col" className="edit_mar_decs">Administer </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {listingData && listingData.map((item) => {
                                            let time = formatAMPM(item?.administer_time)
                                            let disableHour = getDisableHours(`${item?.administer_date} ${item?.administer_time}`);
                                            let date = monthDayYearFormat(item?.administer_date)
                                            return (
                                                <tr>
                                                    <td>{date || ''}<span class="d-block">{time || ''}</span></td>
                                                    <td>{item?.masterRoute?.name || ''}</td>
                                                    <td className="lower">{item?.frequency || ''} x Daily</td>
                                                    <td>{item?.give_amount_quantity || ''} Tablet</td>
                                                    <td className="edit_mar_decs">
                                                            <input class="form-check-input" type="checkbox"
                                                                checked={item?.id === checkBoxId}
                                                                onChange={(e) =>
                                                                    handleChange(e, item?.id)}
                                                                disabled={approvalStatus === 'pending' ? true : disableHour}
                                                            />
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                        <tr>
                                            <td colspan="7">
                                                <Pagination
                                                    currentPage={currentPage}
                                                    totalPages={totalPages}
                                                    onPageChange={handlePageChange}
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            }
                        </div>
                    </div>
                </div>
            </div>
            {addComment && <AddComment handleCommentToggle={setAddComment} checkBoxId={checkBoxId} parentId = {AllListingData[0]?.membersMarDetail?.id} />}
        </>
    )

}
export default PandingTab