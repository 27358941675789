import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import UploadAndManually from './UploadAndManually';
import { useDispatch, useSelector } from 'react-redux';
import { Get_step_form_complete_or_not } from '../../../redux/services/AuthMembers';

const FORMS = [
    { title: 'DDD PCSP', type: 'ddd_pcsp', url: '/auth/edit-members/ddd/pcsp/' },
    { title: 'Individualized health care & safety needs', type: 'Individualized_health', url: '/auth/edit-members/individualized/' },
    { title: 'Medical history', type: 'Medical_history', url: '/auth/edit-members/medicalhistory/' },
    { title: 'General consent and authorization', type: 'General_Consent', url: '/auth/edit-members/general-consent/' },
    { title: 'Behavior modifying medication consent', type: 'Medication_Consent', url: '/auth/edit-members/behavior-modifying/' }
];

export const Forms = ({ member_id, handleClose = ()=>{} }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const totalSteps = 13;
    const stepFormComplete = useSelector((state) => state?.AuthMember?.stepFormComplete);
    const [uploadAndManuallyModal, setUploadAndManuallyModal] = useState(false);
    const [completedForms, setCompletedForms] = useState({});
    const [isIndividualizedHealthEnabled, setIsIndividualizedHealthEnabled] = useState(true);
    const [isOtherFormsEnabled, setIsOtherFormsEnabled] = useState(false);
    const [currentStep, setCurrentStep] = useState(0);
    const [progressPercentage, setProgressPercentage] = useState(0);

    useEffect(() => {
        dispatch(Get_step_form_complete_or_not(member_id));
    }, [dispatch, member_id]);

    useEffect(() => {                
        if (stepFormComplete) {
            let newData = {};
            stepFormComplete.forEach((item) => {                
                newData[item.form_type] = !item.profile_status;

                // Control logic for enabling/disabling forms
                if (item.form_type === 'ddd_pcsp') {
                    setCurrentStep(Number(item.step_no));
                    setProgressPercentage((Number(item.step_no) / totalSteps) * 100);

                    if (!item.profile_status) {
                        setIsIndividualizedHealthEnabled(item.profile_status);
                    }
                }

                if (item.form_type === 'Individualized_health') {
                    if (!item.profile_status && completedForms['ddd_pcsp']) {
                        setIsOtherFormsEnabled(true);
                    }
                }
            });
            setCompletedForms(newData);
        }
    }, [stepFormComplete]);

    const handleFormNavigate = (url) => {
        setUploadAndManuallyModal(true);
    };

    const handleEdit = (navigateUrl) => {
        if(location.pathname === navigateUrl){
            handleClose()
        }else{
            navigate(navigateUrl)
        }
    }

    const renderFormSection = (title, formKey, navigateUrl) => {        
        return (
            <div className="memb_mult_field_main" key={formKey}>
                <div className="member_field_left">
                    <h4>{title} <span className={`mem_field_bages ${completedForms[formKey] ? 'comp_badge' : ''}`}>{completedForms[formKey] ? 'Completed' : 'Not submitted'}</span></h4>
                </div>
                <div className="member_field_right">
                    {completedForms[formKey] ? (
                        <button type="button" onClick={() => handleEdit(navigateUrl)}>Edit</button>
                    ) : (
                        <button
                            type="button"
                            onClick={() => formKey === 'ddd_pcsp' ? handleFormNavigate() : navigate(navigateUrl)}
                            disabled={
                                (formKey === 'Individualized_health' ? isIndividualizedHealthEnabled : false ||
                                !completedForms['Individualized_health'] && !isOtherFormsEnabled && formKey !== 'ddd_pcsp')
                            }
                        >
                            Begin
                        </button>
                    )}
                </div>
            </div>
        );
    };

    return (
        <div className="tab-pane active show" id="form" role="tabpanel" aria-labelledby="form-tab">
            <div className="member_vital_form_cont">
                <div className="vital_form_progress">
                    <div className="vital_form_progress_inner">
                        <h4>Complete upload of DDD/PCSP form</h4>
                        <div className="steps-progress">
                            <div className="progress">
                                <div
                                    className="progress-bar"
                                    role="progressbar"
                                    style={{ width: `${progressPercentage}%` }}
                                    aria-valuenow={progressPercentage}
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                >
                                    {Math.round(progressPercentage)}%
                                </div>
                            </div>
                            <h5 className="sap-card-title">STEP {currentStep} OF {totalSteps}</h5>
                        </div>
                    </div>
                    <div className="vital_form_com_btn">
                        <button type="button" className="btn btn-primary" onClick={() => handleFormNavigate()}>
                            Complete now
                        </button>
                    </div>
                </div>

                {FORMS.map((item) => renderFormSection(item.title, item.type, `${item.url}${member_id}`))}
            </div>
            <UploadAndManually id={member_id} isOpen={uploadAndManuallyModal} setIsOpen={setUploadAndManuallyModal} />
        </div>
    );
};
