import React, { useEffect } from "react";
import { useForm, useFieldArray } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Footer from '../../Footer'

const AllergiesSideEffects = ({ onHandleBackStep, onSubmit }) => {
    const allIndividualizedHealth = useSelector(state => state?.AuthMember?.allIndividualizedHealth?.healthMedicalFirstStep);
    // Initialize the form with useForm hook
    const { register, handleSubmit, setValue, formState: { errors } } = useForm();

    useEffect(() => {
        setValue('is_alergicmed_sideeffects', allIndividualizedHealth?.is_alergicmed_sideeffects);
        setValue('food_alergiessideeffeects', allIndividualizedHealth?.food_alergiessideeffeects);
        setValue('medication_alergiessideeffeects', allIndividualizedHealth?.medication_alergiessideeffeects);
        setValue('stings_alergiessideeffeects', allIndividualizedHealth?.stings_alergiessideeffeects);
        setValue('other_alergiessideeffeects', allIndividualizedHealth?.other_alergiessideeffeects);
        setValue('recommeded_response', allIndividualizedHealth?.recommeded_response);

    }, [allIndividualizedHealth])
    return (
        <form onSubmit={handleSubmit(onSubmit)} className="row">
            <div className="col-12">
                <label for="" className="form-label">Allergies/Med side effects*</label>
                <div className="ddd_steptwo_para">
                    <select
                        className="form-select"
                        aria-label="Default select example"
                        {...register(`is_alergicmed_sideeffects`, { required: "Allergies/Med side effects is required!" })}>
                        <option value="">Select</option>
                        <option value="1">Yes</option>
                        <option value="0">No</option>
                    </select>
                    {errors.is_alergicmed_sideeffects && (
                        <span className="error">{errors.is_alergicmed_sideeffects.message}</span>
                    )}
                </div>
            </div>
            <div className="col-12">
                <label for="" className="form-label">To food*</label>
                <div className="ddd_steptwo_para">
                    <input
                        type="input"
                        className="form-control"
                        placeholder="What food(s) are they allergic to"
                        {...register(`food_alergiessideeffeects`, { required: "To food is required!" })}
                        maxLength={20}
                    />
                    {errors?.food_alergiessideeffeects && (
                        <span className="error">{errors.food_alergiessideeffeects.message}</span>
                    )}
                </div>
            </div>
            <div className="col-12">
                <label for="" className="form-label">To medication*</label>
                <div className="ddd_steptwo_para">
                    <input
                        type="input"
                        className="form-control"
                        placeholder="Enter To medication"
                        {...register(`medication_alergiessideeffeects`, { required: "To medication is required!" })}
                        maxLength={20}
                    />
                    {errors?.medication_alergiessideeffeects && (
                        <span className="error">{errors.medication_alergiessideeffeects.message}</span>
                    )}
                </div>
            </div>
            <div className="col-12">
                <label for="" className="form-label">To bee stings*</label>
                <div className="ddd_steptwo_para">
                    <input
                        type="input"
                        className="form-control"
                        placeholder="Enter To bee stings"
                        {...register(`stings_alergiessideeffeects`, { required: "To bee stings is required!" })}
                        maxLength={20}
                    />
                    {errors?.stings_alergiessideeffeects && (
                        <span className="error">{errors.stings_alergiessideeffeects.message}</span>
                    )}
                </div>
            </div>
            <div className="col-12">
                <label for="" className="form-label">Other*</label>
                <div className="ddd_steptwo_para">
                    <input
                        type="input"
                        className="form-control"
                        placeholder="Enter Other"
                        {...register(`other_alergiessideeffeects`, { required: "Other is required!" })}
                        maxLength={20}
                    />
                    {errors?.other_alergiessideeffeects && (
                        <span className="error">{errors.other_alergiessideeffeects.message}</span>
                    )}
                </div>
            </div>
            <div className="col-12">
                <label for="" className="form-label">Recommended response to allergic reaction*</label>
                <div className="ddd_steptwo_para">
                    <textarea className="form-control" placeholder="Enter allergic reaction" {...register("recommeded_response", { required: "true" })} maxLength={150} />
                    {errors.recommeded_response && errors.recommeded_response.type === "required" && (<span className="error">This is required!</span>)}
                </div>
            </div>
            <Footer onHandleBackStep={onHandleBackStep} />
        </form>
    )

}
export default AllergiesSideEffects