import React, { useEffect, useState } from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import { useSelector } from 'react-redux';
import Footer from '../../Footer';

const GeneralConsentAndAuthorizationForm = ({ onHandleBackStep, onSubmit }) => {
  const allGeneralConsentAuthorization = useSelector((state) => state?.AuthMember?.allGeneralConsentAuthorization?.generalConsentAuthorization);

  const [signatureImage, setSignatureImage] = useState('')
  // Initialize the form with useForm hook
  const { control, handleSubmit, register, setValue, formState: { errors } } = useForm({
    defaultValues: {
      protectiveDevice: [{ device_name: "" }],
    }
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'protectiveDevice'
  });

  useEffect(() => {
    setValue('agency_name', allGeneralConsentAuthorization?.agency_name || '');
    setValue('medical_evaluation_assessments', allGeneralConsentAuthorization?.medical_evaluation_assessments || '');
    setValue('necessary_emergency_medical', allGeneralConsentAuthorization?.necessary_emergency_medical || '');
    setValue('routine_dental_care', allGeneralConsentAuthorization?.routine_dental_care || '');
    setValue('sedation_mechanical_restraint', allGeneralConsentAuthorization?.sedation_mechanical_restraint || '');
    setValue('medical_condition', allGeneralConsentAuthorization?.medical_condition || '');
    setValue('signature', allGeneralConsentAuthorization?.signature || '');

    if(allGeneralConsentAuthorization?.signature){
      setSignatureImage(allGeneralConsentAuthorization?.signature)
    }
    const lengthDifference = allGeneralConsentAuthorization?.membersGeneralConsentAuthorizationProtectiveDevice?.length - fields.length;
        if (lengthDifference > 0) {
            for (let i = 0; i < lengthDifference; i++) {
                append({});
            }
        }
        allGeneralConsentAuthorization?.membersGeneralConsentAuthorizationProtectiveDevice.forEach((item, index) => {
            setValue(`protectiveDevice.${index}.device_name`, item.device_name || '');
        });
  }, [allGeneralConsentAuthorization, setValue]);

  const handleImage = (e) => {
    const { files } = e.target;
    if (files && files.length) {
      const selectedFile = files[0];
      const newImageUrl = URL.createObjectURL(selectedFile);
      setSignatureImage(newImageUrl);
      // Update the form value with the new file
      setValue(`signature`, selectedFile);
    }
  };

  return (
    <div className="tab-pane fade goal_tab show active">
      <form onSubmit={handleSubmit(onSubmit)} className="row">

        <div className="col-12 mb-3">
          <label className="form-label">Agency name*</label>
          <input
            type="input"
            className="form-control"
            placeholder="Enter Agency name"
            {...register('agency_name', { required: 'Agency name is required!' })}
            maxLength={50}
          />
          {errors.agency_name && <span className="error">{errors.agency_name.message}</span>}
        </div>
        <div className="col-md-6 mb-3">
          <label className="form-label">Routine medical evaluation, assessments, and treatment*</label>
          <select
            className="form-select"
            aria-label="Default select example"
            {...register('medical_evaluation_assessments', { required: "Routine medical evaluation is required" })}
          >
            <option value="">Select</option>
            <option value="0">No</option>
            <option value="1">Yes</option>
          </select>
          {errors.medical_evaluation_assessments && (
            <span className="error">{errors.medical_evaluation_assessments.message}</span>
          )}
        </div>
        <div className="col-md-6 mb-3">
          <label className="form-label">Necessary emergency medical care and special procedure, if applicable*</label>
          <select
            className="form-select"
            aria-label="Default select example"
            {...register('necessary_emergency_medical', { required: "This is required" })}
          >
            <option value="">Select</option>
            <option value="0">No</option>
            <option value="1">Yes</option>
          </select>
          {errors.necessary_emergency_medical && (
            <span className="error">{errors.necessary_emergency_medical.message}</span>
          )}
        </div>
        <div className="col-md-6 mb-3">
          <label className="form-label">Routine dental care*</label>
          <select
            className="form-select"
            aria-label="Default select example"
            {...register('routine_dental_care', { required: "Routine dental care is required" })}
          >
            <option value="">Select</option>
            <option value="0">No</option>
            <option value="1">Yes</option>
          </select>
          {errors.routine_dental_care && (
            <span className="error">{errors.routine_dental_care.message}</span>
          )}
        </div>
        <div className="col-md-6 mb-3">
          <label className="form-label">The use of sedation, mechanical restraint, or protective device when
            authorized by a physician for medical/dental procedures.*</label>
          <select
            className="form-select"
            aria-label="Default select example"
            {...register('sedation_mechanical_restraint', { required: "This is required" })}
          >
            <option value="">Select</option>
            <option value="0">No</option>
            <option value="1">Yes</option>
          </select>
          {errors.sedation_mechanical_restraint && (
            <span className="error">{errors.sedation_mechanical_restraint.message}</span>
          )}
        </div>
        <div className="col-12">
          <div className="alert alert-light sap-alert-heading">
            <div className="hd-subtitle font-24">If no to any of the above, the responsible person agrees to provide for the necessary medical
              and dental care.</div>
          </div>
        </div>
        <div className="col-12 mb-3">
          <label className="form-label">The ongoing or recurring use of a protective device in response to a medical
            condition.*</label>
          <select
            className="form-select"
            aria-label="Default select example"
            {...register('medical_condition', { required: "This is required" })}
          >
            <option value="">Select</option>
            <option value="0">No</option>
            <option value="1">Yes</option>
          </select>
          {errors.medical_condition && (
            <span className="error">{errors.medical_condition.message}</span>
          )}
        </div>
        {fields.map((member, index) => (
          <div key={member.id} className="row">
            {index > 0 && (
              <hr />
            )}
            <div className="col-12 mb-3">
              <label for="" className="form-label">Type of Protective Device*</label>
              <input
                type="input"
                className="form-control"
                placeholder="Enter Action to be taken"
                {...register(`protectiveDevice.${index}.device_name`, { required: "This field is required!" })}
                maxLength={20}
              />
              {errors.protectiveDevice && errors.protectiveDevice[index]?.device_name && (
                <span className="error">{errors.protectiveDevice[index].device_name.message}</span>
              )}
            </div>
            {index > 0 && (
              <div className="col-12 mb-3">
                <a
                  href="#"
                  className="stepone_remove"
                  onClick={(e) => {
                    e.preventDefault();
                    remove(index);
                  }}
                >
                  Remove Task
                </a>
              </div>
            )}
          </div>
        ))}
        <div className="stepone_health_planbtn">
          <button type="button" className="font-14" onClick={() => append({ id: Date.now() })}><svg width="12" height="12" viewBox="0 0 12 12" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M6.00016 1.33325V10.6666M1.3335 5.99992H10.6668" stroke="#667085" stroke-width="2"
              stroke-linecap="round" stroke-linejoin="round"></path>
          </svg>
            Add another device</button>
        </div>
        <div className="col-12 mb-3">
          <label for="" className="form-label">Responsible Person’s Signature*</label>
          <input
            type="file"
            className="form-control"
            onChange={(e) => handleImage(e)}
            accept="image/png, image/jpeg"
            onKeyDown={(e) => e.preventDefault()}
          />
          {errors.signature && (
            <span className="error">{errors.signature.message}</span>
          )}
          {signatureImage &&
            <div className="imageDisplay">
              <img src={signatureImage} />
            </div>
          }
        </div>

        <Footer onHandleBackStep={onHandleBackStep} />
      </form>
    </div>
  );
};

export default GeneralConsentAndAuthorizationForm;
