import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import PcspFooter from '../../PcspFooter'

const livingArrangement= [
  "Living alone",
  "Lives with Family/Others",
  "Nursing Facility (NF)",
  "Alternative HCBS Setting",
  "Behavioral Health Facility (BHF) or Unit",
  "Uncertified Setting",
  "Other"
]
const HomeLife = ({ onHandleBackStep, onSubmit, isGoBack }) => {

  const allDddPcsp = useSelector(state => state?.AuthMember?.allDddPcsp?.homeLife);
  const UploadedPdfData = useSelector(state => state?.AuthMember?.UploadedPdfData);
  // Initialize the form with useForm hook
  const { register, handleSubmit, setValue, formState: { errors } } = useForm();

  useEffect(() => {
    if (UploadedPdfData?.length > 0 && !isGoBack) {
      UploadedPdfData.forEach((item) => {
        switch (item.key) {
          case 'DESCRIBE CURRENT LIVING/ENVIRONMENT CONDITIONS:':
            setValue('current_living_conditions', item?.value);
            break
        }
      })
    } else {
      setValue('current_living_conditions', allDddPcsp?.current_living_conditions);
      setValue('living_arrangment_option_id', allDddPcsp?.living_arrangment_option_id);
      setValue('living_arrangment_other_text', allDddPcsp?.living_arrangment_other_text);
      setValue('notes', allDddPcsp?.notes);
      setValue('suggestion_to_improve_member_living_conditions', allDddPcsp?.suggestion_to_improve_member_living_conditions);
    }

  }, [allDddPcsp, UploadedPdfData])
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="row">
        <div className="alert alert-light sap-alert-heading">
          <div className="hd-subtitle font-16">The setting in which the member resides or receives services is the most
            integrated and least restrictive setting and affords the member to have full access to the benefits of
            community living. Documentation shall reflect the setting is of the individual’s choosing, provides
            support to the member to integrate into their community of choice as defined by their interests,
            preferences, abilities and health and safety risks.</div>
        </div>
        <div className="col-12">
          <div className="alert alert-light sap-alert-heading">
            <div className="hd-title font-24">Home life</div>
            <div className="hd-subtitle font-16">Questions should be modified appropriately to ensure age appropriateness
              and applicability to institutional setting types. For example, questions related to going out and
              leaving the home may not be applicable to members living in a skilled nursing facility, but other
              questions regarding visitors, picking staff to provide assistance and activities do apply to these
              settings.</div>
          </div>

        </div>
        <div className="col-12">
          <label for="" className="form-label">Notes*</label>
          <div className="ddd_steptwo_para">
            <textarea className="form-control" placeholder="Enter notes" {...register("notes", { required: "true" })} maxLength={150} />
            {errors.notes && errors.notes.type === "required" && (<span className="error">Notes is required!</span>)}
          </div>
        </div>
        <div className="member_vital_basic_title">
          <h4>Living arrangement</h4>
        </div>
        <div className="col-12 mb-3">
          <select
            className="form-select"
            aria-label="Default select example"
            {...register(`living_arrangment_option_id`, { required: "Living arrangement is required!" })}>
            <option value="">Select</option>
           { livingArrangement.map((item,index)=>{
            return(
              <option value={index + 1}>{item}</option>
            )
            })}
          </select>
          {errors.living_arrangment_option_id && (
            <span className="error">{errors.living_arrangment_option_id.message}</span>
          )}

        </div>
        <div className="col-12 mb-3">
          <label for="" className="form-label">Other services (only shows if other is selected)*</label>
          <input
            type="input"
            className="form-control"
            placeholder="child visit"
            {...register(`living_arrangment_other_text`, { required: "Other services is required!" })}
            maxLength={20}
          />
          {errors?.living_arrangment_other_text && (
            <span className="error">{errors.living_arrangment_other_text.message}</span>
          )}
        </div>
        <div className="col-12">
          <label for="" className="form-label">Describe current living/environment conditions*</label>
          <div className="mb-3">
            <textarea className="form-control" placeholder="Enter notes" {...register("current_living_conditions", { required: "true" })} maxLength={150} />
            {errors.current_living_conditions && errors.current_living_conditions.type === "required" && (<span className="error">Notes is required!</span>)}

          </div>
        </div>
        <div className="member_vital_basic_title">
          <h4>If member expresses dissatisfaction with current living situation or wants to explore other things</h4>
        </div>
        <div className="col-12 mb-3">
          <label for="" className="form-label">Do you have suggestions of what we could work on that could make your
            living arrangement better? If yes, note in goal section as appropriate*</label>
          <select
            className="form-select"
            aria-label="Default select example"
            {...register(`suggestion_to_improve_member_living_conditions`, { required: "This is required!" })}>
            <option value="">Select</option>
            <option value="0">No</option>
            <option value="1">Yes</option>
            <option value="2">N/A</option>
          </select>
          {errors.suggestion_to_improve_member_living_conditions && (
            <span className="error">{errors.suggestion_to_improve_member_living_conditions.message}</span>
          )}
        </div>
        <PcspFooter onHandleBackStep={onHandleBackStep} />
      </form>
    </>
  )
}
export default HomeLife