import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Update_security_detail } from '../../../redux/services/AuthSettings';
import { useNavigate } from 'react-router-dom';
const Security = () => {
    const { register, handleSubmit, getValues, setValue, watch, formState: { errors }, reset } = useForm();
    const password = useRef({});
    password.current = watch("password", "");
    const [showPassword, setShowPassword] = useState(false);
    const dispatch=useDispatch();
    const navigate=useNavigate();
    //get security setting data
    const SecurityDetail = useSelector(state => state?.AuthSettings?.settingInfo?.security);
    const Logout = () => {
        // window.location.reload();
        localStorage.clear();
        navigate('/login');
    }
    useEffect(() => {
        setValue('username', SecurityDetail?.username)
    }, [SecurityDetail])
    const onSubmit =async () => {
        const data={
            username:getValues('username'),
            password:getValues('password')
        }
        try {
            const response = await dispatch(Update_security_detail(data));
            if (response?.payload?.status) {
                localStorage.setItem('security','security')
                // navigate('/verify-phone')
                setValue('password','');
                setValue('reType_password','');
                Logout()
            }
        } catch (error) {
            // Handle error if dispatch fails
            console.error("Error during signup:", error);
        }
    }
    return (
        <>
        {/* <div className="tab-pane fade"   > */}
            {/* <div className="alert alert-light sap-alert-heading" >
                <div className="hd-subtitle font-16">Fields coloured gray can only be
                    updated by an admin because they’re sensitive. Kindly contact an
                    admin to help update such details if needed</div>
            </div> */}
            <div className="font-20 py-3">Security</div>
            <form action="" className="row" onSubmit={handleSubmit(onSubmit)}>
                <div className="col-md-12 col-12 mb-3">
                    <label for="" className="form-label">Username*</label>
                    <input type="input" className="form-control" id=""
                        placeholder="Username" {...register('username', { required: "true" })} autoComplete='false' disabled/>
                    {errors.username && errors.username.type === "required" && <span className="error">User name is required!</span>}
                </div>
                <div className="col-md-12 col-12 mb-3">
                    <label for="" className="form-label">Set new password*</label>
                    <div className='pass-sec'>
                    <input type={showPassword ? 'text' : 'password'} className="form-control" id=""
                        placeholder="Password" {...register('password', { required: "true" })} autoComplete='new-password'/>
                    <div onClick={() => showPassword ? setShowPassword(false) : setShowPassword(true)}>
                        {showPassword ?
                            <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.99922 1.5C6.96172 1.5 5.28672 2.425 4.00234 3.61562C2.79922 4.73438 1.96797 6.0625 1.54297 7C1.96797 7.9375 2.79922 9.26562 3.99922 10.3844C5.28672 11.575 6.96172 12.5 8.99922 12.5C11.0367 12.5 12.7117 11.575 13.9961 10.3844C15.1992 9.26562 16.0305 7.9375 16.4555 7C16.0305 6.0625 15.1992 4.73438 13.9992 3.61562C12.7117 2.425 11.0367 1.5 8.99922 1.5ZM2.98047 2.51875C4.45234 1.15 6.47422 0 8.99922 0C11.5242 0 13.5461 1.15 15.018 2.51875C16.4805 3.87812 17.4586 5.5 17.9242 6.61562C18.0273 6.8625 18.0273 7.1375 17.9242 7.38437C17.4586 8.5 16.4805 10.125 15.018 11.4812C13.5461 12.85 11.5242 14 8.99922 14C6.47422 14 4.45234 12.85 2.98047 11.4812C1.51797 10.125 0.539844 8.5 0.0773437 7.38437C-0.0257813 7.1375 -0.0257813 6.8625 0.0773437 6.61562C0.539844 5.5 1.51797 3.875 2.98047 2.51875ZM8.99922 9.5C10.3805 9.5 11.4992 8.38125 11.4992 7C11.4992 5.61875 10.3805 4.5 8.99922 4.5C8.97734 4.5 8.95859 4.5 8.93672 4.5C8.97734 4.65938 8.99922 4.82812 8.99922 5C8.99922 6.10313 8.10234 7 6.99922 7C6.82734 7 6.65859 6.97813 6.49922 6.9375C6.49922 6.95937 6.49922 6.97813 6.49922 7C6.49922 8.38125 7.61797 9.5 8.99922 9.5ZM8.99922 3C10.0601 3 11.0775 3.42143 11.8276 4.17157C12.5778 4.92172 12.9992 5.93913 12.9992 7C12.9992 8.06087 12.5778 9.07828 11.8276 9.82843C11.0775 10.5786 10.0601 11 8.99922 11C7.93835 11 6.92094 10.5786 6.17079 9.82843C5.42065 9.07828 4.99922 8.06087 4.99922 7C4.99922 5.93913 5.42065 4.92172 6.17079 4.17157C6.92094 3.42143 7.93835 3 8.99922 3Z" fill="#888888" />
                            </svg> :
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8 11C6.35 11 5 9.65 5 8C5 6.35 6.35 5 8 5C9.65 5 11 6.35 11 8C11 9.65 9.65 11 8 11ZM8 6C6.9 6 6 6.9 6 8C6 9.1 6.9 10 8 10C9.1 10 10 9.1 10 8C10 6.9 9.1 6 8 6Z" fill="black" />
                                <path d="M8 13C4.81 13 2.01 11.06 1.03 8.16C0.989837 8.05711 0.989837 7.94289 1.03 7.84C2.01 4.95 4.82 3 8 3C11.18 3 13.99 4.94 14.97 7.84C15.01 7.94 15.01 8.06 14.97 8.16C13.99 11.05 11.18 13 8 13ZM2.03 8C2.92 10.4 5.3 12 8 12C10.7 12 13.07 10.4 13.97 8C13.08 5.6 10.7 4 8 4C5.3 4 2.93 5.6 2.03 8Z" fill="black" />
                                <path d="M14 14.5C13.9344 14.5008 13.8694 14.4879 13.8091 14.462C13.7489 14.4362 13.6946 14.398 13.65 14.35L1.65 2.34999C1.45 2.14999 1.45 1.83999 1.65 1.63999C1.85 1.43999 2.16 1.43999 2.36 1.63999L14.35 13.65C14.55 13.85 14.55 14.16 14.35 14.36C14.25 14.46 14.12 14.51 14 14.51V14.5Z" fill="black" />
                            </svg>
                        }
                    </div>
                    </div>
                    {errors.password && errors.password.type === "required" && <span className="error">Password is required!</span>}
                </div>
                <div className="col-md-12 col-12 mb-3">
                    <label for="" className="form-label">Re-type password*</label>
                    <input type="Password" className="form-control" id=""
                        placeholder="Re-type Password" {...register('reType_password', {
                            required: "true", validate: value =>
                                value === password.current || "The password do not match"
                        })} />
                    {errors.reType_password && errors.reType_password.type === "required" && <span className="error">Re-type Password is required!</span>}
                    {errors.reType_password && errors.reType_password.type === "validate" && <span className="error">The password do not match</span>}
                </div>

                <div className="col-md-12 login-btn">
                    <button type="submit" className="btn btn-primary">Save Changes</button>
                </div>

            </form>
        {/* </div> */}
        </>
    )
}

export default Security