import React from 'react'
import Modal from 'react-modal';
import { useDispatch } from 'react-redux';
import { Delete_role_permission,getAuthSettingDetail} from '../../../../redux/services/AuthSettings';

import { useNavigate } from 'react-router-dom';
const ConfirmDelete = ({delteModal,setDeleteModal,id,setEditModalIsOpen}) => {
    const navigate=useNavigate();
    const customModalStyles = {
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 1000
        },
        content: {
            position: 'relative',
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            border: 'none',
            maxWidth: '750px',
            background: '#fff',
            borderRadius: '8px',
            padding: '20px',
            height: 'auto',
            zIndex: 1000,
            transition: 'all 1s ease-in-out'
        }
    };
    const dispatch=useDispatch();
    const onSubmit = async () => {
            const response = await dispatch(Delete_role_permission(id));
            if(response?.payload?.status==true)
            setDeleteModal(false);
            setEditModalIsOpen(false);
            dispatch(getAuthSettingDetail())
            navigate('/auth/settings')
            localStorage.setItem('tabs','roles')
    };
  return (
    <Modal
    isOpen={delteModal}
    onRequestClose={() => setDeleteModal(false)}
    style={customModalStyles}
    contentLabel="Create Role Modal"
>
    <div className="modal-dialog modal-lg"> 
        <div className="modal-content">
            <div className="modal-header close-deactivate">
                <button type="button" className="btn-close" onClick={() => setDeleteModal(false)}></button>
            </div>
            <div className="modal-body">
                <div className="font-18 hd-title">Delete Role</div>
                <div className="hd-subtitle font-14">Are you sure you want to
                    delete this role ?</div>

                <div className="pop-action-btn">
                    <button type="button" className="btn btn-light" onClick={() => setDeleteModal(false)}>Cancel</button>
                    <button type="submit" className="btn btn-danger mx-2" onClick={() => onSubmit()}>Delete</button>
                </div>
            </div>
        </div>
    </div>
</Modal>
  )
}

export default ConfirmDelete