import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import Footer from '../../Footer'

const BasicInformation = ({ onHandleBackStep, onSubmit }) => {
  const allIndividualizedHealth = useSelector(state => state?.AuthMember?.allIndividualizedHealth?.basicInformation);
  // Initialize the form with useForm hook
  const { handleSubmit, register, setValue, formState: { errors } } = useForm();


  useEffect(() => {
    setValue('assessor_name', allIndividualizedHealth?.assessor_name || '');
    setValue('assessment_date', allIndividualizedHealth?.assessment_date || '');
  }, [allIndividualizedHealth])

  return (
    <div className="tab-pane fade  goal_tab show active">
      <form onSubmit={handleSubmit(onSubmit)} className="row">
        <div className="col-12 mb-3">
          <div className="alert alert-light sap-alert-heading p-0">
            <div className="hd-subtitle font-16">Complete this summary by providing the most current and accurate information available</div>
          </div>
        </div>
        <div className="col-12 mb-3">
          <label htmlFor="" className="form-label">Name of assessor*</label>
          <input
            type="input"
            className="form-control"
            placeholder="Enter Name of assessor"
            {...register(`assessor_name`, {
              required: "Name of assessor is required!",
              pattern: {
                value: /^[A-Za-z\s]+$/,
                message: "Only alphabets are allowed!",
              },
            })}
            maxLength={20}
            onKeyPress={(e) => {
              const charCode = e.key.charCodeAt(0);
              if (!/[a-zA-Z\s]/.test(e.key)) {
                e.preventDefault();
              }
            }}
          />
          {errors?.assessor_name && (
            <span className="error">{errors.assessor_name.message}</span>
          )}
        </div>
        <div className="col-12 mb-3">
          <label for="" className="form-label">Date of assessment*</label>

          <input type="date" className="form-control" id="" placeholder="Enter Date of assessment" {...register(`assessment_date`, { required: "Date of assessment is required", })} onKeyDown={(e) => e.preventDefault()} />
          {errors.assessment_date && (
            <span className="error">{errors.assessment_date.message}</span>
          )}
        </div>

        <Footer onHandleBackStep={onHandleBackStep} />
      </form>
    </div>
  )
}

export default BasicInformation