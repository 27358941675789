import React from "react";
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Pagination } from '../../Pagination';

const MarTableView = ({ marList, currentPage, totalPages, handlePageChange, member_id = '' }) => {
    const navigate = useNavigate();
    const approvalStatus = useSelector(state => state?.AuthMar?.EditMemberDetails?.approval_status);

    return (
        <div className="main-content-sec vit_info_data medicat_tb_cont">
            <div className="row">
                <div className="col-md-12 col-12">
                    <div className="table-sec">
                        <div className="table-responsive">
                            <table className="table table-hover">
                                {/* Conditional rendering of table headers based on `fromMember` prop */}
                                <thead>
                                    <tr>
                                        <>
                                                <th scope="col">Medication Name</th>
                                                <th scope="col">Begin Date</th>
                                                <th scope="col">End Date</th>
                                                <th scope="col">Frequency</th>
                                                <th scope="col">Approval Status</th>
                                                <th scope="col">Action</th>
                                            </>
                                    </tr>
                                </thead>
                                <tbody>
                                    {marList && marList.map((item) => {
                                        return (
                                            <tr key={item?.id}>
                                                    <>
                                                        <td>{item?.masterMedication?.name}</td>
                                                        <td>{item?.adminis_start_date}</td>
                                                        <td>{item?.adminis_end_date}</td>
                                                        <td>{item?.frequency} x Daily</td>
                                                        <td>
                                                            <div className="badges">
                                                                <span className={item?.approval_status === 'pending' ? 'pend' : 'actives'}></span>
                                                                {item?.approval_status}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <a onClick={() => navigate(`/auth/open-mar/${member_id}`)}>Open MAR</a>
                                                        </td>
                                                    </>
                                            </tr>
                                        );
                                    })}
                                    <tr>
                                        <td colSpan="7">
                                            <Pagination
                                                currentPage={currentPage}
                                                totalPages={totalPages}
                                                onPageChange={handlePageChange}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MarTableView;
