import React from 'react'
import Modal from 'react-modal';
import { Delete_multiple_member,Delete_single_member,get_all_members } from '../../../redux/services/AuthMembers';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
const DeleteMember = ({setDeleteModal,deleteModal,type,selectedUsers,search,id}) => {
    const dispatch=useDispatch();
    const navigate=useNavigate();
    const customModalStyles = {
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 1000
        },
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            border: 'none',
            maxWidth: '750px',
            // width: '100%',
            background: '#fff',
            borderRadius: '8px',
            padding: '20px',
            height: 'auto',
            zIndex: '99',
            transition: 'all 1s ease-in-out'
        }
    };
    const onSubmit = async() => {
        if (type === 'delete_single') {
          const response= await dispatch(Delete_single_member(id));         
        //   if(response?.payload?.status==true){
              setDeleteModal(false);
              navigate('/auth/members');
        //   }
        } else if (type === 'delete_All') {
            const payload = {
                multiMember: selectedUsers
            };
          const response= await dispatch(Delete_multiple_member(payload));
        //    if(response?.payload?.status==true){
               setDeleteModal(false);
                dispatch(get_all_members(search))
        //    }
        }
        else {
            setDeleteModal(false);
        }
    }
    return (
        <Modal
            isOpen={deleteModal}
            onRequestClose={() => setDeleteModal(false)}
            style={customModalStyles}
            contentLabel="Create Role Modal"
        >
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="btn-close close-deactivate" onClick={()=>setDeleteModal(false)}></button>
                    </div>
                    <div class="modal-body">
                        <div class="font-18 hd-title">Delete member(s)</div>
                        <div class="hd-subtitle font-14">Are you sure you want to delete this member(s)? This action cannot be undone.</div>

                        <div class="pop-action-btn">
                            <button type="button" class="btn btn-light" onClick={()=>setDeleteModal(false)}>Cancel</button>
                            <button type="button" class="btn btn-danger mx-2" onClick={()=>onSubmit()}>Delete</button>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default DeleteMember