import React from 'react'

const StepsProgressBar = ({ currentSubStep, totalSubSteps }) => {
    const subStepPercentage = (currentSubStep / totalSubSteps) * 100;    
    return(
        <>
         <div className="vital_stepone_forth">
         {totalSubSteps > 1 && (
                <div className="steps-progress">
                    <h5 className="sap-card-title">{currentSubStep}/{totalSubSteps}</h5>
                    <div className="progress">
                    <div
                            className="progress-bar sub-step-progress-bar"
                            role="progressbar"
                            style={{ width: `${subStepPercentage}%` }}
                            aria-valuenow={subStepPercentage}
                            aria-valuemin="0"
                            aria-valuemax="100"
                        >
                            {Math.round(subStepPercentage)}%
                        </div>
                    </div>

                </div>
         )}
            </div>
        </>
    )
}

export default StepsProgressBar