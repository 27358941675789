import React, { useEffect, useState } from 'react'
import { useForm, useFieldArray } from 'react-hook-form';
import { useSelector } from 'react-redux';
import Footer from '../../Footer'

const Treatments = ({ onHandleBackStep, onSubmit }) => {

  const allMedicalHistory = useSelector((state) => state?.AuthMember?.allMedicalHistory?.medicalHistoryTreatment);
  // Initialize the form with useForm hook
  const { control, handleSubmit, register, setValue, formState: { errors } } = useForm({
    defaultValues: {
      historyTreatment: [{ date: "", treatment_diagnosis: "", hospital_location: "", physician_therapist: "" }],
    }
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'historyTreatment'
  });

  useEffect(() => {
    const lengthDifference = allMedicalHistory?.length - fields.length;
        if (lengthDifference > 0) {
            for (let i = 0; i < lengthDifference; i++) {
                append({});
            }
        }
        allMedicalHistory?.forEach((item, index) => {
            setValue(`historyTreatment.${index}.date`, item.date || '');
            setValue(`historyTreatment.${index}.treatment_diagnosis`, item.treatment_diagnosis || '');
            setValue(`historyTreatment.${index}.hospital_location`, item.hospital_location || '');
            setValue(`historyTreatment.${index}.physician_therapist`, item.physician_therapist || '');
        });
  }, [allMedicalHistory])

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="row">
      <div className="col-12">
        <div className="alert alert-light sap-alert-heading">
          <div className="hd-title font-14">If applicable, list surgeries, hospitalizations & treatments below</div>
        </div>
      </div>

      {fields.map((member, index) => (
        <div key={member.id} className="row">
          {index > 0 && (
                <hr/>
            )}
          <div className="col-md-6 mb-3">
            <label for="" className="form-label">Date*</label>

            <input type="date" className="form-control" id="" placeholder="Enter Date" {...register(`historyTreatment.${index}.date`, { required: "Date is required", })} onKeyDown={(e) => e.preventDefault()} />
            {errors.historyTreatment && errors.historyTreatment[index]?.date && (
              <span className="error">{errors.historyTreatment[index].date.message}</span>
            )}
          </div>
          <div className="col-md-6 mb-3">
            <label for="" className="form-label">Treatment/Diagnosis*</label>
            <input
              type="input"
              className="form-control"
              placeholder="Enter Result"
              {...register(`historyTreatment.${index}.treatment_diagnosis`, { required: "Treatment/Diagnosis is required!" })}
              maxLength={50}
            />
            {errors.historyTreatment && errors.historyTreatment[index]?.treatment_diagnosis && (
              <span className="error">{errors.historyTreatment[index].treatment_diagnosis.message}</span>
            )}
          </div>
          <div className="col-md-6 mb-3">
            <label for="" className="form-label">Hospital/Location*</label>
            <input
              type="input"
              className="form-control"
              placeholder="Enter Result"
              {...register(`historyTreatment.${index}.hospital_location`, { required: "Hospital/Location is required!" })}
              maxLength={50}
            />
            {errors.historyTreatment && errors.historyTreatment[index]?.hospital_location && (
              <span className="error">{errors.historyTreatment[index].hospital_location.message}</span>
            )}
          </div>
          <div className="col-md-6 mb-3">
            <label for="" className="form-label">Physician/Therapist*</label>
            <input
              type="input"
              className="form-control"
              placeholder="Enter Result"
              {...register(`historyTreatment.${index}.physician_therapist`, { required: "Physician/Therapist is required!" })}
              maxLength={20}
            />
            {errors.historyTreatment && errors.historyTreatment[index]?.physician_therapist && (
              <span className="error">{errors.historyTreatment[index].physician_therapist.message}</span>
            )}
          </div>
          {index > 0 && (
            <div className="col-12 mb-3">
              <a
                href="#"
                className="stepone_remove"
                onClick={(e) => {
                  e.preventDefault();
                  remove(index);
                }}
              >
                Remove
              </a>
            </div>
          )}
        </div>
      ))}
      <div className="stepone_health_planbtn">
        <button
          type="button"
          className="font-14"
          onClick={() =>
            append({ id: Date.now() }) // Add a new member with a unique ID
          }
        >
          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.00016 1.33325V10.6666M1.3335 5.99992H10.6668" stroke="#667085" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
          </svg>
          Add another test
        </button>
      </div>
      <Footer onHandleBackStep={onHandleBackStep} />
    </form>
  )
}
export default Treatments
