import React from 'react';


export const AppointmentSvg = () => (
    <svg
        width={20}
        height={21}
        viewBox="0 0 20 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M6.12602 13C6.57006 14.7252 8.13616 16 10 16C11.8638 16 13.4299 14.7252 13.874 13M9.0177 1.764L2.23539 7.03912C1.78202 7.39175 1.55534 7.56806 1.39203 7.78886C1.24737 7.98444 1.1396 8.20478 1.07403 8.43905C1 8.70352 1 8.9907 1 9.56505V16.8C1 17.9201 1 18.4801 1.21799 18.908C1.40973 19.2843 1.71569 19.5903 2.09202 19.782C2.51984 20 3.07989 20 4.2 20H15.8C16.9201 20 17.4802 20 17.908 19.782C18.2843 19.5903 18.5903 19.2843 18.782 18.908C19 18.4801 19 17.9201 19 16.8V9.56505C19 8.9907 19 8.70352 18.926 8.43905C18.8604 8.20478 18.7526 7.98444 18.608 7.78886C18.4447 7.56806 18.218 7.39175 17.7646 7.03913L10.9823 1.764C10.631 1.49075 10.4553 1.35412 10.2613 1.3016C10.0902 1.25526 9.9098 1.25526 9.73865 1.3016C9.54468 1.35412 9.36902 1.49075 9.0177 1.764Z"
            stroke="#079455"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export const HeadingSvg = () => (
    <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="dc_dt"
    >
        <path
            d="M1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12Z"
            fill="white"
        />
        <path
            d="M1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12Z"
            stroke="#98A2B3"
            strokeWidth={2}
        />
        <path
            d="M17.3332 8L9.99984 15.3333L6.6665 12"
            stroke="#98A2B3"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export const PluseSvg = () => (
    <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d_8994_25122)">
            <path
                d="M2.5 11C2.5 5.75329 6.75329 1.5 12 1.5H40C45.2467 1.5 49.5 5.75329 49.5 11V39C49.5 44.2467 45.2467 48.5 40 48.5H12C6.75329 48.5 2.5 44.2467 2.5 39V11Z"
                stroke="#EAECF0" shape-rendering="crispEdges" />
            <path
                d="M29 17.6C29 17.0399 29 16.7599 28.891 16.546C28.7951 16.3578 28.6422 16.2049 28.454 16.109C28.2401 16 27.9601 16 27.4 16H24.6C24.0399 16 23.7599 16 23.546 16.109C23.3578 16.2049 23.2049 16.3578 23.109 16.546C23 16.7599 23 17.0399 23 17.6V20.4C23 20.9601 23 21.2401 22.891 21.454C22.7951 21.6422 22.6422 21.7951 22.454 21.891C22.2401 22 21.9601 22 21.4 22H18.6C18.0399 22 17.7599 22 17.546 22.109C17.3578 22.2049 17.2049 22.3578 17.109 22.546C17 22.7599 17 23.0399 17 23.6V26.4C17 26.9601 17 27.2401 17.109 27.454C17.2049 27.6422 17.3578 27.7951 17.546 27.891C17.7599 28 18.0399 28 18.6 28H21.4C21.9601 28 22.2401 28 22.454 28.109C22.6422 28.2049 22.7951 28.3578 22.891 28.546C23 28.7599 23 29.0399 23 29.6V32.4C23 32.9601 23 33.2401 23.109 33.454C23.2049 33.6422 23.3578 33.7951 23.546 33.891C23.7599 34 24.0399 34 24.6 34H27.4C27.9601 34 28.2401 34 28.454 33.891C28.6422 33.7951 28.7951 33.6422 28.891 33.454C29 33.2401 29 32.9601 29 32.4V29.6C29 29.0399 29 28.7599 29.109 28.546C29.2049 28.3578 29.3578 28.2049 29.546 28.109C29.7599 28 30.0399 28 30.6 28H33.4C33.9601 28 34.2401 28 34.454 27.891C34.6422 27.7951 34.7951 27.6422 34.891 27.454C35 27.2401 35 26.9601 35 26.4V23.6C35 23.0399 35 22.7599 34.891 22.546C34.7951 22.3578 34.6422 22.2049 34.454 22.109C34.2401 22 33.9601 22 33.4 22L30.6 22C30.0399 22 29.7599 22 29.546 21.891C29.3578 21.7951 29.2049 21.6422 29.109 21.454C29 21.2401 29 20.9601 29 20.4V17.6Z"
                stroke="#344054" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </g>
        <defs>
            <filter id="filter0_d_8994_25122" x="0" y="0" width="52" height="52"
                filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix in="SourceAlpha" type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                <feOffset dy="1" />
                <feGaussianBlur stdDeviation="1" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix type="matrix"
                    values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.05 0" />
                <feBlend mode="normal" in2="BackgroundImageFix"
                    result="effect1_dropShadow_8994_25122" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_8994_25122"
                    result="shape" />
            </filter>
        </defs>
    </svg>
);

export const HanburgerSvg = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M10.0003 10.8335C10.4606 10.8335 10.8337 10.4604 10.8337 10.0002C10.8337 9.53993 10.4606 9.16683 10.0003 9.16683C9.54009 9.16683 9.16699 9.53993 9.16699 10.0002C9.16699 10.4604 9.54009 10.8335 10.0003 10.8335Z"
            stroke="#98A2B3" stroke-width="1.66667"
            stroke-linecap="round" stroke-linejoin="round" />
        <path
            d="M10.0003 5.00016C10.4606 5.00016 10.8337 4.62707 10.8337 4.16683C10.8337 3.70659 10.4606 3.3335 10.0003 3.3335C9.54009 3.3335 9.16699 3.70659 9.16699 4.16683C9.16699 4.62707 9.54009 5.00016 10.0003 5.00016Z"
            stroke="#98A2B3" stroke-width="1.66667"
            stroke-linecap="round" stroke-linejoin="round" />
        <path
            d="M10.0003 16.6668C10.4606 16.6668 10.8337 16.2937 10.8337 15.8335C10.8337 15.3733 10.4606 15.0002 10.0003 15.0002C9.54009 15.0002 9.16699 15.3733 9.16699 15.8335C9.16699 16.2937 9.54009 16.6668 10.0003 16.6668Z"
            stroke="#98A2B3" stroke-width="1.66667"
            stroke-linecap="round" stroke-linejoin="round" />
    </svg>
)
export const DecreaseSvg = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M10.0003 4.1665V15.8332M10.0003 15.8332L15.8337 9.99984M10.0003 15.8332L4.16699 9.99984"
            stroke="#F04438" stroke-width="2" stroke-linecap="round"
            stroke-linejoin="round" />
    </svg>
)
export const IncreaseSvg = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M10.0003 15.8332V4.1665M10.0003 4.1665L4.16699 9.99984M10.0003 4.1665L15.8337 9.99984"
            stroke="#17B26A" stroke-width="2" stroke-linecap="round"
            stroke-linejoin="round" />
    </svg>
)

export const TasksSvg = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M6.77168 11.6667C7.14172 13.1043 8.4468 14.1667 10 14.1667C11.5532 14.1667 12.8583 13.1043 13.2283 11.6667M9.18141 2.30333L3.52949 6.69927C3.15168 6.99312 2.96278 7.14005 2.82669 7.32405C2.70614 7.48704 2.61633 7.67065 2.56169 7.86588C2.5 8.08627 2.5 8.32558 2.5 8.80421V14.8333C2.5 15.7667 2.5 16.2335 2.68166 16.59C2.84144 16.9036 3.09641 17.1585 3.41002 17.3183C3.76654 17.5 4.23325 17.5 5.16667 17.5H14.8333C15.7668 17.5 16.2335 17.5 16.59 17.3183C16.9036 17.1585 17.1586 16.9036 17.3183 16.59C17.5 16.2335 17.5 15.7667 17.5 14.8333V8.80421C17.5 8.32558 17.5 8.08627 17.4383 7.86588C17.3837 7.67065 17.2939 7.48704 17.1733 7.32405C17.0372 7.14005 16.8483 6.99312 16.4705 6.69927L10.8186 2.30333C10.5258 2.07562 10.3794 1.96177 10.2178 1.918C10.0752 1.87938 9.92484 1.87938 9.78221 1.918C9.62057 1.96177 9.47418 2.07562 9.18141 2.30333Z"
            stroke="#667085" stroke-width="2" stroke-linecap="round"
            stroke-linejoin="round" />
    </svg>
)
export const DownArrowSvg = () => (
    <svg width="16" height="13" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.42273 11.9998L1.07186 0.999785C0.88773 0.680919 0.996998 0.273187 1.31586 0.0891199C1.4172 0.0305862 1.5322 -0.000214577 1.6492 -0.000214577L14.3509 -0.000214577C14.7191 -0.000214577 15.0176 0.298252 15.0176 0.666452C15.0176 0.783519 14.9868 0.898453 14.9283 0.999785L8.5774 11.9998C8.39333 12.3187 7.9856 12.4279 7.66673 12.2438C7.5654 12.1853 7.4812 12.1012 7.42273 11.9998Z" fill="#667085" />
    </svg>
)
export const EditSvg = () => (
    <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.99998 15.6667H16.5M1.5 15.6667H2.89545C3.3031 15.6667 3.50693 15.6667 3.69874 15.6206C3.8688 15.5798 4.03138 15.5125 4.1805 15.4211C4.34869 15.318 4.49282 15.1739 4.78107 14.8856L15.25 4.41669C15.9404 3.72634 15.9404 2.60705 15.25 1.91669C14.5597 1.22634 13.4404 1.22634 12.75 1.91669L2.28105 12.3856C1.9928 12.6739 1.84867 12.818 1.7456 12.9862C1.65422 13.1353 1.58688 13.2979 1.54605 13.468C1.5 13.6598 1.5 13.8636 1.5 14.2713V15.6667Z" stroke="#344054" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
)
export const DeleteSvg = () => (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.5 1.5H11.5M1.5 4H16.5M14.8333 4L14.2489 12.7661C14.1612 14.0813 14.1174 14.7389 13.8333 15.2375C13.5833 15.6765 13.206 16.0294 12.7514 16.2497C12.235 16.5 11.5759 16.5 10.2578 16.5H7.74221C6.42409 16.5 5.76503 16.5 5.24861 16.2497C4.79396 16.0294 4.41674 15.6765 4.16665 15.2375C3.88259 14.7389 3.83875 14.0813 3.75107 12.7661L3.16667 4M7.33333 7.75V11.9167M10.6667 7.75V11.9167" stroke="#344054" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
)
export const Mark_as_completeSvg = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18.3333 9.23818V10.0049C18.3323 11.8019 17.7504 13.5504 16.6744 14.9897C15.5984 16.429 14.086 17.4819 12.3628 17.9914C10.6395 18.501 8.79768 18.4398 7.11202 17.817C5.42636 17.1942 3.98717 16.0433 3.00909 14.5357C2.03101 13.0282 1.56645 11.2449 1.68469 9.45178C1.80293 7.65866 2.49763 5.95179 3.66519 4.58575C4.83275 3.2197 6.41061 2.26767 8.16345 1.87164C9.91629 1.47561 11.7502 1.6568 13.3916 2.38818M18.3333 3.33341L9.99996 11.6751L7.49996 9.17508" stroke="white" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
)
export const Mark_cancle_subSvg = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.3335 4.83333H9.66683C12.4283 4.83333 14.6668 7.07191 14.6668 9.83333C14.6668 12.5948 12.4283 14.8333 9.66683 14.8333H1.3335M1.3335 4.83333L4.66683 1.5M1.3335 4.83333L4.66683 8.16667" stroke="#344054" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    
)