import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import PcspFooter from '../../PcspFooter'

const ACTIVITIES = [
  "Independent",
  "Minimal","Moderate","Maximum"
]
const ActivitiesOfDailyLiving = ({ onHandleBackStep, onSubmit }) => {
  const allDddPcsp = useSelector(state => state?.AuthMember?.allDddPcsp?.dailylivingActivities);
  // Initialize the form with useForm hook
  const { register, handleSubmit, setValue, formState: { errors } } = useForm();

  useEffect(() => {
      setValue('mobility', allDddPcsp?.mobility);
      setValue('transferring', allDddPcsp?.transferring);
      setValue('bathing', allDddPcsp?.bathing);
      setValue('dressing', allDddPcsp?.dressing);
      setValue('grooming', allDddPcsp?.grooming);
      setValue('eating', allDddPcsp?.eating);
      setValue('toileting', allDddPcsp?.toileting);
      setValue('content_bladder', allDddPcsp?.content_bladder);
      setValue('content_bowel', allDddPcsp?.content_bowel);
      setValue('behaviour', allDddPcsp?.behaviour);
      setValue('type_frequency', allDddPcsp?.type_frequency);
  }, [allDddPcsp])

  return (
    <div className="tab-pane fade  goal_tab show active" id="v-pills-act" role="tabpanel" aria-labelledby="v-pills-act-tab">
      <form onSubmit={handleSubmit(onSubmit)} className="row">
        <div className="col-md-6 mb-3">
          <label for="" className="form-label">Mobility*</label>
          <select
            className="form-select"
            aria-label="Default select example"
            {...register(`mobility`, { required: "Mobility is required!" })}>
            <option value="">Select</option>
            {ACTIVITIES.map((item, index)=>{
              return(
                <option value={index}>{item}</option>
              )
            })}
          </select>
          {errors.mobility && (
            <span className="error">{errors.mobility.message}</span>
          )}
        </div>
        <div className="col-md-6 mb-3">
          <label for="" className="form-label">Transferring*</label>
          <select
            className="form-select"
            aria-label="Default select example"
            {...register(`transferring`, { required: "Transferring is required!" })}>
            <option value="">Select</option>
            {ACTIVITIES.map((item, index)=>{
              return(
                <option value={index}>{item}</option>
              )
            })}
          </select>
          {errors.transferring && (
            <span className="error">{errors.transferring.message}</span>
          )}
        </div>
        <div className="col-md-6 mb-3">
          <label for="" className="form-label">Bathing*</label>
          <select
            className="form-select"
            aria-label="Default select example"
            {...register(`bathing`, { required: "Bathing is required!" })}>
            <option value="">Select</option>
            {ACTIVITIES.map((item, index)=>{
              return(
                <option value={index}>{item}</option>
              )
            })}
          </select>
          {errors.bathing && (
            <span className="error">{errors.bathing.message}</span>
          )}
        </div>
        <div className="col-md-6 mb-3">
          <label for="" className="form-label">Dressing*</label>
          <select
            className="form-select"
            aria-label="Default select example"
            {...register(`dressing`, { required: "Dressing is required!" })}>
            <option value="">Select</option>
            {ACTIVITIES.map((item, index)=>{
              return(
                <option value={index}>{item}</option>
              )
            })}
          </select>
          {errors.dressing && (
            <span className="error">{errors.dressing.message}</span>
          )}
        </div>
        <div className="col-md-6 mb-3">
          <label for="" className="form-label">Grooming*</label>
          <select
            className="form-select"
            aria-label="Default select example"
            {...register(`grooming`, { required: "Grooming is required!" })}>
            <option value="">Select</option>
            {ACTIVITIES.map((item, index)=>{
              return(
                <option value={index}>{item}</option>
              )
            })}
          </select>
          {errors.grooming && (
            <span className="error">{errors.grooming.message}</span>
          )}
        </div>
        <div className="col-md-6 mb-3">
          <label for="" className="form-label">Eating*</label>
          <select
            className="form-select"
            aria-label="Default select example"
            {...register(`eating`, { required: "Eating is required!" })}>
            <option value="">Select</option>
            {ACTIVITIES.map((item, index)=>{
              return(
                <option value={index}>{item}</option>
              )
            })}
          </select>
          {errors.eating && (
            <span className="error">{errors.eating.message}</span>
          )}
        </div>
        <div className="col-md-6 mb-3">
          <label for="" className="form-label">Toileting*</label>
          <select
            className="form-select"
            aria-label="Default select example"
            {...register(`toileting`, { required: "Toileting is required!" })}>
            <option value="">Select</option>
            {ACTIVITIES.map((item, index)=>{
              return(
                <option value={index}>{item}</option>
              )
            })}
          </select>
          {errors.toileting && (
            <span className="error">{errors.toileting.message}</span>
          )}
        </div>
        <div className="col-md-6 mb-3">
          <label for="" className="form-label">Continent of bladder*</label>
          <select
            className="form-select"
            aria-label="Default select example"
            {...register(`content_bladder`, { required: "Continent of bladder is required!" })}>
           <option value="">Select</option>
            <option value="0">No</option>
            <option value="1">Partial</option>
            <option value="2">Yes</option>
          </select>
          {errors.content_bladder && (
            <span className="error">{errors.content_bladder.message}</span>
          )}
        </div>
        <div className="col-md-6 mb-3">
          <label for="" className="form-label">Continent of bowel*</label>
          <select
            className="form-select"
            aria-label="Default select example"
            {...register(`content_bowel`, { required: "Continent of bowel is required!" })}>
            <option value="">Select</option>
            <option value="0">No</option>
            <option value="1">Partial</option>
            <option value="2">Yes</option>
          </select>
          {errors.content_bowel && (
            <span className="error">{errors.content_bowel.message}</span>
          )}
        </div>
        <div className="col-md-6 mb-3">
          <label for="" className="form-label">Behaviors*</label>
          <select
            className="form-select"
            aria-label="Default select example"
            {...register(`behaviour`, { required: "Behaviors is required!" })}>
            <option value="">Select</option>
            <option value="0">No</option>
            <option value="1">Yes</option>
          </select>
          {errors.behaviour && (
            <span className="error">{errors.behaviour.message}</span>
          )}
        </div>
        <div className="col-12 mb-3">
          <label for="" className="form-label">Type/Frequency (Including interventions)*</label>
          <input
            type="input"
            className="form-control"
            placeholder="Enter Type/Frequency"
            {...register(`type_frequency`, { required: "Type/Frequency is required!" })}
            maxLength={20}
          />
          {errors?.type_frequency && (
            <span className="error">{errors.type_frequency.message}</span>
          )}
        </div>
        <PcspFooter onHandleBackStep={onHandleBackStep} />
      </form>
    </div>
  )
}

export default ActivitiesOfDailyLiving