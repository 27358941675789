import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { PlusSvg, EditButtonSvg } from '../../../Images/MarSvg';

const UrlBar = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const handleClick = (url) => {
        navigate(url)
    }
    return (
        <>
            <ol className="breadcrumb">
                <li className="breadcrumb-item"><PlusSvg /></li>
                <li className="breadcrumb-item active" onClick={() => handleClick('/auth/appointment')} >Appointment</li>
                
            </ol>
        </>
    )

}
export default UrlBar