import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Header from './Header'
import MarTableView from './MarTableView'
import ResultNotFound from '../MAR/ResultNotFound'
import { get_all_users_list_mar, get_all_member_mar_list, get_all_member_list_mar, get_all_master_medication_list, get_all_master_units_list, get_all_master_does_form_list, get_all_master_coding_system_list, get_all_master_route_list, get_all_master_type_list } from '../../../redux/services/AuthMar'

const initionalSearch = {
    approval_status: '',
    page: 1,
    listData: 10,
    searchBar: '',
    member_id: ''
}
const MAR = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const { id } = useParams();
    const totalPages = useSelector(state => state?.AuthMar?.AllMedicationsMember?.totalNoPages);
    const marlist = useSelector(state => state?.AuthMar?.AllMedicationsMember?.marlist);
    const [search, setSearch] = useState(initionalSearch);
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        dispatch(get_all_users_list_mar())
        dispatch(get_all_member_list_mar())
        dispatch(get_all_master_medication_list())
        dispatch(get_all_master_units_list())
        dispatch(get_all_master_does_form_list())
        dispatch(get_all_master_coding_system_list())
        dispatch(get_all_master_route_list())
        dispatch(get_all_master_type_list())
    }, [])

    useEffect(() => {
        if (id) {
            setSearch({
                ...search,
                member_id: id,
            });
        }
    }, [id])

    useEffect(() => {
        dispatch(get_all_member_mar_list(search))
    }, [search])



    const handleSearchBar = (e) => {
        setSearch({
            ...search,
            page: 1,
            searchBar: e.target.value,
        });
    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber)
        setSearch({
            ...search,
            page: pageNumber
        });
    };

    const handleRowClick = (id) => {
        navigate(`/auth/edit-mar/${id}`)
    }

    return (
        <>
            <Header handleSearchBar={handleSearchBar} search={search} setSearch={setSearch} />
            {marlist && marlist.length === 0 ?
                <ResultNotFound />
                :
                <MarTableView marList={marlist} member_id={id} currentPage={currentPage} totalPages={totalPages} handlePageChange={handlePageChange} search={search} onRowClick={handleRowClick} fromMember={true} />
            }
        </>
    )
}
export default MAR