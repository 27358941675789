import React from "react";
import { useNavigate } from "react-router-dom";
import { DocumentsSvg } from '../../../Images/DocumentsSvg';

const UrlBar = () => {
    const navigate = useNavigate();

    const handleClick = (url) => {
        navigate(url)
    }
    return (
        <>
            <ol className="breadcrumb">
                <li className="breadcrumb-item" onClick={() => handleClick('/auth/documents')}><DocumentsSvg /></li>
                <li className="breadcrumb-item active" onClick={() => handleClick('/auth/documents')} >Documents</li>
                
            </ol>
        </>
    )

}
export default UrlBar