import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import AddRoles from './Add';
import EditRoles from './Edit';
import Avatar1 from '../../../../Images/Avatar-1.png';
import NoRoles from './NoRoles'
const Roles = () => {
    const [id, setId] = useState('');
    const AllRoleDetail = useSelector(state => state?.AuthSettings?.settingInfo?.rolesPermission);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [EditmodalIsOpen, setEditModalIsOpen] = useState(false);
    const [delteModal, setDeleteModal] = useState(false);
    return (
        <>
            {/* <div className="tab-pane fade" id="role" role="tabpanel" aria-labelledby="role-tab"> */}
            <div className="row" id="roles">
                <div className="col-md-12 login-btn pb-4">
                    <button type="button" className="btn btn-primary" onClick={() => setModalIsOpen(true)}>
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.99935 4.16699V15.8337M4.16602 10.0003H15.8327"
                                stroke="#FCFCFD" stroke-width="1.66667"
                                stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        New Role
                    </button>
                </div>
                {AllRoleDetail && AllRoleDetail.length === 0 ?
                <NoRoles/>
                :
                <>
                {AllRoleDetail?.map((elem) => {
                    return (
                        <div className="col-md-4 col-12">
                            <div className="role-listing" onClick={() => (setId(elem?.id), setEditModalIsOpen(true),setDeleteModal(false))} >
                                <a >
                                    <div className="font-20 py-3">{elem?.role_name}</div>
                                </a>
                                <p>{elem?.role_desciption}
                                </p>
                                <ul>
                                    {
                                        elem?.roleUser?.length > 0 ?
                                            elem?.roleUser && elem?.roleUser.slice(0, 5)?.map((ele) => {
                                                // console.log(,);
                                                return (
                                                    <>
                                                        <li><img src={ele?.user?.profile_img_url ? ele?.user?.profile_img_url : Avatar1} alt="avtar" /></li>


                                                    </>
                                                )
                                            }) : <li>No members assigned</li>
                                    }
                                    {
                                        elem?.roleUser.length>5?
                                        < li > <a href="#">+{elem?.roleUser.length - 5}</a></li>:''
                                     }
                            </ul>
                        </div>
                        </div>
            )
                })
                }
                </>
                }
        </div >
            <AddRoles modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen} />
            <EditRoles id={id} EditmodalIsOpen={EditmodalIsOpen} setEditModalIsOpen={setEditModalIsOpen} setDeleteModal={setDeleteModal} delteModal={delteModal}/>
        </>
    )
}

export default Roles;
