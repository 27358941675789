
import React, { useEffect } from "react";
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import Footer from '../../Footer'

const MedicalMonitoring = ({ onHandleBackStep, onSubmit }) => {
    const allIndividualizedHealth = useSelector(state => state?.AuthMember?.allIndividualizedHealth?.healthMedicalThirdStep);
    // Initialize the form with useForm hook
    const { register, handleSubmit, setValue, formState: { errors } } = useForm();

    useEffect(() => {
        setValue('labwork_bloodlevel_frequency', allIndividualizedHealth?.labwork_bloodlevel_frequency);
        setValue('bloodpressure_frequency_parm', allIndividualizedHealth?.bloodpressure_frequency_parm);
        setValue('bowel_moment', allIndividualizedHealth?.bowel_moment);
        setValue('other', allIndividualizedHealth?.other);

    }, [allIndividualizedHealth])
    return (
        <form onSubmit={handleSubmit(onSubmit)} className="row">
            <div className="col-12">
                <div className="alert alert-light sap-alert-heading">
                    <div className="hd-title font-24">Medical monitoring</div>
                </div>
            </div>
            <div className="col-12">
                <label for="" className="form-label">Lab work/Blood levels (Frquency)*</label>
                <div className="ddd_steptwo_para">
                    <select
                        className="form-select"
                        aria-label="Default select example"
                        {...register(`labwork_bloodlevel_frequency`, { required: "This is required!" })}>
                        <option value="">Select</option>
                        <option value="1">Yes</option>
                        <option value="0">No</option>
                    </select>
                    {errors.labwork_bloodlevel_frequency && (
                        <span className="error">{errors.labwork_bloodlevel_frequency.message}</span>
                    )}
                </div>
            </div>
            <div className="col-12">
                <label for="" className="form-label">Blood pressure (Frequency and parameters)*</label>
                <div className="ddd_steptwo_para">
                    <input
                        type="input"
                        className="form-control"
                        placeholder="Enter Blood pressure"
                        {...register(`bloodpressure_frequency_parm`, { required: "Blood pressure is required!" })}
                        maxLength={20}
                    />
                    {errors?.bloodpressure_frequency_parm && (
                        <span className="error">{errors.bloodpressure_frequency_parm.message}</span>
                    )}
                </div>
            </div>
            <div className="col-12">
                <label for="" className="form-label">Bowel movements*</label>
                <div className="ddd_steptwo_para">
                    <input
                        type="input"
                        className="form-control"
                        placeholder="Enter Bowel movements"
                        {...register(`bowel_moment`, { required: "Bowel movements is required!" })}
                        maxLength={20}
                    />
                    {errors?.bowel_moment && (
                        <span className="error">{errors.bowel_moment.message}</span>
                    )}
                </div>
            </div>
            <div className="col-12">
                <label for="" className="form-label">Other (Specify)*</label>
                <div className="ddd_steptwo_para">
                    <input
                        type="input"
                        className="form-control"
                        placeholder="Enter Other (Specify)"
                        {...register(`other`, { required: "Other (Specify) is required!" })}
                        maxLength={20}
                    />
                    {errors?.other && (
                        <span className="error">{errors.other.message}</span>
                    )}
                </div>
            </div>
            <Footer onHandleBackStep={onHandleBackStep} />
        </form>
    )

}
export default MedicalMonitoring