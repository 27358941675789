import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Get_all_general_consent_authorization, General_consent_authorization } from '../../../../redux/services/AuthMembers';
import { addSteps } from '../../../../redux/features/AuthMembersSlice';

import Header from './Header';
import SideBar from './SideBar';
import { AfterAddMember } from '../AfterAddMember'

// Import forms
import GeneralConsentAndAuthorizationForm from './Forms/GeneralConsentAndAuthorizationForm';

const GENERALCONSENTFORM = [
    'GeneralConsentAndAuthorizationForm',
];

const formSubSteps = {
    GeneralConsentAndAuthorizationForm: 1,
};

const formComponents = {
    GeneralConsentAndAuthorizationForm,
};

const GeneralConsentAndAuthorization = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [step, setStep] = useState(0);
    const [subStep, setSubStep] = useState(0);
    const [showPopUp, setShowPopUp] = useState({
        modal: false,
        message: '',
        id: ''
    })
    const totalSteps = GENERALCONSENTFORM.length;

    const getSubSteps = (formName) => formSubSteps[formName] || 1;
    const get_all_general_consent_authorization = () => {
        dispatch(Get_all_general_consent_authorization(id))
    }
    useEffect(() => {
        get_all_general_consent_authorization()
    }, [])



    const handleCrossClick = () => {
        navigate(`/auth/edit-member/${id}`);
    };

    const onSubmit = async (data) => {
        let payload = {
            profile_status: 0,
            step_no: step + 1,
            sub_step_no: subStep + 1,
            member_id: id,
            ...data,
        };
        try {
            let response;
            switch (GENERALCONSENTFORM[step]) {
                case 'GeneralConsentAndAuthorizationForm':
                    let formData = new FormData();

                    formData.append('profile_status', payload?.profile_status);
                    formData.append('step_no', payload?.step_no);
                    formData.append('sub_step_no', payload?.sub_step_no);
                    formData.append('member_id', payload?.member_id);
                    formData.append('agency_name', payload?.agency_name);
                    formData.append('medical_evaluation_assessments', payload?.medical_evaluation_assessments);
                    formData.append('necessary_emergency_medical', payload?.necessary_emergency_medical);
                    formData.append('routine_dental_care', payload?.routine_dental_care);
                    formData.append('sedation_mechanical_restraint', payload?.sedation_mechanical_restraint);
                    formData.append('medical_condition', payload?.medical_condition);
                    formData.append('signature', payload?.signature);
                    formData.append("protectiveDevice", JSON.stringify(payload?.protectiveDevice));
                    response = await dispatch(General_consent_authorization(formData));
                    if(response?.payload?.status){
                        setShowPopUp({ modal: true, message: response?.payload?.message, id: id })
                    }
                    break;
                // Add additional cases for other forms as needed
                default:
                    break;
            }
        } catch (error) {
            console.log('Error:', error);
        }
    };

    useEffect(() => {
        const data = {
            step: step,
            subStep: subStep,
            totalSteps: totalSteps,
            totalSubSteps: getSubSteps(GENERALCONSENTFORM[step])
        }
        dispatch(addSteps(data))
    }, [totalSteps, step, subStep])
    const renderForm = () => {
        const FormComponent = formComponents[GENERALCONSENTFORM[step]];
        return FormComponent ? (
            <FormComponent
                step={step}
                subStep={subStep}
                totalSubSteps={getSubSteps(GENERALCONSENTFORM[step])}
                // onHandleBackStep={handleBackStep}
                onSubmit={onSubmit}
            />
        ) : null;
    };

    return (
        <section className="step-home-sec container member_vitalinfo_stepone">
            <Header currentStep={step + 1} totalSteps={totalSteps} onClickCross={handleCrossClick} />
            <div className="pills-steps">
                <SideBar currentStep={step} GENERALCONSENTFORM={GENERALCONSENTFORM} />
                <div className="tab-content" id="v-pills-tabContent">
                    {renderForm()}
                </div>
            </div>
            <AfterAddMember setShowPopUp={setShowPopUp} showPopUp={showPopUp} type='GeneralConsentAndAuthorization' />
        </section>
    );
};

export default GeneralConsentAndAuthorization;
