import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import Footer from '../../Footer'

const Beverages = ({ onHandleBackStep, onSubmit }) => {

  const allIndividualizedHealth = useSelector(state => state?.AuthMember?.allIndividualizedHealth?.dietbeverage);
  // Initialize the form with useForm hook
  const { register, handleSubmit, setValue, formState: { errors } } = useForm();

  useEffect(() => {
    setValue('cup_glass', allIndividualizedHealth?.cup_glass);
    setValue('adaptive_container', allIndividualizedHealth?.adaptive_container);
    setValue('limited_assitance', allIndividualizedHealth?.limited_assitance);
    setValue('significant_assitance', allIndividualizedHealth?.significant_assitance);
    setValue('requesting_beverage', allIndividualizedHealth?.requesting_beverage);
    setValue('fluid_intake', allIndividualizedHealth?.fluid_intake);
    setValue('drinking_equipement', allIndividualizedHealth?.drinking_equipement);

  }, [allIndividualizedHealth])

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="row">
      <div className="col-12">
        <div className="alert alert-light sap-alert-heading">
          <div className="hd-title font-24">Beverages</div>
        </div>
      </div>
      <div className="col-md-6">
        <label for="" className="form-label">Independent with any cup/glass*</label>
        <div className="ddd_steptwo_para">
          <select
            className="form-select"
            aria-label="Default select example"
            {...register(`cup_glass`, { required: "Independent with any cup/glass is required!" })}>
            <option value="">Select</option>
            <option value="1">Yes</option>
            <option value="0">No</option>
          </select>
          {errors.cup_glass && (
            <span className="error">{errors.cup_glass.message}</span>
          )}
        </div>
      </div>
      <div className="col-md-6">
        <label for="" className="form-label">Independent with adaptive container*</label>
        <div className="ddd_steptwo_para">
          <select
            className="form-select"
            aria-label="Default select example"
            {...register(`adaptive_container`, { required: "Independent with adaptive container is required!" })}>
            <option value="">Select</option>
            <option value="1">Yes</option>
            <option value="0">No</option>
          </select>
          {errors.adaptive_container && (
            <span className="error">{errors.adaptive_container.message}</span>
          )}
        </div>
      </div>
      <div className="col-md-6">
        <label for="" className="form-label">Requires limited assistance*</label>
        <div className="ddd_steptwo_para">
          <select
            className="form-select"
            aria-label="Default select example"
            {...register(`limited_assitance`, { required: "Requires limited assistance is required!" })}>
            <option value="">Select</option>
            <option value="1">Yes</option>
            <option value="0">No</option>
          </select>
          {errors.limited_assitance && (
            <span className="error">{errors.limited_assitance.message}</span>
          )}
        </div>
      </div>
      <div className="col-md-6">
        <label for="" className="form-label">Requires significant assistance*</label>
        <div className="ddd_steptwo_para">
          <select
            className="form-select"
            aria-label="Default select example"
            {...register(`significant_assitance`, { required: "Requires significant assistance is required!" })}>
            <option value="">Select</option>
            <option value="1">Yes</option>
            <option value="0">No</option>
          </select>
          {errors.significant_assitance && (
            <span className="error">{errors.significant_assitance.message}</span>
          )}
        </div>
      </div>
      <div className="col-12">
        <label for="" className="form-label">Independent in obtaining or requesting beverage*</label>
        <div className="ddd_steptwo_para">
          <input
            type="input"
            className="form-control"
            placeholder="Enter Independent in obtaining"
            {...register(`requesting_beverage`, { required: "Independent in obtaining is required!" })}
            maxLength={20}
          />
          {errors?.requesting_beverage && (
            <span className="error">{errors.requesting_beverage.message}</span>
          )}
        </div>
      </div>
      <div className="col-12">
        <label for="" className="form-label">System for fluid intake (If applicable)*</label>
        <div className="ddd_steptwo_para">
          <textarea className="form-control" placeholder="Enter System for fluid intake" {...register("fluid_intake", { required: "true" })} maxLength={150} />
          {errors.fluid_intake && errors.fluid_intake.type === "required" && (<span className="error">This is required!</span>)}
        </div>
      </div>
      <div className="col-12">
        <label for="" className="form-label">Describe adaptive eating/drinking equipment (If applicable)*</label>
        <div className="ddd_steptwo_para">
          <textarea className="form-control" placeholder="Enter Describe adaptive eating/drinking equipment" {...register("drinking_equipement", { required: "true" })} maxLength={150} />
          {errors.drinking_equipement && errors.drinking_equipement.type === "required" && (<span className="error">This is required!</span>)}
        </div>
      </div>
      <Footer onHandleBackStep={onHandleBackStep} />
    </form>
  )
}
export default Beverages