import React, { useEffect, useState } from 'react'
import Modal from 'react-modal';
const customModalStyles = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 1000
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        border: 'none',
        maxWidth: '750px',
        // width: '100%',
        background: '#fff',
        borderRadius: '8px',
        padding: '20px',
        height: '34%',
        zIndex: '99',
        transition: 'all 1s ease-in-out'
    }
};
const TimeZoneModal = ({ modalIsOpen, setModalIsOpen, timezones, setSelectedTimezone }) => {
const [value, setValue] = useState('')
    const handleTimezone = (e) => {
        setValue(e.target.value)
    }
    const handleSave =() => {
        setSelectedTimezone(value)
        setModalIsOpen(false)
    }
    return (
        <Modal
            isOpen={modalIsOpen}
            onRequestClose={() => setModalIsOpen(false)}
            style={customModalStyles}
            contentLabel="Create Role Modal"
        >
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header close-deactivate">
                        <button type="button" className="btn-close" onClick={() => setModalIsOpen(false)}></button>
                    </div>
                    <div className="modal-body">
                        <div className="font-18 hd-title">Select Timezone*</div>
                        <select className="form-select" aria-label="Default select example" onChange={(e) => handleTimezone(e)}>
                            <option value="">Select timezone</option>
                            {timezones && timezones?.map((ele) => {
                                return (
                                    <option value={`${ele?.zoneName} (${ele?.gmtOffsetName})`}>{`${ele?.zoneName} (${ele?.gmtOffsetName})`}</option>
                                )
                            })}
                        </select>

                        <div className="pop-action-btn">
                            <button type="button" className="btn btn-light" onClick={() => handleSave()}>Save</button>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default TimeZoneModal