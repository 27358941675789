export const subsciptionPageValidationStep = (step, data) => {
    const emailValidation = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    const numberValidation = /^\d{10,15}$/;
    const cardValidataion = /^\d{16}$/;
    const validationErrors = {};
    switch (step) {
        case 1:
            if (!data.first_name) {
                validationErrors.first_name = 'Please enter  first name.';
            }
            if (!data.middle_name) {
                validationErrors.middle_name = 'Please enter  middle name.';
            }
            if (!data.last_name) {
                validationErrors.last_name = 'Please enter  last name.';
            }
            if (!data.email_address) {
                validationErrors.email_address = 'Please enter  email.';
            }
            else if (!emailValidation.test(data.email_address)) {
                validationErrors.email_address = 'Invaild email address.';
            }
            if (!data.country) {
                validationErrors.country = 'Please select  country code.';
            }
            if (!data.phone) {
                validationErrors.phone = 'Please enter  number.';
            }
            else if (!numberValidation.test(data.phone)) {
                validationErrors.phone = 'Mobile number must be 10 to 15 digits.';
            }
            if (!data.organization_name) {
                validationErrors.organization_name = 'Please enter  organization name.';
            }
            if (!data.organization_country_id){
                validationErrors.organization_country_id = 'Please select  country.';
            }
            if (!data.organization_state_id){
                validationErrors.organization_state_id = 'Please select  state.';
            }
            if (!data.organization_city){
                validationErrors.organization_city = 'Please enter  city.';
            }
            if (!data.organization_address1){
                validationErrors.organization_address1 = 'Please enter  organization address.';
            }
            if (!data.zip_code){
                validationErrors.zip_code = 'Please enter  zip code.';
            }

            // if (!data.message) {
            //     validationErrors.message = 'Please enter  message.';
            // }
            break;
        case 2:
            // if (!data.billing_freq) {
            //     validationErrors.billing_freq = 'Please enter  billing frequency.';
            // }
            if (!data.card_number) {
                validationErrors.card_number = 'Please enter card number.';
            } 
            else if (!cardValidataion.test(data.card_number)) {
                validationErrors.card_number = 'Card number must be 16 digits.';
            }
            if (!data.expiry) {
                validationErrors.expiry = 'Please enter  expiry.';
            }
            if (!data.cvc) {
                validationErrors.cvc = 'Please enter  cvc.';
            }
            if (!data.address1) {
                validationErrors.address1 = 'Please enter  Address.';
            }
            if (!data.City) {
                validationErrors.City = 'Please enter  city.';
            }
            if (!data.State_id) {
                validationErrors.State_id = 'Please select  state.';
            }
            if (!data.country_id) {
                validationErrors.country_id = 'Please select  country.';
            }
            break;
        default:
            break;
    }
    return validationErrors;
}