import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Create_owner_account } from '../../../redux/services/owner-info';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { getOwnerInfo } from '../../../redux/services/owner-info';
import { getCountry, getState } from '../../../redux/services/common';
import { Send_Otp_Change_Number } from '../../../redux/services/VerifyOtp';
import { setChangedNumber } from '../../../redux/features/authSlice';
import defaultImg from "../../../Images/defaultImg.png";

const initionalData = {
    tab1: false,
    tab2: false,
    tab3: false,
    passwordShow: false,
    accepted: false,
    imageUrl: '',
    image: null,
}

const AccountCreation = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const password = useRef({});
    const inputFile = useRef(null);
    const { register, handleSubmit, getValues, setValue, watch, formState: { errors }, reset } = useForm();
    const ownerInfo = useSelector(state => state?.owner?.ownerInfo);
    const CountryData = useSelector(state => state?.common?.CountryData);
    const stateData = useSelector(state => state?.common?.StateData);
    const [file, setFile] = useState(null);
    const [state, setState] = useState('');
    const [showError, setShowError] = useState(false);
    const [countryId, setCountryID] = useState('');
    const [data, setData] = useState(initionalData);
    const [checkStepOne, setCheckStepOne] = useState(false)
    const [checkStepTwo, setCheckStepTwo] = useState(false)

    password.current = watch("password", "");

    useEffect(() => {
        dispatch(getOwnerInfo());
    }, [dispatch])

    useEffect(() => {
        setData({ imageUrl: ownerInfo?.profile_img_url })
        setValue('first_name', ownerInfo?.first_name);
        setValue('last_name', ownerInfo?.last_name);
        setValue('email_address', ownerInfo?.organization?.email_address);
        setValue('organization_name', ownerInfo?.organization?.organization_name);
        // setValue('organization_hq', ownerInfo?.organization?.organization_hq);
        setValue('website', ownerInfo?.organization?.website);
        setValue('zip_code', ownerInfo?.organization?.zip_code);
        setValue('phone', ownerInfo?.organization?.phone);
        setValue('City', ownerInfo?.organization?.City);
        setValue('country_id', ownerInfo?.organization?.country_id);
        setTimeout(() => {
            setValue('State_id', ownerInfo?.organization?.State_id);
        }, 1000)
        setValue('address1', ownerInfo?.organization?.address1);
        setValue('p_email', ownerInfo?.email_address);
        setValue('p_phone', ownerInfo?.phone);
        setCountryID(ownerInfo?.organization?.country_id)

    }, [ownerInfo])

    //country and state api
    useEffect(() => {
        dispatch(getCountry());
        if (ownerInfo?.organization?.country_id || countryId) {
            dispatch(getState(countryId));
        }
    }, [countryId])

    const errorFunction = () => {
        console.log('hereeeee');
        
        let errorOne = !errors?.organization_name && !errors?.address1 && !errors?.country_id && !errors?.State_id && !errors?.City && !errors?.zip_code && !errors?.email_address && !errors?.website && !errors?.phone
        let errorTwo =!errors?.first_name && !errors?.last_name && !errors?.p_email && !errors?.p_phone
        
        setCheckStepOne(!errorOne)
        setCheckStepTwo(!errorTwo)
    }
    useEffect(() => {
        errorFunction()
    }, [errors])

    const onButtonClick = () => {
        inputFile.current.click();
    };
    const handleFileUpload = e => {
        const { files } = e.target;
        if (files && files.length) {
            const selectedFile = files[0];
            setFile(selectedFile)
            setData({ ...data, imageUrl: URL.createObjectURL(selectedFile), image: selectedFile });
        }
    };
    const handleDelete = () => {
        setFile(null);
        data.imageUrl = ''
        if (inputFile.current) {
            inputFile.current.value = null;
        }
    };
    const onSubmit = async () => {
        const number = getValues('p_phone')
        const form = new FormData();
        if (!data?.accepted) {
            setShowError(!data?.accepted);
            // toast.error('You must agree to the Terms & Conditions.');
            return false;
        }
        form.append("profile_img_url", file ? file : (typeof data?.imageUrl !== 'object' ? data?.imageUrl : null));
        form.append("address1", getValues('address1'));
        form.append("City", getValues('City'));
        form.append("country_id", getValues('country_id'));
        form.append("email_address", getValues('email_address'));
        form.append("first_name", getValues('first_name'));
        form.append("last_name", getValues('last_name'));
        // form.append("organization_hq", getValues('organization_hq'));
        form.append("organization_name", getValues('organization_name'));
        // form.append("p_email", getValues('p_email'));
        // form.append("p_phone", getValues('p_phone'));
        // form.append("password", getValues('password'));
        form.append("phone", getValues('phone'));
        form.append("State_id", getValues('State_id'));
        form.append("website", getValues('website'));
        form.append("zip_code", getValues('zip_code'));
        try {
            if (number !== ownerInfo?.phone) {
                dispatch(setChangedNumber({ 'phone': number }))
                const sendOtp = await dispatch(Send_Otp_Change_Number({ 'phone': number }))
                navigate("/verify-phone");
            } else {
                const response = await dispatch(Create_owner_account(form));
                if (response?.payload?.status) {
                    localStorage.setItem('tabs', 'organization');
                    navigate("/auth/settings");
                }
            }
        } catch (error) {
            // Handle error if dispatch fails
            console.error("Error during signup:", error);
        }
    }
    const handleStateChange = (e) => {
        setState(e);
        setValue('City', '')
    }
    const handleCountryChange = (e) => {
        setCountryID(e.target.value);
        setValue('State_id', '');
        e.target.value ? setShowError(false) : setShowError(true);
        setValue('City', '');
        setState('')
    }
    const buymoreLicense = () => {
        window.localStorage.setItem('tabs', 'billings');
        navigate('/auth/settings');
    }
    const handleToggal = (step) => {
        if(step === 'stepOne'){
            setCheckStepOne(!checkStepOne)
        }else{
            setCheckStepTwo(!checkStepTwo)
        }

    }
    const handleButtonClick = () => {
        console.log('updateeeeee');  
        errorFunction()

    }

    const sortedCountryData = CountryData ? 
    [CountryData.find(elem => elem.iso2 === "US"), 
    ...CountryData.filter(elem => elem.iso2 !== "US")] 
    : [];

    return (
        <div className="owner-account-setup-page">
            <section className="oas-sec">
                <div className="container">
                    <div className="sap-card">
                        <div className="sap-card-dialog">
                            <form onSubmit={handleSubmit(onSubmit)} enctype="multipart/form-data">
                                <div className="sap-card-body">
                                    <div className="alert alert-light sap-alert-heading">
                                        <div className="hd-title font-30">Owner account setup</div>
                                        <div className="hd-subtitle font-16">The super-admin account is the first account and has access to control all things</div>
                                    </div>
                                    <div className="accordion accordion-flush sap-accor-form">
                                        <div class="accordion-item">
                                            <h2 className={`accordion-header ${!checkStepOne ? 'none' : ''}`} id="flush-headingOne">
                                                <button class={`accordion-button ${!checkStepOne ? 'collapsed' : ''}`} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded={`${checkStepOne ? 'true' : 'false'}`} aria-controls="flush-collapseOne" onClick={()=>handleToggal('stepOne')}>
                                                    <div className="saf-title-head">
                                                        <div className="saf-left">
                                                            <div className="saf-icon">
                                                                <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M0 8C0 3.58172 3.58172 0 8 0H36C40.4183 0 44 3.58172 44 8V36C44 40.4183 40.4183 44 36 44H8C3.58172 44 0 40.4183 0 36V8Z" fill="#EFF4FF" />
                                                                    <path d="M19.5 17H24.5M19.5 21H24.5M19.5 25H24.5M28 31V16.2C28 15.0799 28 14.5198 27.782 14.092C27.5903 13.7157 27.2843 13.4097 26.908 13.218C26.4802 13 25.9201 13 24.8 13H19.2C18.0799 13 17.5198 13 17.092 13.218C16.7157 13.4097 16.4097 13.7157 16.218 14.092C16 14.5198 16 15.0799 16 16.2V31M30 31H14" stroke="#2342BF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                                </svg>
                                                            </div>
                                                            <div className="font-18">Organizational Information</div>
                                                        </div>
                                                        <div className={`${!checkStepOne ? 'visible' : 'saf-right'}`}>
                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12Z" fill="#D3F8DF" />
                                                                <path d="M1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12Z" stroke="#099250" strokeWidth="2" />
                                                                <path d="M17.3333 8L9.99996 15.3333L6.66663 12" stroke="#099250" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                            </svg>
                                                        </div>
                                                    </div>
                                                </button>
                                            </h2>

                                            <div id="flush-collapseOne" class={`accordion-collapse collapse ${checkStepOne ? 'show' : ''}`} aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                                <div className="accordion-body">
                                                    <div className="saf-img-upload">
                                                        <div className="saf-img-block"><img height="100px" width="100px" src={data?.imageUrl ? data?.imageUrl : defaultImg} alt="defaultImg.png" /></div>
                                                        <input style={{ display: "none" }} ref={inputFile} onChange={handleFileUpload} type="file" accept="image/jpeg, image/png" />
                                                        <button type="button" onClick={onButtonClick} className="btn btn-light">Change logo</button>
                                                        {
                                                            data?.imageUrl ? <button type="button" onClick={() => handleDelete()} className="btn btn-light">Delete</button> : ''
                                                        }
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-6 mb-3">
                                                            <label htmlFor="" className="form-label">Organization name*</label>
                                                            <input type="input" className="form-control" id="" placeholder="Organization name"  {...register('organization_name', { required: "true" })} />
                                                            {errors.organization_name && errors.organization_name.type === "required" && <span className="error">Organization name is required!</span>}
                                                        </div>
                                                        <div className="col-md-6 mb-3">
                                                            <label htmlFor="" className="form-label">Address*</label>
                                                            <input type="input" className="form-control" id="" placeholder="Address" {...register('address1', { required: "true" })} />
                                                            {errors.address1 && errors.address1.type === "required" && <span className="error">Address is required</span>}
                                                        </div>
                                                        <div className="col-md-6 mb-3">
                                                            <label htmlFor="" className="form-label">Country*</label>
                                                            <select className="form-select" aria-label="Default select example" {...register('country_id', { required: 'true' })} onChange={(e) => (handleCountryChange(e))} >
                                                                <option value="">Select country</option>
                                                                {sortedCountryData && sortedCountryData?.map((elem) => {
                                                                    return <option value={elem?.id}>{elem?.country}</option>
                                                                })}
                                                            </select>
                                                            {showError ? errors.country_id && errors.country_id.type === "required" && <span className="error">Counrty is required</span> : ''}
                                                        </div>
                                                        <div className="col-md-6 mb-3">
                                                            <label htmlFor="" className="form-label">State*</label>
                                                            <select className="form-select" aria-label="Default select example" name='State_id' {...register('State_id', { required: 'true' })} onChange={(e) => (handleStateChange(e.target.value))}>
                                                                <option value="">Select state</option>
                                                                {stateData && stateData?.map((elem) => {
                                                                    return <option value={elem?.id}>{elem?.address_state}</option>
                                                                })}
                                                            </select>
                                                            {
                                                                state === '' ?
                                                                    errors.State_id && errors.State_id.type === "required" && <span className="error">State is required!</span> : ''
                                                            }
                                                        </div>
                                                        <div className="col-md-6 mb-3">
                                                            <label htmlFor="" className="form-label">City*</label>
                                                            <input type="input" className="form-control" id="" placeholder="City"  {...register('City', { required: 'true' })} />
                                                            {errors.City && errors.City.type === "required" && <span className="error">City is required</span>}
                                                        </div>
                                                        <div className="col-md-6 mb-3">
                                                            <label htmlFor="" className="form-label">Zip code*</label>
                                                            <input type="input" className="form-control" id="" placeholder="Zip code" onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault(); } }} maxLength={5}  {...register('zip_code', {
                                                                required: 'true',
                                                                pattern: {
                                                                    value: /^\d{5}$/,
                                                                    message: 'ZIP code must be exactly 5 digits'
                                                                }
                                                            })} />
                                                            {errors.zip_code && errors.zip_code.type === "required" && <span className="error">ZIP code is required</span>}
                                                            {errors.zip_code && errors.zip_code.type === "pattern" && <span className="error">{errors?.zip_code?.message}</span>}
                                                        </div>

                                                        <div className="col-md-6 mb-3">
                                                            <label htmlFor="" className="form-label">Email address*</label>
                                                            <input type="email" className="form-control" id="" placeholder="Email address" {...register('email_address', {
                                                                required: 'true',
                                                                pattern: {
                                                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                                    message: 'Invalid email address'
                                                                }
                                                            })} />
                                                            {errors.email_address && errors.email_address.type === "required" && <span className="error">Email is required</span>}
                                                            {errors.email_address && errors.email_address.type === "pattern" && <span className="error">{errors?.email_address?.message}</span>}
                                                        </div>
                                                        <div className="col-md-6 mb-3">
                                                            <label htmlFor="" className="form-label">Website</label>
                                                            <input type="input" className="form-control" id="" placeholder="Website" {...register('website')} />
                                                            {/* {errors.website && errors.website.type === "required" && <span className="error">Website is required!</span>} */}
                                                            {errors.website && errors.website.type === "pattern" && <span className="error">{errors?.website?.message}</span>}
                                                        </div>
                                                        <div className="col-md-6 mb-3">
                                                            <label htmlFor="" className="form-label">Phone number*</label>
                                                            <input type="input" className="form-control" id="" placeholder="Phone number" maxLength={15} onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault() } }} {...register('phone')} />
                                                            {/* {errors.phone && errors.phone.type === "required" && <span className="error">Phone number is required</span>} */}
                                                            {errors.phone && errors.phone.type === "pattern" && <span className="error">{errors?.phone?.message}</span>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="accordion-item">
                                        <h2 className={`accordion-header ${!checkStepTwo ? 'none' : ''}`} id="flush-headingTwo">
                                        <button class={`accordion-button ${!checkStepTwo ? 'collapsed' : ''}`} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded={`${checkStepTwo ? 'true' : 'false'}`} aria-controls="flush-collapseTwo" onClick={()=>handleToggal('stepTwo')}>
                                                    <div className="saf-title-head">
                                                        <div className="saf-left">
                                                            <div className="saf-icon">
                                                                <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M0 8C0 3.58172 3.58172 0 8 0H36C40.4183 0 44 3.58172 44 8V36C44 40.4183 40.4183 44 36 44H8C3.58172 44 0 40.4183 0 36V8Z" fill="#EFF4FF" />
                                                                    <path d="M14 31.8174C14.6026 32 15.4165 32 16.8 32H27.2C28.5835 32 29.3974 32 30 31.8174M14 31.8174C13.8708 31.7783 13.7513 31.7308 13.638 31.673C13.0735 31.3854 12.6146 30.9265 12.327 30.362C12 29.7202 12 28.8802 12 27.2V16.8C12 15.1198 12 14.2798 12.327 13.638C12.6146 13.0735 13.0735 12.6146 13.638 12.327C14.2798 12 15.1198 12 16.8 12H27.2C28.8802 12 29.7202 12 30.362 12.327C30.9265 12.6146 31.3854 13.0735 31.673 13.638C32 14.2798 32 15.1198 32 16.8V27.2C32 28.8802 32 29.7202 31.673 30.362C31.3854 30.9265 30.9265 31.3854 30.362 31.673C30.2487 31.7308 30.1292 31.7783 30 31.8174M14 31.8174C14.0004 31.0081 14.0052 30.5799 14.0769 30.2196C14.3925 28.6329 15.6329 27.3925 17.2196 27.0769C17.606 27 18.0707 27 19 27H25C25.9293 27 26.394 27 26.7804 27.0769C28.3671 27.3925 29.6075 28.6329 29.9231 30.2196C29.9948 30.5799 29.9996 31.0081 30 31.8174M26 19.5C26 21.7091 24.2091 23.5 22 23.5C19.7909 23.5 18 21.7091 18 19.5C18 17.2909 19.7909 15.5 22 15.5C24.2091 15.5 26 17.2909 26 19.5Z" stroke="#2342BF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                                </svg>
                                                            </div>
                                                            <div className="font-18">Owner Information</div>
                                                        </div>
                                                        <div className={`${!checkStepTwo ? 'visible' : 'saf-right'}`}>
                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12Z" fill="#D3F8DF" />
                                                                <path d="M1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12Z" stroke="#099250" strokeWidth="2" />
                                                                <path d="M17.3333 8L9.99996 15.3333L6.66663 12" stroke="#099250" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                            </svg>
                                                        </div>
                                                    </div>
                                                </button>
                                            </h2>
                                            <div id="flush-collapseTwo" class={`accordion-collapse collapse ${checkStepTwo ? 'show' : ''}`} aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                                                <div className="accordion-body">
                                                    <div className="row">
                                                        <div className="col-md-6 mb-3">
                                                            <label htmlFor="" className="form-label">First name*</label>
                                                            <input type="input" className="form-control" id="" placeholder="First name" {...register('first_name', { required: "true" })} />
                                                            {errors.first_name && errors.first_name.type === "required" && <span className="error">First name is required!</span>}
                                                        </div>
                                                        <div className="col-md-6 mb-3">
                                                            <label htmlFor="" className="form-label">Last name*</label>
                                                            <input type="input" className="form-control" id="" placeholder="Last name" {...register('last_name', { required: "true" })} />
                                                            {errors.last_name && errors.last_name.type === "required" && <span className="error">Last name is required!</span>}
                                                        </div>
                                                        <div className="col-md-6 mb-3">
                                                            <label htmlFor="" className="form-label">Email*</label>
                                                            <input type="email" className="form-control" id="" placeholder="Email"  {...register('p_email', {
                                                                required: 'true',
                                                                pattern: {
                                                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                                    message: 'Invalid personal email address'
                                                                }
                                                            })} />
                                                            {errors.p_email && errors.p_email.type === "required" && <span className="error">Personal Email is required</span>}
                                                            {errors.p_email && errors.p_email.type === "pattern" && <span className="error">{errors?.p_email?.message}</span>}
                                                        </div>
                                                        <div className="col-md-6 mb-3">
                                                            <label htmlFor="" className="form-label">Phone number*</label>
                                                            <input type="input" className="form-control" id="" placeholder="Phone number" maxLength={14} {...register('p_phone', {
                                                                required: 'true',
                                                                pattern: {
                                                                    value: /^(\+?\d{2}\s?\d{10}|\d{10,15})$/,
                                                                    message: 'Personal Phone number must be 10 to 15 digits'
                                                                }
                                                            })} disabled />
                                                            {errors.p_phone && errors.p_phone.type === "required" && <span className="error">Personal Phone number is required</span>}
                                                            {errors.p_phone && errors.p_phone.type === "pattern" && <span className="error">{errors?.p_phone?.message}</span>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div class="accordion-item">
                                        <h2 class="accordion-header" id="flush-headingThree">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                                    <div className="saf-title-head">
                                                        <div className="saf-left">
                                                            <div className="saf-icon">
                                                                <svg width="44" height="44" viewBox="0 0 44 44" fill="none"
                                                                    xmlns="http://www.w3.org/2000/svg">
                                                                    <path
                                                                        d="M0 8C0 3.58172 3.58172 0 8 0H36C40.4183 0 44 3.58172 44 8V36C44 40.4183 40.4183 44 36 44H8C3.58172 44 0 40.4183 0 36V8Z"
                                                                        fill="#EFF4FF" />
                                                                    <path
                                                                        d="M14 31.8174C14.6026 32 15.4165 32 16.8 32H27.2C28.5835 32 29.3974 32 30 31.8174M14 31.8174C13.8708 31.7783 13.7513 31.7308 13.638 31.673C13.0735 31.3854 12.6146 30.9265 12.327 30.362C12 29.7202 12 28.8802 12 27.2V16.8C12 15.1198 12 14.2798 12.327 13.638C12.6146 13.0735 13.0735 12.6146 13.638 12.327C14.2798 12 15.1198 12 16.8 12H27.2C28.8802 12 29.7202 12 30.362 12.327C30.9265 12.6146 31.3854 13.0735 31.673 13.638C32 14.2798 32 15.1198 32 16.8V27.2C32 28.8802 32 29.7202 31.673 30.362C31.3854 30.9265 30.9265 31.3854 30.362 31.673C30.2487 31.7308 30.1292 31.7783 30 31.8174M14 31.8174C14.0004 31.0081 14.0052 30.5799 14.0769 30.2196C14.3925 28.6329 15.6329 27.3925 17.2196 27.0769C17.606 27 18.0707 27 19 27H25C25.9293 27 26.394 27 26.7804 27.0769C28.3671 27.3925 29.6075 28.6329 29.9231 30.2196C29.9948 30.5799 29.9996 31.0081 30 31.8174M26 19.5C26 21.7091 24.2091 23.5 22 23.5C19.7909 23.5 18 21.7091 18 19.5C18 17.2909 19.7909 15.5 22 15.5C24.2091 15.5 26 17.2909 26 19.5Z"
                                                                        stroke="#2342BF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                </svg>


                                                            </div>
                                                            <div className="font-18">License Information</div>
                                                        </div>
                                                    </div>
                                                </button>
                                            </h2>
                                            <div id="flush-collapseThree" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                                            <div className="accordion-body">
                                                    <div className="license-info-head">
                                                        <div className="lih-inner lih-second">
                                                            <div className="font-16">Assigned license</div>
                                                            <div className="lih-badges"><span className="badge text-bg-success">{ownerInfo?.assignedCount}</span></div>
                                                        </div>
                                                        <div className="lih-inner lih-third">
                                                            <div className="font-16">Remaining license</div>
                                                            <div className="lih-badges"><span className="badge text-bg-success">{ownerInfo?.remainingCount}</span></div>
                                                        </div>
                                                        <div className="lih-inner lih-forth">
                                                            <div className="font-16">Total licence</div>
                                                            <div className="lih-badges"><span className="badge text-bg-success">{ownerInfo?.organization?.subscription?.no_of_licence}</span></div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                    </div>
                                                    <a
                                                        to=''
                                                        onClick={() => buymoreLicense()}
                                                        className="buy-more-license-link"
                                                        rel="noopener noreferrer"
                                                    >
                                                        Need More License, Buy Now
                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M4 12H20M20 12L14 6M20 12L14 18" stroke="#2342BF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                        </svg>
                                                    </a>
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                                <div className="sap-card-footer">
                                    <div className="form-check">
                                        <input className="form-check-input" onChange={(e) => (setData({ accepted: e.target.checked }), setShowError(!e.target.checked))} type="checkbox" value="" id="flexCheckDefault" />
                                        <label className="form-check-label" htmlFor="flexCheckDefault">
                                            By checking this box you choose to accept our <Link to="/term-services" target="_blank" rel="noopener noreferrer">terms of services</Link> and <Link to="/privacy-policy" target="_blank" rel="noopener noreferrer">privacy policy</Link>
                                        </label>
                                        <p className='error-sec'>{showError ? 'You must agree to the Terms & Conditions.' : ''}</p>
                                    </div>
                                    <button type="submit" className="btn btn-primary" disabled={!data?.accepted} onClick={()=>handleButtonClick()}>Update Profile</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default AccountCreation
