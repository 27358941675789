import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Get_all_behaviour_modifying_medication, Behavior_modifying_medication_consent} from '../../../../redux/services/AuthMembers';
import { addSteps } from '../../../../redux/features/AuthMembersSlice';

import Header from './Header';
import SideBar from './SideBar';
import {AfterAddMember} from '../AfterAddMember'

// Import forms
import BehaviorModifyingMedicationConsentForm from './Forms/BehaviorModifyingMedicationConsentForm';

const BEHAVIORMODIFYINGFORM = [
    'BehaviorModifyingMedicationConsentForm',
];

const formSubSteps = {
    BehaviorModifyingMedicationConsentForm: 1,
};

const formComponents = {
    BehaviorModifyingMedicationConsentForm,
};

const BehaviorModifyingMedicationConsent = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [step, setStep] = useState(0);
    const [subStep, setSubStep] = useState(0);
    const [showPopUp, setShowPopUp] = useState({
        modal: false,
        message: '',
        id: ''
      })
    const totalSteps = BEHAVIORMODIFYINGFORM.length;

    const getSubSteps = (formName) => formSubSteps[formName] || 1;
    const stepForm = useSelector(state => state?.AuthMember?.allBehaviourModifyingMedication?.stepForm);

    const get_all_behaviour_modifying_medication = () => {
        dispatch(Get_all_behaviour_modifying_medication(id))
    }
    useEffect(() => {
        get_all_behaviour_modifying_medication()
    }, [])


    const handleCrossClick = () => {
        navigate(`/auth/edit-member/${id}`);
    };

    const onSubmit = async (data) => {
        let payload = {
            profile_status: 0,
            step_no: step + 1,
            sub_step_no: subStep + 1,
            member_id: id,
            ...data,
        };
        try {
            let response;
            switch (BEHAVIORMODIFYINGFORM[step]) {
                case 'BehaviorModifyingMedicationConsentForm':
                    response = await dispatch(Behavior_modifying_medication_consent(payload));
                    if(response?.payload?.status){
                        setShowPopUp({ modal: true, message: response?.payload?.message, id: id })
                    }
                    break;
                // Add additional cases for other forms as needed
                default:
                    break;
            }
        } catch (error) {
            console.log('Error:', error);
        }
    };
    
    useEffect(()=>{
        const data = {
            step: step,
            subStep: subStep,
            totalSteps: totalSteps,
            totalSubSteps: getSubSteps(BEHAVIORMODIFYINGFORM[step])
        }
        dispatch(addSteps(data))
    },[totalSteps,step, subStep])
    const renderForm = () => {
        const FormComponent = formComponents[BEHAVIORMODIFYINGFORM[step]];
        return FormComponent ? (
            <FormComponent
                step={step}
                subStep={subStep}
                totalSubSteps={getSubSteps(BEHAVIORMODIFYINGFORM[step])}
                // onHandleBackStep={handleBackStep}
                onSubmit={onSubmit}
            />
        ) : null;
    };

    return (
        <section className="step-home-sec container member_vitalinfo_stepone">
            <Header currentStep={step + 1} totalSteps={totalSteps} onClickCross={handleCrossClick} />
            <div className="pills-steps">
                <SideBar currentStep={step} BEHAVIORMODIFYINGFORM={BEHAVIORMODIFYINGFORM} />
                <div className="tab-content" id="v-pills-tabContent">
                    {renderForm()}
                </div>
            </div>
            <AfterAddMember setShowPopUp={setShowPopUp} showPopUp={showPopUp} type= 'BehaviorModifyingMedicationConsent'/>
        </section>
    );
};

export default BehaviorModifyingMedicationConsent;
