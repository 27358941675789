import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { customAlert } from "../../utils/alert";
import { HTTPURL } from "../../constant/Matcher";
//get country
export const getCountry = createAsyncThunk(
    "auth/country",
    async (_, thunkAPI) => {
        try {
            let config = {
                method: 'get',
                url: `${HTTPURL}api/country`,
            };
            const response = await axios.request(config)
            return response.data;
        } catch (error) {
            customAlert(error?.response?.data? error?.response?.data?.message : error?.message);
        }
    }
);
//get states
export const getState = createAsyncThunk(
    "auth/state",
    async (countryId,thunkAPI) => {
        try {
            let config = {
                method: 'get',
                url: `${HTTPURL}api/state?id=${countryId}`,
            };
            const response = await axios.request(config)
            return response.data;
        } catch (error) {
            customAlert(error?.response?.data? error?.response?.data?.message : error?.message);
        }
    }
);

// get all roles
export const get_all_roles = createAsyncThunk(
    "auth/all-organization-roles",
    async (_, thunkAPI) => {
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        try {
            let config = {
                method: 'get',
                url: `${HTTPURL}api/all-organization-roles`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
            };
            const response = await axios.request(config)
            return response.data;
        } catch (error) {
            customAlert(error?.response?.data? error?.response?.data?.message : error?.message);
        }
    }
);

//get language

export const get_languages = createAsyncThunk(
    "auth/languages",
    async (_, thunkAPI) => {
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        try {
            let config = {
                method: 'get',
                url: `${HTTPURL}api/languages`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
            };
            const response = await axios.request(config)
            return response.data;
        } catch (error) {
            customAlert(error?.response?.data? error?.response?.data?.message : error?.message);
        }
    }
);