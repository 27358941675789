import { createSlice } from "@reduxjs/toolkit";
import { Add_documents, get_loggedIn_user_member_list, get_all_documents_list, get_document_by_id, destroy_document, get_list_document_member } from "../services/AuthDocuments";

const initialState = {
    isLoading: false,
    error: null,
    memberList:[],
    AllDocumentsList:[],
    AllDocumentsListMember:[],
    DocumentsById:{},
}
const AuthDocuments = createSlice({
    name: 'AuthDocuments',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // ==========Add_documents ============== //
        builder.addCase(Add_documents.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(Add_documents.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
        });
        builder.addCase(Add_documents.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
        // ==========get_loggedIn_user_member_list============== //
        builder.addCase(get_loggedIn_user_member_list.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(get_loggedIn_user_member_list.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
            state.memberList = action?.payload?.data
        });
        builder.addCase(get_loggedIn_user_member_list.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
         // ==========get_all_documents_list============== //
         builder.addCase(get_all_documents_list.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(get_all_documents_list.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
            state.AllDocumentsList = action?.payload?.data
        });
        builder.addCase(get_all_documents_list.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
        // ==========get_document_by_id============== //
        builder.addCase(get_document_by_id.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(get_document_by_id.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
            state.DocumentsById = action?.payload?.data
        });
        builder.addCase(get_document_by_id.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
        // ==========destroy_document============== //
        builder.addCase(destroy_document.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(destroy_document.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
            // state.DocumentsById = action?.payload?.data
        });
        builder.addCase(destroy_document.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
        // ==========get_list_document_member============== //
        builder.addCase(get_list_document_member.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(get_list_document_member.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
            state.AllDocumentsListMember = action?.payload?.data
        });
        builder.addCase(get_list_document_member.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
    }
});
// Export the synchronous action
export default AuthDocuments?.reducer;