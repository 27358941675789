import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { getOwnerInfo } from '../../../redux/services/owner-info';
import { getCountry, getState, get_all_roles } from '../../../redux/services/common';
import defaultImg from "../../../Images/defaultImg.png";
import { Add_new_user } from '../../../redux/services/AuthUser';
import { setDataTableCheck } from '../../../redux/features/commonSlice';
import { get_all_users } from '../../../redux/services/AuthUser';
import { Activate_homes } from '../../../redux/services/AuthHomes';
import CountryCode from '../../../components/Common/CountryCode'
const AddUser = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const password = useRef({});
    const inputFile = useRef(null);

    const homeslist = useSelector(state => state?.AuthHomes?.ActivateHomes);
    const homeListData = homeslist?.map(item => ({ value: item?.id, label: item?.home_name }));
    const RoleData = useSelector(state => state?.common?.RoleData);
    const stateData = useSelector(state => state?.common?.StateData);
    const ownerInfo = useSelector(state => state?.owner?.ownerInfo);
    const CountryData = useSelector(state => state?.common?.CountryData);

    const { register, handleSubmit, getValues, setValue, setError, watch, formState: { errors }, reset, control } = useForm();
    const [file, setFile] = useState(null);
    const [stateError, setStateError] = useState('');
    const [countryCode, setCountryCode] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [selectedSubOptions, setSelectedSubOptions] = useState([]);
    const search = {
        role: '',
        status: '',
        page: 1,
        listData: 10,
        home: '',
        searchBar: ''
    };
    const [showErrorMsg, setShowErrorMsg] = useState({
        email: '',
        phone: '',
        username: '',
        home: false
    });
    const [data, setData] = useState({
        tab1: false,
        tab2: false,
        tab3: false,
        passwordShow: false,
        accepted: false,
        imageUrl: '',
        image: null,
    });
    password.current = watch("password", "");

    useEffect(() => {
        dispatch(getOwnerInfo());
        dispatch(Activate_homes());
    }, [dispatch]);

    const handleHomeChange = (selectedSubOptions) => {
        if (selectedSubOptions.length > 0) {
            setShowErrorMsg({ ...showErrorMsg, home: false });
        } else {
            setShowErrorMsg({ ...showErrorMsg, home: true });
        }
        setSelectedSubOptions(selectedSubOptions);
    };
    // Remove a selected option
    const handleRemoveOption = (optionToRemove) => {
        if (optionToRemove && optionToRemove.value) {
            setSelectedSubOptions(selectedSubOptions.filter(option => option.value !== optionToRemove.value));
        }
    };
    const onButtonClick = () => {
        inputFile.current.click();
    };
    const handleFileUpload = e => {
        const { files } = e.target;
        if (files && files.length) {
            const selectedFile = files[0];
            setFile(selectedFile)
            setData({ ...data, imageUrl: URL.createObjectURL(selectedFile), image: selectedFile });
        }
    };
    const handleDelete = () => {
        setFile(null);
        data.imageUrl = ''
        if (inputFile.current) {
            inputFile.current.value = null;
        }
    };
    const checkState = (e) => {
        setStateError(e.target.value)
        setValue('City', '')
    }
    const onSubmit = async () => {
        if (countryCode === '') {
            setError('country_code', { type: 'custom', message: 'country code is required' });
        } else {

        }
        const form = new FormData();
        form.append("profile_img_url", file ? file : (typeof data?.imageUrl !== 'object' ? data?.imageUrl : null));
        form.append("address1", getValues('address1'));
        form.append("City", getValues('City'));
        form.append("email_address", getValues('email_address'));
        form.append("first_name", getValues('first_name'));
        form.append("middle_name", getValues('middle_name'));
        form.append("last_name", getValues('last_name'));
        form.append("phone", getValues('phone'));
        form.append("State_id", getValues('State_id'));
        form.append("zip_code", getValues('zip_code'));
        form.append("username", getValues('username'));
        form.append("password", getValues('password'));
        form.append("is_active", getValues('is_active'));
        form.append("role_id", getValues('role_id'));
        form.append("country_code", getValues('country_code'));
        form.append("homes", JSON.stringify(selectedSubOptions.map(option => option.value)));
        try {
            if (selectedSubOptions.length == 0) {
                setShowErrorMsg({ home: true });
            } else {
                setShowErrorMsg({ home: false });
                const response = await dispatch(Add_new_user(form));

                if (response?.payload?.status === false) {
                    setShowErrorMsg({
                        email: response?.payload?.message?.email,
                        phone: response?.payload?.message?.phone,
                        username: response?.payload?.message?.username,
                    });
                } else {
                    dispatch(setDataTableCheck({ userData: 1 }));
                    dispatch(get_all_users(search));
                    navigate("/auth/users");
                }
            }
        } catch (error) {
            console.error("Error during add user:", error);
        }
    }
    //country and state api
    useEffect(() => {
        dispatch(get_all_roles());
        dispatch(getCountry());
        if (ownerInfo?.organization?.country_id) {
            dispatch(getState(ownerInfo?.organization?.country_id));
        }
    }, [ownerInfo?.organization?.country_id]);

    const inputChangeHandler = (e) => {
        const { name, value } = e.target;
        setValue(name, value)
        setCountryCode(value)
    }
    return (
        <div id="layoutSidenav_content">
            <main>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 col-lg-12 col-12">
                            <div className="right-main-sec">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <Link to="/auth/dashboard">
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M6.77168 11.6663C7.14172 13.104 8.4468 14.1663 10 14.1663C11.5532 14.1663 12.8583
                                                     13.104 13.2283 11.6663M9.18141 2.30297L3.52949 6.6989C3.15168 6.99275 2.96278 7.13968 2.82669
                                                      7.32368C2.70614 7.48667 2.61633 7.67029 2.56169 7.86551C2.5 8.0859 2.5 8.32521 2.5 8.80384V14.833C2.5
                                                       15.7664 2.5 16.2331 2.68166 16.5896C2.84144 16.9032 3.09641 17.1582 3.41002 17.318C3.76654 17.4996 4.23325
                                                        17.4996 5.16667 17.4996H14.8333C15.7668 17.4996 16.2335 17.4996 16.59 17.318C16.9036 17.1582 17.1586
                                                         16.9032 17.3183 16.5896C17.5 16.2331 17.5 15.7664 17.5 14.833V8.80384C17.5 8.32521 17.5 8.0859 17.4383
                                                          7.86551C17.3837 7.67029 17.2939 7.48667 17.1733 7.32368C17.0372 7.13968 16.8483 6.99275 16.4705 
                                                          6.69891L10.8186 2.30297C10.5258 2.07526 10.3794 1.9614 10.2178 1.91763C10.0752 1.87902 9.92484 
                                                          1.87902 9.78221 1.91763C9.62057 1.9614 9.47418 2.07526 9.18141 2.30297Z" stroke="#667085"
                                                    stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>
                                        </Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to="/auth/users">
                                            Users
                                        </Link>
                                    </li>
                                    <li className="breadcrumb-item active">Add User</li>
                                </ol>
                                <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
                                    <div className="sap-card-body">
                                        <div className="alert alert-light sap-alert-heading">
                                            <div className="hd-title font-30">Add new user</div>
                                            <div className="hd-subtitle font-16">User include other admins, care givers, and PCs</div>
                                        </div>
                                        <div className="accordion accordion-flush sap-accor-form">
                                            <div className="accordion-item">
                                                <h2 className={`accordion-header `}>
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                                        <div className="saf-title-head">
                                                            <div className="saf-left">
                                                                <div className="saf-icon">
                                                                    <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M0 8C0 3.58172 3.58172 0 8 0H36C40.4183 0 44 3.58172 44 8V36C44 40.4183 40.4183 44 36 44H8C3.58172 44 0 40.4183 0 36V8Z" fill="#EFF4FF" />
                                                                        <path d="M19.5 17H24.5M19.5 21H24.5M19.5 25H24.5M28 31V16.2C28 15.0799 28 14.5198 27.782 14.092C27.5903 13.7157 27.2843 13.4097 26.908 13.218C26.4802 13 25.9201 13 24.8 13H19.2C18.0799 13 17.5198 13 17.092 13.218C16.7157 13.4097 16.4097 13.7157 16.218 14.092C16 14.5198 16 15.0799 16 16.2V31M30 31H14" stroke="#2342BF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                                    </svg>
                                                                </div>
                                                                <div className="font-18">Personal information</div>
                                                            </div>
                                                            <div className='saf-right'>
                                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12Z" fill="#D3F8DF" />
                                                                    <path d="M1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12Z" stroke="#099250" strokeWidth="2" />
                                                                    <path d="M17.3333 8L9.99996 15.3333L6.66663 12" stroke="#099250" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                                </svg>
                                                            </div>
                                                        </div>
                                                    </button>
                                                </h2>
                                                <div id="flush-collapseOne" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        <div className="saf-img-upload">
                                                            <div className="saf-img-block"><img height="100px" width="100px" src={data?.imageUrl ? data?.imageUrl : defaultImg} alt="defaultImg.png" /></div>
                                                            <input style={{ display: "none" }} ref={inputFile} onChange={handleFileUpload} type="file" accept="image/jpeg, image/png" />
                                                            <button type="button" onClick={onButtonClick} className="btn btn-light">Change logo</button>
                                                            {
                                                                data?.imageUrl ? <button type="button" onClick={() => handleDelete()} className="btn btn-light">Delete</button> : ''
                                                            }
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-4 mb-3">
                                                                <label htmlFor="" className="form-label">First name*</label>
                                                                <input type="input" className="form-control" id="" placeholder="First name" {...register('first_name', { required: "true" })} />
                                                                {errors.first_name && errors.first_name.type === "required" && <span className="error">First name is required!</span>}
                                                            </div>
                                                            <div className="col-md-4 mb-3">
                                                                <label htmlFor="" className="form-label">Middle name*</label>
                                                                <input type="input" className="form-control" id="" placeholder="Middle name" {...register('middle_name', { required: "true" })} />
                                                                {errors.middle_name && errors.middle_name.type === "required" && <span className="error">Middle name is required!</span>}
                                                            </div>
                                                            <div className="col-md-4 mb-3">
                                                                <label htmlFor="" className="form-label">Last name*</label>
                                                                <input type="input" className="form-control" id="" placeholder="Last name" {...register('last_name', { required: "true" })} />
                                                                {errors.last_name && errors.last_name.type === "required" && <span className="error">Last name is required!</span>}
                                                            </div>
                                                            <div className="col-md-6 mb-3">
                                                                <label htmlFor="" className="form-label">Address</label>
                                                                <input type="input" className="form-control" id="" placeholder="Address" {...register('address1')} />
                                                                {/* {errors.address1 && errors.address1.type === "required" && <span className="error">Address is required</span>} */}
                                                            </div>
                                                            <div className="col-md-6 mb-3">
                                                                <label htmlFor="" className="form-label">State</label>
                                                                <select className="form-select" aria-label="Default select example" name='State_id' {...register('State_id')} onChange={(e) => checkState(e)}>
                                                                    <option value="">Select state</option>
                                                                    {stateData && stateData?.map((elem) => {
                                                                        return <option value={elem?.id}>{elem?.address_state}</option>
                                                                    })}
                                                                </select>
                                                                {/* {stateError == '' ? errors.State_id && errors.State_id.type === "required" && <span className="error">State is required</span> : ''} */}
                                                            </div>
                                                            <div className="col-md-6 mb-3">
                                                                <label htmlFor="" className="form-label">City</label>
                                                                <input type="input" className="form-control" id="" placeholder="City"  {...register('City')} />
                                                                {/* {errors.City && errors.City.type === "required" && <span className="error">City is required</span>} */}
                                                            </div>

                                                            <div className="col-md-6 mb-3">
                                                                <label htmlFor="" className="form-label">Zip code</label>
                                                                <input type="input" className="form-control" id="" placeholder="Zip code" onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault(); } }} maxLength={5}  {...register('zip_code', {
                                                                    pattern: {
                                                                        value: /^\d{5}$/,
                                                                        message: 'ZIP code must be exactly 5 digits'
                                                                    }
                                                                })} />
                                                                {/* {errors.zip_code && errors.zip_code.type === "required" && <span className="error">ZIP code is required</span>} */}
                                                                {errors.zip_code && errors.zip_code.type === "pattern" && <span className="error">{errors?.zip_code?.message}</span>}
                                                            </div>
                                                            <div className="col-md-6 mb-3">
                                                                <label for="" className="form-label">Country-code*</label>
                                                                <div className='flag_main'>
                                                                    <CountryCode CountryData={CountryData} country={countryCode} onChange={inputChangeHandler} name='country_code' />
                                                                    <input type="input" className="form-control" id="" maxLength={15} onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault() } }} placeholder=" Phone number" {...register("phone", { required: 'true', pattern: { value: /^\d{10,15}$/, message: "Phone number must be 10 to 15 digits" } })} />
                                                                </div>
                                                                {errors?.country_code && <p className="error">{errors?.country_code?.message}</p>}
                                                                {errors.phone && errors.phone.type === "required" && <span className="error">Phone number is required</span>}
                                                                {errors.phone && errors.phone.type === "pattern" && <span className="error">{errors?.phone?.message}</span>}
                                                                <p className='error'>{showErrorMsg.phone}</p>
                                                            </div>
                                                            <div className="col-md-6 mb-3">
                                                                <label htmlFor="" className="form-label">Email Address</label>
                                                                <input type="email" className="form-control" id="" placeholder="Email Address"  {...register('email_address', {
                                                                    pattern: {
                                                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                                        message: 'Invalid  email address'
                                                                    }
                                                                })} />
                                                                {/* {errors.email_address && errors.email_address.type === "required" && <span className="error">Email is required</span>} */}
                                                                {errors.email_address && errors.email_address.type === "pattern" && <span className="error">{errors?.email_address?.message}</span>}
                                                                {/* <p className='error'>{showErrorMsg.email}</p> */}
                                                            </div>
                                                            <div className="col-md-12 mb-3">
                                                                <label htmlFor="" className="form-label">Status*</label>
                                                                <select className="form-select" aria-label="Default select example" name='is_active' {...register('is_active', { required: 'true' })}>
                                                                    <option value="">Select status</option>
                                                                    <option value="1">Active</option>
                                                                    <option value="0">Inactive</option>
                                                                </select>
                                                                {errors.is_active && errors.is_active.type === "required" && <span className="error">Status is required</span>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h2 className={`accordion-header`}>
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                                        <div className="saf-title-head">
                                                            <div className="saf-left">
                                                                <div className="saf-icon">
                                                                    <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M0 8C0 3.58172 3.58172 0 8 0H36C40.4183 0 44 3.58172 44 8V36C44 40.4183 40.4183 44 36 44H8C3.58172 44 0 40.4183 0 36V8Z" fill="#EFF4FF" />
                                                                        <path d="M14 31.8174C14.6026 32 15.4165 32 16.8 32H27.2C28.5835 32 29.3974 32 30 31.8174M14 31.8174C13.8708 31.7783 13.7513 31.7308 13.638 31.673C13.0735 31.3854 12.6146 30.9265 12.327 30.362C12 29.7202 12 28.8802 12 27.2V16.8C12 15.1198 12 14.2798 12.327 13.638C12.6146 13.0735 13.0735 12.6146 13.638 12.327C14.2798 12 15.1198 12 16.8 12H27.2C28.8802 12 29.7202 12 30.362 12.327C30.9265 12.6146 31.3854 13.0735 31.673 13.638C32 14.2798 32 15.1198 32 16.8V27.2C32 28.8802 32 29.7202 31.673 30.362C31.3854 30.9265 30.9265 31.3854 30.362 31.673C30.2487 31.7308 30.1292 31.7783 30 31.8174M14 31.8174C14.0004 31.0081 14.0052 30.5799 14.0769 30.2196C14.3925 28.6329 15.6329 27.3925 17.2196 27.0769C17.606 27 18.0707 27 19 27H25C25.9293 27 26.394 27 26.7804 27.0769C28.3671 27.3925 29.6075 28.6329 29.9231 30.2196C29.9948 30.5799 29.9996 31.0081 30 31.8174M26 19.5C26 21.7091 24.2091 23.5 22 23.5C19.7909 23.5 18 21.7091 18 19.5C18 17.2909 19.7909 15.5 22 15.5C24.2091 15.5 26 17.2909 26 19.5Z" stroke="#2342BF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                                    </svg>
                                                                </div>
                                                                <div className="font-18">Security</div>
                                                            </div>
                                                            <div className='saf-right'>
                                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12Z" fill="#D3F8DF" />
                                                                    <path d="M1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12Z" stroke="#099250" strokeWidth="2" />
                                                                    <path d="M17.3333 8L9.99996 15.3333L6.66663 12" stroke="#099250" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                                </svg>
                                                            </div>
                                                        </div>
                                                    </button>
                                                </h2>
                                                <div id="flush-collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        <div className="row">
                                                            <div className="col-md-12 col-12 mb-3">
                                                                <label for="" className="form-label">Username*</label>
                                                                <input type="input" className="form-control" id=""
                                                                    placeholder="Username" {...register('username', { required: "true" })} autoComplete="off" />
                                                                {errors.username && errors.username.type === "required" && <span className="error">User name is required!</span>}
                                                                <p className='error'>{showErrorMsg.username}</p>
                                                            </div>
                                                            <div className="col-md-12 col-12 mb-3">
                                                                <label for="" className="form-label">Temp password*</label>
                                                                <div className='pass-sec'>
                                                                    <input type={showPassword ? 'text' : 'password'} className="form-control" id=""
                                                                        placeholder="Password" {...register('password', { required: "true" })} autoComplete="new-password" />
                                                                    <div onClick={() => showPassword ? setShowPassword(false) : setShowPassword(true)}>
                                                                        {showPassword ?
                                                                            <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M8.99922 1.5C6.96172 1.5 5.28672 2.425 4.00234 3.61562C2.79922 4.73438 1.96797 6.0625 1.54297 7C1.96797 7.9375 2.79922 9.26562 3.99922 10.3844C5.28672 11.575 6.96172 12.5 8.99922 12.5C11.0367 12.5 12.7117 11.575 13.9961 10.3844C15.1992 9.26562 16.0305 7.9375 16.4555 7C16.0305 6.0625 15.1992 4.73438 13.9992 3.61562C12.7117 2.425 11.0367 1.5 8.99922 1.5ZM2.98047 2.51875C4.45234 1.15 6.47422 0 8.99922 0C11.5242 0 13.5461 1.15 15.018 2.51875C16.4805 3.87812 17.4586 5.5 17.9242 6.61562C18.0273 6.8625 18.0273 7.1375 17.9242 7.38437C17.4586 8.5 16.4805 10.125 15.018 11.4812C13.5461 12.85 11.5242 14 8.99922 14C6.47422 14 4.45234 12.85 2.98047 11.4812C1.51797 10.125 0.539844 8.5 0.0773437 7.38437C-0.0257813 7.1375 -0.0257813 6.8625 0.0773437 6.61562C0.539844 5.5 1.51797 3.875 2.98047 2.51875ZM8.99922 9.5C10.3805 9.5 11.4992 8.38125 11.4992 7C11.4992 5.61875 10.3805 4.5 8.99922 4.5C8.97734 4.5 8.95859 4.5 8.93672 4.5C8.97734 4.65938 8.99922 4.82812 8.99922 5C8.99922 6.10313 8.10234 7 6.99922 7C6.82734 7 6.65859 6.97813 6.49922 6.9375C6.49922 6.95937 6.49922 6.97813 6.49922 7C6.49922 8.38125 7.61797 9.5 8.99922 9.5ZM8.99922 3C10.0601 3 11.0775 3.42143 11.8276 4.17157C12.5778 4.92172 12.9992 5.93913 12.9992 7C12.9992 8.06087 12.5778 9.07828 11.8276 9.82843C11.0775 10.5786 10.0601 11 8.99922 11C7.93835 11 6.92094 10.5786 6.17079 9.82843C5.42065 9.07828 4.99922 8.06087 4.99922 7C4.99922 5.93913 5.42065 4.92172 6.17079 4.17157C6.92094 3.42143 7.93835 3 8.99922 3Z" fill="#888888" />
                                                                            </svg> :
                                                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M8 11C6.35 11 5 9.65 5 8C5 6.35 6.35 5 8 5C9.65 5 11 6.35 11 8C11 9.65 9.65 11 8 11ZM8 6C6.9 6 6 6.9 6 8C6 9.1 6.9 10 8 10C9.1 10 10 9.1 10 8C10 6.9 9.1 6 8 6Z" fill="black" />
                                                                                <path d="M8 13C4.81 13 2.01 11.06 1.03 8.16C0.989837 8.05711 0.989837 7.94289 1.03 7.84C2.01 4.95 4.82 3 8 3C11.18 3 13.99 4.94 14.97 7.84C15.01 7.94 15.01 8.06 14.97 8.16C13.99 11.05 11.18 13 8 13ZM2.03 8C2.92 10.4 5.3 12 8 12C10.7 12 13.07 10.4 13.97 8C13.08 5.6 10.7 4 8 4C5.3 4 2.93 5.6 2.03 8Z" fill="black" />
                                                                                <path d="M14 14.5C13.9344 14.5008 13.8694 14.4879 13.8091 14.462C13.7489 14.4362 13.6946 14.398 13.65 14.35L1.65 2.34999C1.45 2.14999 1.45 1.83999 1.65 1.63999C1.85 1.43999 2.16 1.43999 2.36 1.63999L14.35 13.65C14.55 13.85 14.55 14.16 14.35 14.36C14.25 14.46 14.12 14.51 14 14.51V14.5Z" fill="black" />
                                                                            </svg>
                                                                        }
                                                                    </div>
                                                                </div>
                                                                {errors.password && errors.password.type === "required" && <span className="error">Password is required!</span>}
                                                            </div>
                                                            <div className="col-md-12 col-12 mb-3">
                                                                <label htmlFor="" className="form-label">Role*</label>
                                                                <select className="form-select" aria-label="Default select example" name='role_id' {...register('role_id', { required: 'true' })}>
                                                                    <option value="">Select role</option>
                                                                    {RoleData && RoleData?.map((elem) => {
                                                                        return <option value={elem?.id}>{elem?.role_name}</option>
                                                                    })}
                                                                </select>
                                                                {errors.role_id && errors.role_id.type === "required" && <span className="error">Role is required</span>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h2 className="accordion-header">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                        data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">

                                                        <div className="saf-title-head">
                                                            <div className="saf-left">
                                                                <div className="saf-icon">
                                                                    <svg width="44" height="44" viewBox="0 0 44 44" fill="none"
                                                                        xmlns="http://www.w3.org/2000/svg">
                                                                        <path
                                                                            d="M0 8C0 3.58172 3.58172 0 8 0H36C40.4183 0 44 3.58172 44 8V36C44 40.4183 40.4183 44 36 44H8C3.58172 44 0 40.4183 0 36V8Z"
                                                                            fill="#EFF4FF" />
                                                                        <path
                                                                            d="M14 31.8174C14.6026 32 15.4165 32 16.8 32H27.2C28.5835 32 29.3974 32 30 31.8174M14 31.8174C13.8708 31.7783 13.7513 31.7308 13.638 31.673C13.0735 31.3854 12.6146 30.9265 12.327 30.362C12 29.7202 12 28.8802 12 27.2V16.8C12 15.1198 12 14.2798 12.327 13.638C12.6146 13.0735 13.0735 12.6146 13.638 12.327C14.2798 12 15.1198 12 16.8 12H27.2C28.8802 12 29.7202 12 30.362 12.327C30.9265 12.6146 31.3854 13.0735 31.673 13.638C32 14.2798 32 15.1198 32 16.8V27.2C32 28.8802 32 29.7202 31.673 30.362C31.3854 30.9265 30.9265 31.3854 30.362 31.673C30.2487 31.7308 30.1292 31.7783 30 31.8174M14 31.8174C14.0004 31.0081 14.0052 30.5799 14.0769 30.2196C14.3925 28.6329 15.6329 27.3925 17.2196 27.0769C17.606 27 18.0707 27 19 27H25C25.9293 27 26.394 27 26.7804 27.0769C28.3671 27.3925 29.6075 28.6329 29.9231 30.2196C29.9948 30.5799 29.9996 31.0081 30 31.8174M26 19.5C26 21.7091 24.2091 23.5 22 23.5C19.7909 23.5 18 21.7091 18 19.5C18 17.2909 19.7909 15.5 22 15.5C24.2091 15.5 26 17.2909 26 19.5Z"
                                                                            stroke="#2342BF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                    </svg>


                                                                </div>
                                                                <div className="font-18">Group home assignment</div>
                                                            </div>
                                                        </div>
                                                    </button>
                                                </h2>
                                                <div id="flush-collapseFour" className="accordion-collapse collapse"
                                                    data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        <div className='row'>
                                                            <div className="col-md-12 mb-3">
                                                                <label htmlFor="" className="form-label">Group Home*</label>
                                                                <Controller
                                                                    name='home'
                                                                    control={control}
                                                                    defaultValue={[]}
                                                                    render={({ field }) => (
                                                                        <Select
                                                                            options={homeListData}
                                                                            className=""
                                                                            placeholder='Select home'
                                                                            isMulti
                                                                            {...field}
                                                                            value={selectedSubOptions}
                                                                            onChange={(option) => handleHomeChange(option)}
                                                                            closeMenuOnSelect={true}
                                                                            components={{
                                                                                MultiValue: () => null,
                                                                                MultiValueLabel: () => null,
                                                                                MultiValueRemove: () => null,
                                                                                IndicatorSeparator: () => null,
                                                                            }}
                                                                        />
                                                                    )}
                                                                />
                                                                {selectedSubOptions.length > 0 && (
                                                                    <div className="col-md-12">
                                                                        <div className="home-user-sec">
                                                                            {selectedSubOptions.map(option => (
                                                                                <button type="button" className="btn btn-light home-users" key={option.value}>
                                                                                    {option.label}
                                                                                    <span onClick={() => handleRemoveOption(option)}>
                                                                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" >
                                                                                            <path d="M15 5L5 15M5 5L15 15" stroke="#344054" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"></path>
                                                                                        </svg>
                                                                                    </span>
                                                                                </button>
                                                                            ))}
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                            {/* {errors.home_id && errors.home_id.type === "required" && <span className="error">Home is required</span>} */}
                                                            {showErrorMsg?.home === true && <span className="error">Home is required</span>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="sap-card-footer">
                                            <button type="submit" onClick={() => (selectedSubOptions?.length === 0 ? setShowErrorMsg({ home: true }) : setShowErrorMsg({ home: false }))} className="btn btn-primary">Add User</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>

    )
}

export default AddUser;
