import React, { useEffect, useState } from 'react'
import { useForm, useFieldArray } from 'react-hook-form';
import { useSelector } from 'react-redux';
import PcspFooter from '../../PcspFooter'

const MedicalSupplies = ({ onHandleBackStep, onSubmit, isGoBack }) => {

  const allDddPcsp = useSelector(state => state?.AuthMember?.allDddPcsp?.medicalsuppliesInfo);
  const UploadedPdfData = useSelector(state => state?.AuthMember?.UploadedPdfData);
  // Initialize the form with useForm hook
  const { control, handleSubmit, register, setValue, formState: { errors } } = useForm({
    defaultValues: {
      medicalSupplie: [{ medical_supplies: "", supplies_used_for: "", usage_frequency: "" }],
    }
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'medicalSupplie'
  });

  useEffect(() => {
    if (UploadedPdfData?.length > 0 && !isGoBack) {
      UploadedPdfData.forEach((item) => {
        switch (item.key) {
          case 'Height (inches):':
            setValue('height', item?.value);
            break
            case 'Estimated date recorded:':
              setValue('date_recorderd_height', item?.value);
              break
          case 'Weight:':
            setValue('weight', item?.value);
            break
            case 'Estimated date recorded:':
              setValue('date_recorderd_weight', item?.value);
              break
        }
      })
    } else {
    setValue('body_mass_index', allDddPcsp?.body_mass_index);
    setValue('body_mass_index_education', allDddPcsp?.body_mass_index_education);
    setValue('date_recorderd_height', allDddPcsp?.date_recorderd_height);
    setValue('date_recorderd_weight', allDddPcsp?.date_recorderd_weight);
    setValue('height', allDddPcsp?.height);
    setValue('weight', allDddPcsp?.weight);

    const lengthDifference = allDddPcsp?.memberdddpcspPrefStrengthsMedicalSuppliesData?.length - fields.length;
        if (lengthDifference > 0) {
            for (let i = 0; i < lengthDifference; i++) {
                append({});
            }
        }
        allDddPcsp?.memberdddpcspPrefStrengthsMedicalSuppliesData.forEach((item, index) => {
            setValue(`medicalSupplie.${index}.medical_supplies`, item.medical_supplies || '');
            setValue(`medicalSupplie.${index}.supplies_used_for`, item.supplies_used_for || '');
            setValue(`medicalSupplie.${index}.usage_frequency`, item.usage_frequency || '');
        });
      }
  }, [allDddPcsp, UploadedPdfData])

  return (
    <>
      <div className="member_vitalstep_one_form">
        <form onSubmit={handleSubmit(onSubmit)} className="row">
          <div className="alert alert-light sap-alert-heading">
            <div className="hd-title font-24">Medical supplies</div>
          </div>
          <div className="member_vital_basic_title">
            <h4>List all medical supplies</h4>
          </div>
          {fields.map((member, index) => (
            <div key={member.id} className="row">
              {index > 0 && <hr />}
              <div className="col-12 mb-3">
                <label for="" className="form-label">Medical supplies*</label>
                <input
                  type="input"
                  className="form-control"
                  placeholder="Enter Medical supplies"
                  {...register(`medicalSupplie.${index}.medical_supplies`, { required: "Medical supplies is required!" })}
                  maxLength={20}
                />
                {errors.medicalSupplie && errors.medicalSupplie[index]?.medical_supplies && (
                  <span className="error">{errors.medicalSupplie[index].medical_supplies.message}</span>
                )}
              </div>
              <div className="col-12 mb-3">
                <label for="" className="form-label">What are the supplies used for*</label>
                <input
                  type="input"
                  className="form-control"
                  placeholder="Enter supplies used for"
                  {...register(`medicalSupplie.${index}.supplies_used_for`, { required: "Supplies used for is required!" })}
                  maxLength={50}
                />
                {errors.medicalSupplie && errors.medicalSupplie[index]?.supplies_used_for && (
                  <span className="error">{errors.medicalSupplie[index].supplies_used_for.message}</span>
                )}
              </div>
              <div className="col-12 mb-3">
                <label for="" className="form-label">How often are they used*</label>
                <input
                  type="input"
                  className="form-control"
                  placeholder="Enter How often are they used"
                  {...register(`medicalSupplie.${index}.usage_frequency`, { required: "This is required!" })}
                  maxLength={20}
                />
                {errors.medicalSupplie && errors.medicalSupplie[index]?.usage_frequency && (
                  <span className="error">{errors.medicalSupplie[index].usage_frequency.message}</span>
                )}
              </div>
              {index > 0 && (
                <div className="col-12 mb-3">
                  <a
                    href="#"
                    className="stepone_remove"
                    onClick={(e) => {
                      e.preventDefault();
                      remove(index);
                    }}
                  >
                    Remove
                  </a>
                </div>
              )}
            </div>
          ))}
          <div className="stepone_health_planbtn">
            <button type="button" className="font-14" onClick={() => append({ id: Date.now() })}><svg width="12" height="12" viewBox="0 0 12 12" fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path d="M6.00016 1.33325V10.6666M1.3335 5.99992H10.6668" stroke="#667085" stroke-width="2"
                stroke-linecap="round" stroke-linejoin="round"></path>
            </svg>
              Add another health plan</button>
          </div>
          <div className="col-md-6 mb-3">
            <label for="" className="form-label">Height (inches):*</label>
            <input
              type="text"
              className="form-control"
              id="height"
              placeholder="Enter height"
              {...register("height", {
                required: "Height is required",
                pattern: {
                  value: /^\d{1,2}'\d{1,2}$/,
                  message: "Height must be in the format X'Y where X and Y are digits"
                }
              })}
              maxLength={3}
              onInput={(e) => e.target.value = e.target.value.replace(/[^\d']/g, '')}
            />
            {errors.height && errors.height.type === "required" && (<span className="error">Height is required</span>)}
            {errors.height && errors.height.type === "pattern" && (<span className="error">{errors.height.message}</span>)}
          </div>
          <div className="col-md-6 mb-3">
            <label for="" className="form-label">Estimated date recorded*</label>
            <input type="date" className="form-control" id="" placeholder="Exp. Jan 6, 2024" {...register(`date_recorderd_height`, { required: "true", })} onKeyDown={(e) => e.preventDefault()} />
            {errors?.date_recorderd_height && (
              <span className="error">{errors.date_recorderd_height.message}</span>
            )}
          </div>
          <div className="col-md-6 mb-3">
            <label for="" className="form-label">Weight*</label>
            <input
              type="text"
              className="form-control"
              id="weight"
              placeholder="Enter weight"
              {...register("weight", {
                required: "Weight is required",
                pattern: {
                  value: /^\d+$/
                }
              })}
              maxLength={3}
              onInput={(e) => e.target.value = e.target.value.replace(/\D/g, '')}
            />
            {errors.weight && errors.weight.type === "required" && (<span className="error">Weight is required</span>)}
            {errors.weight && errors.weight.type === "pattern" && (<span className="error">{errors.weight.message}</span>)}
          </div>
          <div className="col-md-6 mb-3">
            <label for="" className="form-label">Estimated date recorded*</label>
            <input type="date" className="form-control" id="" placeholder="Exp. Jan 6, 2024" {...register(`date_recorderd_weight`, { required: "true", })} onKeyDown={(e) => e.preventDefault()} />
            {errors?.date_recorderd_weight && (
              <span className="error">{errors.date_recorderd_weight.message}</span>
            )}
          </div>
          <div className="col-12 mb-3">
            <label for="" className="form-label">Body Mass Index (pediatric members)*</label>
            <input
              type="input"
              className="form-control"
              placeholder="Enter Body Mass Index"
              {...register(`body_mass_index`, { required: "Body Mass Index is required!" })}
              maxLength={20}
            />
            {errors?.body_mass_index && (
              <span className="error">{errors.body_mass_index.message}</span>
            )}
          </div>
          <div className="col-12 mb-3">
            <label for="" className="form-label">Document Body Mass Index education for pediatric members (if
              applicable)*</label>
            <input
              type="input"
              className="form-control"
              placeholder="Enter Document Body Mass Index"
              {...register(`body_mass_index_education`, { required: "Document Body Mass Index is required!" })}
              maxLength={20}
            />
            {errors?.body_mass_index_education && (
              <span className="error">{errors.body_mass_index_education.message}</span>
            )}
          </div>
          <PcspFooter onHandleBackStep={onHandleBackStep} />
        </form>
      </div>
    </>
  )
}
export default MedicalSupplies