import React from "react";
import MembersChart from './MembersChart'
import StaffChart from './StaffChart'
import HomeChart from './HomeChart'

const Charts = () => {

    return(
        <div className="row">
        <MembersChart/>
       <StaffChart/>
       <HomeChart/>
        
      </div>
    )
}
export default Charts